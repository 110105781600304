import axios from 'axios';

import {
  FETCH_COMMENT_API,
  CREATE_COMMENT_API,
  EDIT_COMMENT_API,
  DELETE_COMMENT_API,
} from '../constants/apiConstants';

import {
  FETCH_ALL_COMMENTS,
  CREATE_COMMENT,
  FETCH_REPLAY_COMMENTS,
  CREATE_COMMENT_REPLAY,
  EDIT_COMMENT,
  EDIT_COMMENT_REPLY,
  DELETE_COMMENT,
  DELETE_COMMENT_REPLY,
} from '../constants/actionTypes';

const fetchComments = async (config) => {
  let comments = [];
  await axios(config)
    .then((res) => {
      comments = res.data.data.result;
    })
    .catch((error) => {
      console.log('error: ', error);
    });
  return comments;
};

export const fetchAllComments = (listId, parent) => async (
  dispatch,
  getState
) => {
  let allComments = [];
  const data = {
    aid: getState().auth.aid,
    listId,
    parent,
  };
  const reqOptions = {
    method: 'POST',
    url: FETCH_COMMENT_API,
    data: data,
    headers: {
      'x-access-token': getState().auth.token,
    },
  };
  allComments = await fetchComments(reqOptions);
  if (parent) {
    dispatch({
      type: FETCH_REPLAY_COMMENTS,
      data: allComments,
      parent: parent,
    });
  } else {
    dispatch({
      type: FETCH_ALL_COMMENTS,
      payload: allComments,
    });
  }
};

export const createComment = (listId, text, parent) => async (
  dispatch,
  getState
) => {
  const data = {
    aid: getState().auth.aid,
    listId,
    text,
    parent,
  };
  const reqOptions = {
    method: 'POST',
    url: CREATE_COMMENT_API,
    data: data,
    headers: {
      'x-access-token': getState().auth.token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      if (parent) {
        dispatch({
          type: CREATE_COMMENT_REPLAY,
          parent: parent,
          data: res.data.data,
        });
      } else {
        dispatch({
          type: CREATE_COMMENT,
          payload: res.data.data,
        });
      }
    })
    .catch((error) => {
      console.log('error: ', error);
    });
};

export const editComment = (commentId, text, parent) => async (
  dispatch,
  getState
) => {
  const data = {
    aid: getState().auth.aid,
    text,
    commentId,
  };
  const reqOptions = {
    method: 'POST',
    url: EDIT_COMMENT_API,
    data: data,
    headers: {
      'x-access-token': getState().auth.token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      if (parent) {
        dispatch({
          type: EDIT_COMMENT_REPLY,
          id: res.data.data._id,
          data: res.data.data,
          parent: parent,
        });
      } else {
        dispatch({
          type: EDIT_COMMENT,
          id: res.data.data._id,
          data: res.data.data,
        });
      }
    })
    .catch((error) => {
      console.log('error: ', error);
    });
};

export const deleteComment = (commentId, parent) => async (
  dispatch,
  getState
) => {
  const data = {
    aid: getState().auth.aid,
    commentId,
  };
  const reqOptions = {
    method: 'POST',
    url: DELETE_COMMENT_API,
    data: data,
    headers: {
      'x-access-token': getState().auth.token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      if (parent) {
        dispatch({
          type: DELETE_COMMENT_REPLY,
          id: commentId,
          parent: parent,
        });
      } else {
        dispatch({
          type: DELETE_COMMENT,
          id: commentId,
        });
      }
    })
    .catch((error) => {
      console.log('error: ', error);
    });
};
