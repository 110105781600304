import React, {Component, Fragment} from 'react';
import CSVReader from 'react-csv-reader';

export default class CSVInput extends Component {

  handleError = err => {
    alert('Something wrong happened!');
  };

  render() {
    return (
      <Fragment>
        <CSVReader
          cssClass="csv-input-container"
          cssInputClass="csv-input"
          onFileLoaded={this.props.handleCSVOutput}
          onError={this.handleError}
        />
      </Fragment>
    );
  }
}
