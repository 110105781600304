import React, { Component, Fragment } from "react";
import AsyncSelect from "react-select/async-creatable";
import Axios from "axios";
import { GET_USERS_LIST_API } from "../../constants/apiConstants";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export default class CollabModal extends Component {
  state = {
    selectedOption: [],
    finalSelectedOpts: null,
    searchInput: "",
  };

  getUsersList = async (inputValue) => {
    if (!inputValue || inputValue.length < 4) {
      return [];
    } else {
      let token = this.props.token;
      let res = await Axios.get(
        `${GET_USERS_LIST_API}${this.state.searchInput}`,
        {
          headers: { "x-access-token": token },
        }
      );
      let opts = [];
      res.data.data.map((opt) => {
        opts.push({
          label: opt.user_name,
          value: opt.email,
        });
      });
      return opts;
    }
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.selectedOption) return;
    this.state.selectedOption.forEach((option) => {
      this.props.onCollabSubmit(option.value);
    });
    this.props.toggleCollabModal();
  };

  onSelectChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  onSearchInputChange = (event) => {
    if (event[0] === "@") {
      this.setState({ searchInput: event.slice(1) });
    } else {
      this.setState({ searchInput: event });
    }
  };

  render() {
    // const { isCollabModalOpen, toggleCollabModal } = this.props;
    return (
      <Fragment>
        <Modal isOpen={this.props.isCollabModalOpen} toggle={this.props.toggleCollabModal}>
          <ModalHeader toggle={this.props.toggleCollabModal}>Add Collaborator</ModalHeader>
          <ModalBody>
            <form onSubmit={this.onHandleSubmit.bind(this)}>
              <div className="form-group search-call">
                <AsyncSelect
                  cacheOptions
                  formatCreateLabel={(searchInput) => `Invite ${searchInput}`}
                  isMulti
                  value={this.state.selectedOption}
                  placeholder="Email Or Username"
                  onInputChange={this.onSearchInputChange}
                  menuIsOpen={this.state.searchInput.length > 0 ? true : false}
                  loadOptions={this.getUsersList}
                  ref="collabs"
                  onChange={(e) => {
                    this.onSelectChange(e);
                  }}
                />
              </div>
              <div className="form-group" style={{ paddingTop: "10px" }}>
                <button type="submit" className={"btn btn-primary full-width"}>
                  Submit
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}
