import React from "react";

export default class Team extends React.Component {
    render() {
        return (
            <div className="team-container container">
                <div className="team-header">
                    <h1>Meet Our Team</h1>
                </div>
                <div className="team-profiles">
                    <h3>Founder</h3>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="profile-card">
                                <div className="img-wrapper">
                                    <img src="/assets/profiles/vishal.jpg" alt='team member' />
                                </div>

                                <div className="member-title">Vishal Bhojwani</div>
                                <div className="member-role">Founder of Listiee</div>
                                <ul className="social-handles">
                                    <li><a href="https://twitter.com/vizackk" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="https://in.linkedin.com/in/vishalbhojwanivizack" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="https://angel.co/vizack" target="_blank" rel="noopener noreferrer"><i className="fab fa-angellist"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="team-profiles">
                    <h3>Past Warriors</h3>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="profile-card">
                                <div className="img-wrapper">
                                    <img src="/assets/profiles/nitesh.jpg" alt='team member' />
                                </div>
                                <div className="member-title">Nitesh Tosniwal</div>
                                <div className="member-role">Fullstack Developer</div>
                                <ul className="social-handles">
                                    <li><a href="https://twitter.com/_tosni_" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/sudonitesh/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="https://sudonitesh.netlify.com/" target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="profile-card">
                                <div className="img-wrapper">
                                    <img src="/assets/profiles/shubham.jpg" alt='team member' />
                                </div>
                                <div className="member-title">Shubham Prakash</div>
                                <div className="member-role">Fullstack Developer</div>
                                <ul className="social-handles">
                                    <li><a href="https://twitter.com/isuvm" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/ishubhamprakash" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="https://shubhamprakash.dev" target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="profile-card">
                                <div className="img-wrapper">
                                    <img src="/assets/profiles/ritik.jpeg" alt='team member' />
                                </div>
                                <div className="member-title">Ritik Srivastava</div>
                                <div className="member-role">Fullstack developer (Intern)</div>
                                <ul className="social-handles">
                                    <li><a href="https://twitter.com/ritiksr25" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="https://linkedin.com/in/ritiksr25" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="https://ritiksr25.github.io/" target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="team-profiles">
                    <h3>Active Warriors</h3>
                    <div className="row">
                    <div className="col-lg-4 col-md-6">
                            <div className="profile-card">
                                <div className="img-wrapper">
                                    <img src="/assets/profiles/ali.jpeg" alt='team member' />
                                </div>
                                <div className="member-title">Ali Zaidi</div>
                                <div className="member-role">Fullstack Developer (Intern)</div>
                                <ul className="social-handles">
                                    <li><a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/ali-zaidi-a3537b153/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="https://github.com/Enigmage" target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="profile-card">
                                <div className="img-wrapper">
                                    <img src="/assets/profiles/rushan.jpg" alt='team member' />
                                </div>
                                <div className="member-title">Rushan Khan</div>
                                <div className="member-role">Fullstack Developer (Intern)</div>
                                <ul className="social-handles">
                                    <li><a href="https://twitter.com/RushanKhan15" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/rushankhan/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="http://www.github.com/RushanKhan1" target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a></li>
                                </ul>
                            </div>
                        </div>
                      {/*   <div className="col-lg-4 col-md-6"> */}
                      {/*       <div className="profile-card"> */}
                      {/*           <div className="img-wrapper"> */}
                      {/*               <img src="/assets/profiles/debabrata.jpg" alt='team member' /> */}
                      {/*           </div> */}
                      {/*           <div className="member-title">Debabrata Basak</div> */}
                      {/*           <div className="member-role">Fullstack Developer (Intern)</div> */}
                      {/*           <ul className="social-handles"> */}
                      {/*               <li><a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li> */}
                      {/*               <li><a href="https://www.linkedin.com/in/debabrata-basak-3925841b6/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li> */}
                      {/*               <li><a href="https://coder-boy-debabrata.web.app/" target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a></li> */}
                      {/*           </ul> */}
                      {/*       </div> */}
                      {/*   </div> */}
                      <div className="col-lg-4 col-md-6">
                            <div className="profile-card">
                                <div className="img-wrapper">
                                    <img src="/assets/profiles/piyushNew.jpg" alt='team member' />
                                </div>
                                <div className="member-title">Piyush Soni</div>
                                <div className="member-role">Fullstack Developer (Intern)</div>
                                <ul className="social-handles">
                                    <li><a href="https:twitter.com/ViewsOfPiyush?s=09" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="https:www.linkedin.com/in/i-am-piyush/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="https:p1yu5h-50n1.github.io/" target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
