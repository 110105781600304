import {
  ASK_QUESTION,
  DELETE_LIST,
  DELETE_LIST_ITEM,
  FETCH_BOOKMARKS,
  FETCH_LIST,
  FETCH_DRAFT_LIST,
  FETCH_LIST_BY_ID,
  CLEAR_CURRENT_LIST_DATA_FROM_STORE,
  ADD_LIST_COLLAB,
  DELETE_LIST_COLLAB,
  RESPOND_LIST_COLLAB,
  SAVE_LIST,
  FETCH_REQUESTS,
  FETCH_REQUEST_REPLIES,
  REPLY_TO_ASK,
  SAVE_LIST_ITEM,
  UPVOTE_LIST_ITEM,
  FETCH_USERFOLLOWING,
  FOLLOW_USER,
  FETCH_PROFILE,
  FETCH_PEOPLE,
  FETCH_TAG,
  FETCH_BOOKMARKS_LIST,
  GET_PAGE_STATE,
  ADD_FOLDER,
  RENAME_FOLDER,
  PRIVATE_FOLDER,
  EDIT_FOLDER,
  ADD_BOARD,
  ADD_LISTS_TO_FOLDER,
  FETCH_FOLDER_ITEMS,
  FETCH_BOARDS,
  UPDATE_FOLDER,
  FETCH_FOLDER_ITEM_DETAILS,
  GET_PUBLIC_PROFILE,
  HANDLE_ERROR,
  EDIT_LIST,
  GET_NOTIFICATIONS,
  MARK_READ_NOTIF,
  MARK_ALLREAD_NOTIF,
  REPORT_LIST,
  SEARCH_LIST,
  CHANGE_PASSWORD,
  CHECK_PASSWORD,
  CHANGE_EMAIL,
  ADD_LIST_ITEM,
  REMOVE_LIST_ITEM,
  EDIT_PROFILE,
  FETCH_USERS_BOOKMARKED,
  BOOKMARK_USER,
  UNBOOKMARK_USER,
  FETCH_USER_LIST,
  FETCH_USER_BOOKMARKS,
  FETCH_USER_COLLAB_LISTS,
  FETCH_ALL_USER_BOOKMARKS,
  RENAME_BOARD,
  FETCH_STACK,
  DELETE_ASKED,
  REFRESH_DISCOVER_TRUE,
  REFRESH_DISCOVER_FALSE,
  SEND_VERIFY_EMAIL,
  MAKE_LIST_SUSPECTED,
  RESPOND_SUSPECT_LIST,
  UPDATE_SUSPECT_STATUS,
  UPDATE_RECOMMENDATION,
} from '../constants/actionTypes';
import { SEND_VERIFY_EMAIL_API } from '../constants/apiConstants';

const initialState = {
  folderItems: [],
  editedFolder: {},
  collab: null,
  deletedCollab: null,
  respondedCollab: null,
  folders: [],
  updatedFolder: {},
  editedFolderDetails: {},
  createdFolder: {},
  boards: [],
  createdBoard: {},
  folderItemDetails: {},
  lists: {},
  draftlists: {},
  allUserBookmarks:[],
  // myLists: [],
  searchedLists: [],
  fetchedList: {},
  bookmarks: [],
  createdList: [],
  followingList: [],
  following: false,
  usersBookmarked: [],
  deletedList: false,
  profile: {},
  people: [],
  tags: [],
  listItems: [],
  requests: [],
  replies: [],
  reply: [],
  pstate: {},
  publicProfile: {},
  notifications: [],
  markedNotifications: [],
  reportedListMessage: '',
  changePasswordMessage: '',
  checkPasswordMessage: '',
  sendVerifyEmailMessage: '',
  changeEmailMessage: '',
  addListItemMessage: '',
  addedListItem: '',
  removedListItem: false,
  editProfileMessage: '',
  fetchedStack: {},
  renamedBoard: '',
  deletedAsked: '',
  refreshDiscover: false,
  makeListSuspectedMessage: '',
  respondSuspectedListMessage: '',
  updateSuspectedListStatusMessage: '',
  recommendedList: [],
  contributors: []
};

const api = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LIST:
      return {
        ...state,
        createdList: action.payload,
      };
    case SAVE_LIST_ITEM:
      return state;
    case EDIT_LIST:
      return state;
    case ASK_QUESTION:
      return state;
    case FETCH_REQUESTS:
      return {
        ...state,
        requests: action.payload,
      };
    case FETCH_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_DRAFT_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_LIST_BY_ID:
      return {
        ...state,
        fetchedList: action.payload,
        listItems: action.payload.result[0].item,
      };
    case SEARCH_LIST:
      return {
        ...state,
        searchedLists: action.payload,
      };
    case CLEAR_CURRENT_LIST_DATA_FROM_STORE:
      return {
        ...state,
        fetchedList: {},
        listItems: [],
      };
    case ADD_LIST_COLLAB:
      return {
        ...state,
        collab: action.payload,
      };
    case DELETE_LIST_COLLAB:
      return {
        ...state,
        deletedCollab: action.payload,
      };
    case RESPOND_LIST_COLLAB:
      return {
        ...state,
        respondedCollab: action.payload,
      };
    case DELETE_LIST:
      return {
        ...state,
        deletedList: action.payload,
      };
    case DELETE_LIST_ITEM:
      return {
        ...state,
        removedListItem: action.payload,
      };
    case UPVOTE_LIST_ITEM:
      return state;
    case FETCH_BOOKMARKS:
      return {
        ...state,
        bookmarks: action.payload,
      };
    case FETCH_USERFOLLOWING:
      return {
        ...state,
        followingList: action.payload,
      };
    case FOLLOW_USER:
      return {
        ...state,
        following: action.payload,
      };
    case FETCH_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case FETCH_PEOPLE:
      return {
        ...state,
        people: action.payload,
      };
    case FETCH_TAG:
      return {
        ...state,
        tags: action.payload,
      };
    case FETCH_BOOKMARKS_LIST:
      return {
        ...state,
        listItems: action.payload,
      };
    case FETCH_ALL_USER_BOOKMARKS:
      return {
        ...state,
        allUserBookmarks: action.payload.allUserBookmarks
      }
    case FETCH_BOARDS:
      return {
        ...state,
        folders: action.payload.folderData,
        boards: action.payload.boardData,
      };
    case ADD_FOLDER:
      return {
        ...state,
        createdFolder: action.payload,
      };
    case RENAME_FOLDER:
      return {
        ...state,
        renamedFolder: action.payload,
      };
    case PRIVATE_FOLDER:
      return {
        ...state,
        privateFolder: action.payload,
      };
    case EDIT_FOLDER:
      return {
        ...state,
        editedFolderDetails: action.payload,
      };
    case ADD_BOARD:
      return {
        ...state,
        createdBoard: action.payload,
      };
    case ADD_LISTS_TO_FOLDER:
      return {
        ...state,
        editedFolder: action.payload,
      };
    case FETCH_FOLDER_ITEMS:
      return {
        ...state,
        folderItems: action.payload.folderItems,
      };
    case UPDATE_FOLDER:
      return {
        ...state,
        updatedFolder: action.payload,
      };
    case FETCH_FOLDER_ITEM_DETAILS:
      return {
        ...state,
        folderItemDetails: action.payload,
      };
    case FETCH_REQUEST_REPLIES:
      return {
        ...state,
        replies: action.payload,
      };
    case REPLY_TO_ASK:
      return {
        ...state,
        reply: action.payload,
      };
    case GET_PAGE_STATE:
      return {
        ...state,
        pstate: action.payload,
      };
    case GET_PUBLIC_PROFILE:
      return {
        ...state,
        publicProfile: action.payload,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case MARK_READ_NOTIF:
      return {
        ...state,
        markedNotifications: action.payload,
      };
    case REPORT_LIST:
      return {
        ...state,
        reportedListMessage: action.payload,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordMessage: action.payload,
      };
    case CHECK_PASSWORD:
      return {
        ...state,
        checkPasswordMessage: action.payload,
      };
    case SEND_VERIFY_EMAIL:
      return {
        ...state,
        sendVerifyEmailMessage: action.payload,
      };
    case CHANGE_EMAIL:
      return {
        ...state,
        changeEmailMessage: action.payload,
      };
    case FETCH_USERS_BOOKMARKED:
      return {
        ...state,
        usersBookmarked: action.payload,
      };
    case ADD_LIST_ITEM:
      return {
        ...state,
        addListItemMessage: action.payload.message,
        addedListItem: action.payload.addedListItem,
      };
    case REMOVE_LIST_ITEM:
      return {
        ...state,
        removeListItemMessage: action.payload,
      };
    case EDIT_PROFILE:
      return {
        ...state,
        editProfileMessage: action.payload,
      };
    case FETCH_USER_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_USER_COLLAB_LISTS:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_USER_BOOKMARKS:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_STACK:
      return {
        ...state,
        fetchedStack: action.payload,
      };
    case RENAME_BOARD:
      return {
        ...state,
        renamedBoard: action.payload,
      };
    case DELETE_ASKED:
      return {
        ...state,
        deletedAsked: action.payload,
      };
    case REFRESH_DISCOVER_TRUE:
      return {
        ...state,
        refreshDiscover: true,
      };
    case REFRESH_DISCOVER_FALSE:
      return {
        ...state,
        refreshDiscover: false,
      };
    case MAKE_LIST_SUSPECTED:
      return {
        ...state,
        makeListSuspectedMessage: action.payload,
      };
    case RESPOND_SUSPECT_LIST:
      return {
        ...state,
        respondSuspectedListMessage: action.payload,
      };
    case UPDATE_SUSPECT_STATUS:
      return {
        ...state,
        updateSuspectedListStatusMessage: action.payload,
      };
    case UPDATE_RECOMMENDATION:
      return {
        ...state,
        recommendedList: action.payload,
      };
    default:
      return state;
  }
};

export default api;
