// GET SOCKET CONSTANTS

export const LIST_COUNT = 'LIST_COUNT';
export const LIST_COUNT_BY_CATEGORY = 'LIST_COUNT_BY_CATEGORY';
export const REGISTERED_USERS_COUNT = 'REGISTERED_USERS_COUNT';
export const LIVE_USERS_COUNT = "LIVE_USERS_COUNT";
export const LIVE_USERS_ARRAY = "LIVE_USERS_ARRAY";
export const IP_ADDRESS = "IP_ADDRESS"
export const USERS_BY_COUNTRY = "USERS_BY_COUNTRY";
export const TOP_LISTS = "TOP_LISTS";
export const LIST_TRAFFIC_STATS = "LIST_TRAFFIC_STATS";
export const SHORT_URL_STATS = "SHORT_URL_STATS";

// SEND SOCKET CONSTANTS

export const USER_INFO = 'USER_INFO';
export const REQUEST = "REQUEST";
