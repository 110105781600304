// you placed the accordion under the guide content class which fixed the layout.

import React from "react";
import { Link, animateScroll as scroll } from 'react-scroll';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isMobileOnly, isTablet } from "mobile-device-detect";


export default class GuideLines extends React.Component {

    componentDidMount() {
        if (window.location.pathname == '/request') {
            this.stickToLeft();
        }
    };

    // moveCursor = () =>


    /* keeps the bar on left and over the footer */
    stickToLeft = () => {
        window.addEventListener('scroll', () => {
            const topOfFooter = document.querySelector('.footer').offsetTop;
            const scrollDistanceFromTopOfDoc = window.scrollY + 700;
            const scrollDistanceFromTopOfFooter = scrollDistanceFromTopOfDoc - topOfFooter;
            if (scrollDistanceFromTopOfDoc > topOfFooter) {
                const marginTop = 0 - scrollDistanceFromTopOfFooter;
                document.getElementById('sidebar').style.marginTop = `${marginTop}px`;
            } else {
                document.getElementById('sidebar').style.marginTop = `0px`;
            }
        })
    }

    render() {
        return (



            <div className="container">

            {
              // MOBILE ONLY VIEW

                isMobileOnly  &&


                <div className="guidelines-container">
                    <div className="row">


                        <div className="col-md-4">

                            {/* <aside id="sidebar"> */}
                            {/*     <ul className="side-nav"> */}
                            {/*         <li><a href="#" onClick={scroll.scrollToTop()}>Note to Every user</a></li> */}
                            {/*         <li><Link to="guide-content1" spy={true} smooth={true} duration={500}>Content Guidelines</Link></li> */}
                            {/*         <li><Link to="guide-content2" spy={true} smooth={true} duration={500}>Publishing Sensitive Content</Link></li> */}
                            {/*         <li><Link to="guide-content3" spy={true} smooth={true} duration={500}>Re-Publishing Content</Link></li> */}
                            {/*         <li><Link to="guide-content4" spy={true} smooth={true} duration={500}>User Roles</Link></li> */}
                            {/*         <li><Link to="guide-content5" spy={true} smooth={true} duration={500}>Business Guidelines</Link></li> */}
                            {/*         <li><Link to="guide-content6" spy={true} smooth={true} duration={500}>Username Policy</Link></li> */}
                            {/*         <li><Link to="guide-content7" spy={true} smooth={true} duration={500}>Upcoming features & spaces</Link></li> */}
                            {/*     </ul> */}
                            {/* </aside> */}

                        </div>

                        <div className="col-md-8">
                            <div className="guide-content">

                              {/* <Accordion> */}
                              {/*   <AccordionSummary expandIcon={<ExpandMoreIcon />} */}
                              {/*       aria-controls="panel1a-content" */}
                              {/*       id="panel1a-header" */}
                              {/*       className="profile-setting-box" */}
                              {/*   ><strong>Accordion Summary</strong> */}
                              {/*   </AccordionSummary> */}
                              {/*   <AccordionDetails className="accordion-details"> */}
                              {/*     Placeholder */}
                              {/*   </AccordionDetails> */}
                              {/* </Accordion> */}






                                <div className="mb-4" id="guide-content0">
                                    <div className="guide-header">
                                        <h1>Listiee Guidelines</h1>
                                        <div>Listiee User Guidelines.Effective March, 2020</div>
                                    </div>



                              <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="profile-setting-box"
                                ><strong>Note to Every user</strong>
                                </AccordionSummary>
                                <AccordionDetails className="accordion-details">
                                <div className="mb-4" id="guide-content0">
                                    <h3>General Note To Every User</h3>
                                    <div className="bullet-points">
                                        <p>Please read <a href="/terms-of-use">terms</a>, <a href="/disclaimer">disclaimer</a> and <a href="/privacy-policy">privacy-policy</a> before sharing the information on the platform.</p>
                                        <ul>
                                            <li>Do not share any information that is private, copyrighted or hostile in any way;</li>
                                            <li>If a list involves anything critical, suggestive or sensitive in a matter
                                            (including accusation/declaration/judgement) of any sort about something/someone,
                                                mark the content sensitive and compulsorily provide proof links from a trusted source;</li>
                                            <li>Trusted source may refer to a Reputed Newspapers, Private Research Organization,
                                                Government Websites and databases, Public Trusted Databases etc);</li>
                                            <li>Failing to do so will result in taking down the list along with account suspension;</li>
                                        </ul>
                                    </div>
                                    <div className="bullet-points">
                                        <p>Share content responsibly</p>
                                        <ul>
                                            <li>Share the content responsibly and in good faith;</li>
                                            <li>You are yourself responsible for the content you share;</li>
                                            <li>Make sure that the info you are sharing is not violating privacy and copyright material;</li>
                                            <li>Don’t share any content and carry out any activity that directly or indirectly
                                                puts anyone or anything in harm or at unease;</li>
                                            <li>Use report and flag features provided by the platform to aware us of any ill or suspicious content, people or,activity that you may come across being carried out on the platform;</li>
                                        </ul>
                                    </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                              <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="profile-setting-box"
                                ><strong>Content Guidelines</strong>
                                </AccordionSummary>
                                <AccordionDetails className="accordion-details">
                                <div className="mb-4" id="guide-content1">
                                    <h3>Content Guidelines</h3>
                                    <div className="inner-section">
                                        <strong>Type of Lists</strong>
                                        <div className="bullet-points">
                                            <ul>
                                                <li>Public Lists : Lists that will be available and editable by anyone using the platform;</li>
                                                <li>Private Lists : Lists that are exclusively yours;</li>
                                                <li>Closed Lists : Lists that will be public lists but won’t be open for editing;</li>
                                                <li>Conscious Lists : Lists that needs their elements to be sequentially frozen e.g - methods / Processes etc;</li>
                                                <li>Sensitive Lists : Lists that contain any nature of sensitive information as per it’s publisher or the people all-together;</li>
                                            </ul>
                                            <p class="note-text">Note: A list can have two different properties: such as a list can be
                                            a ‘public list’ + ‘closed list’
                                        which means it will be accessible to everyone but nobody can make any kind of contribution to it.</p>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>List View Variants :</strong>
                                        <div className="bullet-points">
                                            <ul>
                                                <li>Numbered lists;</li>
                                                <li>Dotted lists;</li>
                                                <li>Checkbox lists;</li>
                                                <li>Threaded lists;</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Creating A List</strong>
                                        <p>Listiee is a generic listing platform, so you can enjoy
                                        creating a list of anything.Considering a lot of platforms
                                        there, the base content of a list on Listiee is limited to
                                        text or link only at the moment. But links allow you to
                                        load images, videos and any other type of content that may
                                    exist out there.</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>List Rules</strong>
                                        <div className="bullet-points">
                                            <p>A list can have certain rules that needed to be followed for that particular lists.</p>
                                            <ul>
                                                <li>It can be added by any list Anchor, Menders, Amenders or Content Care Team of the platform wherever felt necessary;</li>
                                                <li>List rules are individual to lists and need not be followed throughout the platform;</li>
                                                <li>Lists rules are meant to help you adhere to the best best content adding practices on the platform;</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Adding Details To List Items</strong>
                                        <p>Adding details to list items is unique to Listiee. You get to manually
                                        create a section based on context or purpose (personal use only) and
                                            add relevant links or lists</p>
                                        <div className="bullet-points">
                                            <ul>
                                                <li>Eventually everyone</li>
                                            </ul>
                                            <p className="note-text">Note: Every section will eventually end up being a list on the platform.</p>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Contexts And Linking</strong>
                                        <p>With the help of linking you can create Contextual sections linked
                                            to list Items and link as many links as you like</p>

                                        <div className="bullet-points">
                                            <p>Type of things you can add to these section :</p>
                                            <ul>
                                                <li>You can add any type of content or media link to the list items;</li>
                                                <li>You can add text lists as well to the list item from the platform(must be published before);</li>
                                            </ul>
                                        </div>
                                        <div className="bullet-points">
                                            <p>We created an architecture that allows you to publish links based on their context
                                            of the content in it for a necessary reason. Make sure -</p>
                                            <ul>
                                                <li>Adhere to the name of sections based on context of the information only. Do not name sections based on the type of content (Media, Images, Videos etc) on the url</li>
                                                <li>The links you add are relevant to the item/list;</li>
                                                <li>The link is under the proper section/context;</li>
                                                <li>Follow list rules if added by the list anchor/creator</li>
                                                <li>Multiple links from a single website will be grouped together by default
                                                (e.g. all youtube links in the section will be under one youtube icon);</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Requesting A List</strong>
                                        <p>You can add a request to ask a list to the users.
                                        The list is then pushed to the answer page on the
                                            platform where users can add items to your lists.</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Editing/Deleting Content</strong>
                                        <p>You can edit/delete items added only by you on the platform including an entire list.</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Reporting and Flagging Content</strong>
                                        <p>You can report and Flag content or users using flag icons wherever you see it on the platform.</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Bookmarking People And Lists</strong>
                                        <p>You can bookmark people and give them some tags for your personal use, instead of following them.</p>
                                        <p className="note-text">(Why - Often we bump into people on social media, which we are not sure that we want to follow but want to save them. Bookmark helps you do just that.)</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Following People And Lists</strong>
                                        <p>Stay put, launching soon</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Promote</strong>
                                        <p>The upward arrow on every list, acts as a voting button. The list you vote, eventually gets promoted and so we simply named it promote button.</p>
                                    </div>
                                </div>

                               </AccordionDetails>
                            </Accordion>

                              <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="profile-setting-box"
                                ><strong>Publishing Sensitive Content</strong>
                                </AccordionSummary>
                                <AccordionDetails className="accordion-details">
                                <div className="mb-4" id="guide-content2">
                                    <h3>Publishing Sensitive Content</h3>

                                    <div className="bullet-points">
                                        <p>Any piece of list that has sensitive content in nature,
                                            it.must be published carefully with caution, necessary details and proper verification.</p>
                                        <ul>
                                            <li>Mark right the check-box of ‘sensitive content’ on the share page while creating such a list;</li>
                                            <li>Add proof / reputable reference links compulsorily for each list item</li>
                                        </ul>
                                    </div>
                                    <div className="bullet-points">
                                        <p>Sensitive content may refer to:</p>
                                        <ul>
                                            <li>Suggestive content: content/lists/links that are directly influential in nature;</li>
                                            <li>Declaration/Announcement: content/lists/links that are accusing someone or any organization of anything;</li>
                                            <li>Press Release: Any official organizational content;</li>
                                            <li>Hostile content: may refer to the content/lists/links that can incite violence,
                                                anger or hatred among anyone anywhere;</li>
                                            <li>Adult content: content/lists/links that contains information/media sexual in
                                                nature can be added only if they have educational material</li>
                                        </ul>
                                        <p className="note-text">Note: We are sensitive to the nature of content on the platform.</p>
                                    </div>
                                </div>



                                </AccordionDetails>
                              </Accordion>

                              <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="profile-setting-box"
                                ><strong>Re-Publishing Content</strong>
                                </AccordionSummary>
                                <AccordionDetails className="accordion-details">

                                <div className="mb-4" id="guide-content3">
                                    <h3>Re-Publishing Content</h3>
                                    <div className="inner-section">
                                        <strong>From other sources to Listiee</strong>
                                        <div className="bullet-points">
                                            <p>We aspire to be a no competition platform. If you are collecting from other blogs or websites</p>
                                            <ul>
                                                <li>Please read their websites T&C’s before you share;</li>
                                                <li>We request you to add them as your source, so they get the credit;</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inner-section">
                                        <strong>Sharing Content from the platform to recreate/modify or share it on your website in any form.</strong>
                                        <div className="bullet-points">
                                            <ul>
                                                <li>You are allowed to re-publish upto any 30% items of public lists from the platform;</li>
                                                <li>You must provide a clear visible link back to the Listiee as your reference;</li>
                                            </ul>
                                            <p>You should not copy or publish any other collection of lists or reference links directly leading
                                                to the third party platform unless you have requested and have a prior written approval from the platform.</p>
                                        </div>
                                    </div>
                                </div>



                                </AccordionDetails>
                              </Accordion>

                              <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="profile-setting-box"
                                ><strong>User Roles</strong>
                                </AccordionSummary>
                                <AccordionDetails className="accordion-details">
                                <div className="mb-4" id="guide-content4">

                                    <h3>User Roles</h3>

                                    <div className="inner-section">
                                        <strong>An Anchor (List Admin)</strong>
                                        <p>When you create a list you become list admin (an anchor) by default.
                                             You will get organized updates on your lists-</p>

                                        <div className="bullet-points">
                                            <p>As an anchor you attract certain responsibilities</p>
                                            <ul>
                                                <li>Keeping an eye on the crowdsourced list items for their relevance to your list;</li>
                                                <li>Flagging unnecessary items/links;</li>
                                                <li>Building a healthy environment around the list for it to grow;</li>
                                            </ul>
                                            <p class="note-text">Note to kind Anchors: The maintenance of information is voluntary work & we will have Mender’s, Amender’s and our team to back you up. But for any reason you want to opt out, you can always disassociate yourself with the list & you will no longer receive those updates.</p>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Mender</strong>
                                        <div className="bullet-points">
                                            <p>A rightful vibrant user of the platform who</p>
                                            <ul>
                                                <li>actively contributes</li>
                                                <li>help others</li>
                                                <li>Do things that makes Listiee a healthy platform and community</li>
                                            </ul>
                                            <p>are saluted with the Mender title.</p>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Amender</strong>
                                        <p>A person doing more than mendership is observed, analyzed and saluted as Amender</p>
                                    </div>
                                </div>




                                </AccordionDetails>
                              </Accordion>

                              <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="profile-setting-box"
                                ><strong>Business Guidelines</strong>
                                </AccordionSummary>
                                <AccordionDetails className="accordion-details">
                                <div className="mb-4" id="guide-content5">
                                    <h3>Business Guidelines</h3>

                                    <div className="inner-section">
                                        <strong>General</strong>
                                        <div className="bullet-points">
                                            <p>For companies/products/artists/service providers or anyone adding information and link to their businesses:</p>
                                            <ul>
                                                <li>We are glad and happy to help you find relevant audiences through our platform;</li>
                                                <li>Listing Yourself: Please add your product title or
                                                your title to whatever relevant lists you come across where you think you
                                            should be a part of but you are not</li>
                                                <li>Avoid: Create multiple lists on your own and add your Brand/Name/Trademark and links to too
                                            many items unnecessarily</li>
                                                <li>Unnecessary listing and linking: We want the content to be significantly relevant.
                                                In the same series we have taken some series steps. Any unnecessary listing, linking and networking
                                                will be considered spam and block you from further using the platform. Not following the guidelines
                                            after warning will result in banning your business/product completely from the platform;</li>
                                                <li>Multiple lists are allowed in special cases such as
                                                    <ul>
                                                        <li>All the list are unique in nature;</li>
                                                        <li>All lists have at least upto 3 item to each (your competitors);</li>
                                                        <li>Multiple lists numbers are not more than 5 per day;</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p className="note-text">Note: Every brand, product or person on the platform has only single entry on the platform and a page, irrespective of the number of lists they have got listed in. All contexts and links on the subject are eventually clubbed together on that single page covering all the information, contexts and links from the platform.</p>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Note for Marketing Individuals and Entities</strong>
                                        <p>We are glad that you want to be with us. One of our core missions is to get new brands, artists, other people,
                                        products and companies to get direct exposure to your relevant
                                    Market by presenting you right next to your known competitions where you can be easily discovered.</p>

                                        <div className="bullet-points">
                                            <p>To do that we need better quality of data to attract more users for you,
                                        and we want you to stick by the following guidelines-</p>
                                            <ul>
                                                <li>Add information about you or links only to most relevant places lists;</li>
                                                <li>Try not to over-mention or link yourself unnecessarily;</li>
                                                <li>Go slow and steady about your listing and linking;</li>
                                            </ul>
                                        </div>
                                    </div>



                                </div>





                                </AccordionDetails>
                              </Accordion>

                              <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="profile-setting-box"
                                ><strong>Username Policy</strong>
                                </AccordionSummary>
                                <AccordionDetails className="accordion-details">
                                <div className="mb-4" id="guide-content6">
                                    <h3>Username Policy</h3>
                                    <p>Listiee is a new social media platform. We respect the efforts of
                                    individuals and brands spent on building their online brand/community
                                    using their unique usernames.</p>

                                    <div className="bullet-points">
                                        <p>While you create a username-</p>
                                        <ul>
                                            <li>Create a unique username. (minimum length of 5 alphanumeric letters);</li>
                                            <li>Don't open an account with well known* username from any social media
                                            - eventually your account will be under scrutiny upon their claim or
                                                 request;</li>
                                        </ul>
                                    </div>

                                    <div className="bullet-points">
                                        <p>*Known username/brand may be considered based on one or more of the below definitions</p>
                                        <ul>
                                            <li>Username is at-least 3 months old;</li>
                                            <li>Username has a minimum of 3k followers on at-least any one social media platform;</li>
                                        </ul>
                                    </div>
                                </div>



                                </AccordionDetails>
                              </Accordion>

                              <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="profile-setting-box"
                                ><strong>Upcoming features & spaces</strong>
                                </AccordionSummary>
                                <AccordionDetails className="accordion-details">
                                <div className="mb-4" id="guide-content7">
                                    <h3>Upcoming Features And Spaces</h3>
                                    <div className="bullet-points">
                                        <ul>
                                            <li>Live Lists</li>
                                            <li>Subject Pages</li>
                                        {/* <li>About - webpages/media that gives detailed or unique
                                            information about the list item.
                                            Includes - profile, financial data, activity</li>
                                        <li>Do not mistake - reviews, judgement</li> */}
                                        </ul>
                                    </div>
                                </div>




                                </AccordionDetails>
                              </Accordion>


                               </div>

                            </div>
                        </div>
                    </div>
                </div>
}

              {


              // TABLET AND DESKTOP VIEW

              !isMobileOnly &&


                <div className="guidelines-container">
                    <div className="row">


                        <div className="col-md-4">

                            <aside id="sidebar">
                                <ul className="side-nav">
                                    <li><a href="#" onClick={scroll.scrollToTop()}>Note to Every user</a></li>
                                    <li><Link to="guide-content1" spy={true} smooth={true} duration={500}>Content Guidelines</Link></li>
                                    <li><Link to="guide-content2" spy={true} smooth={true} duration={500}>Publishing Sensitive Content</Link></li>
                                    <li><Link to="guide-content3" spy={true} smooth={true} duration={500}>Re-Publishing Content</Link></li>
                                    <li><Link to="guide-content4" spy={true} smooth={true} duration={500}>User Roles</Link></li>
                                    <li><Link to="guide-content5" spy={true} smooth={true} duration={500}>Business Guidelines</Link></li>
                                    <li><Link to="guide-content6" spy={true} smooth={true} duration={500}>Username Policy</Link></li>
                                    <li><Link to="guide-content7" spy={true} smooth={true} duration={500}>Upcoming features & spaces</Link></li>
                                </ul>
                            </aside>

                        </div>

                        <div className="col-md-8">
                            <div className="guide-content">

                              {/* <Accordion> */}
                              {/*   <AccordionSummary expandIcon={<ExpandMoreIcon />} */}
                              {/*       aria-controls="panel1a-content" */}
                              {/*       id="panel1a-header" */}
                              {/*       className="profile-setting-box" */}
                              {/*   ><strong>Accordion Summary</strong> */}
                              {/*   </AccordionSummary> */}
                              {/*   <AccordionDetails className="accordion-details"> */}
                              {/*     Placeholder */}
                              {/*   </AccordionDetails> */}
                              {/* </Accordion> */}






                                <div className="mb-4" id="guide-content0">
                                    <div className="guide-header">
                                        <h1>Listiee Guidelines</h1>
                                        <div>Listiee User Guidelines.Effective March, 2020</div>
                                    </div>






                                <div className="mb-4" id="guide-content0">
                                    <h3>General Note To Every User</h3>
                                    <div className="bullet-points">
                                        <p>Please read <a href="/terms-of-use">terms</a>, <a href="/disclaimer">disclaimer</a> and <a href="/privacy-policy">privacy-policy</a> before sharing the information on the platform.</p>
                                        <ul>
                                            <li>Do not share any information that is private, copyrighted or hostile in any way;</li>
                                            <li>If a list involves anything critical, suggestive or sensitive in a matter
                                            (including accusation/declaration/judgement) of any sort about something/someone,
                                                mark the content sensitive and compulsorily provide proof links from a trusted source;</li>
                                            <li>Trusted source may refer to a Reputed Newspapers, Private Research Organization,
                                                Government Websites and databases, Public Trusted Databases etc);</li>
                                            <li>Failing to do so will result in taking down the list along with account suspension;</li>
                                        </ul>
                                    </div>
                                    <div className="bullet-points">
                                        <p>Share content responsibly</p>
                                        <ul>
                                            <li>Share the content responsibly and in good faith;</li>
                                            <li>You are yourself responsible for the content you share;</li>
                                            <li>Make sure that the info you are sharing is not violating privacy and copyright material;</li>
                                            <li>Don’t share any content and carry out any activity that directly or indirectly
                                                puts anyone or anything in harm or at unease;</li>
                                            <li>Use report and flag features provided by the platform to aware us of any ill or suspicious content, people or,activity that you may come across being carried out on the platform;</li>
                                        </ul>
                                    </div>
                                    </div>
                               </div>

                                <div className="mb-4" id="guide-content1">
                                    <h3>Content Guidelines</h3>
                                    <div className="inner-section">
                                        <strong>Type of Lists</strong>
                                        <div className="bullet-points">
                                            <ul>
                                                <li>Public Lists : Lists that will be available and editable by anyone using the platform;</li>
                                                <li>Private Lists : Lists that are exclusively yours;</li>
                                                <li>Closed Lists : Lists that will be public lists but won’t be open for editing;</li>
                                                <li>Conscious Lists : Lists that needs their elements to be sequentially frozen e.g - methods / Processes etc;</li>
                                                <li>Sensitive Lists : Lists that contain any nature of sensitive information as per it’s publisher or the people all-together;</li>
                                            </ul>
                                            <p class="note-text">Note: A list can have two different properties: such as a list can be
                                            a ‘public list’ + ‘closed list’
                                        which means it will be accessible to everyone but nobody can make any kind of contribution to it.</p>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>List View Variants :</strong>
                                        <div className="bullet-points">
                                            <ul>
                                                <li>Numbered lists;</li>
                                                <li>Dotted lists;</li>
                                                <li>Checkbox lists;</li>
                                                <li>Threaded lists;</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Creating A List</strong>
                                        <p>Listiee is a generic listing platform, so you can enjoy
                                        creating a list of anything.Considering a lot of platforms
                                        there, the base content of a list on Listiee is limited to
                                        text or link only at the moment. But links allow you to
                                        load images, videos and any other type of content that may
                                    exist out there.</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>List Rules</strong>
                                        <div className="bullet-points">
                                            <p>A list can have certain rules that needed to be followed for that particular lists.</p>
                                            <ul>
                                                <li>It can be added by any list Anchor, Menders, Amenders or Content Care Team of the platform wherever felt necessary;</li>
                                                <li>List rules are individual to lists and need not be followed throughout the platform;</li>
                                                <li>Lists rules are meant to help you adhere to the best best content adding practices on the platform;</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Adding Details To List Items</strong>
                                        <p>Adding details to list items is unique to Listiee. You get to manually
                                        create a section based on context or purpose (personal use only) and
                                            add relevant links or lists</p>
                                        <div className="bullet-points">
                                            <ul>
                                                <li>Eventually everyone</li>
                                            </ul>
                                            <p className="note-text">Note: Every section will eventually end up being a list on the platform.</p>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Contexts And Linking</strong>
                                        <p>With the help of linking you can create Contextual sections linked
                                            to list Items and link as many links as you like</p>

                                        <div className="bullet-points">
                                            <p>Type of things you can add to these section :</p>
                                            <ul>
                                                <li>You can add any type of content or media link to the list items;</li>
                                                <li>You can add text lists as well to the list item from the platform(must be published before);</li>
                                            </ul>
                                        </div>
                                        <div className="bullet-points">
                                            <p>We created an architecture that allows you to publish links based on their context
                                            of the content in it for a necessary reason. Make sure -</p>
                                            <ul>
                                                <li>Adhere to the name of sections based on context of the information only. Do not name sections based on the type of content (Media, Images, Videos etc) on the url</li>
                                                <li>The links you add are relevant to the item/list;</li>
                                                <li>The link is under the proper section/context;</li>
                                                <li>Follow list rules if added by the list anchor/creator</li>
                                                <li>Multiple links from a single website will be grouped together by default
                                                (e.g. all youtube links in the section will be under one youtube icon);</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Requesting A List</strong>
                                        <p>You can add a request to ask a list to the users.
                                        The list is then pushed to the answer page on the
                                            platform where users can add items to your lists.</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Editing/Deleting Content</strong>
                                        <p>You can edit/delete items added only by you on the platform including an entire list.</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Reporting and Flagging Content</strong>
                                        <p>You can report and Flag content or users using flag icons wherever you see it on the platform.</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Bookmarking People And Lists</strong>
                                        <p>You can bookmark people and give them some tags for your personal use, instead of following them.</p>
                                        <p className="note-text">(Why - Often we bump into people on social media, which we are not sure that we want to follow but want to save them. Bookmark helps you do just that.)</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Following People And Lists</strong>
                                        <p>Stay put, launching soon</p>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Promote</strong>
                                        <p>The upward arrow on every list, acts as a voting button. The list you vote, eventually gets promoted and so we simply named it promote button.</p>
                                    </div>
                                </div>

                                <div className="mb-4" id="guide-content2">
                                    <h3>Publishing Sensitive Content</h3>

                                    <div className="bullet-points">
                                        <p>Any piece of list that has sensitive content in nature,
                                            it.must be published carefully with caution, necessary details and proper verification.</p>
                                        <ul>
                                            <li>Mark right the check-box of ‘sensitive content’ on the share page while creating such a list;</li>
                                            <li>Add proof / reputable reference links compulsorily for each list item</li>
                                        </ul>
                                    </div>
                                    <div className="bullet-points">
                                        <p>Sensitive content may refer to:</p>
                                        <ul>
                                            <li>Suggestive content: content/lists/links that are directly influential in nature;</li>
                                            <li>Declaration/Announcement: content/lists/links that are accusing someone or any organization of anything;</li>
                                            <li>Press Release: Any official organizational content;</li>
                                            <li>Hostile content: may refer to the content/lists/links that can incite violence,
                                                anger or hatred among anyone anywhere;</li>
                                            <li>Adult content: content/lists/links that contains information/media sexual in
                                                nature can be added only if they have educational material</li>
                                        </ul>
                                        <p className="note-text">Note: We are sensitive to the nature of content on the platform.</p>
                                    </div>
                                </div>


                                <div className="mb-4" id="guide-content3">
                                    <h3>Re-Publishing Content</h3>
                                    <div className="inner-section">
                                        <strong>From other sources to Listiee</strong>
                                        <div className="bullet-points">
                                            <p>We aspire to be a no competition platform. If you are collecting from other blogs or websites</p>
                                            <ul>
                                                <li>Please read their websites T&C’s before you share;</li>
                                                <li>We request you to add them as your source, so they get the credit;</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="inner-section">
                                        <strong>Sharing Content from the platform to recreate/modify or share it on your website in any form.</strong>
                                        <div className="bullet-points">
                                            <ul>
                                                <li>You are allowed to re-publish upto any 30% items of public lists from the platform;</li>
                                                <li>You must provide a clear visible link back to the Listiee as your reference;</li>
                                            </ul>
                                            <p>You should not copy or publish any other collection of lists or reference links directly leading
                                                to the third party platform unless you have requested and have a prior written approval from the platform.</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <a id="user-roles">
                                <div className="mb-4" id="guide-content4">

                                    <h3>User Roles</h3>

                                    <div className="inner-section">
                                        <strong>An Anchor (List Admin)</strong>
                                        <p>When you create a list you become list admin (an anchor) by default.
                                             You will get organized updates on your lists-</p>

                                        <div className="bullet-points">
                                            <p>As an anchor you attract certain responsibilities</p>
                                            <ul>
                                                <li>Keeping an eye on the crowdsourced list items for their relevance to your list;</li>
                                                <li>Flagging unnecessary items/links;</li>
                                                <li>Building a healthy environment around the list for it to grow;</li>
                                            </ul>
                                            <p class="note-text">Note to kind Anchors: The maintenance of information is voluntary work & we will have Mender’s, Amender’s and our team to back you up. But for any reason you want to opt out, you can always disassociate yourself with the list & you will no longer receive those updates.</p>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Mender</strong>
                                        <div className="bullet-points">
                                            <p>A rightful vibrant user of the platform who</p>
                                            <ul>
                                                <li>actively contributes</li>
                                                <li>help others</li>
                                                <li>Do things that makes Listiee a healthy platform and community</li>
                                            </ul>
                                            <p>are saluted with the Mender title.</p>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Amender</strong>
                                        <p>A person doing more than mendership is observed, analyzed and saluted as Amender</p>
                                    </div>
                                </div>
                                </a>





                                <div className="mb-4" id="guide-content5">
                                    <h3>Business Guidelines</h3>

                                    <div className="inner-section">
                                        <strong>General</strong>
                                        <div className="bullet-points">
                                            <p>For companies/products/artists/service providers or anyone adding information and link to their businesses:</p>
                                            <ul>
                                                <li>We are glad and happy to help you find relevant audiences through our platform;</li>
                                                <li>Listing Yourself: Please add your product title or
                                                your title to whatever relevant lists you come across where you think you
                                            should be a part of but you are not</li>
                                                <li>Avoid: Create multiple lists on your own and add your Brand/Name/Trademark and links to too
                                            many items unnecessarily</li>
                                                <li>Unnecessary listing and linking: We want the content to be significantly relevant.
                                                In the same series we have taken some series steps. Any unnecessary listing, linking and networking
                                                will be considered spam and block you from further using the platform. Not following the guidelines
                                            after warning will result in banning your business/product completely from the platform;</li>
                                                <li>Multiple lists are allowed in special cases such as
                                                    <ul>
                                                        <li>All the list are unique in nature;</li>
                                                        <li>All lists have at least upto 3 item to each (your competitors);</li>
                                                        <li>Multiple lists numbers are not more than 5 per day;</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p className="note-text">Note: Every brand, product or person on the platform has only single entry on the platform and a page, irrespective of the number of lists they have got listed in. All contexts and links on the subject are eventually clubbed together on that single page covering all the information, contexts and links from the platform.</p>
                                        </div>
                                    </div>

                                    <div className="inner-section">
                                        <strong>Note for Marketing Individuals and Entities</strong>
                                        <p>We are glad that you want to be with us. One of our core missions is to get new brands, artists, other people,
                                        products and companies to get direct exposure to your relevant
                                    Market by presenting you right next to your known competitions where you can be easily discovered.</p>

                                        <div className="bullet-points">
                                            <p>To do that we need better quality of data to attract more users for you,
                                        and we want you to stick by the following guidelines-</p>
                                            <ul>
                                                <li>Add information about you or links only to most relevant places lists;</li>
                                                <li>Try not to over-mention or link yourself unnecessarily;</li>
                                                <li>Go slow and steady about your listing and linking;</li>
                                            </ul>
                                        </div>
                                    </div>



                                </div>






                                <div className="mb-4" id="guide-content6">
                                    <h3>Username Policy</h3>
                                    <p>Listiee is a new social media platform. We respect the efforts of
                                    individuals and brands spent on building their online brand/community
                                    using their unique usernames.</p>

                                    <div className="bullet-points">
                                        <p>While you create a username-</p>
                                        <ul>
                                            <li>Create a unique username. (minimum length of 5 alphanumeric letters);</li>
                                            <li>Don't open an account with well known* username from any social media
                                            - eventually your account will be under scrutiny upon their claim or
                                                 request;</li>
                                        </ul>
                                    </div>

                                    <div className="bullet-points">
                                        <p>*Known username/brand may be considered based on one or more of the below definitions</p>
                                        <ul>
                                            <li>Username is at-least 3 months old;</li>
                                            <li>Username has a minimum of 3k followers on at-least any one social media platform;</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="mb-4" id="guide-content7">
                                    <h3>Upcoming Features And Spaces</h3>
                                    <div className="bullet-points">
                                        <ul>
                                            <li>Live Lists</li>
                                            <li>Subject Pages</li>
                                        {/* <li>About - webpages/media that gives detailed or unique
                                            information about the list item.
                                            Includes - profile, financial data, activity</li>
                                        <li>Do not mistake - reviews, judgement</li> */}
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

              
              }

            </div>


        )


    }


}
