import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function Board(props) {
    const [test, setTest] = useState({});

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="card">hi</div>
                </div>
                <div className="col">
                    <div className="card">hi</div>
                </div>
                <div className="col">
                    <div className="card">hi</div>
                </div>
                <div className="col">
                    <div className="card">hi</div>
                </div>
                <div className="col">
                    <div className="card">hi</div>
                </div>
            </div>
        </div>
    );
}

Board.propTypes = {
    aid: PropTypes.string
};
