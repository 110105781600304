import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import ErrorAlert from './Nested/ErrorAlert';

/*
Structure of a Note object:
{
  {
    text: { type: String, required: true },
    aid: { type: ObjectId, required: true },
    listId: { type: ObjectId, required: true },
    lsItemId: { type: String, required: false, default: "" },
    public: { type: Boolean, default: true }, // private or public
  },
  {
    timestamps: true
  }
}

*/
class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      publicVal: false,
      isSearching: false,
      searchTerm: '',

      editingId: '',
      editingNoteText: {},
      editingNotePublicVal: false,

      showAlert: false,
      alertMessage: '',
      alertCode: null,
    };
  }

  //Snackbar auto-hide method
  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false,
    });
  };

  showSnackBar = (message, code) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
      alertCode: code,
    });
  };

  componentDidMount() {
    if (window.innerWidth < 600) {
      return;
    }
    // Replcae link in placeholder to listiee.com
    document
      .querySelector('.ql-tooltip-editor input')
      .setAttribute('data-link', 'https://listiee.com');

    const noteSearcParent = document.querySelector('.note-parent');
    const noteSearchHeader = document.querySelector('.search-parent-container');

    noteSearcParent.addEventListener('scroll', (e) => {
      if (e.target.scrollTop > 10) noteSearchHeader.classList.add('add-shadow');
      else noteSearchHeader.classList.remove('add-shadow');
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.listDataForNote) !==
      JSON.stringify(this.props.listDataForNote)
    )
      this.handleAddToNotesOnDrop(nextProps);
  }

  handleAddToNotesOnDrop = (props) => {
    if (
      Object.keys(props.listDataForNote).length !== 0 &&
      props.listDataForNote.constructor === Object
    ) {
      console.log('ListDataForNote=', props.listDataForNote);
      const { type, data } = props.listDataForNote;
      const listName = data.listName;
      const listUrl = data.url;

      const url = `<p>Page: <a href=${window.location.pathname} rel=\"noopener noreferrer\" target=\"_blank\">${listName}</a></p><p><br></p>`;
      const textData =
        type == 'link'
          ? `<p><a href=${data.text} rel=\"noopener noreferrer\" target=\"_blank\">${data.text}</a></p>`
          : JSON.stringify(data.text);

      if (this.state.text.search('<p>Page: <a href=') == -1) {
        this.setState({
          text: url + textData + this.state.text,
        });
      } else {
        this.setState({
          text: this.state.text + textData,
        });
      }
    }
  };

  handleChange = (text) => {
    this.setState({ text });
  };

  handleChangeInEditMode = (text) => {
    this.setState({ editingNoteText: text });
  };

  handleEdit = (noteId, note) => {
    this.setState({
      editingId: noteId,
      editingNoteText: note.text,
      editingNotePublicVal: note.public,
    });
  };

  handleSaveEdit = (noteId, note) => {
    const { editingNoteText, editingNotePublicVal } = this.state;

    let newNote = {
      ...note,
      text: editingNoteText.trim(),
      public: editingNotePublicVal,
    };

    if (newNote.text.length === 0) {
      this.showSnackBar('Please add content', 'error');
      return;
    }

    this.props.editNote(noteId, newNote, this.props.mode);
    this.handleEditCancel();
  };

  handleDelete = async (noteId) => {
    if (window.confirm('Delete this note?')) {
      await this.props.deleteNote(noteId, this.props.mode);
    }
  };

  handleEditCancel = () => {
    this.setState({
      editingId: '',
      editingNoteText: '',
    });
  };

  saveNote = async (e) => {
    const { id, itemId } = this.props.match.params;
    let text = this.state.text.trim();
    let publicVal = this.state.publicVal;
    let listId = id;
    let lsItemId = itemId !== 'item' ? itemId : undefined;

    if (text.length === 0) {
      this.showSnackBar('Please add content', 'error');
      return;
    }

    await this.props.createNote(text, publicVal, listId, lsItemId);

    this.setState({
      text: '',
      publicVal: false,
    });
  };

  clearEditorContent = (e) => {
    if (
      this.state.text.length > 0 &&
      window.confirm('Clear without saving this note?')
    ) {
      this.setState({
        text: '',
        publicVal: true,
      });
    }
  };

  handleStartSearch = () => {
    this.setState({ isSearching: true });
  };

  handleClearSearch = () => {
    this.setState({ isSearching: false, searchTerm: '' });
  };

  handleSearchKeyPress = (e) => {
    const { isSearching } = this.state;
    if (isSearching === false) {
      this.setState((prevState) => ({ isSearching: true }));
    }
    this.setState({ searchTerm: e.target.value.toLowerCase() });
  };

  createNoteElement = (note, i, readOnly) => {
    const { editingNoteText, editingNotePublicVal } = this.state;
    return (
      <div key={note._id} className='col col-12'>
        <ErrorAlert
          AutoHideSnackbar={this.AutoHideSnackbar}
          showAlert={this.state.showAlert}
          message={this.state.alertMessage}
          status={this.state.alertCode}
        />
        <div className='note' id={!readOnly ? 'noteInEditMode' : null}>
          <div className='note-inner'>
            {readOnly ? (
              <ReactQuill
                value={note.text}
                readOnly={true}
                theme={'bubble'}
                className='note-text note-text-bottom'
              />
            ) : (
              <ReactQuill
                value={editingNoteText}
                onChange={this.handleChangeInEditMode}
                readOnly={false}
                theme={'bubble'}
                className='note-text note-text-bottom'
              />
            )}
          </div>
          {this.props.mode === 'my' ||
          note.user_name === this.props.user_name ? (
            <div
              className='note-tool-container'
              style={!readOnly ? { opacity: '1' } : null}
            >
              {!readOnly ? (
                <span
                  className='note-tool btn'
                  title='edit'
                  onClick={(e) => this.handleSaveEdit(note._id, note)}
                >
                  <i className='far fa-check-circle' /> Save
                </span>
              ) : (
                <span
                  className='note-tool btn'
                  title='edit'
                  onClick={(e) => this.handleEdit(note._id, note)}
                >
                  <i className='fas fa-pen' />
                </span>
              )}
              {!readOnly ? (
                <span
                  className='note-tool btn'
                  title='edit'
                  onClick={(e) => this.handleEditCancel()}
                >
                  <i className='far fa-times-circle' /> Cancel{' '}
                </span>
              ) : null}
              {/* {!readOnly ?
                <span
                  className="note-tool btn"
                  title={editingNotePublicVal ? "This note is public- Click to make it private" : "This note is not public- Click to make it public"}
                  style={editingNotePublicVal ? { color: "green" } : null}
                >
                    <i className="fas fa-globe" onClick={e=>this.setState({editingNotePublicVal:!editingNotePublicVal})} />
                </span>
                  :
                <span
                  className="note-tool btn"
                  title={note.public ? "This note is public" : "This note is not public"}
                  style={note.public ? { color: "green" } : null}
                >
                  <i className="fas fa-globe" />
                </span>
              } */}
              <span
                className='note-tool btn'
                title='delete'
                onClick={(e) => this.handleDelete(note._id)}
              >
                <i className='far fa-trash-alt' />
              </span>
            </div>
          ) : (
            <div className='note-tool-container'>
              <span className='note-tool btn' title='author'>
                @ {note.user_name}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderSavedNotes = () => {
    let notes;
    if (this.props.mode === 'my') {
      notes = this.props.myNotes;
      return notes.map((note, i) => {
        if (this.state.editingId === note._id)
          return this.createNoteElement(note, i, false);
        else return this.createNoteElement(note, i, true);
      });
    } else {
      notes = this.props.allNotes;
      if (notes.length !== 0) {
        return notes.map((note, i) => {
          if (this.state.editingId === note._id)
            return this.createNoteElement(note, i, false);
          else return this.createNoteElement(note, i, true);
        });
      } else {
        return <p className='mx-auto mt-4'>No Notes for this List!!</p>;
      }
    }
  };

  renderSearchedNotes = () => {
    console.log(this.props);
    const notes = this.props.myNotes;
    const filteredNotes = notes.filter((note) => {
      const { searchTerm } = this.state;
      return note.text.toLowerCase().includes(searchTerm);
    });

    return filteredNotes.map((note, i) => {
      if (this.state.editingId === note._id)
        return this.createNoteElement(note, i, false);
      else return this.createNoteElement(note, i, true);
    });
  };

  render() {
    const { isSearching, searchTerm } = this.state;
    return (
      <div className='container note-parent' style={{ width: '320px' }}>
        <div className='notes-component-container'>
          <div className='row'>
            {this.props.mode === 'my' ? (
              <React.Fragment>
                <div className='col col-12 search-parent-container'>
                  <div className='search-container'>
                    <div
                      className='notes-search-icon'
                      onClick={this.handleStartSearch}
                    >
                      <i className='fas fa-search' />
                    </div>
                    <input
                      type='text'
                      className='notes-search'
                      placeholder='Search Notes'
                      name='search'
                      value={searchTerm}
                      onChange={this.handleSearchKeyPress}
                    />
                    <div
                      className='notes-search-clear-icon'
                      onClick={this.handleClearSearch}
                    >
                      <i className='fas fa-times' />
                    </div>
                  </div>
                </div>
                {isSearching ? (
                  <div className='col col-12'>
                    <div className='searched-note-header container'>
                      <span className='item'>
                        <p className='search-reasult-header'>
                          <i className='fas fa-search'></i> Search results
                        </p>
                      </span>
                      <p
                        className='item go-back'
                        onClick={this.handleClearSearch}
                      >
                        <i className='fas fa-chevron-circle-left'></i> Go back{' '}
                      </p>
                    </div>
                    <div className='row'>{this.renderSearchedNotes()}</div>
                  </div>
                ) : (
                  <div className='col col-12'>
                    <div className='notes-container'>
                      <div className='active-note-container'>
                        <ReactQuill
                          value={this.state.text}
                          onChange={this.handleChange}
                          theme={'bubble'}
                          className='note-text note-text-top'
                          ref='quill'
                        />
                        <div className='tool-container'>
                          <button
                            className='tool btn'
                            onClick={this.saveNote}
                            title='click to save this note'
                          >
                            <i className='far fa-check-circle' /> Save
                          </button>
                          <button
                            className='tool btn'
                            onClick={(e) =>
                              this.setState({
                                publicVal: !this.state.publicVal,
                              })
                            }
                            style={
                              this.state.publicVal ? { color: 'green' } : null
                            }
                            title='public visibility'
                          >
                            <i className='fas fa-globe' /> Public
                          </button>
                          <button
                            className='tool btn'
                            onClick={this.clearEditorContent}
                            title='clear editor'
                          >
                            <i className='fas fa-times' /> Cancel
                          </button>
                        </div>
                      </div>

                      <div className='saved-note-header saveNotes'>
                        <small>SAVED NOTES:</small>
                        <div className='stretch'></div>
                        <button
                          className='fetchNotesBtn'
                          onClick={(e) => this.props.fetchMyNotes()}
                          title='Refresh'
                        >
                          <i className='fas fa-sync' />
                        </button>
                      </div>

                      <div className='row'>{this.renderSavedNotes()}</div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className='col col-12'>
                  <div className='notes-container'>
                    <div className='saved-note-header saveNotes'>
                      <small>LIST NOTES:</small>
                      <div className='stretch'></div>
                      {/* <button className="fetchNotesBtn" onClick={e=>this.props.fetchMyNotes()} title="Refresh"><i className="fas fa-sync"/></button> */}
                    </div>
                    <div className='row'>{this.renderSavedNotes()}</div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Notes;
