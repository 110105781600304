import React, { Component } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import StackSubmenu from "./StackSubmenu";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import {
  getListPath,
  LOGIN_PATH,
  HOME_PATH,
} from "../../constants/pathConstants";
import { getShortenedUrlCode } from "../Utils/helperFunctions";
import { GET_SHORT_URL_CODE_API } from "../../constants/apiConstants";
import Report from "../Report";
import { IconButton, Box } from "@material-ui/core";

class DiscoverDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareURL: "",
      isOpen: false,
      isCopied: false,
      shortenedCode: "",
      isReportModalOpen: false,
      state: null,
    };
  }

  //formats the whole URI
  formatUrl = () => {
    const path = getListPath(this.props.listTitle, this.props.listID);
    return `${window.location.origin}${path}`;
  };

  //format the title to a working URI string
  // formatListTitle = (str) => {
  //     return str.split(' ').join('-');
  // }

  // no need to modify these ui stuffs -->
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Hide Menu if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isOpen: false,
      });
      if (this.props.mode !== "mylist") {
        this.increaseOpacity();
      }
    }
  };

  lowerOpacity = () => {
    if (this.props.mode !== "mylist") {
      document
        .getElementById(this.props.specificID)
        .querySelector(".inner-wrapper").style.opacity = 0.5;
    }
  };

  increaseOpacity = () => {
    if (this.props.mode !== "mylist") {
      document
        .getElementById(this.props.specificID)
        .querySelector(".inner-wrapper").style.opacity = 1;
    }
  };

  showMenu = async () => {
    this.setState({
      isOpen: !this.state.isOpen,
      isCopied: false,
    });

    if (!this.state.shortenedCode) {
      let code = await getShortenedUrlCode(this.formatUrl());
      this.setState({ shortenedCode: code.slice(0, 8) });
      this.props.setShortenedCode(code.slice(0, 8));
      console.log("GENERATED SHORT CODE", code);
    }

    if (this.props.mode !== "mylist") {
      this.props.handleClickOnFolder();
      if (this.state.isOpen === false) {
        this.lowerOpacity();
      } else {
        this.increaseOpacity();
      }
    }
  };

  // <---End
  handleClick = async (e) => {
    this.setState({
      isCopied: true,
    });
  };

  handleReport = (e) => {
    e.preventDefault();
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, { redirect_to: HOME_PATH });
      return;
    }
    /*this.props.history.push('/report', {
      lsId: this.props.listID,
      listOwner: this.props.listOwner,
      type: this.props.listType === 'ask' ? 'ask' : 'list'
    });*/
    this.setState({
      state: {
        lsId: this.props.listID,
        listOwner: this.props.listOwner,
        type: this.props.listType === "ask" ? "ask" : "list",
      },
    });
    this.setState({
      isReportModalOpen: true,
    });
  };

  handleReportClose = () => {
    this.setState({
      isReportModalOpen: false,
    });
  };

  showShareMenu = async (e) => {
    this.props.mode === "mylist"
      ? this.props.toggleShareMenu(e, this.props.specificID)
      : this.props.toggleShareMenu();
  };

  render() {
    return (
      <div ref={this.setWrapperRef}>
        <MoreVertIcon className="moreVert" onClick={this.showMenu} />
        <Box
          className={
            this.props.mode === "mylist" ? "hidden-menu_mylist" : "hidden-menu"
          }
          style={this.state.isOpen ? { display: "block" } : { display: "none" }}
        >
          <Box className="tooltip" mb={1}>
            <StackSubmenu
              isMenuOpen={this.state.isOpen}
              listID={this.props.listID}
              folders={this.props.folders}
              increaseOpacity={this.increaseOpacity}
              lowerOpacity={this.lowerOpacity}
              aid={this.props.aid}
              token={this.props.token}
              addListToFolder={this.props.addListToFolder}
              showLoginModal={this.props.showLoginModal}
              fetchFolders={this.props.fetchFolders}
            />
          </Box>
          <br />
          <Box className="tooltip" mb={1} onClick={(e) => this.handleClick(e)}>
            <CopyToClipboard
              text={`🤗 Hey guys, Just created a ${this.props.listTitle} on Listiee @listiee01!

Check it out.

${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}sl

#lists #listiee

`}


            >
              <IconButton size="small">
                <i className="fas fa-link" />
              </IconButton>
            </CopyToClipboard>
            <span className="tooltiptext">
              {this.state.isCopied ? "Copied!" : "Copy link"}
            </span>
          </Box>
          <br />
          {/* <li className="tooltip">
                        <i className="fas fa-save" />
                        <span className="tooltiptext">Save</span>
                    </li> */}

          <Box
            className="tooltip"
            mb={1}
            onClick={(e) => this.showShareMenu(e)}
          >
            <IconButton size="small">
              <i className="fas fa-share-square" />{" "}
            </IconButton>
            <span className="tooltiptext">Share</span>
          </Box>
          <br />
          <Box className="tooltip" mb={1} onClick={(e) => this.handleReport(e)}>
            <IconButton size="small">
              {" "}
              <i className="fas fa-exclamation-circle" />{" "}
            </IconButton>
            <span className="tooltiptext">Report</span>
          </Box>
        </Box>
        {this.state.isReportModalOpen && (
          <Report
            {...this.props}
            state={this.state.state}
            isReportModalOpen={this.state.isReportModalOpen}
            handleReportClose={this.handleReportClose}
          />
        )}
      </div>
    );
  }
}

export default DiscoverDropdown;
