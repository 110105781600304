import React from "react";
import { Link } from "react-router-dom";

export default function ShareAskLinkBtn(props) {
  const path = props.location.pathname;

  return (
    
      <div className="share-ask-link-btn">
        <Link to="/share">
          <div
            style={{ borderRadius: "20px 0 0 20px" }}
            className={path === "/share" ? "active" : null}
          >
            Share
          </div>
        </Link>
        <Link to="/ask">
          <div
            style={{ borderRadius: "0 20px 20px 0" }}
            className={path === "/ask" ? "active" : null}
          >
            Ask
          </div>
        </Link>
      </div>
  );
}
