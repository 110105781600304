import React, { Component } from "react";
import {NavLink} from 'react-router-dom';
import "./styles/blogCard.sass";
import Tooltip from "@material-ui/core/Tooltip";

class blogCard extends Component {
	render() {
		return (
			<div className="blogCard">
				<div className="blog_content">
					<img className="blog_img" src={"/assets/test.jpg"} alt="blog_img" />
					<h3 className="blog_head">To Be Well-Informed, Cut the News and Read ..</h3>
					<p className="blog_txt">
					If you want to become a genuinely well-informed citizen, more and even better information is not enough.
					</p>
					<Tooltip  title="Click Here to see more" aria-label="BlogNav">
					<NavLink to="/blogPage" style={{textDecoration:"none" , color:'black'}}><div className="blog_btn">Read More</div></NavLink>
					</Tooltip>
				</div>
			</div>
		);
	}
}

export default blogCard;
