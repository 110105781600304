import React, { Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class UserNamePolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="footer-doc-container">
        <ExpansionPanel style={{ boxShadow: "none" }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >{this.props.checkbox||""}
            <Typography>Username Policy</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography style={{ paddingLeft: "0px !important" }}>
              <div className="footer-doc-content username">
                <h6>Username rules -</h6>
                <p>
                  Listiee is a new social media platform. We respect efforts of
                  individuals and brands spent on building their online
                  brand/community using their unique username's.
                </p>
                <p className="text-bold"> While you create a username - </p>
                <ol>
                  <li>
                    Create a unique username. (minimum length of 5 alphanumeric
                    letters)
                  </li>
                  <li>
                    Don't open an account with well known* username from any
                    social media - eventually your account will be under
                    scrutiny upon their claim or request.
                  </li>
                </ol>

                <br />
                <br />
                <p className="text-bold">
                  * Well known username/brand may refer to one or more of the
                  below definitions -
                </p>

                <ol>
                  <li>username is at-least 3 months old.</li>
                  <li>
                    username has a minimum of 3k followers on at-least one
                    social media platform.
                  </li>
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default UserNamePolicy;
