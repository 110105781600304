import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { CATEGORIES } from "../../constants/categoryConstants";

function RenderCategories(categories) {
  return categories.map((item) => <option value={item}>{item}</option>);
}

function EditCategory(props) {
  const { onHandleChange, value } = props;

  return (
    <Fragment>
      <div className="form-group category-container">
        {/* <label
          htmlFor={"tags"}
          style={
            props.value
              ? { display: "block" }
              : { display: "block", opacity: 0 }
          }
        >
          Category
        </label> */}
        <select
          name={"category"}
          className={"form-control"}
          id={"listCategory"}
          onChange={onHandleChange}
          value={value}
        >
          <option style={{ textAlign: "center", fontWeight: "bold" }}>
            Category
          </option>
          {RenderCategories(CATEGORIES)}
        </select>
      </div>
    </Fragment>
  );
}

EditCategory.propTypes = {
  onHandleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default EditCategory;
