import React, { Component } from "react";

export default class About extends Component {
    render() {
        return (
            <div className="about-page-container">
                <div className="container">
                    <div className="greetings mb-3 mt-4">About Listiee 🎉</div>
                    <div className="content mb-2 text-left">
                        <div className="inbetween-title">What is Listiee?</div>
                        Listiee is a simple generic listing and linking platform. A person can build lists,
                        organize these lists with and folder, link list items with any other lists and links
                        forming a network of information.
                    <div className="mb-3" />
                        <div className="inbetween-title">What is Listiee?</div>
                    Lists are lucid, quick to consume and easy to remember. They can be effortlessly noted, collected, organized, re-organized, linked, featured, sorted. Over time, we realized so much more can be done with them compared to any other form of data.
                    <div className="mb-3" />
                    We looked at the internet - the way data is evolving and size of data. It thought it may not have made more sense to build a universal listing and linking platform. A simple idea, just because of digital data and links, can be leveraged to collaborate and achieve incomprehensible things like never before in the history of information. We have seen examples like Wikipedia & Quora  where people are eager to collaborate and share knowledge.
                    <div className="mb-3" />
                    Our research found dozens of list based platforms clearly indicating the need of organized information, while there wasn’t any active universal listing platform as such. So we decided to build one.
                    <div className="mb-3" />
                        <div className="inbetween-title">Listiee’s mission?</div>
                    It’s simple, we want to put a most organized structure to digital information on the internet. So that anyone can easily navigate through the chaos of information without getting blindsided by the lack of prior knowledge on any subject.
                    <div className="mb-3" />
                        <div className="inbetween-title">Who can use the platform?</div>
                    Listiee is a social platform built keeping in mind the necessity of relevant and organized information in the information age. Curious minds, researchers, scientists, youtubers, bloggers, products, companies and anyone can come and join the platform for publishing, organizing, sharing or collaborating to build great digital stacks of lists and links for various purposes.
                    <div className="inbetween-title">Ok now, how Listiiee works?</div>
                    Anyone can sign-up and start organizing/publishing or collaborating upon any type of inforamtion. To understand the detailed structure of list type, user types, and other stuff - please refer to user guidelines.
                    <div className="mb-3" />
                    Most of the information is crowdsourced which means kind citizens of the world come together to build the gigantic database.

                    <div className="inbetween-title">List of ways I can use Listiee to my benefit generally?</div>
                        <ul>
                            <li>
                                Organize & collaborate to build useful digital lists and stacks: It’s simply a significant problem that is faced by internet wizards who are doing some research all the time. Use Listiee’’s unique architecture to
                        </li>
                            <li>Share or Publish information: It doesn’t takes time to demolish, but takes a long time to build things up. The knowledge that you have might being sought by hundreds of users, as you share you build a credit / trust for yourself.</li>
                            <li>Discover lists and stacks</li>
                        </ul>

                        <div className="inbetween-title">Businesses, content makers, and anyone seeking audience:</div>
                        <ul>
                            <li>Add yourself to relevant list and be found - Our aim is to build up a single list of everything at some point & which is going to work great for the committed business in the long run irrespective of the age.</li>
                            <li>Classic free advertising: Link your website / content / video or any other forms of links to relevant sections of list items and attract essential audiences to your outlet. Hurray! Finally some classic free advertising. Refer these important guidelines before engaging here.</li>
                            <li>Share or Publish information: Whether it’s small scale or large, sharing content that aware the audience your business, business background or research you carry out ultimately gets you attraction. It’s about time you start sharing </li>
                            <li>Help us help you: Being a crowdsourced platform, variants and types of info will be added to your thing. While you cannot take control of the whole page, keep check on relevant info, links & reviews added to your listing using Report & Flag option.</li>
                        </ul>

                        <div className="inbetween-title">Anything more unique/special about Listiee?</div>
                        <ul>
                            <li>No Cyber Tracking - apart from your activity within the platform and accessing your local cookies directly related to our website, we don’t track your IP activity across the internet.</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
