import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import {
  fetchUserLists,
  fetchUserListsFunction
} from '../../actions/apiActions';
import { Card, CardContent, Typography } from '@material-ui/core';
import { ListLoaderForDiscover } from '../ListLoader';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import { getListPath } from '../../constants/pathConstants';

import './ProfileLists.sass';
import DiscoverDropdown from '../Nested/DiscoverDropdown';
import ProfileShareIcons from './ProfileShareIcons';
import ProfilePlusButton from './ProfilePlusBtn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link } from 'react-router-dom';
import { GET_SHORT_URL_CODE_API } from '../../constants/apiConstants';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';


class ProfileLists extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: Array.from({ length: 20 }),
      lists: [],
      searchedLists: [],
      hasMore: true,
      page: 1,
      total: 0,
      limit: 6,
      loading: true,
      isShareMenuOpen: false,
      isSelfProfile: false,
      shareMenuIndex: -1,
      foldersForAdd: [],
      shortenedCode: '',
      query: ''
    };
  }

  componentDidMount() {
    const data = {
      aid: this.props.user.aid,
      email: this.props.user.email,
      username: this.props.username,
      page: this.state.page,
      limit: this.state.limit
    };
    this.setState({ loading: true });
    this.fetchFoldersForAdd();
    fetchUserListsFunction(data, this.props.user.token).then(res => {
      // console.log(res);
      this.setState({
        lists: this.state.lists.concat(res.result),
        total: res.total,
        page: this.state.page + 1,
        loading: false
      });
    });
  }

  fetchMoreData = () => {
    if (this.state.lists.length === this.state.total) {
      this.setState({ hasMore: false });
      return;
    }
    this.setState({ loading: true });
    // console.log("fetching");
    const data = {
      aid: this.props.user.aid,
      email: this.props.user.email,
      username: this.props.username,
      page: this.state.page,
      limit: this.state.limit
    };

    // if(!this.state.loading) {
    // console.log("here");
    fetchUserListsFunction(data, this.props.user.token).then(res => {
      this.setState({
        lists: this.state.lists.concat(res.result),
        page: this.state.page + 1,
        loading: false
      });
    });
    // }
  };

  handleLink = (e, id, slug) => {
    e.preventDefault();
    this.props.history.push(getListPath(slug, id));
  };

  toggleShareMenu = (e, ind) => {
    e.preventDefault();
    this.setState({
      isShareMenuOpen: !this.state.isShareMenuOpen,
      shareMenuIndex: ind
    });
  };

  hideShareMenu = () => {
    this.setState({
      isShareMenuOpen: false
    });
  };

  showShareMenu = () => {
    this.setState({
      isShareMenuOpen: true
    });
  };

  fetchFoldersForAdd = () => {
    let boardFolders = [],
      initFolders = this.props.folders;
    this.props.boards.map(board => {
      board.folders.map(fldr => {
        boardFolders.push(fldr);
      });
    });
    let allFolders = initFolders.concat(boardFolders);
    this.setState({ foldersForAdd: allFolders });
  };

  setShortenedCode = code => {
    if (!this.state.shortenedCode) {
      this.setState({ shortenedCode: code });
    }
  };

  handleListSearch = e => {
    e.preventDefault();
    let query = e.target.value;
    this.setState({ query });
    let { lists } = this.state;
    if (query) {
      let filteredLists = lists.filter(list => {
        return list.name.toLowerCase().includes(query.toLowerCase());
      });

      this.setState({ searchedLists: filteredLists });
    } else {
      return;
    }
  };

  render() {
    return (
      // <div>
      <>

        {this.state.lists.length <= 0 ? (

!this.props.isSelfProfile ?
            <CircularProgress /> : (

	    <Tooltip title="Make a new list" placement="top">
		<div className='default-card' style={{height: "264px", width: "247px"}}>
		    <div className='default-card-title'>Create a new list</div>
		    <div className='default-card-sign'>
			<Link to='/share'>
			    <AddCircleIcon />
			</Link>
		    </div>
		</div>
	    </Tooltip>

            )
	) : (
          <InfiniteScroll
            dataLength={this.state.lists.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={<ListLoader />}
            height={550}
            endMessage={
              <div style={{ textAlign: 'center', height: '150px' }}>
                <b>Yay! You have seen it all</b>
              </div>
            }
          >
            <div className='search_container' style={{ marginBottom: '20px' }}>
              <input
                type='text'
                className='board_search list_search'
                placeholder='Search Lists'
                onChange={e => this.handleListSearch(e)}
              />
            </div>

            <div className='listContainer'>
              <GridList className='gridList'>

		  {/*Add a list card always stays on top*/}

		  {/* Only showing the add a list card if the user is on his own profile */}
	    {this.props.isSelfProfile ?

	     (	    <Tooltip title="Make a new list" placement="top">
		 <div className='default-card' style={{height: "264px", width: "247px"}}>
		     <div className='default-card-title'>Create a new list</div>
		     <div className='default-card-sign'>
			 <Link to='/share'>
			     <AddCircleIcon />
			 </Link>
		     </div>
		 </div>
	     </Tooltip>
		 
	     )
		
:
	     
	     ""
	     
	    }
	    
                  {this.state.query === ''
                  ? this.state.lists &&
                    this.state.lists.map((list, index) => (
                      <GridListTile className='gridTile' key={index}>
                        <div className='add-folder' onClick={e => {}}>
                          <DiscoverDropdown
                            specificID={index}
                            listID={list._id}
                            toggleShareMenu={this.toggleShareMenu}
                            hideShareMenu={this.hideShareMenu}
                            // handleClickOnFolder={this.props.handleClickOnFolder}
                            listTitle={list.slug}
                            addListToFolder={this.props.addListToFolder}
                            folders={this.state.foldersForAdd}
                            aid={this.props.aid}
                            token={this.props.token}
                            listOwner={
                              list.profile.map(profile => profile.aid)[0]
                            }
                            mode='mylist'
                            history={this.props.history}
                            setShortenedCode={this.setShortenedCode}
                          />


                        </div>
                        <ProfileShareIcons
                          isShareMenuOpen={
                            this.state.isShareMenuOpen &&
                            this.state.shareMenuIndex === index
                          }
                          hideShareMenu={this.hideShareMenu}
                          shareBaseUrl={`${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}`}
                          mode='mylist'
                        />
                        <Card
                          key={index}
                          className='card'
                          onClick={e => {
                            this.handleLink(e, list._id, list.slug);
                          }}
                        >
                          <CardContent>
                            <Typography className="profile-listMob" style={{fontWeight:"bolder"}} gutterBottom>
                              <img
                                src='/assets/lists.png'
                                alt=''
                                style={{ height: '25px',marginRight:"1rem" }}
                              />
                              {list.name && list.name.length > 27
                                ? `${list.name.substring(0, 24)}...`
                                : list.name}
                            </Typography>
                            {list.item.map(({ list_name }, i) => (
                              <li className='mylist_item' key={i}>
                                {list_name.length > 27
                                  ? `${list_name.substring(0, 24)}...`
                                  : list_name}
                              </li>
                            ))}
                          </CardContent>
                        </Card>
                        <div className='list-category'>{list.category}</div>
                      </GridListTile>
                    ))
                  : this.state.searchedLists &&
                    this.state.searchedLists.map((list, index) => (
                      <GridListTile className='gridTile' key={index}>
                        <div className='add-folder' onClick={e => {}}>
                          <DiscoverDropdown
                            specificID={index}
                            listID={list._id}
                            toggleShareMenu={this.toggleShareMenu}
                            hideShareMenu={this.hideShareMenu}
                            // handleClickOnFolder={this.props.handleClickOnFolder}
                            listTitle={list.slug}
                            addListToFolder={this.props.addListToFolder}
                            folders={this.state.foldersForAdd}
                            aid={this.props.aid}
                            token={this.props.token}
                            listOwner={
                              list.profile.map(profile => profile.aid)[0]
                            }
                            mode='mylist'
                            history={this.props.history}
                            setShortenedCode={this.setShortenedCode}
                          />
                        </div>
                        <ProfileShareIcons
                          isShareMenuOpen={
                            this.state.isShareMenuOpen &&
                            this.state.shareMenuIndex === index
                          }
                          hideShareMenu={this.hideShareMenu}
                          shareBaseUrl={`${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}`}
                          mode='mylist'
                        />
                        <Card
                          key={index}
                          className='card'
                          onClick={e => {
                            this.handleLink(e, list._id, list.slug);
                          }}
                        >
                          <CardContent>
                            <Typography color='textSecondary' gutterBottom>
                              <img
                                src='/assets/lists.png'
                                alt=''
                                style={{ height: '25px' }}
                              />
                              {list.name && list.name.length > 27
                                ? `${list.name.substring(0, 24)}...`
                                : list.name}
                            </Typography>
                            {list.item.map(({ list_name }, i) => (
                              <li className='mylist_item' key={i}>
                                {list_name.length > 27
                                  ? `${list_name.substring(0, 24)}...`
                                  : list_name}
                              </li>
                            ))}
                          </CardContent>
                        </Card>
                        <div className='list-category'>{list.category}</div>
                      </GridListTile>
                    ))}

		  {/* <div className='default-card' style={{height: "264px", width: "247px"}}>
		      <div className='default-card-title'>Create a new list</div>
		      <div className='default-card-sign'>
		      <Link to='/share'>
                      <AddCircleIcon />
		      </Link>
		      </div>
		      </div>

		    */}
              </GridList>
              <ProfilePlusButton history={this.props.history} />
            </div>

            {/* {this.state.lists &&
          this.state.lists.map((list, index) => (
            <Card key={index} style={{margin: '5px'}}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  {list.name}
                </Typography>
                <Typography variant="h5" component="h2">
                  {list.category}
                </Typography>
              </CardContent>
            </Card>
          ))} */}
          </InfiniteScroll>
        )}
      </>

      // </div>
    );
  }
}

const ListLoader = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col col-12 col-md-6 col-lg-4'>
          <ListLoaderForDiscover />
        </div>
        <div className='col col-12 col-md-6 col-lg-4'>
          <ListLoaderForDiscover />
        </div>
        <div className='col col-12 col-md-6 col-lg-4'>
          <ListLoaderForDiscover />
        </div>
      </div>
      <div className='row'>
        <div className='col col-12 col-md-6 col-lg-4'>
          <ListLoaderForDiscover />
        </div>
        <div className='col col-12 col-md-6 col-lg-4'>
          <ListLoaderForDiscover />
        </div>
        <div className='col col-12 col-md-6 col-lg-4'>
          <ListLoaderForDiscover />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    myLists: state.api.myLists,
    user: state.auth
  };
};

const mapDispatchToProps = {
  fetchUserLists
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLists);
