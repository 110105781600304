import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getShortenedUrlCode } from "./Utils/helperFunctions";
import CancelIcon from '@material-ui/icons/Cancel';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
} from 'react-share';
import Tooltip from '@material-ui/core/Tooltip';


//TODO: More icons can be added later-
class ShareIcons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCopied: false,
      highlight: false,
      open: false,
      showShareListModal: false,
    };
  };

  handleClick = () => {
    this.setState({
      isCopied: true
    });
  };

  // no need to modify these ui stuffs -->
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleIgnore = () => {
    this.setState( { highlight: false } );
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    // performing continous checks because the value of the props get updated late and the initial state doesn't get updated
    this.interval = setInterval(() => {
      if(this.props.highlight == true) {

        this.setState({ highlight: true});

        // will set the share button tooltip to be true, so that it is shown automatically without a hover, acting as a prompt
        // this.setState({ open: true });

        // clear the interval if the initial state is updated
        clearInterval(this.interval);
      }
    }, 1000)
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    clearInterval(this.interval)
      };

  /**
   * Hide Menu if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isCopied: false
      });
    };

  };


  // tooltip methods

handleClose = () => {
  this.setState({ open: false });
}

handleOpen = () => {
  this.setState({ open: true });
}


  // opening share modal

  handleShareClick = () => {

    this.props.showShareListModal();

  }


  render() {
    const shareUrl = this.props.shareUrl;
    console.log(shareUrl);
    const title = this.props.title;
    const listieeLogoLink = "https://media-exp1.licdn.com/dms/image/C560BAQH-K9C6CP3p6Q/company-logo_200_200/0/1586771782777?e=2159024400&v=beta&t=Kuo6mOX5akI2D36zKws4Yhsdyzd5_Pt8FuIbFv3274M";

    return (
      <div className={"ShareIcons__container " + (this.state.highlight === true ? "gradient-border" : "")}>

        {
          this.state.highlight ? (
            <div className="ShareIcons__some-network" ref={this.setWrapperRef}>
          <li className="tooltip" onClick={this.handleIgnore}>

              <span className=""><i className="shareIcon-social fas fa-times-circle" /></span>
            <span className="tooltiptext">Ignore</span>
          {/* <CancelIcon /> */}
          </li>
      </div>
          ) : ""
        }


        {/* MUI tooltip not needed now */}
        {/* <Tooltip open={this.state.open} onClose={this.handleClose} onOpen={this.handleOpen} title="Share" placement="left-start"> */}
        <div className="ShareIcons__some-network" ref={this.setWrapperRef} onClick={this.handleShareClick}>
          <li className="tooltip">
              <span className="">
                <i class="shareIcon-social fas fa-share-square" />
          </span>
      <span className="tooltiptext" style={{visibility: this.state.highlight ? "visible" : ""}}>Share</span>
          </li>
        </div>
      {/* </Tooltip> */}


        <div className="ShareIcons__some-network" ref={this.setWrapperRef}>

          <li className="tooltip" onClick={this.handleClick}>
            <CopyToClipboard text={`${shareUrl}sl`}>
              <span className="copy-url-btn"><i className="far fa-copy" /></span>
            </CopyToClipboard>
            <span className="tooltiptext">{this.state.isCopied ? 'Copied!' : 'Copy link'}</span>
          </li>
        </div>

        <div className="ShareIcons__some-network">
          <CopyToClipboard text={`${shareUrl}sq`}>
            <span
              className="copy-url-btn"
              data-toggle="modal"
              data-target="#qrModal"
            >
              <i className="fa fa-qrcode"></i>
            </span>
          </CopyToClipboard>
        </div>

        <div className="ShareIcons__some-network">
          {/* <FacebookShareButton
            url={`${shareUrl}fb`}            
            quote={title}
            className="ShareIcons__some-network__share-button">
            <FacebookIcon
              size={32}
              round />
          </FacebookShareButton> */}
          <FacebookShareButton url={`${shareUrl}sl`} quote={title}>
            {/* <a href="https://www.facebook.com/" target="_blank"> */}
            <i class="shareIcon-social fab fa-facebook"></i>
            {/* </a> */}
          </FacebookShareButton>

        </div>

        <div className="ShareIcons__some-network">
          <TwitterShareButton url={`${shareUrl}sl`} title={title}>
            {/* <a href="https:twitter.com/" target="_blank"> */}
               <i class="shareIcon-social fab fa-twitter"></i>
               {/* </a>  */}
          </TwitterShareButton>
        </div>

        <div className="ShareIcons__some-network">
          <LinkedinShareButton url={`${shareUrl}sl`} title={title} >
          {/* <a href="https://www.linkedin.com/" target="_blank"> */}
            <i class="shareIcon-social fab fa-linkedin-in"></i>
          {/* </a> */}
            
          </LinkedinShareButton>
        </div>

        <div className="ShareIcons__some-network">
          <RedditShareButton url={`${shareUrl}sl`} title={title}>
          {/* <a href="https://www.reddit.com/" target="_blank"> */}
            <i class="shareIcon-social fab fa-reddit"></i>
          {/* </a> */}
          </RedditShareButton>
        </div>

        <div className="ShareIcons__some-network">
          <WhatsappShareButton url={`${shareUrl}sl`} title={title}>
          {/* <a href="https://web.whatsapp.com/" target="_blank"> */}
            <i class="shareIcon-social fab fa-whatsapp"></i>
          {/* </a> */}
            
          </WhatsappShareButton>
        </div>

        <div className="ShareIcons__some-network">
          <TelegramShareButton url={`${shareUrl}sl`} title={title}>
          {/* <a href="https://web.telegram.org/" target="_blank"> */}
            <i class="shareIcon-social fab fa-telegram"></i>
          {/* </a> */}
          </TelegramShareButton>
        </div>

        <div className="ShareIcons__some-network">
          <PinterestShareButton media={listieeLogoLink} url={`${shareUrl}sl`} description={title}>
          {/* <a href="https://www.pinterest.com/" target="_blank"> */}
            <i class="shareIcon-social fab fa-pinterest"></i>
          {/* </a> */}
          </PinterestShareButton>
        </div>
      </div>
    );
  }
}

export default ShareIcons;
