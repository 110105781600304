import React, { Component } from "react";
import {
	DISCOVER_PATH,
	getListPath,
	HOME_PATH
} from "../constants/pathConstants";
import queryString from "query-string";
import ListCard from "./ListCard"
import Swal from "sweetalert2";

class Categories extends Component {
	constructor(props) {
		super(props);

		this.state = {
			folders: [],
			searchTags: ["alpha", "beta", "gamma"],
			searchFilters: ["Type 1", "Type 2", "Type 3", "Type 4", "Type 5"],
			loading: 1
		};

		this.setSelectedList = this.setSelectedList.bind(this);
	}

	componentDidMount = () => {
		this.fetchFolders();
		this.activateOnRendered();
	}

	activateOnRendered = () => {
		document.querySelector('.categories-conatiner').focus();
	}

	navigateThroughKeyBoard = (e) => {
		const lists = document.querySelectorAll('.list-cards');
		const listArr = [...lists];
		const index = listArr.indexOf(document.activeElement);
		let nextIndex = 0;


		if (e.keyCode === 37 || e.keyCode === 38) {
			// up arrow
			e.preventDefault();
			nextIndex = index > 0 ? index - 1 : 0;
			lists[nextIndex].focus();
		}
		else if (e.keyCode === 39 || e.keyCode === 40) {
			// down arrow
			e.preventDefault();
			nextIndex = index + 1 < listArr.length ? index + 1 : index;
			lists[nextIndex].focus();
		} else if (e.keyCode === 13) {
			const listIndex = lists[index].getAttribute('listIndex');
			const lsid = lists[index].getAttribute('lsid');
			const title = lists[index].getAttribute('slug');
			this.setSelectedList(listIndex, lsid, title)
		}
	};


	componentWillMount() {
		if (!this.props.token)
			this.props.history.push(HOME_PATH, {
				login_required: true,
				redirect_to: DISCOVER_PATH
			});
		else {
			var queryStr = queryString.parse(this.props.location.search);
			// console.log(queryStr);
			this.props.fetchListv4(queryStr, this.props.token);
		}
	};

	// pre-fetching all the folders
	async fetchFolders() {
		await this.props.fetchBoards({ aid: this.props.aid, requester: this.props.user_name }, this.props.token)
			.then(() => {
				this.props.boards.forEach(board => {
					board.folders.forEach(folder => {
						this.setState({
							folders: [...this.state.folders, { _id: folder._id, name: folder.name }]
						})
					});
				});
				this.props.folders.forEach(folder => {
					this.setState({
						folders: [...this.state.folders, { _id: folder._id, name: folder.name }]
					})
				});
			});
	};

	async setSelectedList(index, lsid, slug, aid) {
		if (!this.state.folderClick) {
			await this.setState({
				selectedList: index,
				selectedListId: lsid,
				selectedListName: slug,
			});
			// this.toggleListModal();
			// set correct path in url
			this.props.history.push(getListPath(this.state.selectedListName, this.state.selectedListId));
			// if(this.props.aid != aid) {
			// 	this.handleView();
			// }
		}
		this.state.folderClick = false;
	};

	handleClickOnFolder = () => {
		this.state.folderClick = true;
	};

	// async handleView() {
	// 	await this.props.increaseView(this.state.selectedListId, this.props.token);
	// }

	displayLoading = () =>
		this.state.loading === "1" ? (
			<div className={"logo_loader"}>
				<div className={"text"}>Loading....</div>
				<div className={"bar-1"} />
				<div className={"bar-2"} />
				<div className={"bar-3"} />
			</div>
		) : null;

	displayCategories = () => {
		const url = this.props.location;
		const keyword = url.search.split("?query=");
		const uppercaseFirstLetter = keyword[1].charAt(0).toUpperCase();
		const remainingLetter = keyword[1].slice(1, 20);
		return <div style={{}}>{uppercaseFirstLetter + remainingLetter}</div>;
	};

	addSearchTags = e => {
		if (e.keyCode === 13 || e.keyCode === 188) {
			const tag = e.target.value;
			this.setState({ searchTags: [...this.state.searchTags, tag] });
			e.target.value = "";
		}
	};
	deleteSearchTags = index => {
		let { searchTags } = this.state;
		searchTags.splice(index, 1);
		this.setState({ searchTags });
	};

	renderSearchTags = () => {
		let { searchTags } = this.state;
		return searchTags.map((tag, i) => (
			<span>
				{tag}{" "}
				<i
					className="fa fa-times"
					onClick={e => this.deleteSearchTags(i)}
					style={{ color: "lightgray" }}
				/>
			</span>
		));
	};

	handleGoBack = () => {
		this.props.history.goBack();
	};

	render() {
		var lists = [];
		if (Object.keys(this.props.lists).length != 0) {
			if (this.props.lists.result.length != 0) {
				lists = this.props.lists.result;
			}
		}

		const showUpvote = e => {
			e.target.setAttribute("src", "/assets/upvote.png");
		};
		const hideUpvote = e => {
			e.target.setAttribute("src", "/favicon.png");
		};

		const renderLists = () => {
			return lists.map((listItem, index) => (
				<div
					key={index}
					className="list-cards col-12 col-md-6 col-lg-4 mb-4"
					tabIndex='0'
					listIndex={index}
					lsid={listItem._id}
					slug={listItem.slug}
				>
					<ListCard
						setSelectedList={() => this.setSelectedList(index, listItem._id, listItem.name, listItem.slug, listItem.profile.map(profile => profile.aid)[0])}
						handleClickOnFolder={this.handleClickOnFolder}
						id={index}
						listItem={listItem}
						index={index}
						handleAddToFolder={() => this.handleAddToFolder(listItem)}
						addListToFolder={this.props.addListToFolder}
						fetchedFolders={this.state.folders}
						aid={this.props.aid}
						token={this.props.token}
						{...this.props}
					/>
				</div>
			));
		};

		return (
			<div className="categories-conatiner" tabIndex='-1' onKeyDown={this.navigateThroughKeyBoard}>
				<div
					style={{
						margin: "1vw 2vw 3vw 2vw",
						fontSize: "3vw",
						fontWeight: "bold"
					}}
				>
					{this.displayCategories()}
				</div>
				<div className="filter-container">
					<div className="filter-area">
						<p>Filters:</p>
						<span>Type 1</span>
						<span>Type 2</span>
						<span>Type 3</span>
						<span>Type 4</span>
						<span>Type 5</span>
					</div>
					<br />
					<div className="tag-area">
						<p>Tags: </p>
						{this.renderSearchTags()}
						<input
							type="text"
							className="searchTagsInput"
							placeholder="Search by tags.."
							onKeyDown={e => this.addSearchTags(e)}
						/>
					</div>

					<div>
						<i
							// className="fa fa-times go-back-btn"
							className="far fa-arrow-alt-circle-left go-back-btn"
							onClick={this.handleGoBack}
							title="Go back"
							style={{ margin: "5vw 2vw" }}
						/>
					</div>
				</div>
				<div className="col-12"
					style={{ marginTop: "3vw" }}>
					<div
						className="list-card-container container"
					>
						<div className="row">{renderLists()}</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Categories;
