import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { USERNAME_POLICY } from "../../constants/pathConstants";
import { GET_USERS_LIST_API } from "../../constants/apiConstants";
import Axios from "axios";
import ErrorAlert from "../Nested/ErrorAlert";
import UserNamePolicy from "../UserNamePolicy";
import { getProfilePath } from "../../constants/pathConstants";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class ChangeUserName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aid: this.props.aid,
      user_name: "",

      showAlert: false,
      alertCode: "",
      message: "",
    };
  }

  getUsersList = async () => {};

  showSnackBar = (code, msg) => {
    this.setState({
      showAlert: true,
      alertCode: code,
      message: msg,
    });
  };

  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false,
    });
  };

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (Object.keys(nextProps.profile).length != 0) {
      this.setState({
        user_name: nextProps.profile.user_name || "",
      });
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleSubmit = async () => {
    let token = this.props.token;
    let res = await Axios.get(`${GET_USERS_LIST_API}${this.state.user_name}`, {
      headers: { "x-access-token": token },
    });
    let opts = [];
    res.data.data.map((opt) => {
      opts.push(opt.user_name);
    });
    if (!opts.includes(this.state.user_name)) {
      await this.props.changeUserName(this.state, this.props.token);
      await this.props.fetchProfile({ aid: this.props.aid }, this.props.token);
      this.showSnackBar("success", "username changed successfully.");
    } else {
      this.showSnackBar("error", "username already exists!");
    }
  };

  handleGoBack = () => {
    if (this.props.history.action === "PUSH") {
      this.props.history.goBack();
    } else {
      this.props.history.push(getProfilePath(this.props.user_name));
    }
  };

  render() {
    return (
      <div className="profile-setting-container container-fluid">
        {/* {console.log(this.props)} */}
        <div className="profile-setting-box">
          <div
            style={{ display: "flex",flexWrap:"wrap",justifyContent:"space-between",gap:"1.5rem", alignItems: "flex-end" }}
            // className="row"
          >
            <ErrorAlert
              AutoHideSnackbar={this.AutoHideSnackbar}
              showAlert={this.state.showAlert}
              status={this.state.alertCode}
              message={this.state.message}
            />
            <TextField
              // className="edit-user-input"
              id="user_name"
              label="User Name"
              value={this.state.user_name}
              onChange={this.handleChange("user_name")}
              margin="normal"
              style={{ margin: "auto" }}
            />
            <div
              style={{ margin: "16px auto 8px auto" }}
              // className="edit-username-botton"
            >
              <button className="btn-username" onClick={this.handleSubmit}>
                Set Username
              </button>
            </div>

          </div>
            <UserNamePolicy checkbox={this.props.checkbox} />
        </div>
      </div>
    );
  }
}

ChangeUserName.propTypes = {
  token: PropTypes.string.isRequired,
  aid: PropTypes.string.isRequired,
};

export default withStyles(styles)(ChangeUserName);
