export const CATEGORIES = [
  "Academic",
  "Art",
  "Business",
  "Crime",
  "Culture",
  "Economy",
  "Education",
  "Energy",
  "Entertainment",
  "Funny",
  "Finance",
  "Geography",
  "Government",
  "Health",
  "History",
  "Language",
  "Law",
  "Life",
  "Mind",
  "Music",
  "Nature",
  "People",
  "Philosophy",
  "Politics",
  "Religion",
  "Science",
  "Society",
  "Sports",
  "Technology",
  "Universe",
  "World",
  // "covid19"
];

export const Primary = [
  "Subjects",
  "Objects",
  "Utilities",
  "Organization names",
  "Products",
  "Words",
  "Community",
  "People",
  "Skills",
  "Techniques / tactics",
  "Set of rules",
  "Events",
  "Tools",
  "Methods",
  "Process",
  "Jobs",
  "Services",
  "Businesses",
  "Space",
  "Courses",
  "Inventions",
  "Other",
];
