import React from 'react';
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div className={'error_container'}>
      <div className="error-text">Whoops, that page is gone.</div>
      <div className="img-wrapper">
        <img src="https://i.ibb.co/YPVNbY6/404.png" alt="404" border="0" /></div>
      <div className="buttons-wrapper">
        <Link to="/share"><button className="error-back-btn">Share</button></Link>
        <Link to="/"><button className="error-back-btn" style={{ backgroundColor: "#544790e3" }}>Discover</button></Link>
        <Link to="/ask"><button className="error-back-btn">Ask</button></Link>
      </div>
    </div>
  );
};

export default Error404;