import axios from "axios";
import Cookies from "js-cookie";
import { getRegionAsync } from "../utils/utils";
import {
  CLEAR_AUTH_ERR,
  LOGIN_USER,
  LOGIN_USER_ERR,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_ERR,
  TOGGLE_EMAIL_VERIFIED,
  RESET_PASSWORD,
  RETRY_SIGNUP,
} from "../constants/actionTypes";
import {
  LOGIN_API,
  REGISTER_API,
  REGISTER_API_CLOSED,
  VERIFY_API,
  RESEND_OTP_API,
  FORGET_PASSWORD_API,
  RESET_PASSWORD_API,
  AUTH_GOOGLE_API,
  AUTH_LINKEDIN_API,
  AUTH_TWITTER_API,
  VERIFY_EMAIL_API,
} from "../constants/authConstants";
import { COOKIE_AUTH } from "../constants/baseConstants";

export const loginUser = userData => async dispatch => {
  const reqOptions = {
    method: "POST",
    url: LOGIN_API,
    data: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": "5",
    },
  };

  dispatch({
    type: "LOADING",
    payload: { loading: true },
  });

  await axios(reqOptions)
    .then(async res => {
      // console.clear();

      const loc = (await getRegionAsync()) || null;

      const newData = {
        token: res.data.data.token,
        email: res.data.data.profile_data.email,
        user_type: res.data.data.profile_data.user_type,
        aid: res.data.data.profile_data.aid,
        user_name: res.data.data.profile_data.user_name,
        avatar: res.data.data.profile_data.avatar,
        name: res.data.data.profile_data.name,
        bio: res.data.data.profile_data.bio,
        city: loc ? loc.city : "",
        state: loc ? loc.region : "",
        country: loc ? loc.country : "",
      };

      Cookies.set(COOKIE_AUTH, newData, { expires: 30 });
      newData["loading"] = !res.data.success;
      console.log(newData);
      dispatch({
        type: LOGIN_USER,
        payload: newData,
      });
    })
    .catch(error => {
      dispatch({
        type: "LOADED",
        payload: { loading: false },
      });
      let data = {
        error: "",
      };
      data.error = error.response ? error.response.data.message : error.message;
      dispatch({
        type: LOGIN_USER_ERR,
        payload: data,
      });
    });
};

// register action for locked site.
export const registerUserClosed = userData => dispatch => {
  const reqOptions = {
    method: "POST",
    url: REGISTER_API_CLOSED,
    data: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": "5",
    },
  };
  dispatch({
    type: "LOADING",
    payload: { loading: true },
  });
  axios(reqOptions)
    .then(res => {
      console.log(res);
      const newData = {
        token: res.data.data.token,
        email: res.data.data.profile_data.email,
        aid: res.data.data.profile_data.aid,
        user_name: res.data.data.profile_data.user_name,
        avatar: res.data.data.profile_data.avatar,
        name: res.data.data.profile_data.name,
        bio: res.data.data.profile_data.bio,
      };
      Cookies.set(COOKIE_AUTH, newData, { expires: 30 });
      newData["emailSent"] = false;
      newData["loading"] = false;
      dispatch({
        type: LOGIN_USER,
        payload: newData,
      });
    })
    .catch(err => {
      // console.log(`This is error ${err}`);
      dispatch({
        type: "LOADED",
        payload: { loading: false },
      });
      const error = err.response
        ? err.response.data.message
        : err.message;
      const data = {
        error,
      };
      dispatch({
        type: REGISTER_USER_ERR,
        payload: data,
      });
      return err;
    });
};

export const registerUser = userData => dispatch => {
  const reqOptions = {
    method: "POST",
    url: REGISTER_API,
    data: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": "5",
    },
  };
  dispatch({
    type: "LOADING",
    payload: { loading: true },
  });
  axios(reqOptions)
    .then(res => {
      // console.log("test res: ", res);
      let newData;
      newData = {
        aid: res.data.data._id,
        emailSent: res.data.success,
        verify_email_type: 1,
        loading: false,
      };
      dispatch({
        type: REGISTER_USER,
        payload: newData,
      });
    })
    .catch(error => {
      console.log("test: ", error);
      dispatch({
        type: "LOADED",
        payload: { loading: false },
      });
      const data = {
        error: "",
      };
      data.error = error.response ? error.response.data.message : error.message;
      dispatch({
        type: REGISTER_USER_ERR,
        payload: data,
      });

      return error;
    });
};

export const twitterLogin = data => async dispatch => {
  const reqOptions = {
    method: "POST",
    url: AUTH_TWITTER_API,
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": "5",
    },
  };

  dispatch({
    type: "LOADING",
    payload: { loading: true },
  });

  await axios(reqOptions)
    .then(res => {
      if (!res.data.success) {
        alert(`Something went wrong: ${res.data.message}`);
        return;
      }
      const newData = {
        token: res.data.data.token,
        email: res.data.data.email,
        aid: res.data.data.aid,
        user_name: res.data.data.user_name,
        avatar: res.data.data.avatar,
        name: res.data.data.name,
        bio: res.data.data.bio,
        user_type: res.data.data.user_type,
      };
      Cookies.set(COOKIE_AUTH, newData, { expires: 30 });
      newData["loading"] = !res.data.success;
      console.log(newData);
      dispatch({
        type: LOGIN_USER,
        payload: newData,
      });
    })
    .catch(error => {
      dispatch({
        type: "LOADED",
        payload: { loading: false },
      });
      let data = {
        error: "",
      };
      data.error = error.response ? error.response.data.message : error.message;
      dispatch({
        type: LOGIN_USER_ERR,
        payload: data,
      });
    });
};

export const googleLogin = data => async dispatch => {
  const reqOptions = {
    method: "POST",
    url: AUTH_GOOGLE_API,
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": "5",
    },
  };

  dispatch({
    type: "LOADING",
    payload: { loading: true },
  });

  await axios(reqOptions)
    .then(res => {
      if (!res.data.success) {
        alert(`Something went wrong: ${res.data.message}`);
        return;
      }
	console.log(res.data.data.user_name);
      const newData = {
        token: res.data.data.token,
        email: res.data.data.email,
        aid: res.data.data.aid,
        user_name: res.data.data.user_name,
        avatar: res.data.data.avatar,
        name: res.data.data.name,
        bio: res.data.data.bio,
        user_type: res.data.data.user_type,
      };
	console.log(newData);
      Cookies.set(COOKIE_AUTH, newData, { expires: 30 });
	console.log(JSON.parse(Cookies.get(COOKIE_AUTH)).user_name);
      newData["loading"] = !res.data.success;
      dispatch({
        type: LOGIN_USER,
        payload: newData,
      });
    })
    .catch(error => {
      dispatch({
        type: "LOADED",
        payload: { loading: false },
      });
      let data = {
        error: "",
      };
      data.error = error.response ? error.response.data.message : error.message;
      dispatch({
        type: LOGIN_USER_ERR,
        payload: data,
      });
    });
};

export const linkedinLogin = data => async dispatch => {
  const reqOptions = {
    method: "POST",
    url: AUTH_LINKEDIN_API,
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": "5",
    },
  };

  dispatch({
    type: "LOADING",
    payload: { loading: true },
  });

  await axios(reqOptions)
    .then(res => {
      if (!res.data.success) {
        alert(`Something went wrong: ${res.data.message}`);
        return;
      }
      const newData = {
        token: res.data.data.token,
        email: res.data.data.email,
        aid: res.data.data.aid,
        user_name: res.data.data.user_name,
        avatar: res.data.data.avatar,
        name: res.data.data.name,
        bio: res.data.data.bio,
        user_type: res.data.data.user_type,
      };
      Cookies.set(COOKIE_AUTH, newData, { expires: 30 });
      newData["loading"] = !res.data.success;
      console.log(newData);
      dispatch({
        type: LOGIN_USER,
        payload: newData,
      });
    })
    .catch(error => {
      dispatch({
        type: "LOADED",
        payload: { loading: false },
      });
      let data = {
        error: "",
      };
      data.error = error.response ? error.response.data.message : error.message;
      dispatch({
        type: LOGIN_USER_ERR,
        payload: data,
      });
    });
};

export const verifyOTP = (userData, urlLink, type) => dispatch => {
  console.log(urlLink, type);
  const reqOptions = {
    method: "POST",
    url: urlLink,
    data: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": "5",
    },
  };
  dispatch({
    type: "LOADING",
    payload: { loading: true },
  });
  axios(reqOptions)
    .then(res => {
      // console.log("test res: ", res);
      if (type == 1) {
        const newData = {
          token: res.data.data.token,
          email: res.data.data.profile_data.email,
          aid: res.data.data.profile_data.aid,
          user_name: res.data.data.profile_data.user_name,
          avatar: res.data.data.profile_data.avatar,
          name: res.data.data.profile_data.name,
          bio: res.data.data.profile_data.bio,
        };
        Cookies.set(COOKIE_AUTH, newData, { expires: 30 });
        newData["emailSent"] = false;
        newData["loading"] = false;
        dispatch({
          type: LOGIN_USER,
          payload: newData,
        });
      } else if (type == 2) {
        const newData = {
          resetPasswordBool: res.data.success,
          aid: res.data.data.aid,
          emailSent: false,
          loading: false,
        };
        dispatch({
          type: RESET_PASSWORD,
          payload: newData,
        });
      }
    })
    .catch(error => {
      dispatch({
        type: "LOADED",
        payload: { loading: false },
      });
      console.log("test: ", error);
      let data = {
        error: "",
      };
      data.error = error.response ? error.response.data.message : error.message;
      dispatch({
        type: LOGIN_USER_ERR,
        payload: data,
      });
    });
};

export const resetPassword = userData => dispatch => {
  const reqOptions = {
    method: "POST",
    url: RESET_PASSWORD_API,
    data: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": "5",
    },
  };
  dispatch({
    type: "LOADING",
    payload: { loading: true },
  });
  axios(reqOptions)
    .then(res => {
      console.log(res);
      if (res.data.success) {
        // alert("successfully reset your password. please login with new password");
        let newData;
        newData = {
          emailSent: false,
          resetPasswordBool: false,
          verify_email_type: 0,
          loading: false,
        };
        // data.error = error.response ? error.response.data.message : error.message;
        dispatch({
          type: REGISTER_USER,
          payload: newData,
        });
      }
    })
    .catch(error => {
      dispatch({
        type: "LOADED",
        payload: { loading: false },
      });
      const data = {
        error: "",
      };
      data.error = error.response ? error.response.data.message : error.message;
      dispatch({
        type: REGISTER_USER_ERR,
        payload: data,
      });
    });
};

export const forgetPassword = userData => dispatch => {
  const reqOptions = {
    method: "POST",
    url: FORGET_PASSWORD_API,
    data: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
    },
  };
  dispatch({
    type: "LOADING",
    payload: { loading: true },
  });
  axios(reqOptions)
    .then(res => {
      console.log(res);
      let newData;
      newData = {
        aid: res.data.data.aid,
        emailSent: res.data.success,
        verify_email_type: 2,
        loading: false,
      };
      dispatch({
        type: REGISTER_USER,
        payload: newData,
      });
    })
    .catch(error => {
      console.log(error.response.data);
      dispatch({
        type: "LOADED",
        payload: { loading: false },
      });
      const data = {
        error: "",
      };
      data.error = error.response ? error.response.data.message : error.message;
      dispatch({
        type: REGISTER_USER_ERR,
        payload: data,
      });
    });
};

export const resendOtp = data => dispatch => {
  const reqOptions = {
    method: "POST",
    url: RESEND_OTP_API,
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios(reqOptions)
    .then(res => {
      console.log(res);
      dispatch({
        type: "RESEND_OTP",
        payload: data,
      });
    })
    .catch(error => {
      console.log(error.response.data);
      dispatch({
        type: "LOADED",
        payload: { loading: false },
      });
      const data = {
        error: "",
      };
      data.error = error.response ? error.response.data.message : error.message;
      dispatch({
        type: REGISTER_USER_ERR,
        payload: data,
      });
    });
};

export const logoutUser = () => dispatch => {
  dispatch({ type: LOGOUT_USER });
};

export const clearAuthError = () => dispatch => {
  dispatch({ type: CLEAR_AUTH_ERR });
};

export const toggleEmailVerified = () => dispatch => {
  dispatch({ type: TOGGLE_EMAIL_VERIFIED });
};

export const retrySignup = () => dispatch => {
  dispatch({ type: RETRY_SIGNUP });
};
