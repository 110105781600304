import React, { Component } from "react";
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

class ErrorAlert extends Component {
    constructor(props) {
        super(props);
    };

    render() {
        return (
            <Snackbar
                open={this.props.showAlert}
                autoHideDuration={3000}
                onClose={this.props.AutoHideSnackbar}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}>
                <Alert severity="success" style={this.props.addedStyles||{}} severity={this.props.status}>
                    <p style={{ marginBottom: '0px', fontWeight: '600' }}>{this.props.message}</p>
                </Alert>
            </Snackbar>
        )
    }
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const ErrorIconStyle = {
    float: 'left',
    color: "#fdc206",
    position: "relative",
    top: "-1px"
}

export default ErrorAlert;