import React, { Component } from 'react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,

    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon
} from 'react-share';
import { getListPath } from "../../constants/pathConstants"

//TODO: More icons can be added later- 
class ListShareIcons extends Component {

    // no need to modify these ui stuffs -->
    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    };

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    };

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.hideShareMenu();
        };

    };

    render() {
        const baseShareUrl = this.props.shareBaseUrl;
        // console.log(this.props.listName);
        const title = `🤗 Hey guys, Just created a ${this.props.listName} on Listiee @listiee01!

Check it out.

#lists #listiee

`;

        return (
            <div className={this.props.mode === "mylist" ? "text-center shareMenuIcons_mylist" : "text-center shareMenuIcons"}
                style={this.props.isShareMenuOpen ? { display: 'flex' } : { display: 'none' }}
                onMouseDown={this.props.handleClickOnFolder}
                ref={this.setWrapperRef}
            >
                <div className='iconWrapper' >
                    <FacebookShareButton
                        url={`${baseShareUrl}fb`}
                        quote={title}
                        className="ShareIcons__some-network__share-button">
                        <FacebookIcon
                            size={25}
                            round />
                    </FacebookShareButton>
                </div>

                <div className='iconWrapper'>
                    <TwitterShareButton
                        url={`${baseShareUrl}tw`}
                        title={title}
                        className="ShareIcons__some-network__share-button">
                        <TwitterIcon
                            size={25}
                            round />
                    </TwitterShareButton>
                </div>

                <div className='iconWrapper'>
                    <LinkedinShareButton
                        url={`${baseShareUrl}ln`}
                        windowWidth={750}
                        windowHeight={600}
                        className="ShareIcons__some-network__share-button">
                        <LinkedinIcon
                            size={25}
                            round />
                    </LinkedinShareButton>
                </div>

                <div className='iconWrapper' >
                    <RedditShareButton
                        url={`${baseShareUrl}ot`}
                        title={title}
                        windowWidth={660}
                        windowHeight={460}
                        className="ShareIcons__some-network__share-button">
                        <RedditIcon
                            size={25}
                            round />
                    </RedditShareButton>
                </div>

                <div className='iconWrapper' >
                    <WhatsappShareButton
                        url={`${baseShareUrl}wa`}
                        title={title}
                        separator=":: "
                        className="ShareIcons__some-network__share-button">
                        <WhatsappIcon size={25} round />
                    </WhatsappShareButton>
                </div>

                <div className='iconWrapper'>
                    <TelegramShareButton
                        url={`${baseShareUrl}tl`}
                        title={title}
                        className="ShareIcons__some-network__share-button">
                        <TelegramIcon size={25} round />
                    </TelegramShareButton>
                </div>

                <div className='iconWrapper'>
                    <PinterestShareButton
                        url={`${baseShareUrl}ot`}
                        windowWidth={1000}
                        windowHeight={730}
                        className="ShareIcons__some-network__share-button">
                        <PinterestIcon size={25} round />
                    </PinterestShareButton>
                </div>
            </div>
        );
    }
};


export default ListShareIcons;
