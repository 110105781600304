import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./styles/index.sass";
import configureStore from "./store/configureStore";
import RootContainer from "./containers/RootContainer";
import CacheBuster from "./CacheBuster";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


class App extends React.Component {
  render() {
    return (
      <Provider store={configureStore()}>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }
            return <RootContainer />;
          }}
        </CacheBuster>
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorkerRegistration.register();
