import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const AddLinkModal = (props) => {
  const [applytoAll, setapplytoAll] = useState(false);
  return (
    <Modal  isOpen={props.isOpen} toggle={props.toggle} autoFocus={false}>
      <ModalHeader
        toggle={props.toggle}
      >{`Add Link in "${props.sectionTitle}"`}</ModalHeader>
      <ModalBody className="addLinkModal" >
        <div  style={{height: "39vh"}} className="row">
          <div style={{height: "44vh"}} className="linkModal-box linkModal-box-left">
            <div className="add-link-logo-container">
      {/* <img
          src={"/assets/default-logo.png"}
          alt="link logo"
          className="add-link-logo"
         */}
              <small style={{position: "relative", top: "15px"}} className="text-center">Add a link</small>
            </div>
          </div>
          <div style={{height: "44vh"}} className="linkModal-box linkModal-box-right">
            <input
              className="modal-links-input"
              placeholder="Link"
              value={props.modalLink}
              onChange={(e) => props.handleChange(e, "modalLink")}
              autoFocus={true}
            />
          </div>
          {!props.showEditDetails && (
            <div className="mx-auto">
              <input
                type="checkbox"
                name="apllytoAll"
                value={applytoAll}
                onClick={() => setapplytoAll(!applytoAll)}
	      style={{position: "relative", top: "4.3vh", right: "21vh"}}
              />
              <label for="apllytoAll" style={{position: "relative", right: "21vh", top: "4vh"}}>
                &nbsp; Apply link to all List Items
              </label>
              <br></br>
            </div>
          )}
        </div>
        {/* <div className="text-center h5">Or</div>
        <input
          className="modal-links-input"
          placeholder="Add link from a list within the platform"
        /> */}
      </ModalBody>
      <ModalFooter>
        {props.linkFromPlatform ? (
          <Button
            color="primary"
            onClick={(e) =>
              props.handleModelOk(
                props.modalLink,
                props.sectionTitle,
                true,
                applytoAll
              )
            }
          >
            Link items from this list
          </Button>
        ) : (
          <></>
        )}{" "}
        <Button
          color="primary"
          onClick={(e) =>
            props.handleModelOk(
              props.modalLink,
              props.sectionTitle,
              false,
              applytoAll
            )
          }
        >
          Add link
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddLinkModal;
