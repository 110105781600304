import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import moment from "moment";
import ReactQuill from "react-quill";
import swal from "sweetalert";
import {
  DISCOVER_PATH,
  getEditPath,
  getListPath,
  getProfilePath,
  LOGIN_PATH,
} from "../constants/pathConstants";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import ShareIcons from "./ShareIcons";
import {
  Popover,
  Typography,
  Menu,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardActionArea,
  Button,
  Avatar,
} from "@material-ui/core";
import axios from "axios";

import ListLoader from "./ListLoader";
import Error404 from "./Error404";

import QRShareModal from "../components/Nested/QRShareModal";
import { Helmet } from "react-helmet";
import ErrorAlert from "./Nested/ErrorAlert";

/*start import right drawer*/
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Notes from "./Notes";
import Comments from "./Comments";
import ListSettings from "./Nested/ListSettings";
import Recommendations from "./Nested/Recommendations";
import CollaboratorContainer from "./Nested/CollaboratorContainer";
import { fetchMetaData, setRefreshDiscover } from "../actions/apiActions";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import EmbeddedPlayer from "./Nested/EmbeddedPlayer";
import Tooltip from "@material-ui/core/Tooltip";
import EditLogs from "./EditLogs/EditLogs";

import {
  confirmDeletion,
  getShortenedUrlCode,
  is_url,
  getNameFromLink,
  getValidURL,
  getIconFromLink,
} from "./Utils/helperFunctions";
import {
  GET_SHORT_URL_CODE_API,
  ITEM_APPROVAL_API,
} from "../constants/apiConstants";
import { REFRESH_DISCOVER_TRUE } from "../constants/actionTypes";
import { Translate } from "@material-ui/icons";
import ShareNewListModal from "./ShareNewListModal.jsx";
import ShareListModal from "./ShareListModal.jsx";
import ShareAskListModal from "./ShareAskListModal.jsx";

/* end for right drawer*/

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: null,
      lsid: "",
      slug: "",
      bookmarkedStatus: true,
      folders: [],
      fetched: false,
      popoverOpen: false,
      anchorEl: null,
      openedPopoverId: null,
      showAlert: false,
      alertMessage: "",
      alertCode: null,
      isEditable: false,
      showListNotes: false,
      loadingMetadata: false,
      metaURL: "",
      metaTitle: "",
      metaDescription: "",
      metaImage: "",
      promoters: [],
      promotes: 0,
      newListItem: "",
      dialogId: null,
      dialogAncorEl: null,
      isRightOpen: true,
      noOfQuickAddedItems: 0,
      checkedItems: [],
      shortenedCode: "",
      isReportModalOpen: false,
      showSuspectDrawer: false,
      listSuspectMessage: "",
      showShareNewListModal: false,
      highlightRightPanel: false,
      showShareListModal: false,
      listsCreated: 0,
      showAskListModal: true,
      isAskList: false,
    };
  }
  //Snackbar auto-hide method
  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false,
    });
  };

  snackBar = (code, message) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
      alertCode: code,
    });
  };

  async componentWillMount() {
    this.setState({});
    if (!this.props.token) {
      // this.props.history.push("/", {
      //   login_required: true,
      //   redirect_to: this.props.location.pathname
      // });
      this.setState({ fetched: false });
      await this.props
        .fetchListByIdPublic({ lsid: this.props.match.params.id })
        .then(async () => {
          // console.clear();
          // console.log(this.props);
          // console.log(this.props.match.params.id);
          this.setState({
            fetched: true,
          });
          this.setState({
            promoters:
              this.props.fetchedList &&
              this.props.fetchedList.result &&
              this.props.fetchedList.result[0].promoters &&
              this.props.fetchedList.result[0].promoters.length !== 0
                ? this.props.fetchedList.result[0].promoters[0].users
                : [],
            promotes:
              this.props.fetchedList &&
              this.props.fetchedList.result &&
              this.props.fetchedList.result[0].promoters &&
              this.props.fetchedList.result[0].promoters.length !== 0
                ? this.props.fetchedList.result[0].promoters[0].users.length
                : 0,
          });
          if (this.props.fetchedList.result) {
            await this.props.getRecommendation({
              category: this.props.fetchedList.result[0].category,
              lsid: this.props.match.params.id,
            });
          }
        });
      this.setState({ fetched: true });
    } else {
      var { aid, followingList, following } = this.props;
      var data = { aid };
      this.props.fetchFollowingUserList(data, this.props.token);
      this.props.fetchUsersBookmarked(data, this.props.token);
      // this.props.fetchListById({ lsid: this.props.match.params.id, aid: this.props.aid, email:this.props.email}, this.props.token);
      // fetching all folders and boarditems for add to folder functionality
      this.fetchMyFolders();
    }
    document.querySelector("body").classList.add("remove-scroll-bar");



  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.token) {
      // console.log(this.props);
      const { fetchedList } = this.props;
      const paramsId = this.props.match.params.id;
      const paramsName = this.props.match.params.name;
      const paramsItemId = this.props.match.params.itemId;
      // TODO: Only fetch if list not already fetched
      // if(Object.keys(fetchedList).length === 0 || fetchedList.constructor === Object)
      await this.fetchListFromServer(paramsId, paramsName, paramsItemId).then(
        async () => {
          await this.props.getRecommendation({
            category: this.props.fetchedList.result[0].category,
            lsid: this.props.match.params.id,
          });
        }
      );
      if (
        this.props.fetchedList &&
        this.props.fetchedList.result &&
        this.props.fetchedList.result[0] &&
        this.props.fetchedList.result[0] &&
        this.props.fetchedList.result[0].checked &&
        this.props.fetchedList.result[0].listStyle === "checkbox"
      ) {
        this.setState({
          checkedItems: this.props.fetchedList.result[0].checked,
        });
      }
      if (this.isEditable()) {
        this.setState({ isEditable: true });
      } else {
        this.setState({ isEditable: false });
      }
    }
    let code = await getShortenedUrlCode(window.location.href, "ot");
    this.setState({ shortenedCode: code.slice(0, 8) });
    console.log("GENERATED SHORT CODE", code);

    if (
      this.props.fetchedList &&
      this.props.fetchedList.result &&
      this.props.fetchedList.result[0]
    ) {
      await this.handleView(this.props.fetchedList.result[0]);
      let code = await getShortenedUrlCode(window.location.href, "ot");
      this.setState({ shortenedCode: code.slice(0, 8) });
      console.log("GENERATED SHORT CODE", code);
    }


    // checking if the user is here because he was redirected after creating a list

    // if the user has been redirected or if the list is an ask list
    if ( this.props.location.state ) {

      // if the user has created a list
      if( this.props.location.state.listsCreated ) {
        this.setState({ listsCreated: this.props.location.state.listsCreated });

      if (this.props.location.state.listsCreated <= 3) {
        this.setState({ showShareNewListModal: true });
      }
        else if (this.props.location.state.listsCreated >= 4) {
          this.setState({ highlightRightPanel: true });
      }


      }

      // if the user has created an ask list
      if (this.props.location.state.askPublished == true) {

        this.setState({ highlightRightPanel: true });
        this.setState({ showShareAskListModal: true });

      }


      if ( this.props.location.state.isAskList == true ) {

        this.setState( { isAskList: true } );

      }

    }


}

  async handleView(list) {
    if (
      (this.props.history.action === "PUSH" &&
        (!this.props.aid || (this.props.aid && this.props.aid !== list.aid))) ||
      window.$IS_FRESH_REQUEST
    )
      await this.props.increaseView(list._id, this.props.aid);
  }

  async componentDidUpdate(nextProps) {
    var { aid } = this.props;
    var data = { aid };
    if (!this.state.fetched) {
      var paramsId = this.props.match.params.id;
      var paramsName = this.props.match.params.name;
      // data['lsid'] = this.props.lists.result[paramsId]['_id'];
      data["lsid"] = paramsId;
      data["slug"] = paramsName;
      if (this.state.lsid === "") {
        await this.setState({ lsid: data["lsid"] }, () => {
          this.props.fetchBookmarkOfList(data, this.props.token);
        });
      }

      // this.props.fetchBookmarkOfList(data,this.props.token)
    }
    // this.props.fetchFollowingUserList(data,this.props.token);
    if (this.state.openedPopoverId && this.state.loadingMetadata === false) {
      console.log(this.state.openedPopoverId.split("--")[1]);
      if (this.state.metaURL !== this.state.openedPopoverId.split("--")[1]) {
        this.setState({ loadingMetadata: true });
        fetchMetaData({
          url: this.state.openedPopoverId.split("--")[1],
        }).then((res) => {
          console.log(res);
          this.setState({
            metaURL: res.url,
            metaTitle: res.title,
            metaDescription: res.description,
            metaImage: res.image,
            loadingMetadata: false,
          });
        });
      }
    }
  }

  async componentWillUnmount() {
    // TODO: Remove list data from the redux store.
    this.props.clearCurrentListDataFromStore();
    // Add the scrollbar back
    document.querySelector("body").classList.remove("remove-scroll-bar");
  }

  //Handle note button drop action
  noteBtnDragOver = (e) => {
    e.preventDefault();
  };

  noteBtnDragEnter = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    e.target.classList.add("note-btn-hovered");
  };

  noteBtnDragLeave = (e) => {
    e.target.classList.remove("note-btn-hovered");
  };

  noteBtnDrop = async (e, data) => {
    e.target.classList.remove("note-btn-hovered");
    // console.log("Dropped", data);
    if (data.type === "listItem") await e.target.click();

    this.props.handleNoteButtonDrop(data);
  };

  fetchListFromServer = async (paramsId, paramsName, paramsItemId) => {
    this.setState({ fetched: false });
    await this.props
      .fetchListById(
        {
          nameSlug: paramsName,
          lsid: paramsId,
          aid: this.props.aid,
          email: this.props.email,
        },
        this.props.token
      )
      .then(() => {
        this.setState({
          fetched: true,
        });
      });
    var { aid } = this.props;
    var data = { aid };
    this.props.fetchFollowingUserList(data, this.props.token);
    if (Object.keys(this.props.fetchedList).length !== 0) {
      console.log("PROMOTE_LIST");
      console.log(
        this.props.fetchedList && this.props.fetchedList.result[0].promotes
          ? this.props.fetchedList.result[0].promotes
          : 0
      );

      this.setState({
        promoters:
          this.props.fetchedList &&
          this.props.fetchedList.result[0].promoters &&
          this.props.fetchedList.result[0].promoters.length !== 0
            ? this.props.fetchedList.result[0].promoters[0].users
            : [],
        promotes:
          this.props.fetchedList &&
          this.props.fetchedList.result[0].promoters &&
          this.props.fetchedList.result[0].promoters.length !== 0
            ? this.props.fetchedList.result[0].promoters[0].users.length
            : 0,
      });
    }

    // this.props.fetchBookmarkOfList(data,this.props.token)
  };

  fetchMyFolders = () => {
    if (!this.props.token) return;
    let folders = [];
    this.props
      .fetchBoards(
        { aid: this.props.aid, requester: this.props.user_name },
        this.props.token
      )
      .then(() => {
        this.props.folders.forEach((folder) => {
          folders.push({ _id: folder._id, name: folder.name });
        });
        this.props.boards.forEach((board) => {
          board.folders.forEach((folder) => {
            folders.push({ _id: folder._id, name: folder.name });
          });
        });
      });
    this.setState({ folders: folders });
  };

  handlePopoverOpen = (event, popoverId) => {
    if (
      this.state.openedPopoverId !== popoverId &&
      this.state.anchorEl === null
    ) {
      this.setState({
        openedPopoverId: popoverId,
        anchorEl: event.target,
      });
    }
  };
  handlePopoverClose = () => {
    this.setState({
      openedPopoverId: null,
      anchorEl: null,
    });
  };

  handleUndoClick = async () => {
    let data = {
      aid: this.props.aid,
      lsid: this.state.lsid,
      lsitid: this.props.listItems[this.props.listItems.length - 1]._id,
    };

    await this.props.deleteListItem(data, this.props.token);
    if (this.props.removedListItem === true) {
      this.props.fetchListById(
        {
          nameSlug: this.props.fetchedList.result[0].slug,
          lsid: this.state.lsid,
          aid: this.props.aid,
          email: this.props.email,
        },
        this.props.token
      );

      let data2 = {
        aid: this.props.aid,
        lsid: this.state.lsid,
      };
      this.setState({
        newListItem: "",
      });
      this.props.fetchBookmarkOfList(data2, this.props.token);
      this.snackBar("success", `Listitem removed successfully!!`);
      this.setState({
        noOfQuickAddedItems: this.state.noOfQuickAddedItems - 1,
      });
    } else {
      this.snackBar("error", this.props.removedListItem);
    }
  };

  onDeleteList = async () => {
    let isConfirmed = await confirmDeletion();
    if (isConfirmed) {
      const { token, aid } = this.props;
      const { id } = this.props.match.params;
      const data = {
        aid,
        lsid: id,
      };
      console.log(data);
      const res = await this.props.deleteList(data, token);
      if (res !== "ERR") {
        this.props.setRefreshDiscover();
        this.props.history.push(DISCOVER_PATH);
      } else {
        this.snackBar("error", "Something went wrong!!");
      }
    }
  };

  onDeleteListItem = async (e, index) => {
    e.stopPropagation();
    const { token, aid, fetchedList } = this.props;
    const { id } = this.props.match.params;
    const data = {
      aid,
      lsid: id,
      lsitid: fetchedList.result[0].item[index]._id,
    };
    const res = await this.props.deleteListItem(data, token);
    if (res !== "ERR") {
      setTimeout(() => {
        this.snackBar("success", "Listitem Deleted Successfully!!");
      }, 3000);
      this.props.history.push(DISCOVER_PATH);
    } else {
      this.snackBar("error", "Something went wrong!!");
    }
  };

  onUpvoteListItem = async (e, index) => {
    e.stopPropagation();
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, {
        redirect_to: this.props.location.pathname,
      });
      return;
    }
    const { token, aid, fetchedList } = this.props;
    const { id } = this.props.match.params;
    const data = {
      aid,
      lsitid: fetchedList.result[0].item[index]._id,
    };
    this.props
      .upvoteListItem(data, token)
      .then((res) => {
        // console.log(res);
        var data2 = {
          aid,
          lsid: id,
        };
        this.props.fetchBookmarkOfList(data2, token);
      })
      .catch((e) => {
        console.log(e);
      });
    // if (res !== 'ERR') {
    //   // alert('Upvoted list successfully!');
    //   window.location.reload();
    // }
  };

  onDeleteUpvoteListItem = async (e, index) => {
    e.stopPropagation();
    const { token, aid, fetchedList } = this.props;
    const { id } = this.props.match.params;
    const data = {
      aid,
      lsitid: fetchedList.result[0].item[index]._id,
    };
    this.props
      .deleteUpvoteListItem(data, token)
      .then((res) => {
        // console.log(res);
        var data2 = {
          aid,
          lsid: id,
        };
        this.props.fetchBookmarkOfList(data2, token);
      })
      .catch((e) => {
        console.log(e);
      });
    // if (res !== 'ERR') {
    //   // alert('Upvoted list successfully!');
    //   window.location.reload();
    // }
  };

  onBookMarkListItem = async (e, index) => {
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, {
        redirect_to: this.props.location.pathname,
      });
      return;
    }
    e.stopPropagation();
    const { token, aid, fetchedList } = this.props;
    const { id } = this.props.match.params;
    const data = {
      aid,
      lsitid: fetchedList.result[0].item[index]._id,
    };
    this.props
      .bookmarkListItem(data, token)
      .then((res) => {
        var data2 = {
          aid,
          lsid: id,
        };

        this.props.fetchBookmarkOfList(data2, token);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onDeleteBookMarkListItem = async (e, index) => {
    e.stopPropagation();
    const { token, aid, fetchedList } = this.props;
    const { id } = this.props.match.params;
    const data = {
      aid,
      lsitid: fetchedList.result[0].item[index]._id,
    };
    this.props
      .deletebookmarkListItem(data, token)
      .then((res) => {
        var data2 = {
          aid,
          lsid: id,
        };
        this.props.fetchBookmarkOfList(data2, token);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  async handleAddToFolder(item, type) {
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, {
        redirect_to: this.props.location.pathname,
      });
      return;
    }
    var folders = [];
    await this.props
      .fetchBoards(
        { aid: this.props.aid, requester: this.props.user_name },
        this.props.token
      )
      .then(() => {
        this.props.folders.forEach((folder) => {
          folders.push({ _id: folder._id, name: folder.name });
        });
        this.props.boards.forEach((board) => {
          board.folders.forEach((folder) => {
            folders.push({ _id: folder._id, name: folder.name });
          });
        });
      });
    this.setState({ folders: folders });
    var folderOptions = {};
    folders.forEach((folder) => {
      folderOptions[folder._id] = folder.name;
    });

    if (Object.keys(folderOptions).length > 0) {
      const { value: folderChoice } = await Swal.fire({
        title: "Select A folder",
        input: "radio",
        inputOptions: folderOptions,
        inputValidator: (value) => {
          if (!value) {
            return "You need to select a Folder!";
          }
        },
      });

      if (folderChoice) {
        const { aid, token } = this.props;
        let data;
        if (type === "list") {
          data = {
            aid: aid,
            folderId: folderChoice,
            lsid: item._id,
          };
        } else {
          data = {
            aid: aid,
            folderId: folderChoice,
            lsitid: item._id,
          };
        }

        await this.props.addListToFolder(data, token).then(() => {
          this.snackBar(
            "success",
            `Added listitem to ${folderOptions[folderChoice]}`
          );
        });
      }
    } else {
      // Swal.fire({
      //   title: "No Folders :(",
      //   text: "Please create a folder in your profile page",
      //   type: "error"
      // });
      this.snackBar("error", "No Folders!!");
    }
  }

  /*===============================add list to folder==================================*/

  handleAddListToFolder = async (e, folderId, lsid) => {
    e.preventDefault();
    let data = {
      aid: this.props.aid,
      folderId,
      lsid,
    };
    console.log(data);
    await this.props.addListToFolder(data, this.props.token).then(() => {
      this.snackBar("success", `Added list to folder!!`);
    });
  };

  handleGoBack = () => {
    window.history.back();
  };

  isEditable = () => {
    let list = this.props.fetchedList.result
      ? this.props.fetchedList.result[0]
      : null;
    if (!list) return false;
    if ((list.public && !list.close) || list.aid == this.props.aid) {
      return true;
    } else if (list.collaborators && list.collaborators.length > 0) {
      let collabIndex = list.collaborators
        .map((collab) => {
          return collab.email;
        })
        .indexOf(this.props.email);
      if (
        collabIndex !== -1 &&
        list.collaborators[collabIndex].status === "accepted"
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.props.user_type !== "normal") {
      return true;
    } else {
      return false;
    }
  };

  handleEditlist = () => {
    const id = this.state.lsid;
    const slug = this.props.match.params.name;
    this.props.history.push(getEditPath(slug, id));
  };

  handlePromoteList = async (e, flag) => {
    e.preventDefault();
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, {
        redirect_to: this.props.location.pathname,
      });
      return;
    }
    let data = {
      username: this.props.user_name,
      lsid: this.state.lsid,
    };
    if (flag == 1) {
      let promoters = new Set([
        ...(this.props.fetchedList.result[0].promoters.length !== 0
          ? this.props.fetchedList.result[0].promoters[0].users
          : []),
        this.props.user_name,
      ]);
      promoters = Array.from(promoters);

      this.setState({
        promoters,
        promotes: promoters.length,
      });
    } else {
      let promoters =
        this.props.fetchedList.result[0].promoters.length !== 0
          ? this.props.fetchedList.result[0].promoters[0].users
          : [];
      promoters.splice(promoters.indexOf(this.props.user_name), 1);

      this.setState({
        promoters,
        promotes: promoters.length,
      });
    }
    await this.props.promoteList(data, this.props.token);
    this.props.fetchListById(
      {
        nameSlug: this.props.fetchedList.result[0].slug,
        lsid: this.state.lsid,
        aid: this.props.aid,
        email: this.props.email,
      },
      this.props.token
    );
  };

  handleAddListItemValue = (e) => {
    this.setState({
      newListItem: e.target.value,
    });
  };

  addItemInDB = async (data) => {
    await this.props.addListItem(data, this.props.token);
    if (this.props.addListItemMessage !== "success") {
      this.snackBar("error", this.props.addListItemMessage);
      return;
    }

    this.props.fetchListById(
      {
        nameSlug: this.props.fetchedList.result[0].slug,
        lsid: this.state.lsid,
        aid: this.props.aid,
        email: this.props.email,
      },
      this.props.token
    );

    let data2 = {
      aid: this.props.aid,
      lsid: this.state.lsid,
    };
    this.setState({
      newListItem: "",
    });
    this.props.fetchBookmarkOfList(data2, this.props.token);
    this.snackBar("success", `Listitem added successfully!!`);
  };

  getCurrentListItems = () => {
    return this.props.fetchedList.result[0].item.map((listItem) =>
      listItem.list_name.toLowerCase()
    );
  };

  checkIfItemAlreadyPresentInTheList = (item) => {
    return this.getCurrentListItems().includes(item.toLowerCase());
  };

  handleAddListItem = async (e) => {
    e.preventDefault();
    let key = e.keyCode;
    if (key === 13) {
      let isURL = false;
      let URLitem = "";

      let { newListItem } = this.state;

      // trim white space
      newListItem = newListItem.trim();

      if (newListItem !== "") {
        // Check if item is a URL

        if (is_url(newListItem)) {
          isURL = true;
          URLitem = newListItem;
          newListItem = getNameFromLink(newListItem);
        }

        // Check if this list already exixts
        if (this.checkIfItemAlreadyPresentInTheList(newListItem)) {
          alert(`${newListItem} already present in the list !!`);
          return;
        }

        let data = {
          aid: this.props.aid,
          user_name: this.props.user_name,
          lsid: this.state.lsid,
          listItem: {
            list_name: newListItem,
            description: "",
            highlights: [],
            about: !isURL
              ? []
              : [
                  {
                    link: getValidURL(URLitem),
                    icon: getIconFromLink(URLitem),
                  },
                ],
            sections: [],
            links: "",
          },
        };
        // console.clear();
        // console.log(this.props);
        await this.props.addListItem(data, this.props.token);
        if (this.props.addListItemMessage === "success") {
          this.props.fetchListById(
            {
              nameSlug: this.props.fetchedList.result[0].slug,
              lsid: this.state.lsid,
              aid: this.props.aid,
              email: this.props.email,
            },
            this.props.token
          );

          let data2 = {
            aid: this.props.aid,
            lsid: this.state.lsid,
          };
          this.setState({
            newListItem: "",
          });
          this.props.fetchBookmarkOfList(data2, this.props.token);
          this.snackBar("success", `Listitem added successfully!!`);
          this.setState({
            noOfQuickAddedItems: this.state.noOfQuickAddedItems + 1,
          });
        } else {
          this.snackBar("error", this.props.addListItemMessage);
        }
      }
    } else {
      return;
    }
  };

  onDeleteCollab = async (e, collab) => {
    e.stopPropagation();
    if (!collab) {
      this.snackBar("error", "Something went wrong!!");
      return;
    }
    Swal.fire({
      title: "Are you sure to proceed?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Proceed",
    }).then(async (result) => {
      if (result.value) {
        const { token, aid } = this.props;
        const { id } = this.props.match.params;
        const data = {
          aid,
          lsid: id,
          collabEmail: collab,
        };
        await this.props.deleteCollab(data, token);
        if (this.props.deletedCollab && this.props.deletedCollab !== "ERR") {
          this.props.fetchListById(
            {
              nameSlug: this.props.fetchedList.result[0].slug,
              lsid: this.state.lsid,
              aid: this.props.aid,
              email: this.props.email,
            },
            this.props.token
          );
        } else {
          this.snackBar("error", `Something went wrong!!`);
        }
      }
    });
  };

  toggleDrawer = () => {
    this.setState({ showListNotes: !this.state.showListNotes });
  };

  handleShowListNotes = async () => {
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, {
        redirect_to: this.props.location.pathname,
      });
      return;
    }
    let listId = this.state.lsid;
    let lsItemId = null;
    if (
      this.props.match.params.itemId &&
      this.props.match.params.itemId !== "item"
    ) {
      lsItemId = this.props.match.params.itemId;
    }

    // ============fetch all comments for a list ====================X
    this.props.fetchAllComments(listId);

    // ============fetch reply comments for a comment ===============X
    // this.props.fetchAllComments(listId, '6041fc9e77f683882860f892')

    // ============create comment ===================================x
    // this.props.createComment(listId, 'test comment 5')

    // ============create reply comment =============================x
    // this.props
    //   .createComment(
    //     listId,
    //     'reply to test comment',
    //     '603f3776414e062b3557f24b'
    //   )

    // ============edit comment =====================================
    // this.props
    //   .editComment('603f3776414e062b3557f24b', 'edited test comment 30')

    // ============delete comment ===================================
    // this.props.deleteComment("6041fb9977f683882860f891", '603f3776414e062b3557f24b');

    // ============old code =========================================
    // this.props.fetchAllListNotes(listId, lsItemId);
    this.toggleDrawer();
  };

  renderDrawer = () => {
    if (this.props.token) {
      return (
        <Drawer
          variant="persistent"
          anchor="right"
          open={this.state.showListNotes}
          className="right-drawers"
        >
          <div className={"drawerHeader"}>
            <IconButton onClick={this.toggleDrawer}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          <Comments {...this.props} />
          {/* <Notes mode='all' {...this.props} /> */}
        </Drawer>
      );
    } else {
      return <></>;
    }
  };

  handleMakeListSuspected = async () => {
    swal({
      content: {
        element: "input",
        attributes: {
          placeholder: "Justify reason for making the list suspected!",
          type: "text",
        },
      },
    }).then(async (message) => {
      if (!message) return;

      let data = {
        aid: this.props.aid,
        lsid: this.state.lsid,
        message,
      };

      await this.props.makeListSuspected(data, this.props.token);

      if (this.props.makeListSuspectedMessage !== "success") {
        this.snackBar("error", this.props.makeListSuspectedMessage);
        return;
      }

      this.props.fetchListById(
        {
          nameSlug: this.props.fetchedList.result[0].slug,
          lsid: this.state.lsid,
          aid: this.props.aid,
          email: this.props.email,
        },
        this.props.token
      );

      this.snackBar(
        "success",
        "List is marked suspected and anchor is notified."
      );
    });
  };

  handleShowSuspectDrawer = () => {
    this.setState({ showSuspectDrawer: !this.state.showSuspectDrawer });
  };

  updateListSuspectMessage = (text) => {
    this.setState({ listSuspectMessage: text.trim() });
  };

  submitListSuspectMessage = async (e) => {
    e.preventDefault();
    let data = {
      aid: this.props.aid,
      message: this.state.listSuspectMessage,
      sid: this.props.fetchedList.result[0].suspectedDetails._id,
    };

    await this.props.respondSuspectedList(data, this.props.token);

    if (this.props.respondSuspectedListMessage !== "success") {
      this.snackBar("error", this.props.respondSuspectedListMessage);
      return;
    }

    this.setState({ listSuspectMessage: "" });
    this.props.fetchListById(
      {
        nameSlug: this.props.fetchedList.result[0].slug,
        lsid: this.state.lsid,
        aid: this.props.aid,
        email: this.props.email,
      },
      this.props.token
    );
  };

  updateListSuspectStatus = async (e, status) => {
    e.preventDefault();

    let cnf = await confirmDeletion();

    if (!cnf) return;

    let data = {
      aid: this.props.aid,
      status,
      sid: this.props.fetchedList.result[0].suspectedDetails._id,
    };

    await this.props.updateSuspectedListStatus(data, this.props.token);

    if (this.props.updateSuspectedListStatusMessage !== "success") {
      this.snackBar("error", this.props.updateSuspectedListStatusMessage);
      return;
    }

    this.props.fetchListById(
      {
        nameSlug: this.props.fetchedList.result[0].slug,
        lsid: this.state.lsid,
        aid: this.props.aid,
        email: this.props.email,
      },
      this.props.token
    );
  };

  renderSuspectDrawer = () => {
    if (
      this.props.fetchedList &&
      this.props.fetchedList.result &&
      this.props.fetchedList.result[0] &&
      this.props.fetchedList.result[0].lsStatus === "suspected" &&
      (this.props.aid ===
        this.props.fetchedList.result[0].suspectedDetails.aaid ||
        this.props.aid === this.props.fetchedList.result[0].aid)
    ) {
      return (
        <Drawer
          variant="persistent"
          anchor="right"
          open={this.state.showSuspectDrawer}
          className="right-drawer"
        >
          <div className={"drawerHeader"}>
            <IconButton onClick={this.handleShowSuspectDrawer}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          <div className="modal-dialog notif_container">
            <div className="modal-content" style={{ border: "none" }}>
              <div className="modal-header">
                <h4
                  className="modal-title notif-header-title"
                  id="ModalLabel"
                  style={{ marginLeft: "20px" }}
                >
                  List Suspect Details
                </h4>
              </div>
              {(this.props.user_type === "amender" ||
                this.props.user_type === "mender") &&
              this.props.aid ===
                this.props.fetchedList.result[0].suspectedDetails.aaid ? (
                <div className="mt-1" style={{ marginLeft: "25px" }}>
                  <button
                    className="btn btn-success m-2"
                    onClick={(e) => this.updateListSuspectStatus(e, "resolved")}
                  >
                    RESOLVE
                  </button>

                  <button
                    className="btn btn-danger m-2"
                    onClick={(e) => this.updateListSuspectStatus(e, "rejected")}
                  >
                    REJECT
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          <div className="container note-parent" style={{ width: "400px" }}>
            <div className="notes-component-container">
              {this.props.fetchedList.result[0].suspectedDetails.messages.map(
                (msg, i) => (
                  <div
                    className="notes-container"
                    key={i}
                    style={
                      msg.by === this.props.aid
                        ? { background: "#deffe5" }
                        : null
                    }
                  >
                    <div className="active-note-container">
                      <ReactQuill
                        value={`
                        <h1>${msg.message}</h1><br>
                        <h3>${
                          msg.by === this.props.aid
                            ? "me"
                            : this.props.aid ===
                              this.props.fetchedList.result[0].aid
                            ? "Admin"
                            : "Anchor"
                        }</h3><br>
                        <h4>${moment(moment(msg.at).utc()).fromNow()}</h4>
                      `}
                        readOnly={true}
                        theme={"bubble"}
                        className="note-text note-text-top"
                      />
                    </div>
                  </div>
                )
              )}
              <div className="notes-container">
                <div className="active-note-container">
                  <ReactQuill
                    value={this.state.listSuspectMessage}
                    onChange={this.updateListSuspectMessage}
                    readOnly={false}
                    theme={"bubble"}
                    className="note-text note-text-top"
                  />

                  <div className="tool-container">
                    <button
                      className="tool btn"
                      onClick={(e) => this.submitListSuspectMessage(e)}
                      title="click to save this note"
                    >
                      <i className="far fa-check-circle" /> Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      );
    }

    return <></>;
  };

  renderShareIcons = (listName) => {
    const baseShareUrl = `${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}`;
    // don't remove the new lines in the title they are for formatting
    const title = `🤗 Hey guys, Just created a ${listName} on Listiee @listiee01!

Check it out.

#lists #listiee

`;
    return <ShareIcons shareUrl={baseShareUrl} title={title} highlight={(this.state.highlightRightPanel == true ? true : false )} showShareListModal={this.handleShowShareListModal} />
  };

  handleExpandGroupedLink = (dialogId, event) => {
    this.setState({ dialogId, dialogAncorEl: event.currentTarget });
  };

  handleShrinkGroupedLink = () => {
    console.log("Closing group link popover");
    this.setState({ dialogId: null, dialogAncorEl: null });
  };

  handleCheckUpdate = async (e, lsitid) => {
    let { aid, token, checkboxListItem } = this.props;
    let currentChecked = this.state.checkedItems;
    let data = {
      aid,
      lsid: this.state.lsid,
      lsitid,
    };
    if (e.target.checked) {
      currentChecked.push(lsitid);
    } else {
      currentChecked.splice(currentChecked.indexOf(lsitid), 1);
    }
    this.setState({ checkedItems: currentChecked });
    if (token) {
      checkboxListItem(data, token);
    }
  };

  toApproveItem = async (item_id, current_user_aid, approved_or_not) => {
    let data = {
      item_id,
      user_aid: current_user_aid,
      to_be_approved: approved_or_not,
    };
    let options = {
      headers: {
        "x-access-token": this.props.token,
      },
    };
    try {
      let response = await axios.post(ITEM_APPROVAL_API, data, options);
      const { fetchedList } = this.props;
      const paramsId = this.props.match.params.id;
      const paramsName = this.props.match.params.name;
      const paramsItemId = this.props.match.params.itemId;
      await this.fetchListFromServer(paramsId, paramsName, paramsItemId);
    } catch (err) {
      this.snackBar("error", `An error occured on server`);
      console.log(err);
    }
  };

  // ---------------------------Share modal methods---------------------------

  // handling when the user closes the ShareNewListModal
  handleCloseShareNewListModal = (e) => {

    this.setState({ showShareNewListModal: false });

  }

  handleShowShareListModal = () => {

    // checking if redirected after asking for a list
    if (this.props.location.state) {
      if ( this.props.location.state.askPublished == true || this.state.isAskList == true) {
        this.setState({
          showShareAskListModal: true
        })
      }
      else {
        this.setState({
          showShareListModal: true
        })
      }
    }
    else {
      this.setState({
        showShareListModal: true
      })
    }
  }

  handleCloseShareListModal = () => {


    // // checking if the redirected after asking for a list
    // if (this.props.location.state) {
    //   if ( this.props.location.state.askPublished == true || this.state.isAskList == true) {
    //     this.setState({
    //       showShareAskListModal: false
    //     })
    //   }
    //   else {
    //     this.setState({
    //       showShareListModal: false
    //     })
    //   }
    // }
    // else {
    //   this.setState({
    //     showShareListModal: false
    //   })
    // }

    // closing both the modals when the user clicks the close button on the share modal to avoid wrong modal being opened and getting stuck due to slow props loading

    this.setState({ showShareAskListModal: false });
    this.setState({ showShareListModal: false });
  }

  render() {
    const paramsId = this.props.match.params.id;
    const paramsName = this.props.match.params.name;
    const paramsItemId = this.props.match.params.itemId;

    // console.log("state",this.state);
    // console.log("props", this.props);
    if (!this.state.fetched)
      return (
        <div>
          {/* <div className="top-overlay"></div> */}
          <div className="list-page-loader">
            <button className="cancel-load" onClick={this.handleGoBack}>
              <i
                className="fas fa-backspace"
                style={{ fontSize: "25px", color: "#545454" }}
              />
            </button>
            <ListLoader />
          </div>
        </div>
      );

    const { selectedItem } = this.state;
    const { fetchedList, lists, aid, followingList } = this.props;
    var { following } = this.props;
    const { id } = this.props.match.params;
    //var currentList = lists.result[id];

    // ERROR HANDELING when list link is invalid. i.e No list data is fetched from the server
    if (
      Object.keys(fetchedList).length === 0 &&
      fetchedList.constructor === Object
    ) {
      return <Error404 />;
    }

    var currentList = fetchedList.result[0];
    var currentList2 = this.props.listItems;
    // var currentList2 = fetchedList.result[0].item;

    if (followingList && followingList.length !== 0) {
      if (followingList[0].following)
        following = followingList[0].following.includes(currentList.aid);
    }
    const onSelectItem = (selectedItem, itemId) => {
      this.props.history.replace(getListPath(paramsName, paramsId, itemId));
      this.setState({
        selectedItem,
      });
    };

    const renderItems = () => {
      let idx = 0;
      return currentList2.map((item, index) => {
        if (item.approved == true || typeof item.approved === "undefined") {
          idx = idx + 1;
          // console.log(item.approved)
          return (
            <>
              <li
                key={index}
                className={
                  item._id === paramsItemId
                    ? "list_item_selected"
                    : "list_item_unselected"
                }
                onClick={() => onSelectItem(index, item._id)}
              >
                <span
                  className="list-items-name"
                  title="List item"
                  onDragEnter={this.noteBtnDragEnter}
                  onDragLeave={this.noteBtnDragLeave}
                  onDragOver={this.noteBtnDragOver}
                  onDrop={(e) =>
                    this.noteBtnDrop(e, {
                      type: "listItem",
                      data: {
                        url: window.location.pathname,
                        listName: currentList.name,
                        text: item.list_name,
                      },
                    })
                  }
                >
                  {currentList.listStyle === "radio" ? (
                    <i className="fas fa-circle listitem_bullet"> </i>
                  ) : currentList.listStyle === "checkbox" ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          size="small"
                          style={{
                            fontSize: "1rem",
                            padding: "0px",
                          }}
                          checked={
                            this.state.checkedItems.filter((itm) => {
                              return itm == item._id;
                            }).length
                              ? true
                              : false
                          }
                          onChange={(e) => this.handleCheckUpdate(e, item._id)}
                          onClick={(e) => {}}
                        />
                      }
                    />
                  ) : (
                    `${idx}. `
                  )}{" "}
                  {item.list_name}
                </span>

                <span className="list-items-controls">
                  {item.upvotes && item.upvotes.length !== 0 ? (
                    <button
                      onClick={(e) => this.onDeleteUpvoteListItem(e, index)}
                      title="Upvote"
                      className={"text-success"}
                    >
                      <i className={"fas fa-thumbs-up"} />
                    </button>
                  ) : (
                    <button
                      onClick={(e) => this.onUpvoteListItem(e, index)}
                      className={"text-success"}
                      title="Upvote"
                    >
                      <i className={"far fa-thumbs-up"} />
                    </button>
                  )}
                  {item.bookmarks && item.bookmarks.length !== 0 ? (
                    <button
                      onClick={(e) => this.onDeleteBookMarkListItem(e, index)}
                      className={"text-primary"}
                      title="Bookmark this"
                    >
                      <i className={"fas fa-bookmark"} />
                    </button>
                  ) : (
                    <button
                      onClick={(e) => this.onBookMarkListItem(e, index)}
                      className={"text-primary"}
                      title="Bookmark this"
                    >
                      <i className={"far fa-bookmark"} />
                    </button>
                  )}
                  {/* {currentList.aid === aid ?<button onClick={e => this.onDeleteListItem(e, index)} className={'text-danger'}>
              <i className={'far fa-trash-alt'} title="Delete Item"/>
              </button>: null} */}
                </span>
              </li>
              {item.about.length > 0 ? (
                <EmbeddedPlayer link={item.about[0].link} />
              ) : null}
            </>
          );
        } else if (
          this.props.aid === this.props.fetchedList.result[0].aid ||
          this.props.aid === item.aid
        ) {
          idx = idx + 1;
          return (
            <>
              <li
                key={index}
                className={`${
                  item._id === paramsItemId
                    ? "list_item_selected"
                    : "list_item_unselected"
                } unapproved`}
                onClick={() => onSelectItem(index, item._id)}
              >
                <span
                  className="list-items-name"
                  title="List item"
                  onDragEnter={this.noteBtnDragEnter}
                  onDragLeave={this.noteBtnDragLeave}
                  onDragOver={this.noteBtnDragOver}
                  onDrop={(e) =>
                    this.noteBtnDrop(e, {
                      type: "listItem",
                      data: {
                        url: window.location.pathname,
                        listName: currentList.name,
                        text: item.list_name,
                      },
                    })
                  }
                >
                  {currentList.listStyle === "radio" ? (
                    <i className="fas fa-circle listitem_bullet"> </i>
                  ) : currentList.listStyle === "checkbox" ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          size="small"
                          style={{
                            fontSize: "1rem",
                            padding: "0px",
                          }}
                          checked={
                            this.state.checkedItems.filter((itm) => {
                              return itm == item._id;
                            }).length
                              ? true
                              : false
                          }
                          onChange={(e) => this.handleCheckUpdate(e, item._id)}
                          onClick={(e) => {}}
                        />
                      }
                    />
                  ) : (
                    `${idx}. `
                  )}{" "}
                  {item.list_name}
                </span>
                <span className="list-items-controls">
                  {/* {console.log(item)} */}
                  {this.props.aid === this.props.fetchedList.result[0].aid ? (
                    <Fragment>
                      <button
                        onClick={(e) =>
                          this.toApproveItem(item._id, this.props.aid, true)
                        }
                        className={"text-success"}
                        title="Approve this item"
                      >
                        <i className={"far fa-check-circle"} />
                      </button>
                      <button
                        onClick={(e) =>
                          this.toApproveItem(item._id, this.props.aid, false)
                        }
                        className={"text-primary"}
                        title="Reject this item"
                      >
                        <i className={"far fa-times-circle"} />
                      </button>
                    </Fragment>
                  ) : (
                    <i
                      title="Pending For Review"
                      className={"fas fa-info-circle"}
                    />
                  )}
                </span>
              </li>
              {item.about.length > 0 ? (
                <EmbeddedPlayer link={item.about[0].link} />
              ) : null}
            </>
          );
        }
      });
    };

    const renderSearchEngines = () => {
      const item = currentList2.filter((item) => item._id === paramsItemId)[0];
      const googleLink = "https://google.com/search?q=" + item.list_name,
        bingLink = "https://www.bing.com/search?q=" + item.list_name,
        duckduckgoLink = "https://duckduckgo.com/?q=" + item.list_name,
        baiduLink =
          "https://www.baidu.com/s?ie=utf-8&f=8&rsv_bp=1&rsv_idx=1&tn=baidu&wd=" +
          item.list_name,
        yandexLink = "https://yandex.com/search/?text=" + item.list_name;
      return (
        <div className="searchEngine">
          {/* <h6>Search Engines</h6> */}
          <Tooltip title="search on google">
            <a href={googleLink} target="_blank">
              <img
                className="searchEngineImg"
                src="https://img.icons8.com/color/35/000000/google-logo.png"
              />
            </a>
          </Tooltip>

          <Tooltip title="search on duckduckgo">
            <a href={duckduckgoLink} target="_blank">
              <img
                className="searchEngineImg"
                src="https://img.icons8.com/color/35/000000/duckduckgo--v1.png"
              />
            </a>
          </Tooltip>

          <Tooltip title="search on baidu">
            <a href={baiduLink} target="_blank">
              <img
                className="searchEngineImg"
                src="https://img.icons8.com/color/35/000000/baidu.png"
              />
            </a>
          </Tooltip>

          <Tooltip title="search on bing">
            <a href={bingLink} target="_blank">
              <img
                className="searchEngineImg"
                src="https://img.icons8.com/color/35/000000/bing.png"
              />
            </a>
          </Tooltip>

          <Tooltip title="search on yandex">
            <a href={yandexLink} target="_blank">
              <img
                className="searchEngineImg"
                src="https://img.icons8.com/windows/35/000000/yandex-international.png"
              />
            </a>
          </Tooltip>
        </div>
      );
    };

    // ====================== list details right box area ==================================
    const renderItemDetails = () => {
      const item = currentList.item.filter(
        (item) => item._id === paramsItemId
      )[0];

      if (!item)
        return (
          <p className="no-detail-text">
            Select an item to see if we have some further links and stuff for
            you.
            <img
              draggable="false"
              className="emoji"
              alt="😀"
              src={"/assets/smileEmoji.svg"}
            />
          </p>
        );
      return (
        <div>


          <div className={"desc_row"}>
            <div className="detail-card">
              <div className="detail-box">
                <div className="detail-header">
                  <div className="header-top">
                    <img
                      src={"/assets/lists.png"}
                      alt="list item logo"
                      className="list-item-header-logo"
                      title="Logo"
                    />
                    <p
                      className="list-header-title"
                      title="List item title"
                      onDragEnter={this.noteBtnDragEnter}
                      onDragLeave={this.noteBtnDragLeave}
                      onDragOver={this.noteBtnDragOver}
                      onDrop={(e) =>
                        this.noteBtnDrop(e, {
                          type: "listItem",
                          data: {
                            url: window.location.pathname,
                            listName: currentList.name,
                            text: item.list_name,
                          },
                        })
                      }
                    >
                      {item.list_name}
                    </p>
                    {renderSearchEngines()}
                  </div>

                  {/* {item.description ? (
                      <p
                    style={{
                      whiteSpace: 'pre-wrap',
                      lineHeight: '1.5',
                      fontSize: '14px',
                      fontWeight: '500',
                      color: '#858586',
                      padding: '16px 34px 2px 34px',
                      fontFamily:
                        '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                    }}
                    title='Description'
                    onDragEnter={this.noteBtnDragEnter}
                    onDragLeave={this.noteBtnDragLeave}
                    onDragOver={this.noteBtnDragOver}
                    onDrop={(e) =>
                      this.noteBtnDrop(e, {
                        type: 'listItem',
                        data: {
                          url: window.location.pathname,
                          listName: currentList.name,
                          text: item.description,
                        },
                      })
                    }
                  >
                    {item.description}
                  </p>
                    ):
                    (
                    <p style={{
                      whiteSpace: 'pre-wrap',
                      lineHeight: '1.5',
                      fontSize: '14px',
                      fontWeight: '500',
                      color: '#858586',
                      width: "90%",
                      margin: "14px",
                      
                      background: "#efeff1",
                      padding: '34px',
                      fontFamily:
                        '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                    }}>Add a description for {item.list_name}</p>

                )} */}
                </div>
                <div className="detail-body">
                  {/* {item.highlights ? (
                    item.highlights.length ? (
                      <div className='highlights-container'>
                        <div className='hl-title'> Highlights</div>
                        <ul>{renderHighlights(item.highlights)}</ul>
                      </div>
                    ) : null
                  ) : null} */}

                  {item.about ? (
                    item.about.length ? (
                      <div className="about-container">
                        <h5>About</h5>
                        {renderAbout(item.about)}
                      </div>
                    ) : (
                      <>
                        <p
                          style={{
                            fontFamily:
                              '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                            fontWeight: "600",
                            marginTop: "10px",
                          }}
                        >
                          About
                        </p>
                        <p
                          style={{
                            padding: "14px",
                            background: "#efeff1",
                            width: "fit-content",
                            borderRadius: "5px",
                            color: "#858586",
                          }}
                        >
                          <i
                            class="fas fa-plus dummy-list-add"
                            onClick={this.handleEditlist}
                          ></i>
                        </p>
                      </>
                    )
                  ) : null}

                  {item.sections ? (
                    <div className="section-container">
                      {renderSections(item.sections)}
                    </div>
                  ) : null}

                  {item.links[0] ? (
                    <div className="link-container">
                      <h5>Other Links</h5>
                      <ul>{convertStringToLink(item.links[0])}</ul>
                    </div>
                  ) : (
                    <>
                      <p
                        style={{
                          fontFamily:
                            '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      >
                        Other link
                      </p>
                      <p
                        style={{
                          padding: "14px",
                          background: "#efeff1",
                          width: "fit-content",
                          borderRadius: "5px",
                          color: "#858586",
                        }}
                      >
                        <i
                          class="fas fa-plus dummy-list-add"
                          onClick={this.handleEditlist}
                        ></i>
                      </p>
                    </>
                  )}
                </div>

                {/* ======================================================================================================== */}
                <div className="detail-footer">
                  {item.description ||
                  item.highlights.length ||
                  item.about.length ||
                  item.sections.length ? null : (
                    <>
                      <p
                        style={{
                          fontFamily:
                            '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                          fontWeight: "400",
                          marginTop: "10px",
                          paddingLeft: "10px",
                          color: "#858586",
                        }}
                      >
                        Share links, categorize stuff here.
                      </p>
                    </>
                  )}
                  {/* Add controls here */}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const renderHighlights = (highlights) => {
      return highlights.map((highlight, i) => (
        <li
          key={i}
          onDragEnter={this.noteBtnDragEnter}
          onDragLeave={this.noteBtnDragLeave}
          onDragOver={this.noteBtnDragOver}
          onDrop={(e) =>
            this.noteBtnDrop(e, {
              type: "highlights",
              data: {
                url: window.location.pathname,
                listName: currentList.name,
                text: highlight,
              },
            })
          }
        >
          <i className="fa fa-hand-point-right"> </i> {highlight}
        </li>
      ));
    };

    const renderAbout = (about) => {
      return <ul className="about">{renderLinks(about)}</ul>;
    };

    const renderSections = (sections) => {
      return sections.map((section, i) => {
        if (section.items) {
          return (
            <div key={section.title + i} className="section">
              <div className="section-title"> {section.title} </div>
              <ul className="section-items">{renderLinks(section.items)}</ul>
            </div>
          );
        }
      });
    };

    const groupLinks = (items) => {
      // console.log("links=",items)
      let groupedLinksObj = {};
      items.forEach((item) => {
        try {
          const url = new URL(item.link);
          if (groupedLinksObj[url.host] && groupedLinksObj[url.host].length) {
            groupedLinksObj[url.host].push(item);
          } else {
            groupedLinksObj[url.host] = [item];
          }
        } catch (e) {
          return null;
        }
      });
      return groupedLinksObj;
    };

    const createLinkItems = (items, isGrouped) => {
      const open = Boolean(this.state.anchorEl);

      const links = items.map((item, i) => (
        <li
          key={"item" + i}
          onDragEnter={this.noteBtnDragEnter}
          onDragLeave={this.noteBtnDragLeave}
          onDragOver={this.noteBtnDragOver}
          onDrop={(e) =>
            this.noteBtnDrop(e, {
              type: "link",
              data: {
                url: window.location.pathname,
                listName: currentList.name,
                text: item.link,
              },
            })
          }
        >
          <Typography
            onMouseEnter={(e) =>
              this.handlePopoverOpen(e, `${i}--${item.link}`)
            }
            onMouseLeave={this.handlePopoverClose}
          >
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <Avatar
                src={item.icon}
                onError={(e) => {
                  // redundant because of material ui Avater which has it's own error handling
                  e.target.onerror = null;
                  e.target.src = "/assets/default-logo-www.png";
                }}
                alt=""
                className="list-link-logo"
                style={{ textTransform: "capitalize" }}
              >
                {/*In case of an error this always executes*/}
                {/*Displaying the first two letter of the domain name*/}
                {item.link.startsWith("www")
                  ? item.link.substring(4, 6)
                  : item.link.substring(0, 2)}
              </Avatar>
              {isGrouped ? (
                <>
                  <p style={isGrouped ? { display: "inline" } : null}>
                    {item.text || item.link}
                  </p>
                </>
              ) : null}
            </a>
          </Typography>
          <Popover
            open={this.state.openedPopoverId === `${i}--${item.link}`}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            className={"link-popover"}
          >
            <Card style={{ maxWidth: 555 }}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {this.state.loadingMetadata ? (
                  <CardActionArea>
                    <CardMedia
                      style={{ height: 140, width: "100%" }}
                      src="https://i.stack.imgur.com/ndqUb.gif"
                      title="loading"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="p">
                        <small>{item.link}</small>
                        <br />
                        <small style={{ fontSize: "13px" }}>
                          Fetching Preview...
                        </small>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      ></Typography>
                    </CardContent>
                  </CardActionArea>
                ) : (
                  <CardActionArea>
                    {this.state.metaImage ? (
                      <CardMedia
                        style={{ height: 140, width: "100%" }}
                        image={this.state.metaImage}
                        title={this.state.metaTitle}
                      />
                    ) : (
                      <></>
                    )}
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="p">
                        {this.state.metaTitle}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {this.state.metaDescription}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                )}
              </a>
            </Card>
          </Popover>
        </li>
      ));
      return links;
    };

    const createGroupedLinkDialog = (items, urlOrigin, dialogueKey) => {
      // const dialogueKey= uuidv4();
      const open = dialogueKey === this.state.dialogId;
      return (
        <React.Fragment>
          <li
            aria-owns={open ? dialogueKey : undefined}
            aria-haspopup="true"
            variant="contained"
            onClick={(e) => this.handleExpandGroupedLink(dialogueKey, e)}
            key={"li--" + dialogueKey}
            className="grouped-link-main-icon"
          >
            <span
              className="grouped-link-count"
              title={`${items.length} links inside`}
            >
              {items.length}
            </span>
            <Avatar
              src={items[0].icon}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/default-logo.png";
              }}
              alt=""
              className="list-link-logo"
            >
              {items[0].link.startsWith("www")
                ? items[0].link.substring(4, 6)
                : items[0].link.substring(0, 2)}
            </Avatar>
          </li>
          <Modal
            isOpen={open}
            toggle={this.handleShrinkGroupedLink}
            centered={true}
            fade={false}
            scrollable={true}
            className="groupedLinkModal"
          >
            <ModalHeader
              toggle={this.handleShrinkGroupedLink}
              className="groupedLinkModalHeader"
            >
              <i className="fas fa-chevron-right" />
              <Avatar
                src={items[0].icon}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/assets/default-logo.png";
                }}
                alt=""
                className="list-link-logo"
              >
                {items[0].link.startsWith("www")
                  ? items[0].link.substring(4, 6)
                  : items[0].link.substring(0, 2)}
              </Avatar>

              <h5>{urlOrigin}</h5>
            </ModalHeader>
            <ModalBody>
              <div className="link-dialogue">
                <ul>{createLinkItems(items, true)}</ul>
              </div>
            </ModalBody>
          </Modal>
        </React.Fragment>
      );
    };

    const renderLinks = (items) => {
      // console.log("links=",items)
      const groupedLinks = groupLinks(items);
      // Fallback link render if an error is thrown by groupLinks function
      if (groupedLinks == null) {
        return createLinkItems(items);
      }

      let linkItems = [];
      let i = 0;
      for (const property in groupedLinks) {
        i++;
        if (groupedLinks[property].length == 1) {
          linkItems = [...linkItems, createLinkItems(groupedLinks[property])];
        } else {
          //create popover
          linkItems = [
            ...linkItems,
            createGroupedLinkDialog(
              groupedLinks[property],
              property,
              property + i
            ),
          ];
        }
      }
      return linkItems;
    };

    const convertStringToLink = (linkString) => {
      let linkArray = [];
      if (linkString.indexOf(",") > -1) {
        linkArray = linkString.split(",");
      } else {
        linkArray = linkString.split("\n");
      }

      return linkArray.map((link) => {
        link = link.startsWith("http") ? link : `http://${link}`;
        const linkURL = link.split("/");
        let domainURL = linkURL[2];
        let icon = `http://${domainURL}/favicon.ico`;
        return (
          <li>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <img
                src={icon}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/assets/default-logo-www.png";
                }}
                alt=""
                className="lp-link-logo"
              />
              <p
                className="lp-link"
                title={link}
                onDragEnter={this.noteBtnDragEnter}
                onDragLeave={this.noteBtnDragLeave}
                onDragOver={this.noteBtnDragOver}
                onDrop={(e) =>
                  this.noteBtnDrop(e, {
                    type: "link",
                    data: {
                      url: window.location.pathname,
                      listName: currentList.name,
                      text: link,
                    },
                  })
                }
              >
                {" "}
                {link}
              </p>
            </a>
          </li>
        );
      });
    };

    const render_container = () => {
      if (currentList.length <= 0)
        return (
          <div className={"logo_loader"}>
            <div className={"text"}>Loading</div>
            <div className={"bar-1"} />
            <div className={"bar-2"} />
            <div className={"bar-3"} />
          </div>
        );
      return (
        <div className="list-box-container">
          <div className="left-tool-container">
            <ListSettings
              // addToFolder={() => this.handleAddToFolder(currentList, 'list')}
              handleAddListToFolder={this.handleAddListToFolder}
              isEditable={this.state.isEditable}
              aid={aid}
              currentList={currentList}
              handleEditlist={this.handleEditlist}
              handleShowListNotes={this.handleShowListNotes}
              handlePromoteList={this.handlePromoteList}
              promoters={this.state.promoters}
              promotes={this.state.promotes}
              foldersList={this.state.folders}
              handleDeleteList={this.onDeleteList}
              fetchMyFolders={this.fetchMyFolders}
              handleShowSuspectDrawer={this.handleShowSuspectDrawer}
              handleMakeListSuspected={this.handleMakeListSuspected}
              {...this.props}
            />
          </div>
          {/* ==================================collaborator container ========================= */}
          <div className="list-box-inner-container">
            <i
              className="fa fa-times close-btn"
              onClick={this.handleGoBack}
              title="Go back"
              style={{ margin: "0vw 1vw", marginTop: "5px" }}
            />

            <div style={{ display: "flex", flexWrap: "wrap", height: "100%" }}>
              <div
                className="col col-12 col-md-5"
                style={{
                  padding: "0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="list-meta-info">
                  <span className={"list_category"} title="Category">
                    {currentList.profile.length !== 0 ? (
                      <Fragment>
                        <i className="fas fa-tags"></i>
                        <b> {currentList.category}</b>
                      </Fragment>
                    ) :<div style={{height:"1rem"}}></div>}
                  </span>
                </div>

                <div className="title-container">
                  <ErrorAlert
                    AutoHideSnackbar={this.AutoHideSnackbar}
                    showAlert={this.state.showAlert}
                    message={this.state.alertMessage}
                    status={this.state.alertCode}
                  />
                  <img src={"/assets/lists.png"} alt="" title="Upvote" />
                  <h4
                    className={"list_title"}
                    title="List Name"
                    onDragEnter={this.noteBtnDragEnter}
                    onDragLeave={this.noteBtnDragLeave}
                    onDragOver={this.noteBtnDragOver}
                    onDrop={(e) =>
                      this.noteBtnDrop(e, {
                        type: "listTitle",
                        data: {
                          url: window.location.pathname,
                          listName: currentList.name,
                          text: currentList.name,
                        },
                      })
                    }
                  >
                    {currentList.name}
                  </h4>
                  <Tooltip
                    title={
                      "#" +
                      this.props.fetchedList.result[0].tags
                        .map((t) => {
                          return t.tagName;
                        })
                        .join(" ,#")
                    }
                    arrow
                    placement="right"
                  >
                    <IconButton>
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="list_items_content">
                  <div className="display-tool-box">
                    {currentList.hasOwnProperty("description") &&
                    currentList.description.length > 0 ? (
                      <p
                        className="list-view-description-container"
                        title="List description"
                        onDragEnter={this.noteBtnDragEnter}
                        onDragLeave={this.noteBtnDragLeave}
                        onDragOver={this.noteBtnDragOver}
                        onDrop={(e) =>
                          this.noteBtnDrop(e, {
                            type: "listDescription",
                            data: {
                              url: window.location.pathname,
                              listName: currentList.name,
                              text: currentList.description,
                            },
                          })
                        }
                      >
                        {currentList.description}
                      </p>
                    ) : (
                      <span
                        className="list-view-description-container"
                        style={{ color: "lightgrey" }}
                      >
                        No Description
                      </span>
                    )}
                    {/* // ) : null} */}
                  </div>
                  <ul
                    //  style={{ paddingBottom: "50px" }}
                    id="list-item-ul"
                  >
                    {renderItems()}
                  </ul>
                </div>
                {!this.props.token ? (
                  <Fragment>
                    <Link to="/login">
                      <div className="quickAddItem" title="Add list item">
                        <input disabled placeholder="Login To Add Item" />
                      </div>
                    </Link>
                  </Fragment>
                ) : (
                  <Fragment>
                    {!currentList.sensitive &&
                    (this.props.aid == currentList.aid ||
                      (currentList.public == true &&
                        (typeof currentList.close === "undefined" ||
                          currentList.close == false)) ||
                      (currentList.collaborators &&
                        currentList.collaborators.length > 0 &&
                        currentList.collaborators
                          .map((collab) => {
                            if (collab.status === "accepted") {
                              return collab.email;
                            }
                          })
                          .indexOf(this.props.email) !== -1)) ? (
                      <div className="quickAddItem" title="Add list item">
                        <input
                          placeholder="Add a new item"
                          value={this.state.newListItem}
                          onKeyUp={(e) => {
                            this.handleAddListItem(e);
                          }}
                          onChange={(e) => {
                            this.handleAddListItemValue(e);
                          }}
                        />
                        {this.state.noOfQuickAddedItems > 0 ? (
                          <button
                            onClick={() => {
                              this.handleUndoClick();
                            }}
                          >
                            Undo
                          </button>
                        ) : null}
                      </div>
                    ) : (
                      <Link to="/login">
                        <div className="quickAddItem" title="Add list item">
                          <input
                            disabled
                            placeholder="List Is Closed For Editing"
                          />
                        </div>
                      </Link>
                    )}
                  </Fragment>
                )}
                <div
                  className="list-page-scroll-to-top-btn"
                  title="Scroll UP"
                  onClick={(e) => {
                    console.log(document.querySelector("#list-item-ul li"));
                    document.querySelector("#list-item-ul li").scrollIntoView({
                      behavior: "smooth",
                      block: "end",
                      inline: "nearest",
                    });
                  }}
                >
                  <i className="fas fa-arrow-up"></i>
                </div>
              </div>
              <div
                className="col col-12 col-md-7 list_description_content"
                // style={{ margin: "0" }}
              >
                {renderItemDetails()}
              </div>
            </div>
          </div>
          {/* <div className='right-tool-container'></div> */}
        </div>
      );
    };

    return (
      <div>
      <div className="list_container">




        {/* <div className='top-overlay'></div> */}
        {/* FOR SEO IMPROVEMENT */}
        <Helmet>
          <title>{currentList.name + "-Listiee.com"}</title>
          <meta name="description" content={currentList.description} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>

        {this.props.recommendedList.length > 0 ? (
          <Recommendations
            recommendations={this.props.recommendedList}
            history={this.props.history}
          />
        ) : (
          <div style={{ width: "18%" }}></div>
        )}

        {render_container()}
        {this.renderDrawer()}
        {this.renderSuspectDrawer()}
        <div className="shareIcon-container-aboveMdSize">
          {this.renderShareIcons(currentList.name)}
        </div>
        <div className="shareIcon-container-belowMdSize">
          {this.renderShareIcons(currentList.name)}
        </div>
        {/* MODAL FOR VIEW COLLABORATORS */}
        <div
          className="modal fade"
          id="collabModal"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{ background: "#EBECF0" }}>
              <div className="modal-header">
                <p
                  className="modal-title"
                  id="ModalLabel"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#626262",
                  }}
                >
                  Collaborators
                </p>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ul className="list-group">
                  {currentList &&
                  currentList.collaborators &&
                  currentList.collaborators.length > 0 ? (
                    currentList.collaborators.map((collab, i) => {
                      // let indexInCollaborators = currentList.collaborators
                      //   .map(itm => {
                      //     return itm.aid;
                      //   })
                      //   .indexOf(collab.aid);

                      return (
                        <div key={i}>
                          {currentList.aid === aid ? (
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <span className="list-items-name">
                                {collab.user_name
                                  ? collab.user_name
                                  : collab.email.split("@")[0]}
                              </span>
                              <span className="list-items-controls">
                                <button className="btn bg-transparent">
                                  <i
                                    className="fa fa-circle"
                                    style={{
                                      color:
                                        collab.status === "pending"
                                          ? "orange"
                                          : collab.status === "rejected"
                                          ? "red"
                                          : "green",
                                    }}
                                  />
                                </button>
                                <button
                                  className="btn bg-transparent"
                                  onClick={(e) =>
                                    this.onDeleteCollab(e, collab.email)
                                  }
                                >
                                  <i className="far fa-trash-alt del-coll"></i>
                                </button>
                              </span>
                            </li>
                          ) : collab.status === "accepted" ? (
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <span className="list-items-name">
                                {collab.user_name
                                  ? collab.user_name
                                  : collab.email.split("@")[0]}
                              </span>
                            </li>
                          ) : null}
                        </div>
                      );
                    })
                  ) : (
                    <div className="no_collab">
                      No collaborators on this list.
                    </div>
                  )}
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                {/* <button type="button" className="btn btn-danger" onClick={this.onDeleteList}>Proceed</button> */}
              </div>
            </div>
          </div>
        </div>
        {/* MODAL FOR QR CODE SHARE LIST */}
        <QRShareModal
          baseShareUrl={`${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}`}
        />
      </div>
      {/* showing the share new list modal */}
        {

          this.state.showShareNewListModal == true ?

          <ShareNewListModal
            closeModal={this.handleCloseShareNewListModal}
            baseShareUrl = {`${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}`}
            listName={currentList.name}
            listsCreated = {this.state.listsCreated}
          />

          :

          ""

        }

{
  this.state.showShareListModal ?
    <ShareListModal
      closeModal={this.handleCloseShareListModal}
      baseShareUrl = {`${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}`}
      listName={currentList.name}
      /* listsCreated = {this.props.location.state.listsCreated ? this.props.location.state.listsCreated : 0} */
    />

  :

  ""
}

        {
          this.state.showShareAskListModal ?
            <ShareAskListModal
              closeModal={this.handleCloseShareListModal}
              baseShareUrl = {`${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}`}
              listName={currentList.name}
          /* listsCreated = {this.props.location.state.listsCreated ? this.props.location.state.listsCreated : 0} */
            />

          :

          ""
        }

      </div>
    );
  }
}

List.propTypes = {
  deleteList: PropTypes.func.isRequired,
  deleteListItem: PropTypes.func.isRequired,
  upvoteListItem: PropTypes.func.isRequired,
  bookmarkListItem: PropTypes.func.isRequired,
  fetchFollowingUserList: PropTypes.func.isRequired,
};

export default List;
