import React from "react";

export default class Career extends React.Component {
    state = {
        showMore: false
    }


    showMore = () => {
        this.setState({
            showMore: !this.state.showMore
        })
    }

    render() {
        return (
            <div className="career-container">
                <div className="career-header">
                    <h1 className="career-title">Careers <i className="fas fa-briefcase"></i></h1>
                    <div className="career-sub-title">Join our team!</div>
                    <div className="career-sub-section">Listiee is expanding and we are looking for <br />
                     talented people to join our team.</div>
                </div>

                <div className="job-section container">
                    <div className="title">Open Positions</div>
                    <div className="roles row">
                        <div className="col-md-6">
                            <div className="role-card">
                                <div className="role-name">Python Developer</div>
                                <div className="review">Remote, 6 months</div>
                                <ul className="summary">
                                    <li>Job Status: Remote starting in june to november of 2020.</li>
                                    <li>The ideal candidate needs to have a good knowledge of core programming skills.</li>
                                    <div style={this.state.showMore ? { display: 'block' } : { display: 'none' }}>
                                        <li>Needs to have good python skills</li>
                                        <li>have coding experience over 3 years</li>
                                        <li>needs to open for learning new things.</li>
                                    </div>
                                </ul>
                                <div className="post-time">
                                    <span>30+ days ago</span>
                                    <span className="separator">·</span>
                                    <span onClick={this.showMore} className="show-more">More...</span>
                                    <span className="separator">·</span>
                                </div>
                                <button className="btn btn-primary">Apply</button>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="role-card">
                                <div className="role-name">Python Developer</div>
                                <div className="review">Remote, 6 months</div>
                                <ul className="summary">
                                    <li>Job Status: Remote starting in june to november of 2020.</li>
                                    <li>The ideal candidate needs to have a good knowledge of core programming skills.</li>
                                    <div style={this.state.showMore ? { display: 'block' } : { display: 'none' }}>
                                        <li>Needs to have good python skills</li>
                                        <li>have coding experience over 3 years</li>
                                        <li>needs to open for learning new things.</li>
                                    </div>
                                </ul>
                                <div className="post-time">
                                    <span>30+ days ago</span>
                                    <span className="separator">·</span>
                                    <span onClick={this.showMore} className="show-more">More...</span>
                                    <span className="separator">·</span>
                                </div>
                                <button className="btn btn-primary">Apply</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}