import React from "react";
import ToggleBtn from "./ToggleBtn";
import ReactTooltip from "react-tooltip";

export default function AccessControl(props) {
  const { listAid, isControlDisable } = props;

  const isSwitchDisabled = isControlDisable(listAid);

  // console.log("DISABLES-", isSwitchDisabled);
  // data-tip="Lists that will be available to anyone using the platform"
  return (
    <>
      <div className="switch-btn-wrapper ">
        <small>
          Public{" "}
          <i className="far fa-question-circle" data-for = "test-1" data-tip />
          <ReactTooltip id="test-1">
            <span >Lists that will be available <br></br>to anyone using the platform</span>
            </ReactTooltip>
        </small>

        <ToggleBtn
          isChecked={props.publicBtnVal}
          isDisabled={isSwitchDisabled}
          toggleChange={() =>
            props.handleSwitchChange(!props.publicBtnVal, "publicBtnVal")
          }
        />
      </div>

      <div className="switch-btn-wrapper">
        <small>
          Concious{" "}
          <i className="far fa-question-circle" data-for = "test-2" data-tip  />
          <ReactTooltip id="test-2">
            <span>Lists that needs their elements to be sequentially frozen <br></br>e.g - methods / Processes etc.</span>
            </ReactTooltip>
        </small>

        <ToggleBtn
          isChecked={props.conciousBtnVal}
          isDisabled={isSwitchDisabled}
          toggleChange={() =>
            props.handleSwitchChange(!props.conciousBtnVal, "conciousBtnVal")
          }
        />
      </div>

      <div className="switch-btn-wrapper">
        <small>
          Close{" "}
          <i className="far fa-question-circle" data-for = "test-3" data-tip  />
            <ReactTooltip id="test-3">
            <span>Allow people to edit and add more info.</span>
            </ReactTooltip>

        </small>

        <ToggleBtn
          isChecked={props.closedBtnVal}
          isDisabled={isSwitchDisabled}
          toggleChange={() =>
            props.handleSwitchChange(!props.closedBtnVal, "closedBtnVal")
          }
        />
      </div>

      <div className="switch-btn-wrapper">
        <small>
          Sensitive{" "}
          <i className="far fa-question-circle" data-for = "test-4" data-tip  />
            <ReactTooltip id="test-4" place="left">
            <span >Lists that Contain sensitive information,<br></br>require proof/link for every item</span>
            </ReactTooltip>

        </small>

        <ToggleBtn
          isChecked={props.sensitive}
          isDisabled={isSwitchDisabled}
          toggleChange={() =>
            props.handleSwitchChange(!props.sensitive, "sensitive")
          }
        />
      </div>

      <div className="switch-btn-wrapper">
        <small>
          Covid19{" "}
          <i className="far fa-question-circle" data-for = "test-5" data-tip  />
            <ReactTooltip id="test-5" place="left">
            <span>Covid19 related list?</span>
            </ReactTooltip>
        </small>

        <ToggleBtn
          isChecked={props.covid}
          isDisabled={isSwitchDisabled}
          toggleChange={() => props.handleSwitchChange(!props.covid, "covid")}
        />
      </div>
    </>
  );
}
