import React from "react";
import BlogCard from "./blogs/blogCard";
import Footer from "./Footer";

function Blog() {
  return (
    
    <div className="blog_container">
      <div className="blog_heading">
        <h2 className="blog_heading_h2">Latest Posts :</h2>
        <div className="sort_select">
          <select>
            <option value="0">Sort</option>
            <option value="1">Newest</option>
            <option value="2">Oldest</option>
          </select>
        </div>
      </div>

      <div className="news_cont">
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
      </div>

      <h2 className="blog_heading_h2" style={{marginLeft:"20vw"}}>
        Latest Updates :
      </h2>
      <div className="news_cont">
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
        <BlogCard />
      </div>
      <Footer/>
    </div>
  );
}

export default Blog;
