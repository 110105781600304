import React, { Component } from 'react';
import EditProfile from './settings/EditProfile';
// import ProfileSettingMob from './ProfileSettingMob'
import {
  DISCOVER_PATH,
  getListPath,
  HOME_PATH,
  getProfilePath
} from '../constants/pathConstants';
import {
    logoutUser
} from "../actions/authActions";
import ChangeUserName from './settings/ChangeUserName';
import ChangePassword from './settings/ChangePassword';
import ChangeEmail from './settings/ChangeEmail';
import { changeUserName } from '../actions/apiActions';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EmailIcon from '@material-ui/icons/Email';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import SettingsIcon from '@material-ui/icons/Settings';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isMobileOnly, isTablet } from "mobile-device-detect";

class ProfileSettings extends Component {
  constructor(props) {
    super(props);
      this.state = {
	 isChangePasswordClicked: true 
      };
  }

  componentWillMount() {
    if (!this.props.token)
      this.props.history.push(HOME_PATH, {
        login_required: true,
        redirect_to: DISCOVER_PATH
      });
    else this.props.fetchProfile({ aid: this.props.aid }, this.props.token);
  }

  handleGoBack = () => {
    if (this.props.history.action === 'PUSH') {
      this.props.history.goBack();
    } else {
      this.props.history.push(getProfilePath(this.props.user_name));
    }
  };  

  onLogoutUser = async (e) => {
    e.preventDefault();
    await this.props.logoutUser();
    this.props.history.push("/forgot-password");
  };

  render() {
    const isMobile = (isMobileOnly || isTablet);
    const {
      aid,
      token,
      editProfile,
      checkPassword,
      user_name,
      profile,
      changeUserName,
      fetchProfile,
      changePassword,
      editProfileMessage,
      history
    } = this.props;
// ============================= Mobile and desktop view ==============================
    if (isMobile) {
// =============================== Mobile view ====================================      
      return (
        
        <div >
          <h1 className="edit-profile-mob">Edit Profile <i className="fas fa-user-cog"></i></h1>
          <button className="close-btn-profile" onClick={this.handleGoBack}
          >
          <i className="fas fa-times"></i>
          </button>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="profile-setting-box"
              >
              <Typography >About</Typography>
            </AccordionSummary>
        <AccordionDetails>
        <EditProfile
                  aid={aid}
                  token={token}
                  editProfile={editProfile}
                  profile={profile}
                  fetchProfile={fetchProfile}
                  editProfileMessage={editProfileMessage}
                  history={history}
                  user_name={user_name}
                />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="profile-setting-box"
        >
          <Typography >User Name</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ChangeUserName
                  aid={aid}
                  token={token}
                  profile={profile}
                  changeUserName={changeUserName}
                  fetchProfile={fetchProfile}
                  history={history}
                  user_name={user_name}
                />
        </AccordionDetails>
      </Accordion>  

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="profile-setting-box"
        >
          <Typography >Email</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ChangeEmail
                  aid={aid}
                  token={token}
                  profile={profile}
                  fetchProfile={fetchProfile}
                  checkPassword={checkPassword}
                  checkPasswordMessage={this.props.checkPasswordMessage}
                  history={history}
                  user_name={user_name}
                  profile={profile}
                  changeEmailMessage={this.props.changeEmailMessage}
                  verifyEmailOTP={this.props.verifyEmailOTP}
                  sendVerifyEmailMessage={this.props.sendVerifyEmailMessage}
                  changeEmail={this.props.changeEmail}
                  sendVerifyEmail={this.props.sendVerifyEmail}
                  fetchProfile={this.props.fetchProfile}
                />
        </AccordionDetails>
      </Accordion> 

      <Accordion
	  onClick={ (event) => {

	      //if the user has logged in using third party outh then redirecting him to /forgot-password to change his password.
	      
	      // if the change password div has not been clicked and the window cofirm is true
	      if (!this.state.changePasswordClicked && window.confirm("Did you signin using a third party login? (Google, LinkedIn)")) {

		  // logging out the user
		  this.onLogoutUser(event);
		  
	      }
	      this.setState({changePasswordClicked: true});
	  } }


      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="profile-setting-box"
        >
            <Typography>Password</Typography>
	</AccordionSummary>
        <AccordionDetails>
	    <ChangePassword
		aid={aid}
		token={token}
		profile={profile}
		changePassword={changePassword}
		changePasswordMessage={this.props.changePasswordMessage}
		history={history}
		user_name={user_name}

	    />
	</AccordionDetails>
      </Accordion>  
      
          {/* <p>Hello world</p>
          <h1>Edit Profile</h1>
          
          <h1>Change User</h1>
          
                   
          <h1>Change password</h1>
           */}


        </div>
      );
    } else {
 //================================ Desktop view ======================================     
    return (
      
      <div className='container mt-5'>
        <div className='row' style={{ justifyContent: 'space-around', paddingBottom: "7.4vh" }}>
          <div className='col-md-3'>
            <div
              className='nav flex-column nav-pills'
              id='v-pills-tab'
              role='tablist'
              aria-orientation='vertical'
            >
              <a
                className='nav-link active'
                id='v-pills-home-tab'
                data-toggle='pill'
                href='#v-pills-home'
                role='tab'
                aria-controls='v-pills-home'
                aria-selected='true'
              >
                <AccountCircleIcon className='mr-3' /> Edit profile
              </a>
              <a
                className='nav-link'
                id='v-pills-profile-tab'
                data-toggle='pill'
                href='#v-pills-profile'
                role='tab'
                aria-controls='v-pills-profile'
                aria-selected='false'
              >
                <PermIdentityIcon className='mr-3' /> Change username
              </a>
              <a
                className='nav-link'
                id='v-pills-messages-tab'
                data-toggle='pill'
                href='#v-pills-messages'
                role='tab'
                aria-controls='v-pills-messages'
                aria-selected='false'
		  onClick={ (event) => {

		      //if the user has logged in using third party outh then redirecting him to /forgot-password to change his password.
		      
		      // if the change password div has not been clicked and the window cofirm is true
		      if (!this.state.changePasswordClicked && window.confirm("Did you signin using a third party login? (Google, LinkedIn)")) {

			  // logging out the user
			  this.onLogoutUser(event);
			  
		      }
		      this.setState({changePasswordClicked: true});
		  } }


              >
                <LockOpenIcon className='mr-3' /> Change password
              </a>
              <a
                className='nav-link'
                id='v-pills-email-tab'
                data-toggle='pill'
                href='#v-pills-email'
                role='tab'
                aria-controls='v-pills-email'
                aria-selected='false'
              >
                <EmailIcon className='mr-3' /> Change email
              </a>
            </div>
          </div>
          <div className='col-md-8'>
            <div className='tab-content' id='v-pills-tabContent'>
              {/* <div className="overview-title">
                                <h3>Personal info</h3>
                                <div>Basic info, like your bio and photo, that you use on Listiee platform</div>
                            </div> */}
              <div
                className='tab-pane fade show active'
                id='v-pills-home'
                role='tabpanel'
                aria-labelledby='v-pills-home-tab'
              >
                <EditProfile
                  aid={aid}
                  token={token}
                  editProfile={editProfile}
                  profile={profile}
                  fetchProfile={fetchProfile}
                  editProfileMessage={editProfileMessage}
                  history={history}
                  user_name={user_name}
                />
              </div>
              <div
                className='tab-pane fade'
                id='v-pills-profile'
                role='tabpanel'
                aria-labelledby='v-pills-profile-tab'
              >
                <ChangeUserName
                  aid={aid}
                  token={token}
                  profile={profile}
                  changeUserName={changeUserName}
                  fetchProfile={fetchProfile}
                  history={history}
                  user_name={user_name}
                />
              </div>
              <div
                className='tab-pane fade '
                id='v-pills-email'
                role='tabpanel'
                aria-labelledby='v-pills-email-tab'
              >
                <ChangeEmail
                  aid={aid}
                  token={token}
                  profile={profile}
                  fetchProfile={fetchProfile}
                  checkPassword={checkPassword}
                  checkPasswordMessage={this.props.checkPasswordMessage}
                  history={history}
                  user_name={user_name}
                  profile={profile}
                  changeEmailMessage={this.props.changeEmailMessage}
                  verifyEmailOTP={this.props.verifyEmailOTP}
                  sendVerifyEmailMessage={this.props.sendVerifyEmailMessage}
                  changeEmail={this.props.changeEmail}
                  sendVerifyEmail={this.props.sendVerifyEmail}
                  fetchProfile={this.props.fetchProfile}
                />
              </div>
              <div
                className='tab-pane fade'
                id='v-pills-messages'
                role='tabpanel'
                aria-labelledby='v-pills-messages-tab'
              >
                <ChangePassword
                  aid={aid}
                  token={token}
                  profile={profile}
                  changePassword={changePassword}
                  changePasswordMessage={this.props.changePasswordMessage}
                  history={history}
                  user_name={user_name}
				
                />
              </div>
            </div>
          </div>
        </div>
      </div>
                          
    );
                          }
  }
}

export default ProfileSettings;
