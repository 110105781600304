import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import SelectCreatable from './SelectCreatable';

function EditPrimary(props) {
  const { onHandleChange, value } = props;

  return (
    <Fragment>
      <div className='form-group category-container'>
        {/* <label
          htmlFor={'tags'}
          style={
            props.value
              ? { display: 'block' }
              : { display: 'block', opacity: 0 }
          }
        >
          Primary
        </label> */}
        <select
          // style = {{marginTop: "10px"}}
          name={'primary'}
          className={'form-control'}
          id={'listPrimary'}
          title="Select what have you added to the list."
          onChange={onHandleChange}
          placeholder={'Primary'}
          value={value}
        >
          <option style={{textAlign: 'center', fontWeight: 'bold' }}>
            What is it?
          </option>
          <option value='subjects'>subjects</option>
          <option value='objects'>objects</option>
          <option value='locations'>locations</option>
          <option value='utilities'>utilities</option>
          <option value='organization names'>organization names</option>
          <option value='products'>products</option>
          <option value='words'>words</option>
          <option value='community'>community</option>
          <option value='people'>people</option>
          <option value='skills'>skills</option>
          <option value='techniques / tactics'>techniques / tactics</option>
          <option value='events'>events</option>
          <option value='tools'>tools</option>
          <option value='methods'>methods</option>
          <option value='process'>process</option>
          <option value='set of rules'>set of rules</option>
          <option value='jobs'>jobs</option>
          <option value='services'>services</option>
          <option value='businesses'>businesses</option>
          <option value='space'>space</option>
          <option value='courses'>courses</option>
          <option value='inventions'>inventions</option>
          <option value='other'>Other</option>
          {/* <option value="hotspots">Hotspots</option> */}
        </select>
        {/* <SelectCreatable/> */}
      </div>
    </Fragment>
  );
}

EditPrimary.propTypes = {
  onHandleChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default EditPrimary;
