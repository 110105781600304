import React, { Fragment, useState, useEffect } from "react";
// import Button from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import HistoryIcon from "@material-ui/icons/History";
import { EDIT_HISTORY_API } from "../../constants/apiConstants";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import moment from "moment";
import { connect } from "react-redux";
import { markReadNotif, markAllReadNotif } from "../../actions/apiActions";

const EditLogs = (props) => {
  let [visibility, setVisibility] = useState(false);
  let [editHistory, setEditHistory] = useState(null);

  useEffect(() => {
    (async () => {
      let data = {
        list_id: props.listId,
      };
      let options = {
        headers: {
          "x-access-token": props.token,
        },
      };
      console.log(data, options);
      try {
        let response = await axios.post(EDIT_HISTORY_API, data, options);
        setEditHistory(response.data.data);
        console.log(response.data.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const toggleDrawer = () => {
    setVisibility(!visibility);
  };

  return (
    <Fragment>
      <IconButton
        className="scale-on-hover"
        title="Edit History Of List"
        size="small"
        onClick={toggleDrawer}
      >
        <HistoryIcon />
      </IconButton>
      <Drawer
        variant="persistent"
        anchor="right"
        style={{ textAlign: "left" }}
        open={visibility}
      >
        <div className={"drawerHeader"}>
          <IconButton onClick={toggleDrawer}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <div className="modal-dialog notif_container">
          <div className="modal-content" style={{ border: "none" }}>
            <div className="modal-header">
              <h4 className="modal-title notif-header-title" id="ModalLabel">
                Edit History
              </h4>
            </div>
          </div>
        </div>

        {editHistory === null || editHistory.length === 0 ? (
          <div className="card notif_card">
            <div className="card-design">
              <HistoryIcon />
              <div className="vl"></div>
              <div className="notif-title-wrapper">
                This list was created
              </div>
            </div>
          </div>
        ) : (
          <Fragment>
            {editHistory.map((log, i) => {
              return (
                <div key={i} className="card notif_card">
                  <div className="card-design">
                    <HistoryIcon />
                    <div className="vl"></div>
                    <div className="notif-title-wrapper">
                      {log.isBeingCreated === true ? (
                        <div
                          style={{ width: "300px" }}
                        >{`${log.itemName} has been added to the list`}</div>
                      ) : (
                        <Fragment>
                          {["list_name", "description"].includes(
                            log.fieldChanged
                          ) ? (
                            <div style={{ width: "300px" }}>{`${
                              log.itemName
                            }'s ${
                              log.fieldChanged === "list_name"
                                ? ""
                                : log.fieldChanged
                            } changed to ${log.changedTo} from ${
                              log.changedFrom
                            }`}</div>
                          ) : (
                            <div style={{ width: "300px" }}>{`${
                              log.changedFrom.length - log.changedTo.length > 0
                                ? log.changedFrom.length - log.changedTo.length
                                : log.changedTo.length - log.changedFrom.length
                            } link has been ${
                              log.changedFrom.length - log.changedTo.length > 0
                                ? "removed from"
                                : "added to"
                            } ${log.itemName}'s ${log.fieldChanged} `}</div>
                          )}
                        </Fragment>
                      )}
                      <div className="notif-time">
                        {moment(moment(log.modifiedAt).utc()).fromNow()}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Fragment>
        )}
      </Drawer>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    listId: state.api.fetchedList.result[0]._id,
    accessId: state.auth.aid,
    token: state.auth.token,
  };
};

const mapDispatchToProps = {
  markReadNotif,
  markAllReadNotif,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLogs);
