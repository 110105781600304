import React, { Component } from "react";
import Swal from "sweetalert2";
import { HOME_PATH, LOGIN_PATH } from "../constants/pathConstants";

class Invitation extends Component {
    componentDidMount() {
        let { lsid, collabEmail } = this.props.match.params;
        let status = new URLSearchParams(window.location.search).get("status");

        if (!this.props.token) {
            this.props.history.push(LOGIN_PATH, {
                login_required: true,
                redirect_to: this.props.location.pathname
            });
        } else if (status === "accepted" || status === "rejected") {
            if (this.props.email === collabEmail) {
                let data = {
                    lsid,
                    collabEmail,
                    status
                };
                this.validateInvitation(data);
            } else {
                this.props.history.push("/notfound");
            }
        } else {
            this.props.history.push("/notfound");
        }
    }

    validateInvitation = async data => {
        let { lsid, collabEmail, status } = data;
        data.aid = this.props.aid;
        await this.props.respondInvite(data, this.props.token);
        if (
            this.props.respondedCollab &&
            this.props.respondedCollab !== "ERR"
        ) {
            this.fireSwalAndProceed(
                `Invitation ${status}`,
                "",
                "success",
                lsid
            );
        } else {
            this.props.history.push("/notfound");
        }
    };

    fireSwalAndProceed = (title, text, type, lsid) => {
        Swal.fire({
            title,
            text,
            type
        }).then(() => {
            this.props.history.push("/");
        });
    };

    render() {
        return <div></div>;
    }
}

export default Invitation;
