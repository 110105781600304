import {
  FETCH_ALL_COMMENTS,
  CREATE_COMMENT,
  FETCH_REPLAY_COMMENTS,
  CREATE_COMMENT_REPLAY,
  EDIT_COMMENT,
  EDIT_COMMENT_REPLY,
  DELETE_COMMENT,
  DELETE_COMMENT_REPLY,
} from '../constants/actionTypes';

const initialState = {
  comments: [],
  commentsReplys: {},
};

const commentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_COMMENTS: {
      return {
        ...state,
        comments: action.payload,
      };
    }

    case CREATE_COMMENT: {
      return {
        ...state,
        comments: [...state.comments, action.payload],
      };
    }

    case FETCH_REPLAY_COMMENTS: {
      const { data, parent } = action;
      let commentsReplys = state.commentsReplys;
      commentsReplys[parent] = data;
      return {
        ...state,
        commentsReplys,
      };
    }

    case CREATE_COMMENT_REPLAY: {
      const { parent, data } = action;
      const commentsReplys = state.commentsReplys;
      commentsReplys[parent]
        ? commentsReplys[parent].push(data)
        : (commentsReplys[parent] = data);
      return {
        ...state,
        commentsReplys,
      };
    }

    case EDIT_COMMENT: {
      const i = state.comments
        .map(function (e) {
          return e._id;
        })
        .indexOf(action.id);
      const comments = state.comments;
      comments[i].text = action.data.text;
      return {
        ...state,
        comments,
      };
    }

    case EDIT_COMMENT_REPLY: {
      const commentsReplys = state.commentsReplys;
      const replys = commentsReplys[action.parent];
      const i = replys
        .map(function (e) {
          return e._id;
        })
        .indexOf(action.id);
      replys[i].text = action.data.text;
      commentsReplys[action.parent] = replys;
      return {
        ...state,
        commentsReplys,
      };
    }

    case DELETE_COMMENT: {
      const i = state.comments
        .map(function (e) {
          return e._id;
        })
        .indexOf(action.id);
      let comments = state.comments;
      comments.splice(i, 1);
      return {
        ...state,
        comments,
      };
    }

    case DELETE_COMMENT_REPLY: {
      let commentsReplys = state.commentsReplys;
      const replys = commentsReplys[action.parent];
      const i = replys
        .map(function (e) {
          return e._id;
        })
        .indexOf(action.id);
      replys.splice(i, 1);
      commentsReplys[action.parent] = replys;
      return {
        ...state,
        commentsReplys,
      };
    }

    default:
      return state;
  }
};

export default commentReducer;
