import axios from 'axios';
import Swal from 'sweetalert2';
import { getListPath } from '../constants/pathConstants';
import Cookies from 'js-cookie';
import { COOKIE_AUTH } from "../constants/baseConstants";

import {
  ASK_QUESTION,
  ASK_QUESTION_ERR,
  BOOKMARK_LIST_ITEM,
  DELETE_LIST_ITEM,
  FETCH_BOOKMARKS,
  FETCH_LIST,
  FETCH_DRAFT_LIST,
  SEARCH_LIST,
  CLEAR_CURRENT_LIST_DATA_FROM_STORE,
  ADD_LIST_COLLAB,
  DELETE_LIST_COLLAB,
  RESPOND_LIST_COLLAB,
  SAVE_LIST,
  EDIT_LIST,
  FETCH_LIST_BY_ID,
  SAVE_LIST_ITEM,
  UPVOTE_LIST_ITEM,
  FETCH_USERFOLLOWING,
  FOLLOW_USER,
  FETCH_PROFILE,
  FETCH_PEOPLE,
  LOGOUT_USER,
  FETCH_TAG,
  FETCH_BOOKMARKS_LIST,
  FETCH_REQUESTS,
  FETCH_REQUEST_REPLIES,
  REPLY_TO_ASK,
  GET_PAGE_STATE,
  FETCH_BOARDS,
  DELETE_FOLDER,
  EDIT_FOLDER,
  DELETE_BOARD,
  CHANGE_BOARD_VISIBILITY,
  ADD_FOLDER,
  RENAME_FOLDER,
  PRIVATE_FOLDER,
  ADD_BOARD,
  ADD_LISTS_TO_FOLDER,
  FETCH_FOLDER_ITEMS,
  UPDATE_FOLDER,
  FETCH_FOLDER_ITEM_DETAILS,
  GET_PUBLIC_PROFILE,
  GET_NOTIFICATIONS,
  MARK_READ_NOTIF,
  EDIT_PROFILE,
  REPORT_LIST,
  CHANGE_PASSWORD,
  PROMOTE_LIST,
  LOGIN_USER,
  ADD_LIST_ITEM,
  DELETE_ASKED,
  BOOKMARK_USER,
  UNBOOKMARK_USER,
  FETCH_USERS_BOOKMARKED,
  FETCH_USER_LIST,
  FETCH_USER_BOOKMARKS,
  FETCH_ALL_USER_BOOKMARKS,
  FETCH_USER_COLLAB_LISTS,
  RENAME_BOARD,
  REFRESH_DISCOVER_FALSE,
  REFRESH_DISCOVER_TRUE,
  CHANGE_EMAIL,
  LOGIN_USER_ERR,
  CHECK_PASSWORD,
  SEND_VERIFY_EMAIL,
  MAKE_LIST_SUSPECTED,
  RESPOND_SUSPECT_LIST,
  UPDATE_SUSPECT_STATUS,
  PASSWORD_CHANGED,
  TOGGLE_EMAIL_VERIFIED,
  UPDATE_USER,
  UPDATE_RECOMMENDATION,
} from '../constants/actionTypes';

import {
  ASK_QUESTION_API,
  BOOKMARK_LIST_ITEM_API,
  DELETE_LIST_API,
  DELETE_LIST_ITEM_API,
  FETCH_BOOKMARKS_API,
  FETCH_LIST_API,
  FETCH_LIST_V3_API,
  FETCH_LIST_V4_API,
  FETCH_DRAFT_LIST_V4_API,
  SAVE_LIST_API,
  EDIT_LIST_API,
  FETCH_LIST_BY_ID_API,
  ADD_LIST_COLLAB_API,
  DELETE_LIST_COLLAB_API,
  RESPOND_INVITE_API,
  SEARCH_LIST_API,
  SAVE_LIST_ITEM_API,
  SAVE_LIST_VERSION_THREE,
  SAVE_SINGLE_LIST_ITEM_API,
  UPVOTE_LIST_ITEM_API,
  FETCH_USERFOLLOWING_API,
  FOLLOWING_API,
  UNFOLLOWING_API,
  INCREASE_VIEW_API,
  EDIT_PROFILE_API,
  CHANGE_USERNAME_API,
  FETCH_USER_API,
  CHANGE_PASSWORD_API,
  CREATE_PASSWORD_API,
  CHANGE_EMAIL_API,
  SEND_VERIFY_EMAIL_API,
  FETCH_PEOPLE_API,
  FETCH_TAG_API,
  FETCH_BOOKMARKS_LIST_API,
  DELETE_BOOKMARK_LIST_ITEM_API,
  DELETE_UPVOTE_LIST_ITEM_API,
  FETCH_BOARDS_API,
  CHANGE_BOARD_VISIBILITY_API,
  DELETE_FOLDER_API,
  DELETE_BOARD_API,
  ADD_FOLDER_API,
  RENAME_FOLDER_API,
  PRIVATE_FOLDER_API,
  ADD_BOARD_API,
  EDIT_FOLDER_API,
  ADD_LISTS_TO_FOLDERS_API,
  FETCH_STACK_API,
  PIN_STACK_ITEM_API,
  DELETE_STACK_ITEM_API,
  FETCH_FOLDER_ITEMS_API,
  UPDATE_FOLDER_API,
  FETCH_FODLER_ITEM_DETAILS_API,
  FETCH_REQUESTS_API,
  FETCH_REQUEST_REPLIES_API,
  REPLY_TO_ASK_API,
  GET_PUBLIC_PROFILE_API,
  GET_NOTIFICATIONS_API,
  MARK_READ_NOTIF_API,
  MARK_ALLREAD_NOTIF_API,
  REPORT_LIST_API,
  PROMOTE_LIST_API,
  ADD_LIST_ITEM_API,
  FETCH_METADATA_API,
  DELETE_ASKED_API,
  CHECKBOX_LIST_ITEM_API,
  BOOKMARK_USER_API,
  UNBOOKMARK_USER_API,
  FETCH_USERS_BOOKMARKED_API,
  FETCH_USER_LISTS_API,
  FETCH_USER_BOOKMARKS_API,
  FETCH_ALL_USER_BOOKMARKS_API,
  FETCH_USER_COLLAB_LISTS_API,
  RENAME_BOARD_API,
  ADD_COVID_AREAS_API,
  IS_COVID_VERIFIER_API,
  GET_PENDING_COVID_AREAS_API,
  REVIEW_PENDING_COVID_AREAS_API,
  FETCH_COVID_PRIMARIES_API,
  FETCH_LIST_BY_ID_PUB_API,
  GET_RECOMMENDATION,
  VERIFY_EMAIL_API,
  MAKE_LIST_SUSPECTED_API,
  RESPOND_SUSPECT_LIST_API,
  UPDATE_SUSPECT_STATUS_API,
} from '../constants/apiConstants';

// import { render } from 'node-sass';

export const saveList = (listData, listItemData) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: SAVE_LIST_API,
    data: JSON.stringify(listData),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': listItemData.token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      const lsData = {
        lsid: res.data.data._id,
        list_name_arr: listItemData.list_name_arr,
      };

      const reqOptions = {
        method: 'POST',
        url: SAVE_LIST_ITEM_API,
        data: JSON.stringify(lsData),
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': listItemData.token,
        },
      };

      axios(reqOptions)
        .then((res) => {
          const listData = res.data.data.map((obj) => {
            const newObj = {
              list_name: '',
              id: '',
              lsid: '',
            };
            newObj.list_name = obj.list_name;
            newObj.id = obj._id;
            newObj.lsid = obj.lsid;
            return newObj;
          });
          dispatch({
            type: SAVE_LIST,
            payload: listData,
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.code === 1500) {
              dispatch({ type: LOGOUT_USER });
              window.location.reload();
            }
          } else {
            console.log('ERROR: something went wrong');
            return 'ERR';
          }
        });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const saveListItem = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: SAVE_SINGLE_LIST_ITEM_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: SAVE_LIST_ITEM,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchList = (query, token) => async (dispatch) => {
  const reqOptions = {
    method: 'GET',
    url: FETCH_LIST_API,
    params: query,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
console.log(FETCH_LIST_API,query)
  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_LIST,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchListv3 = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_LIST_V3_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_LIST,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchListv4 = (data, token, isSelf) => async (dispatch) => {
  if (window.innerWidth < 600) {
    data['askCardsAfter'] = 4;
    data['askCardsNo'] = 1;
  }
  console.log(data)
  // if isSelf, fetch only user own list by providing aid in data
  const reqOptions = {
    method: 'POST',
    url: FETCH_LIST_V4_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      if (isSelf) {
        dispatch({
          type: FETCH_LIST,
          payload: {
            myLists: res.data.data,
          },
        });
      } else {
        dispatch({
          type: FETCH_LIST,
          payload: {
            lists: res.data.data,
          },
        });
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchListById = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_LIST_BY_ID_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_LIST_BY_ID,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchDraftListv4 = (data, token, isSelf) => async (dispatch) => {
  if (window.innerWidth < 600) {
    data['askCardsAfter'] = 4;
    data['askCardsNo'] = 1;
  }
  // if isSelf, fetch only user own list by providing aid in data
  const reqOptions = {
    method: 'POST',
    url: FETCH_DRAFT_LIST_V4_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      if (isSelf) {
        dispatch({
          type: FETCH_DRAFT_LIST,
          payload: {
            myLists: res.data.data,
          },
        });
      } else {
        dispatch({
          type: FETCH_DRAFT_LIST,
          payload: {
            draftlists: res.data.data,
          },
        });
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchListByIdPublic = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_LIST_BY_ID_PUB_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_LIST_BY_ID,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const getRecommendation = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: GET_RECOMMENDATION,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  await axios(reqOptions)
    .then((res) => {
      // console.clear();
      // console.log('++++++++++++++++++++++++++++++++');
      // console.log(res.data.data.result);
      dispatch({
        type: UPDATE_RECOMMENDATION,
        payload: res.data.data.result,
      });
    })
    .catch((error) => {
      if (error.response) {
        // if (error.response.data.code === 1500) {
        // dispatch({ type: LOGOUT_USER });
        // window.location.reload();
        // }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const searchList = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: SEARCH_LIST_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: SEARCH_LIST,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const clearCurrentListDataFromStore = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CURRENT_LIST_DATA_FROM_STORE,
  });
};

export const addCollaborator = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: ADD_LIST_COLLAB_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  try {
    let res = await axios(reqOptions);
    dispatch({
      type: ADD_LIST_COLLAB,
      payload: res.data.data,
    });
  } catch (error) {
    if (error.response) {
      if (error.response.data.code === 1500) {
        dispatch({ type: LOGOUT_USER });
        window.location.reload();
      }
    } else {
      console.log('ERROR: something went wrong');
      return 'ERR';
    }
  }
};

export const deleteCollab = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: DELETE_LIST_COLLAB_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: DELETE_LIST_COLLAB,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        dispatch({
          type: DELETE_LIST_COLLAB,
          payload: 'ERR',
        });
      }
    });
};

export const respondInvite = (data, token) => async (dispatch) => {
  let { lsid, collabEmail, status, aid } = data;
  const reqOptions = {
    method: 'POST',
    url: `${RESPOND_INVITE_API}?lsid=${lsid}&email=${collabEmail}&status=${status}`,
    data: JSON.stringify({ aid }),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: RESPOND_LIST_COLLAB,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        dispatch({
          type: RESPOND_LIST_COLLAB,
          payload: 'ERR',
        });
      }
    });
};

export const fetchFollowingUserList = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_USERFOLLOWING_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_USERFOLLOWING,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const followUser = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FOLLOWING_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FOLLOW_USER,
        payload: res.data.success,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const unFollowUser = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: UNFOLLOWING_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FOLLOW_USER,
        payload: !res.data.success,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const increaseView = (lsid, aid) => async (dispatch) => {
  var data = { lsid, aid };
  const reqOptions = {
    method: 'POST',
    url: INCREASE_VIEW_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: ASK_QUESTION,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const askQuestion = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: ASK_QUESTION_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

return await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: ASK_QUESTION,
        payload: res.data,
      });
      return res;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: ASK_QUESTION_ERR,
            payload: error.response.data.message,
          });
        }
      } else {
        console.log('ERROR: something went wrong');
        dispatch({
          type: ASK_QUESTION_ERR,
          payload: error.message,
        });
        return "ERR";
      }
    });
};

export const fetchRequests = (query, token) => async (dispatch) => {
  console.log(' fetchRequests ');
  const reqOptions = {
    method: 'POST',
    url: FETCH_REQUESTS_API,
    params: query,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_REQUESTS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchRequestReplies = (query, token) => async (dispatch) => {
  //console.log("fetchRequestReplies");
  const reqOptions = {
    method: 'POST',
    url: FETCH_REQUEST_REPLIES_API,
    params: query,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: FETCH_REQUEST_REPLIES,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const replyToAsk = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: REPLY_TO_ASK_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      console.log(res);
      dispatch({
        type: REPLY_TO_ASK,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const deleteList = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: DELETE_LIST_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: DELETE_LIST_ITEM,
        payload: res.data.success,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const deleteListItem = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: DELETE_LIST_ITEM_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      console.log(res.data.success);
      dispatch({
        type: DELETE_LIST_ITEM,
        payload: res.data.success,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const upvoteListItem = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: UPVOTE_LIST_ITEM_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  return axios(reqOptions)
    .then((res) => {
      // dispatch({
      //   type: UPVOTE_LIST_ITEM,
      //   payload: res.data.success,
      // });
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return Promise.reject(error);
      }
    });
};

export const deleteUpvoteListItem = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: DELETE_UPVOTE_LIST_ITEM_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  return axios(reqOptions)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return Promise.reject(error);
      }
    });
};

export const bookmarkListItem = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: BOOKMARK_LIST_ITEM_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  return axios(reqOptions)
    .then((res) => {
      return Promise.resolve(res);
      // dispatch({
      //   type: BOOKMARK_LIST_ITEM,
      //   payload: res.data.success,
      // });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return Promise.reject(error);
      }
    });
};

export const deletebookmarkListItem = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: DELETE_BOOKMARK_LIST_ITEM_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  return axios(reqOptions)
    .then((res) => {
      return Promise.resolve(res);
      // dispatch({
      //   type: BOOKMARK_LIST_ITEM,
      //   payload: res.data.success,
      // });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return Promise.reject(error);
      }
    });
};

export const fetchUserBookmarks = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_BOOKMARKS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_BOOKMARKS,
        payload: res.data.data.reverse(),
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

/*====profile setting api call =================*/

export const editProfile = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: EDIT_PROFILE_API,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: EDIT_PROFILE,
        payload: 'success',
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: EDIT_PROFILE,
            payload: error.response.data.message,
          });
        }
      } else {
        console.log('ERROR: something went wrong');
        dispatch({
          type: EDIT_PROFILE,
          payload: error.message,
        });
      }
    });
};

export const changeUserName = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: CHANGE_USERNAME_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_PROFILE,
        payload: res.data.data,
      });
	// res.data.data has no field called user_name
	//let { user_name } = res.data.data;
	
	// setting this to be the changed username
	let user_name = data.user_name;
	let newData = { user_name };
	console.log(data.user_name);

	//updating the user details cookie in the browser
	Cookies.set(COOKIE_AUTH, data, { expires: 30 });
      dispatch({
        type: LOGIN_USER,
        payload: newData,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchProfile = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_USER_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_PROFILE,
        payload: res.data.data,
      });
      let { avatar, name, bio } = res.data.data;
      let newData = { avatar, name, bio };
      dispatch({
        type: LOGIN_USER,
        payload: newData,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const changePassword = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: CHANGE_PASSWORD_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: CHANGE_PASSWORD,
        payload: 'success',
      });
      dispatch({
        type: PASSWORD_CHANGED,
        payload: { passwordChanged: true, emailSent: false },
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: CHANGE_PASSWORD,
            payload: error.response.data.message,
          });
        }
      } else {
        dispatch({
          type: CHANGE_PASSWORD,
          payload: error.message,
        });
      }
    });
};

export const createPassword = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: CREATE_PASSWORD_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  dispatch({
    type: 'LOADING',
    payload: { loading: true },
  });
  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: { verify_email_type: 3, loading: false, emailSent: true },
      });
    })
    .catch((error) => {
      dispatch({
        type: 'LOADED',
        payload: { loading: false },
      });
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: CHANGE_PASSWORD,
            payload: error.response.data.message,
          });
        }
      } else {
        dispatch({
          type: CHANGE_PASSWORD,
          payload: error.message,
        });
      }
    });
};

export const changeEmail = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: CHANGE_EMAIL_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: CHECK_PASSWORD,
        payload: 'success',
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: CHECK_PASSWORD,
            payload: error.response.data.message,
          });
        }
      } else {
        dispatch({
          type: CHECK_PASSWORD,
          payload: error.message,
        });
      }
    });
};

export const sendVerifyEmail = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: SEND_VERIFY_EMAIL_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: SEND_VERIFY_EMAIL,
        payload: 'success',
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: SEND_VERIFY_EMAIL,
            payload: error.response.data.message,
          });
        }
      } else {
        dispatch({
          type: SEND_VERIFY_EMAIL,
          payload: error.message,
        });
      }
    });
};

export const verifyEmailOTP = (userData, token) => (dispatch) => {
  //console.log(urlLink, type);
  const reqOptions = {
    method: 'POST',
    url: VERIFY_EMAIL_API,
    data: JSON.stringify(userData),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  dispatch({
    type: 'LOADING',
    payload: { loading: true },
  });
  axios(reqOptions)
    .then((res) => {
      console.log('test res: ', res);

      dispatch({
        type: CHANGE_EMAIL,
        payload: 'success',
      });
    })
    .catch((error) => {
      dispatch({
        type: 'LOADED',
        payload: { loading: false },
      });
      console.log('test: ', error);
      let data = {
        error: '',
      };
      data.error = error.response ? error.response.data.message : error.message;
      dispatch({
        type: LOGIN_USER_ERR,
        payload: data,
      });
    });
};

export const fetchPeople = (token) => async (dispatch) => {
  const reqOptions = {
    method: 'GET',
    url: FETCH_PEOPLE_API,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_PEOPLE,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchTags = (token) => async (dispatch) => {
  const reqOptions = {
    method: 'GET',
    url: FETCH_TAG_API,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_TAG,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchBoards = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_BOARDS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  let resp;
  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_BOARDS,
        payload: res.data.data,
      });
      resp = res.data.data;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
  return resp;
};

export const deleteFolder = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: DELETE_FOLDER_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: DELETE_FOLDER,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const deleteBoard = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: DELETE_BOARD_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: DELETE_BOARD,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const changeBoardVisibility = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: CHANGE_BOARD_VISIBILITY_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: CHANGE_BOARD_VISIBILITY,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const addFolder = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: ADD_FOLDER_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: ADD_FOLDER,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const editFolder = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: EDIT_FOLDER_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  let resp;
  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: EDIT_FOLDER,
        payload: res.data.data,
      });
      resp = res.data.data;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
  return resp;
};

export const renameFolder = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: RENAME_FOLDER_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: RENAME_FOLDER,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const makeFolderPrivate = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: PRIVATE_FOLDER_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: PRIVATE_FOLDER,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const addBoard = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: ADD_BOARD_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: ADD_BOARD,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const addListToFolder = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: ADD_LISTS_TO_FOLDERS_API,
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: ADD_LISTS_TO_FOLDER,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchFolderItems = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_FOLDER_ITEMS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_FOLDER_ITEMS,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const updateFolder = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: UPDATE_FOLDER_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: UPDATE_FOLDER,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchFldrItemDetails = async (data, token) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_FODLER_ITEM_DETAILS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  try {
    let res = await axios(reqOptions);
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export const saveListVersionThree = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: SAVE_LIST_VERSION_THREE,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  return await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: SAVE_LIST_ITEM,
        payload: res.data.data,
      });
      return res;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const editList = (data, token) => async (dispatch) => {
  console.log(data);

  const reqOptions = {
    method: 'POST',
    url: EDIT_LIST_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  return await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: EDIT_LIST,
        payload: res.data.data,
      });
      return res;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchBookmarkOfList = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_BOOKMARKS_LIST_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_BOOKMARKS_LIST,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const pageState = (pstate) => async (dispatch) => {
  await dispatch({
    type: GET_PAGE_STATE,
    payload: pstate,
  });
};

export const getPublicProfile = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: GET_PUBLIC_PROFILE_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: GET_PUBLIC_PROFILE,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const getNotifications = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: GET_NOTIFICATIONS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const markReadNotif = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'post',
    url: MARK_READ_NOTIF_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: MARK_READ_NOTIF,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const markAllReadNotif = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'post',
    url: MARK_ALLREAD_NOTIF_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: MARK_READ_NOTIF,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const reportList = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'post',
    url: REPORT_LIST_API,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: REPORT_LIST,
        payload: 'success',
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: REPORT_LIST,
            payload: error.response.data.message,
          });
        }
      } else {
        console.log('ERROR: something went wrong');
        dispatch({
          type: REPORT_LIST,
          payload: error.message,
        });
      }
    });
};

export const fetchMetaData = (data, token) => {
  const reqOptions = {
    method: 'post',
    url: FETCH_METADATA_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  return new Promise((resolve, reject) => {
    axios(reqOptions)
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.data);
        } else {
          reject(new Error('someting went wrong'));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const promoteList = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'post',
    url: PROMOTE_LIST_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: PROMOTE_LIST,
        payload: 'success',
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const bookmarkUser = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: BOOKMARK_USER_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: BOOKMARK_USER,
        payload: res.data.success,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const unBookmarkUser = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: UNBOOKMARK_USER_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: UNBOOKMARK_USER,
        payload: res.data.success,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchUsersBookmarked = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_USERS_BOOKMARKED_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_USERS_BOOKMARKED,
        payload: res.data.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const addListItem = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'post',
    url: ADD_LIST_ITEM_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: ADD_LIST_ITEM,
        payload: { message: 'success', addedListItem: res.data.data },
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: ADD_LIST_ITEM,
            payload: error.response.data.message,
          });
        }
      } else {
        console.log('ERROR: something went wrong');
        // return 'ERR';
        dispatch({
          type: ADD_LIST_ITEM,
          payload: error,
        });
      }
    });
};

export const fetchUserLists = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_USER_LISTS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_USER_LIST,
        payload: {
          myLists: res.data.data,
        },
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchUserListsFunction = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    const reqOptions = {
      method: 'POST',
      url: FETCH_USER_LISTS_API,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    };

    await axios(reqOptions)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchUserProfileBookmarks = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_USER_BOOKMARKS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_USER_BOOKMARKS,
        payload: {
          myBookmarks: res.data.data,
        },
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};
export const fetchAllUserBookmarks = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_ALL_USER_BOOKMARKS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      console.log("res",res)
      let bookmarkedLists = res.data.data.filter((bookmark)=>{
        if(bookmark.lsitid===null){
          return bookmark.lsid
        }
      })
      console.log(bookmarkedLists)
      dispatch({
        type: FETCH_ALL_USER_BOOKMARKS,
        payload: {
          allUserBookmarks:bookmarkedLists.map((i)=>{return i.lsid})
        },
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchUserProfileBookmarksFunction = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    const reqOptions = {
      method: 'POST',
      url: FETCH_USER_BOOKMARKS_API,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    };

    await axios(reqOptions)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchUserListsWhereCollab = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_USER_COLLAB_LISTS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: FETCH_USER_COLLAB_LISTS,
        payload: {
          myListsWhereCollab: res.data.data,
        },
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const fetchUserListsWhereCollabFunction = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    const reqOptions = {
      method: 'POST',
      url: FETCH_USER_COLLAB_LISTS_API,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    };

    await axios(reqOptions)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchStack = async (data, token) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_STACK_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  try {
    let res = await axios(reqOptions);
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export const pinStackItem = async (data, token) => {
  const reqOptions = {
    method: 'POST',
    url: PIN_STACK_ITEM_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  try {
    let res = await axios(reqOptions);
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export const deleteStackItem = async (data, token) => {
  const reqOptions = {
    method: 'POST',
    url: DELETE_STACK_ITEM_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  try {
    let res = await axios(reqOptions);
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export const renameBoard = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'POST',
    url: RENAME_BOARD_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then((res) => {
      dispatch({
        type: RENAME_BOARD,
        payload: {
          renamedBoard: res.data.data,
        },
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        }
      } else {
        console.log('ERROR: something went wrong');
        return 'ERR';
      }
    });
};

export const deleteAsked = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'post',
    url: DELETE_ASKED_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then(() => {
      dispatch({
        type: DELETE_ASKED,
        payload: 'success',
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: DELETE_ASKED,
            payload: error.response.data.message,
          });
        }
      } else {
        console.log('ERROR: something went wrong');
        // return 'ERR';
        dispatch({
          type: DELETE_ASKED,
          payload: error.message,
        });
      }
    });
};

export const checkboxListItem = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'post',
    url: CHECKBOX_LIST_ITEM_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then(() => {
      dispatch({
        type: CHECKBOX_LIST_ITEM_API,
        payload: 'success',
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: CHECKBOX_LIST_ITEM_API,
            payload: error.response.data.message,
          });
        }
      } else {
        console.log('ERROR: something went wrong');
        // return 'ERR';
        dispatch({
          type: CHECKBOX_LIST_ITEM_API,
          payload: error.message,
        });
      }
    });
};

// covid related actions start

export const fetchDetails = async (data, token) => {
  const reqOptions = {
    method: 'POST',
    url: FETCH_COVID_PRIMARIES_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  try {
    let res = await axios(reqOptions);
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export const addArea = async (data, token) => {
  const reqOptions = {
    method: 'POST',
    url: ADD_COVID_AREAS_API,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-access-token': token,
    },
  };

  try {
    let res = await axios(reqOptions);
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export const isCovidVerifier = async (data, token) => {
  const reqOptions = {
    method: 'POST',
    url: IS_COVID_VERIFIER_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  try {
    let res = await axios(reqOptions);
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export const getPendingCovidAreas = async (data, token) => {
  const reqOptions = {
    method: 'POST',
    url: GET_PENDING_COVID_AREAS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  try {
    let res = await axios(reqOptions);
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export const reviewArea = async (data, token) => {
  const reqOptions = {
    method: 'POST',
    url: REVIEW_PENDING_COVID_AREAS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  try {
    let res = await axios(reqOptions);
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

// covid related actions end

export const setRefreshDiscover = () => (dispatch) => {
  dispatch({ type: REFRESH_DISCOVER_TRUE });
};

export const unsetRefreshDiscover = () => (dispatch) => {
  dispatch({ type: REFRESH_DISCOVER_FALSE });
};

// SUSPECT LIST APIS

export const makeListSuspected = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'post',
    url: MAKE_LIST_SUSPECTED_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then(() => {
      dispatch({
        type: MAKE_LIST_SUSPECTED,
        payload: 'success',
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: MAKE_LIST_SUSPECTED,
            payload: error.response.data.message,
          });
        }
      } else {
        console.log('ERROR: something went wrong');
        // return 'ERR';
        dispatch({
          type: MAKE_LIST_SUSPECTED,
          payload: error.message,
        });
      }
    });
};

export const respondSuspectedList = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'post',
    url: RESPOND_SUSPECT_LIST_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then(() => {
      dispatch({
        type: RESPOND_SUSPECT_LIST,
        payload: 'success',
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: RESPOND_SUSPECT_LIST,
            payload: error.response.data.message,
          });
        }
      } else {
        console.log('ERROR: something went wrong');
        // return 'ERR';
        dispatch({
          type: RESPOND_SUSPECT_LIST,
          payload: error.message,
        });
      }
    });
};

export const updateSuspectedListStatus = (data, token) => async (dispatch) => {
  const reqOptions = {
    method: 'post',
    url: UPDATE_SUSPECT_STATUS_API,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  await axios(reqOptions)
    .then(() => {
      dispatch({
        type: UPDATE_SUSPECT_STATUS,
        payload: 'success',
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data.code === 1500) {
          dispatch({ type: LOGOUT_USER });
          window.location.reload();
        } else {
          dispatch({
            type: UPDATE_SUSPECT_STATUS,
            payload: error.response.data.message,
          });
        }
      } else {
        console.log('ERROR: something went wrong');
        // return 'ERR';
        dispatch({
          type: UPDATE_SUSPECT_STATUS,
          payload: error.message,
        });
      }
    });
};
