import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ASK_PATH,
  HOME_PATH,
  getListPath,
  LOGIN_PATH,
  SHARE_PATH,
} from "../constants/pathConstants";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { FETCH_RELATED_LIST_API } from "../constants/apiConstants";
import { CATEGORIES } from "../constants/categoryConstants";
import ErrorAlert from "./Nested/ErrorAlert";
import CollabModal from "./Nested/collabModal";
import CollabBtnComponent from "./Nested/collabBtnComponent";
import ShareAskLinkBtn from "./ShareAskLinkBtn";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ToggleBtn from "./Nested/ToggleBtn";
import Badge from "@material-ui/core/Badge";
import ShareSettings from "./ShareSettings/ShareSettings";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccessControl from "./Nested/AccessControl";
import SettingsIcon from "@material-ui/icons/Settings";
import ReactTooltip from "react-tooltip";
import Tooltip from "@material-ui/core/Tooltip";

class Ask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listTitle: "",
      listTags: [],
      listDesc: "",
      listItemRequired: 5,
      listCategory: "",
      formError: "",
      relatedLists: [],
      btnLoading: false,
      openModal: false,
      relatedLoading: false,
      showAlert: false,
      alertMessage: "",
      alertCode: null,
      contributors: [],
      isCollabModalOpen: false,
      collaborators: [],
      publicBtnVal: true,
      conciousBtnVal: false,
      liveBtnVal: false,
      mediaBtnVal: false,
      closedBtnVal: false,
      sensitive: false,
      covid: false,
      isSensitiveLocked: false,
      anchorEl: null,
    };
  }

  //Snackbar auto-hide method
  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false,
    });
  };

  showSnackBar = (message, code) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
      alertCode: code,
    });
  };

  async componentDidMount() {
    /*** Populating data added by new user when they were not signed in..after authentication*/

    let cachedData = sessionStorage.getItem("listiee_last_ask_data");

    if (this.props.token && cachedData !== null) {
      console.log("Cached data found...");

      console.log("cachedData\n", cachedData);
      const newState = await this.processCachedData(cachedData);
      this.setState({ ...newState });
      sessionStorage.removeItem("listiee_last_ask_data");
      return;
    }
    this.setState((prevState, props) => {
      const totalList = props.totalList ? props.totalList : {};
      const listAid = totalList.listAid;
      const isSwitchDisabled = this.isControlDisable(listAid);
    });
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  processCachedData = async (data) => {
    data = await JSON.parse(data);
    const { aid } = this.props;
    console.log("Data", data);
    return data;
  };

  onHandleChange = async (e) => {
    const { name, value } = e.target;

    await this.setState({
      [name]: value,
    });
    if (name === "listTitle") this.checkForRelatedList(e);
  };

  handleDelete = (tagName) => {
    const { listTags } = this.state;
    this.setState({
      listTags: listTags.filter((tag) => tag !== tagName),
    });
  };

  handleTagInput = (e) => {
    // e.keyCode was not working on chrome so I wrote this custom code...
    let str = e.target.value.trim();
    let currentTags = this.state.listTags;
    let enterKeyPressed = e.key === "Enter";
    // add tag to state when user press comma key
    if (
      (str !== "," && str.substr(str.length - 1) === ",") ||
      (enterKeyPressed&&str.length!==0)
    ) {
      if (!enterKeyPressed) {
        str = str.slice(0, -1); //remove the comma character from the string
      }
      if (str[0] == "#") {
        str = str.slice(1);
      }
      if (!currentTags.includes(str))
        this.setState({ listTags: [...this.state.listTags, str] });
      else this.showSnackBar("Please enter unique tags...", "error");
      e.target.value = "";
    }
  };

  handleCheckTitle = (e) => {
    let title = this.state.listTitle;
    if (!title.toLowerCase().startsWith("list of")) {
      title = "List of " + title;
      this.setState({
        listTitle: title,
      });
    }
  };
  handleSwitchChange = (value, checked) => {
    this.setState({ [checked]: value });
  };

  checkForRelatedList = async (e) => {
    /* if (this.props.token) return; */

    e.preventDefault();
    let title = this.state.listTitle;
    console.log(title);
    let relatedLists = [];
    if (title.length > 4) {
      this.setState({ relatedLoading: true });
      //fetch related list
      const relatedListsData = await this.fetchRelated(title);
      if (relatedListsData.status === 200) {
        relatedLists = relatedListsData.data.data;
      }
      this.setState({ relatedLists, relatedLoading: false });
    } else {
      this.setState({ relatedLists, relatedLoading: false });
    }
  };

  fetchRelated = async (query) => {
    let data = [];

    const reqOptions = {
      method: "POST",
      url: FETCH_RELATED_LIST_API,
      headers: {
        "x-access-token": this.props.token,
      },
      data: {
        search_query: query,
      },
    };

    console.log("Fetching related list with data:", reqOptions);

    await axios(reqOptions)
      .then((res) => {
        //response is an array of related lists
        data = res;
        console.log(res);
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  };

  onSubmit = async (e) => {
    if (!this.props.token) {
      console.log("Please log in first...");
      sessionStorage.setItem(
        "listiee_last_ask_data",
        JSON.stringify({ ...this.state })
      );
      setTimeout(() => {
        this.props.history.push(LOGIN_PATH, {
          // login_required: true,
          redirect_to: ASK_PATH,
        });
      }, 200);
      return;
    }

    const { aid, askQuestion, token, clearAskError } = this.props;
    let {
      listCategory,
      listTitle,
      listDesc,
      contributors,
      listTags,
      listItemRequired,
    } = this.state;
    if (!listTitle) {
      this.showSnackBar("Please enter a title", "error");
      return;
    }
    if (!listCategory) {
      this.showSnackBar("Please select a category", "error");
      return;
    }
    if (listItemRequired) {
      if (listItemRequired < 0) {
        this.showSnackBar("You asked for invalid no of listItem", "warning");
        return;
      }

      if (
        (listItemRequired < 5 && listItemRequired >= 0) ||
        listItemRequired > 20
      ) {
        this.showSnackBar(
          "Please ask for atleast 5 items and atmost 20 items",
          "warning"
        );
        return;
      }
    } else {
      this.setState({
        listItemRequired: 5,
      });
    }

    this.setState({
      loading: true,
    });
    const data = {
      category: listCategory,
      question: listTitle,
      aid,
      description: listDesc,
      contributors: contributors,
      tags: listTags,
      listItemRequired,
    };
    const response = await askQuestion(data, token);
    const lsid = response.data.data._id;
    const slug = response.data.data.slug;
    console.log(response.data.data);
    if (this.props.askError) {
      this.showSnackBar(this.props.askError, "error");
      this.setState({ loading: false });
      return;
    }
    this.setState({
      openModal: true,
      loading: false,
    });

    clearAskError();
    setTimeout(() => {


      console.log("here");
      this.props.history.push({
        pathname: getListPath(slug, lsid),
        state: {
          askPublished: true,
        }
      });
    }, 3000);
  };

  onCollabSubmit = async (collab) => {
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (collab && emailRegex.test(collab)) {
      let prevCollabs = this.state.contributors;
      if (prevCollabs.indexOf(collab) === -1) {
        prevCollabs.push(collab);
        this.setState({
          contributors: prevCollabs,
        });
      }
    } else {
      this.showSnackBar("Invalid Email", "error");
    }
  };

  toggleCollabModalBtn = (e) => {
    e.stopPropagation();
    this.setState({ isCollabModalOpen: !this.state.isCollabModalOpen });
  };
  toggleCollabModal = (e) => {
    this.setState({ isCollabModalOpen: !this.state.isCollabModalOpen });
  };
  isControlDisable = (itemAid = this.state.listAid) =>
    this.state.mode === "edit"
      ? this.props.aid !== itemAid &&
        this.props.user_type !== "amender" &&
        this.props.user_type === "mender"
      : false;

  render() {
    const {
      loading,
      listTitle,
      listTags,
      listDesc,
      listCategory,
      formError,
      relatedLists,
      btnLoading,
      openModal,
      relatedLoading,
      listItemRequired,
      anchorEl,
      isSwitchDisabled,
      isControlDisable,
    } = this.state;
    const { askError } = this.props;

    const renderRelatedLists = () => {
      if (relatedLoading) {
        return (
          <p>
            Loading <i className="fas fa-spinner"></i>
          </p>
        );
      }
      return relatedLists.length !== 0 ? (
        relatedLists.map((item, id) => (
          <li key={id}>
            <Link
              to={getListPath(item.slug, item._id)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.name}
            </Link>
          </li>
        ))
      ) : (
        <>
          {listTitle.length > 0 ? (
            <p className={"related-no-data"}>
              Your list seems to be unique. <br /> No similar lists found.
            </p>
          ) : null}
        </>
      );
    };

    const toggleModal = () => {
      this.setState({
        openModal: !this.state.openModal,
      });
    };

    let renderCategories = [];
    CATEGORIES.forEach((item) => {
      renderCategories.push(<option value={item}>{item}</option>);
    });

    return (
      <div className={"ask_container"}>
        <ErrorAlert
          AutoHideSnackbar={this.AutoHideSnackbar}
          showAlert={this.state.showAlert}
          message={this.state.alertMessage}
          status={this.state.alertCode}
        />
        <div className="ask-text">
          <p>..let's get some help </p>
        </div>

        <div className="ask_box">
          <Modal isOpen={openModal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}></ModalHeader>
            <ModalBody>
              {loading
                ? "Please wait..."
                : askError === ""
                ? "Question Successfully asked!"
                : "Something wrong happened!"}
              <br />

              {/* removing the option for the user to click on the done button
                * because it redirects them normally and without the option to pass some message,
                * however I need to pass some message while redirecting, which will automatically happen after 3 seconds in the setTimeout method above
                * along with the */}

              {/* <Link className={"btn btn-primary mt-3"} to={HOME_PATH}> */}
              {/*   Done */}
              {/* </Link> */}
            </ModalBody>
          </Modal>
          <div className={"container"}>
            <div className="card-top">
              <div className="list-settings-btn">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="locationDropdown">
                    <select
                      title="change list's relevance"
                      value={this.state.relevance}
                      onChange={this.setRelevance}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      onFocus={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <option value="national">National</option>
                      <option value="global">Global</option>
                      <option value="local">Local</option>
                    </select>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Badge
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={this.state.collaborators.length}
                    >
                      <CollabBtnComponent
                        addCollaborator={this.props.addCollaborator}
                        storeCollabForPublishList={
                          this.storeCollabForPublishList
                        }
                        token={this.props.token}
                        {...this.props}
                        toggleCollabModal={this.toggleCollabModalBtn}
                      />
                    </Badge>
                    <CollabModal
                      onCollabSubmit={this.onCollabSubmit}
                      token={this.props.token}
                      mode={this.props.mode}
                      {...this.props}
                      isCollabModalOpen={this.state.isCollabModalOpen}
                      toggleCollabModal={this.toggleCollabModal}
                    />
                    <ShareSettings
                      publicBtnVal={this.state.publicBtnVal}
                      conciousBtnVal={this.state.conciousBtnVal}
                      closedBtnVal={this.state.closedBtnVal}
                      handleSwitchChange={this.handleSwitchChange}
                      liveBtnVal={this.state.liveBtnVal}
                      sensitive={this.state.sensitive}
                      covid={this.state.covid}
                      listAid={this.state.listAid}
                      isControlDisable={this.isControlDisable}
                    />
                  </div>
                </div>
              </div>
            </div>

            <p className={"text-error"}>{formError}</p>
            <form className={"base-form"} onSubmit={(e) => e.preventDefault()}>
              <div className={"left-form"}>
                <div className={"form-group ask-group"}>
                  <input
                    name={"listTitle"}
                    type="text"
                    className={"form-control"}
                    id="listTitle"
                    autoFocus="autofocus"
                    placeholder="What list are you looking for?"
                    onChange={this.onHandleChange}
                    value={listTitle}
                    onBlur={this.checkForRelatedList}
                    autoFocus
                  />
                  <button
                    className={"btn btn-primary"}
                    type={"button"}
                    onClick={this.onSubmit}
                    style={{ width: "140px" }}
                  >
                    {btnLoading ? <i className={"fas fa-spinner"} /> : null}{" "}
                    {btnLoading ? "Saving..." : "Ask"}
                  </button>
                </div>
                <div className={"form-group"}>
                  <textarea
                    name={"listDesc"}
                    className={"form-control listDesc"}
                    id="listDesc"
                    placeholder="Shortly describe what are you looking for!"
                    rows="3"
                    onChange={this.onHandleChange}
                    value={listDesc}
                    onFocus={this.checkForRelatedList}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                  // className="row"
                  className="ask-group"
                >
                  <div
                    style={{ display: "flex",color:"slategray", gap: "0.5rem",alignItems:"center" }}
                    //  className="col-sm-8"
                    // className="form-group"
                  >
                    <div>Item Count</div>
                    <input
                      type="number"
                      name="listItemRequired"
                      id="listItemRequired"
                      min="5"
                      max="20"
                      placeholder="Number of list items required"
                      value={listItemRequired}
                      onChange={this.onHandleChange}
                      optional
                      title="Number of list items needed."
                    />
                  </div>
                  <div
                  //  className="col-sm-4"
                  >
                    <div
                    //  className={"form-group ask-group"}
                    >
                      <select
                        name={"listCategory"}
                        // className={"form-control"}
                        id={"listCategory"}
                        onChange={this.onHandleChange}
                        value={listCategory}
                      >
                        <option style={{ color: " #a5a5a5 !important" }}>
                          Category
                        </option>
                        {renderCategories}
                      </select>
                    </div>
                  </div>
                </div>

               <div
                //  className="footer-wrapper"
                >
                  <div
                  // className={"item-nos"}
                  >
                    <div className={"form-group "}>
                      <div className="tag-container">
                        {listTags.map((tag, i) => {
                          return (
                            <span className="tag mt-2" key={i}>
                              {"#"}
                              {tag}{" "}
                              <i
                                className="fas fa-times"
                                onClick={(e) => this.handleDelete(tag)}
                              />
                            </span>
                          );
                        })}
                        <input
                          type="text"
                          name="listTags"
                          id="listTags"
                          className="form-control input-box no-border"
                          placeholder="Few primary tags (comma separated)"
                          onFocus={(e) => (e.target.placeholder = "")}
                          onBlur={(e) =>
                            (e.target.placeholder = "Few tags please!")
                          }
                          onKeyUp={this.handleTagInput}
                          onFocus={this.handleCheckTitle}
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="container-2">
            {/* <div className={'row'}>
                      <div className='col-12 ask-live'>
                        <p className='h5'>Ask Live</p>
                        
                        <select
                          name={'askLiveCategory'}
                          className={'form-control'}
                          id={'askLiveCategory'}
                          onChange={this.onHandleChange}
                          value={listCategory}
                        >
                          <option>What's in the image</option>
                        </select>
                        <button
                          className={'btn btn-success mt-2'}
                          type={'button'}
                        >
                          Ask
                        </button>
                      </div>
                      <br />
                      <br />
                      <br />
                    </div>  */}
            <div className="h7 related-title">Related lists</div>
            <div className="related-list-container">
              <ul className={"simple-list"}>{renderRelatedLists()}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Ask.propTypes = {
  askQuestion: PropTypes.func.isRequired,
  clearAskError: PropTypes.func.isRequired,
  aid: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  askError: PropTypes.bool.isRequired,
};

export default Ask;
