import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PEOPLE_PATH, getListPath, HOME_PATH } from "../constants/pathConstants";
import SideBar from './Utils/SideBar';

class People extends Component {
    constructor(props) {
        super(props);

        this.setState = {
            people: []
        }
    }

    componentWillMount() {
        if (!this.props.token) this.props.history.push(HOME_PATH, { login_required: true, redirect_to: PEOPLE_PATH });
        else {
            this.props.fetchPeople(this.props.token)
        };
    }
    render() {
        console.log(this.props)
        const { people } = this.props;
        return (
            <div className="container discover_container">
                <div className="row">
                    <SideBar />
                    <div className="col-12 col-md-9 col-lg-10">
                        <div className="row">
                            {people.map((data) => {
                                return (
                                    <div className="col-12 col-md-6 col-lg-3" key={data.aid}>
                                        <div className={'list_card'}>
                                            <p className="list-card-title">{data.user_name}</p>
                                            <hr />
                                            <ol className={'list'}>
                                                <li key={1}><a href={data.url} target='_blank' rel="noopener noreferrer" >{data.company ? data.company : 'company'}</a> </li>
                                                <li key={2}><a href={data.fb_url} target='_blank' rel="noopener noreferrer" >Facebook</a> </li>
                                                <li key={3}><a href={data.linkedIn_url} target='_blank' rel="noopener noreferrer">LinkedIn profile</a> </li>
                                            </ol>
                                            <div className="card-control">
                                                <i style={{ flexGrow: "5" }}></i>
                                                <i className="fa fa-eye"> 0</i>
                                                <i className="fa fa-heart"> 430</i>
                                                <i className="fa fa-tags"> 23</i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default People;