import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import AddIcon from "@material-ui/icons/Add";
import Switch from "@material-ui/core/Switch";
import ErrorAlert from "./ErrorAlert";

export default function StackModal(props) {
    const [name, setName] = useState("");
    const [privacy, setPrivacy] = useState(false);
    const [purpose, setPurpose] = useState("");
    const [boardId, setBoardId] = useState(null);
    const [mode, setMode] = useState("Add");
    const [modalOpen, setModalOpen] = useState(false);
    const [alert, setAlert] = useState({ show: false, msg: null, type: null });

    const AutoHideSnackbar = () => {
        setAlert({
            show: false,
        });
    };

    const snackBar = (type, msg) => {
        setAlert({
            show: true,
            msg: msg,
            type: type,
        });
    };

    useEffect(() => {
        (async () => {
            let { boardId } = props;
            if (props.boardId) {
                setBoardId(boardId);
            }
        })();
    }, []);

    const toggleModal = () => {
        setName(props.name);
        setPrivacy(props.private);
        setPurpose(props.purpose);
        setMode(props.mode);
        setModalOpen(!modalOpen);
    };

    const handleChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        if (name === "name") {
            setName(value);
        } else if (name === "purpose") {
            setPurpose(value);
        } else {
            return;
        }
    };

    const handleSwitchChange = (e) => {
        e.preventDefault();
        setPrivacy(e.target.checked);
    };

    const onHandleSubmit = (e) => {
        e.preventDefault();
        let { handleAddFolder, handleEditFolder } = props;
        let data = {
            name,
            privacy,
            purpose,
        };

        if (props.id) {
            data.id = props.id;
        }

        if (!name) {
            snackBar("error", "Please enter a name.");
            return;
        } else if (!privacy && !purpose) {
            snackBar("error", "Purpose is required for a Public Stack.");
            return;
        } else {
            if (mode === "Update") {
                handleEditFolder(data);
            } else {
                if (boardId) {
                    data.boardId = boardId;
                }
                handleAddFolder(data);
            }
            toggleModal();
        }
    };

    const handleToggle = (e) => {
        if (props.makePersist) {
            props.makePersist();
        }
        toggleModal(e);
    };

    return (
        <>
            {props.mode === "Update" ? (
                <div
                    className="dropdown-item"
                    onClick={(e) => handleToggle(e)}
                    style={{cursor: "pointer"}}
                >
                    Update
                </div>
            ) : (
                <i onClick={(e) => toggleModal(e)}>
                    <AddIcon />
                </i>
            )}
            <ErrorAlert
                AutoHideSnackbar={AutoHideSnackbar}
                showAlert={alert.show}
                message={alert.msg}
                status={alert.type}
            />

            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader>{props.mode} Stack</ModalHeader>
                <ModalBody>
                    <div className={"modal_form__field ml-2 mr-2 mb-2"}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="adornment-password">
                                Name
                            </InputLabel>
                            <Input
                                type={"text"}
                                name={"name"}
                                className={"custom-password-input"}
                                value={name}
                                onChange={(e) => handleChange(e)}
                                autoFocus={true}
                            />
                        </FormControl>
                    </div>
                    <div className={"modal_form__field m-2"}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="adornment-password">
                                Purpose
                            </InputLabel>
                            <Input
                                type={"textarea"}
                                name={"purpose"}
                                className={"custom-password-input"}
                                value={purpose}
                                onChange={(e) => handleChange(e)}
                                autoFocus={true}
                            />
                        </FormControl>
                    </div>
                    <div className={"modal_form__field m-2"}>
                        Public
                        <Switch
                            checked={privacy}
                            onChange={(e) => handleSwitchChange(e)}
                            name="privacy"
                            color="primary"
                        />
                        Private
                    </div>
                    <div className={"modal_form__field m-2"}>
                        <Button
                            className={"modal_form__button m-2"}
                            style={{
                                width: "20%",
                                float: "center",
                            }}
                            color="primary"
                            variant={"contained"}
                            type={"submit"}
                            onClick={(e) => onHandleSubmit(e)}
                        >
                            {mode}
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

StackModal.propTypes = {
    mode: PropTypes.string.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    purpose: PropTypes.string,
    boardId: PropTypes.string,
    private: PropTypes.bool,
    makePersist: PropTypes.func
};
