import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import randomColor from "randomcolor";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  img: {
    backgroundColor: "transparent",
    textTransform: "capitalize",
  },
  fallback: {
    backgroundColor: randomColor(),
    textTransform: "capitalize",
  },
});

class ListDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayAboutSection: false,
    };
  }

  componentDidMount() {
    if (this.props.details[this.props.currentListItemIndex] !== undefined) {
      const { about = [] } =
        this.props.details[this.props.currentListItemIndex];
      if (about.length > 0 && this.state.displayAboutSection === false)
        this.setState({ displayAboutSection: true });
    }

  }

  componentDidUpdate() {
    if (this.props.details[this.props.currentListItemIndex] !== undefined) {
      const { about = [] } =
        this.props.details[this.props.currentListItemIndex];
      if (about.length > 0 && this.state.displayAboutSection === false)
        this.setState({ displayAboutSection: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentListItemIndex !== this.props.currentListItemIndex) {
      // window.scrollTo(0, 0);
      if (this.refs.detailCard) this.refs.detailCard.scrollTo(0, 0);
      // console.log(this.refs);
    }

    if (nextProps.displayAboutSection == true) {
      this.setState({ displayAboutSection: true });
    }


  }

  render() {
    let { details, currentListItemIndex } = this.props;
    if (details[currentListItemIndex] === undefined) return null;
    const {
      itemName = "",
      description = "",
      highlights = [],
      about = [],
      sections = [],
      links = "",
    } = details[currentListItemIndex];
    const { classes } = this.props;

    const renderHighlights = () => {
      return highlights.map((highlight, i) => (
        <li key={i}>
          <i className="fa fa-hand-point-right" />
          <input
            value={highlight}
            maxLength={100}
            onChange={(e) => this.props.handleHighlightsChange(e, i)}
            placeholder="It's cool!"
          ></input>
          <i
            className="fas fa-times"
            onClick={(e) => this.props.handleDeleteHighlight(e, i)}
          />
        </li>
      ));
    };

    const handleDisplayAbout = () => {
      this.props.setAboutSection(true);
      this.setState({ displayAboutSection: true });
    };

    const renderLinks = (items, section, sectionIndex) => {
      // console.log("links=",items)
      const links = items.map((item, i) => (
        <li key={"item" + i} style={{ position: "relative" }}>
          <a
            href={item.link}
            target="_blank"
            rel={"noopener noreferrer"}
            title={item.link}
          >
            {item.type == "listItem" ? (
              <>
                <p className="linked-list-item">{item.text}</p>
              </>
            ) : (
              <>
                <Avatar
                  src={item.icon}
                  onError={(e) => {
                    // This block is redundant now since we are using the MI-UI Avatar component which automatically defaults to a stock photo in case of an error

                    e.target.onerror = null;
                    e.target.src = "/assets/default-logo.png";
                  }}
                  alt="link icon"
                  className="list-link-logo"
                  style={{ textTransform: "capitalize" }}
                >
                  {/*In case of an error this always executes*/}
                  {/*Displaying the first two letter of the domain name*/}
                  {item.link.startsWith("www")
                    ? item.link.substring(4, 6)
                    : item.link.startsWith("http")
                    ? item.link.substring(11, 13)
                    : item.link.substring(0, 2)}
                </Avatar>
                <i
                  className="fas fa-link"
                  style={{
                    position: "relative",
                    bottom: "13px",
                    left: "24px",
                    color: "#00000099",
                  }}
                ></i>
              </>
            )}
          </a>
          <i
            className="removeLinkIcon fa fa-times"
            onClick={() =>
              this.props.handleRemoveLink(section, sectionIndex, i)
            }
          />
        </li>
      ));
      return links;
    };

    const renderSections = (ItemOwner) => {
      return sections.map((section, i) => {
        const icon = section.items.length > 0 ? "fa fa-plus" : "fas fa-link";
        return (
          <div
            // key={section.title + i}
            className="section"
          >
            <div className="section-title">
              <input
                key={i}
                onChange={(e) => {
                  this.props.handleContextChange(e, i);
                }}
                value={section.title}
              ></input>
              {ItemOwner ? (
                <IconButton
                  data-currentSectionIdx={i}
                  onClick={() => {
                    this.props.handleContextDelete(i);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              ) : null}
            </div>
            <ul className="section-items">
              {renderLinks(section.items, "otherSection", i)}
              <i
                className={icon + " add-section-item-btn"}
                onClick={(e) => this.props.handleAddLink(e, i, section.title)}
                onDrop={(event) => this.props.drop_handler(event, i)}
                onDragOver={(event) => this.props.dragover_handler(event)}
              />
            </ul>
          </div>
        );
      });
    };

    const renderAbout = () => {
      const icon = about.length > 0 ? "fa fa-plus" : "fas fa-link";
      return (
        <ul className="about">
          {renderLinks(about, "aboutSection")}
          <i
            className={icon + " add-section-item-btn"}
            onClick={this.props.toggle}
            onDrop={(event) => this.props.drop_handler(event, "about")}
            onDragOver={(event) => this.props.dragover_handler(event)}
          />
        </ul>
      );
    };

    return (
      <div className="share-list-detail-container">
        {/* {console.log(this.props.details)}
        {console.log(currentListItemIndex)} */}
        <div className="detail-card" ref="detailCard">
          <div
            className="detail-box"
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
            onDrop={(event) => this.props.drop_handler(event, "newSection")}
            onDragOver={(event) => this.props.dragover_handler(event)}
          >
            <div className="detail-header">
              <div className="header-top">
                <span className="list-item-header-logo">
                  <img src={"/assets/lists.png"} alt="list item logo" />
                </span>
                <p
                  style={{
                    lineHeight: "1.05",
                    fontSize: "1.1em",
                    color: "#808081",
                    fontWeight: "500",
                    border: "none",
                    fontFamily:
                      "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
                  }}
                >
                  {itemName}
                </p>
              </div>
              <textarea
                className="description-input"
                placeholder={`What is ${itemName}?`}
                value={description}
                onChange={(e) =>
                  this.props.handleChangeOnDetailComponent(e, "description")
                }
                rows="3"
              />
            </div>
            <div
              className="detail-body"
              style={{
                height: "100%",
                alignContent: "space-between",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
              <div className="about-container">
                <h5>About</h5>
                {renderAbout()}
              </div>
              <div className="section-container">
                {this.props.details[currentListItemIndex].aid === this.props.aid
                  ? renderSections(true)
                  : renderSections(false)}
                <div className="section-input-container">
                  <input
                    type="text"
                    placeholder="Add Context With Links"
                    onKeyPress={this.props.handleSectionInput}
                    id="sectionInput"
                    className="section-input"
                  />
                </div>
              </div>
              </div>
              <div
                className="detail-footer sticky"
                style={{ marginTop: "0px", paddingBottom: "0px" }}
              >
                <div className="stick-footer">
                  {!this.state.displayAboutSection ? (
                    <button className="btn" onClick={handleDisplayAbout}>
                      About
                    </button>
                  ) : null}
                  <button
                    className="btn btn-primary"
                    onClick={this.props.handleAddSource}
                  >
                    Add Source
                  </button>
                  {/* <button className="btn btn-primary">Links</button> */}
                  {/* <button className="btn btn-primary">Perspective</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListDetails.propTypes = {
  details: PropTypes.array,
  currentListItemIndex: PropTypes.number,
  updateDetails: PropTypes.func,
  handleshowEditDetails: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListDetails);
