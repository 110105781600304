import React from "react";
import PropTypes from "prop-types";

class EmbeddedPlayer extends React.Component {
    state = {
        mediaType: ''
    };

    componentDidMount = () => {
        this.checkMediaType(this.props.link);
    }

    checkMediaType = (url) => {
        if (url.includes("https://open.spotify.com/track/")) {
            this.setState({
                mediaType: 'audio'
            })
        } else if (url.includes("https://www.youtube.com/watch?v=") || url.includes("https://youtu.be/")) {
            this.setState({
                mediaType: 'video'
            })
        }
    }

    /**Get the id from a YouTube video link */
    getYouTubeVideoId = (url) => {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
            return match[2];
        } else {
            return 'error'
        }
    };

    /**Get the id from a Spotify song link */
    getSpotifySongId(url) {
        var spRegEx = /https?:\/\/(?:embed\.|open\.)(?:spotify\.com\/)(?:track\/|\?uri=spotify:track:)((\w|-){22})/;

        const getIDfromRegEx = (src, regExpy) => {
            return (src.match(regExpy)) ? RegExp.$1 : null;
        }
        return getIDfromRegEx(url, spRegEx);

    }

    render() {
        return (
            <div className="embedded-player mb-2" style={playerStyle}>
                {this.state.mediaType === 'video' &&
                    (
                        <iframe
                            id="ytPlayer"
                            type="text/html"
                            width="360px"
                            height=" 200px"
                            style={{ borderRadius: '5px' }}
                            src={`https://www.youtube.com/embed/${this.getYouTubeVideoId(this.props.link)}?autoplay=0&origin=http://app.listiee.com`}
                            frameborder="0"
                            allowfullscreen="true" />
                    )}

                {this.state.mediaType === 'audio' &&
                    (
                        <iframe
                            width="360"
                            height="80"
                            style={{ borderRadius: '5px' }}
                            allowtransparency="true"
                            frameborder="0"
                            allow="encrypted-media"
                            src={`https://open.spotify.com/embed/track/${this.getSpotifySongId(this.props.link)}?si=${this.getSpotifySongId(this.props.link)}`} />
                    )}

            </div>
        )
    }
};

EmbeddedPlayer.propTypes = {
    link: PropTypes.string
}

const playerStyle = {
    marginTop: "5px",
    borderLeft: " 3px solid #9c9a9a",
    paddingLeft: "10px",
    marginLeft: "5px"
}

export default EmbeddedPlayer;