import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';

const selectOptions=[
  { value: 'links', label: 'links' },
  { value: 'subjects', label: 'subjects' },
  { value: 'tools', label: 'tools' },
  { value: 'utilities', label: 'utilities' },
  { value: 'businesses', label: 'businesses' },
  { value: 'products', label: 'products' },
  { value: 'words', label: 'words' },
  { value: 'community', label: 'community' },
  { value: 'names', label: 'names' },
  { value: 'techniques', label: 'techniques' },
  { value: "I don't know", label: "I don't know" },
]
export default class CreatableSingle extends Component {
  handleChange = (newValue, actionMeta) => {

    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);

  };
  handleInputChange = (inputValue, actionMeta) => {

    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);

  };
  render() {
    return (
      <CreatableSelect
        isClearable
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        options={selectOptions}
      />
    );
  }
}