import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";

export default class PasteModal extends Component {
  state = {
    isPasteModalOpen:false,
  }

  togglePasteModal = () => {
    this.setState({
      isPasteModalOpen: !this.state.isPasteModalOpen
    });
  };

  onHandleSubmit = (e) => {
    this.props.onPasteSubmit(e);
    this.togglePasteModal();
  }

  render() {
    const { isPasteModalOpen } = this.state;

    return (
      <div>
        <button onClick={this.togglePasteModal} className="fun-icon btn btn-paste"><i className="fa fa-paste"/> 
                  <span className="btn-text">Paste</span>
        </button>
        <Modal isOpen={isPasteModalOpen} toggle={this.togglePasteModal}>
          <ModalHeader toggle={this.togglePasteModal}>
            Paste
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.onHandleSubmit} className={'base-form'}>
              <textarea className={'full-width'} name="paste" id="paste" cols="30" rows="12"
              placeholder={'List of fundamental human health types. \nPhysical Health. \nMental Health \nEmotional Health. \nSpiritual Health. \n\n -- Sample list style for submission, please follow the format. --'}
              style={{fontsize: "smaller !important"}}/>
              <button type="submit"  className={'btn btn-primary full-width'}>Done</button>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}