import {
  FETCH_LIST_BY_ID_API,
  GENERATE_SHORT_URL_CODE_API,
  FETCH_METADATA_API,
} from "../../constants/apiConstants";

import axios from "axios";
import Swal from "sweetalert2";

// Validate the string url for a valid URL
export const is_url = (url) => {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(url);
};

//This function add http:// at the begioning if the url doesn't start with it
export const getValidURL = (url) => {
  const pattern = /^((http|https|ftp):\/\/)/;

  if (!pattern.test(url)) {
    url = "http://" + url;
  }

  return url;
};

export const getIconFromLink = (url) => {
  try {
    url = getValidURL(url);
    const urlObj = new URL(url);
    return `${urlObj.origin}/favicon.ico`;
  } catch (err) {
      // returning the error
      console.log("Error getting favicon", err);
      return err;
  }
};

export const getNameFromLink = (val) => {
  let url = val;
  try {
    url = url.toLowerCase(); //It will change the url, but we care only about the name, so no problem !!
    url = getValidURL(url);
    const urlObj = new URL(url);
    let name = urlObj.hostname.split(".");
    return name.length > 2 ? name[1] : name[0];
  } catch (err) {
    console.log("Error getting favicon", err);
    return val;
  }
};

export const extractListAndItemIdFromListURL = (url) => {
  try {
    // exaple url='https://staging.listiee.com/list/5e6f7aaf488b0d4ac3c64852/list-of-Ultra-test/5e7759048cb5ad0017e7635e'
    const urlObj = new URL(url);
    const pathArr = urlObj.pathname.split("/"); //pathArr= ["", "list", "5e6f7aaf488b0d4ac3c64852", "list-of-Ultra-test", "5e7759048cb5ad0017e7635e"]

    return {
      listId: pathArr[2] || "",
      itemId: pathArr[4] || "",
      nameSlug: pathArr[3] || "",
    };
  } catch (e) {
    console.log("Invalid url", e);
  }
};

export const fetchListById = async (lsid, nameSlug = "", aid, token, email) => {
  const reqOptions = {
    method: "POST",
    url: FETCH_LIST_BY_ID_API,
    data: JSON.stringify({ lsid, aid, email, nameSlug }),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  let resData = "";
  await axios(reqOptions)
    .then((res) => {
      console.log("res fetch_id: ", res.data);
      resData = res.data.data.result && res.data.data.result[0];
    })
    .catch((error) => {
      console.log("Could Not fetch list");
      return;
    });

  return resData;
};

export const slugify = (text) => {
  return text
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/-+/g, "-");
};

export const confirmDeletion = async () => {
  const { value: cta } = await Swal.fire({
    title: "Are you sure?",
    // text: "This action can't be undone",
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Yes, continue!",
  });

  return cta;
};

export const getShortenedUrlCode = async (url, source) => {
  const reqOptions = {
    method: "POST",
    url: GENERATE_SHORT_URL_CODE_API,
    data: JSON.stringify({ url, src: source }),
    headers: {
      "Content-Type": "application/json",
    },
  };
  let resData = "";
  await axios(reqOptions)
    .then((res) => {
      console.log("GET SHORT URL CODE", res.data.data.code);
      resData = res.data.data.code;
    })
    .catch((error) => {
      console.log("ERR IN FETCHING SHORT URL CODE");
    });

  return resData;
};

export const handleCheckTitle = (listName) => {
  if (!listName.toLowerCase().startsWith("list of"))
    listName = "list of " + listName;

  return listName;
};

export const getItemsAndDetailsArrayFromDataArray = (
  data,
  checkDuplicateInItems,
  aid
) => {
  let newItems = [];
  let newDetails = [];
  // Create a set from data object to remove duplicates
  data = [...new Set(data)];

  console.log("List DATA:", data);

  data.forEach((item) => {
    //regex to search for list no/letter (numbered list item) in begining:  /^\d+\.|^[a-z]{1}\.|^[A-Z]{1}\./g
    item = item.replace(/^\d+\.|^[a-z]{1}\.|^[A-Z]{1}\./g, "");

    if(item.length === 0) return;
    
    let isURL = false;

    if (is_url(item)) {
      isURL = true;
    }

    let NewName = isURL ? getNameFromLink(item) : item;

    // If duplicate not present in the list, append these items, else skip it with a console.log
    if (!checkDuplicateInItems(NewName)) {
      // Only add unique items

      let detail = {
        itemName: NewName,
        aid: aid,
        description: "",
        highlights: [],
        about: isURL
          ? [
              {
                link: getValidURL(item),
                icon: getIconFromLink(item),
              },
            ]
          : [],
        sections: [],
        links: "",
        upv: 0,
      };

      newItems.push({ name: NewName, aid: aid });
      newDetails.push(detail);
    } else {
      console.log(
        NewName + " is already present in list, skip adding it in the list !"
      );
    }
  });

  console.log("newItems:", newItems);
  console.log("newDetails:", newDetails);

  return {
    newItems,
    newDetails,
  };
};
