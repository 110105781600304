import React, { Component, Fragment } from "react";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import ListItems from "./ListItems";
import ListDetails from "./ListDetails";
import PropTypes from "prop-types";
import { Prompt, Link } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import { browserHistory } from "react-router";
import {
  getEditPath,
  LOGIN_PATH,
  SHARE_PATH,
} from "../../constants/pathConstants";
import ImportBookmarkModal from "./ImportBookmarkModal";
// import ImportFromPocketModal from "./ImportFromPocketModal";

import ReactTooltip from "react-tooltip";

import ShareAskLinkBtn from "../ShareAskLinkBtn";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ToggleBtn from "./ToggleBtn";
import Badge from "@material-ui/core/Badge";

import { getListPath } from "../../constants/pathConstants";
import CSVBtnComponent from "./CSVBtnComponent";
import PasteBtnComponent from "./PasteBtnComponent";
import CollabBtnComponent from "./collabBtnComponent";
import { whichBrowser } from "../../utils/utils";
import ErrorAlert from "./ErrorAlert";
import { handleInputFocusTransfer } from "../../utils/utils";
// import Checkbox from "@material-ui/core/Checkbox";
import CollabModal from "./collabModal";

import {
  is_url,
  extractListAndItemIdFromListURL,
  fetchListById,
  getIconFromLink,
  getValidURL,
  confirmDeletion,
  handleCheckTitle,
} from "../Utils/helperFunctions";

import AddLinkModal from "./AddLinkModal";

import EditCategory from "./EditCategory";
import EditPrimary from "./EditPrimary";
import EditTags from "./EditTags";
import AccessControl from "./AccessControl";
import { Button } from "@material-ui/core";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import { getRegionAsync, reverseGeocode } from "../../utils/utils";
import ShareSettings from "../ShareSettings/ShareSettings";
import { FETCH_RELATED_LIST_API } from "../../constants/apiConstants";

//TODO: replace Switch from react -switch to material - ui
// import Switch from '@material-ui/core/Switch';




class ListEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalList: {},

      listName: "",
      description: "",
      tags: [],
      primary: "",
      category: "",
      otherPrimary: "",
      bookmarksBeingDropped: false,
      items: [],
      details: [],

      mode: "",
      draft: false,
      views: 0,
      lsStatus: "active",

      showEditDetails: false,
      displayOkbtn: true,
      saveBtnLoading: false,
      draftBtnLoading: false,
      promptOnExit: true,

      currentListItemIndex: 0,
      publishing: false,

      publicBtnVal: true,
      conciousBtnVal: false,
      liveBtnVal: false,
      mediaBtnVal: false,
      closedBtnVal: false,

      modal: false,
      modalLink: "",
      sectionModal: false,
      currentSectionTitle: "",
      currentSectionIndex: null,

      showAlert: false,
      alertMessage: "",
      alertCode: null,
      isCollabModalOpen: false,
      collaborators: [],
      collabs: null,

      proofRequired: false,
      sourceRequired: false,

      listStyle: "numbers",
      sensitive: false,
      covid: false,
      isSensitiveLocked: false,
      linkFromPlatform: false,
      displayAboutSection: false,
      listAid: null,
      showDraftBox: false,

      droppedBookmarks: [],
      anchorEl: null,
      relevance: "",
      checkedLocal: false,
      city: this.props.city,
      state: this.props.state,
      country: this.props.country,
      relatedLoading: false,
      listTitle: "",
      relatedLoading: false,
      relatedLists: [],
    };
  }

  componentDidMount = async () => {
    if (!this.props.country || !this.props.city || !this.props.state) {
      const loc = (await getRegionAsync()) || null;
      // Set everything as global to pass validation at backend.
      if (!loc)
        this.setState({
          relevance: "global",
          city: "global",
          state: "global",
          country: "global",
        });
      else
        this.setState({
          city: loc.city,
          state: loc.region,
          country: loc.country,
        });
    }
    this.setState((prevState, props) => {
      const totalList = props.totalList ? props.totalList : {};
      const listName = props.totalList.listName ? props.totalList.listName : "";
      const description = props.totalList.description
        ? props.totalList.description
        : "";
      const collaborators = props.collaborators ? collaborators : [];
      const listStyle = props.totalList.listStyle
        ? props.totalList.listStyle
        : "numbers";
      const sensitive = props.totalList.sensitive
        ? props.totalList.sensitive
        : false;
      const tags = props.totalList.tags ? props.totalList.tags : [];
      let primary;
      let otherPrimary;
      if (props.totalList.primary) {
        const primaries = [
          "subjects",
          "objects",
          "locations",
          "utilities",
          "organization names",
          "products",
          "words",
          "communities",
          "people",
          "skills",
          "techniques / tactics",
          "events",
          "tools",
          "methods",
          "process",
          "set of rules",
          "jobs",
          "services",
          "businesses",
          "space",
          "courses",
          "inventions",
          "movies",
        ];
        let primaryFound = primaries.filter(
          (pri) => props.totalList.primary === pri
        );
        if (primaryFound.length > 0) {
          primary = props.totalList.primary;
        } else {
          primary = "other";
          otherPrimary = props.totalList.primary;
        }
      }

      const category = props.totalList.category ? props.totalList.category : "";
      const mode = props.mode ? props.mode : "share";
      const showEditDetails = props.mode === "share" ? false : true;

      const items = props.totalList.listItems
        ? props.totalList.listItems.map((item) => {
            console.log(item);
            let obj = { name: item.itemName, aid: item.aid };
            return obj;
          })
        : [];
      const details = props.totalList.listItems
        ? props.totalList.listItems
        : [];
      const {
        isPublic: publicBtnVal = true,
        isConcious: conciousBtnVal = false,
        isMedia: mediaBtnVal = false,
        isClosed: closedBtnVal = false,
        draft = false,
        views = 0,
        lsStatus = "active",
      } = props.totalList;

      let isSensitiveLocked = false;
      if (
        props.mode === "edit" &&
        (props.totalList.listAid !== this.props.aid ||
          (props.collaborators && props.collaborators.length !== 0))
      ) {
        isSensitiveLocked = true;
      }

      const listAid = totalList.listAid;
      const isSwitchDisabled = this.isControlDisable(listAid);

      return {
        totalList,
        listName,
        description,
        collaborators,
        listStyle,
        tags,
        primary,
        otherPrimary,
        category,
        mode,
        publicBtnVal,
        conciousBtnVal,
        mediaBtnVal,
        closedBtnVal,
        draft,
        showEditDetails,
        items,
        details,
        views,
        lsStatus,
        sensitive,
        isSensitiveLocked,
        listAid,
        isSwitchDisabled,
      };
    });

    /*** Populating data added by new user when they were not signed in..after authentication*/

    let cachedData = sessionStorage.getItem("listiee_last_share_data");

    if (this.props.token && this.props.mode !== "edit" && cachedData !== null) {
      console.log("Cached data found...");

      console.log("cachedData\n", cachedData);
      const newState = await this.processCachedData(cachedData);
      this.setState({ ...newState });
      sessionStorage.removeItem("listiee_last_share_data");
      return;
    }
  };

  componentDidUpdate() {
    if (
      this.state.category === "Media (Audio/Video)" &&
      !this.state.mediaBtnVal
    ) {
      this.handleSwitchChange(true, "mediaBtnVal");
    }
    this.adjustRelatedList()
  }

  // Method to set relevance on change and use geolocation api to access accurate
  // location stats if relevance is set to local.
  setRelevance = (e) => {
    // Geolocation callbacks.
    const onGeolocationSuccess = async (position) => {
      const { longitude, latitude } = position.coords;
      // Reverse geocoding api to convert latitude and longitude to human
      // readable address.
      const data = await reverseGeocode(latitude, longitude);
      if (!data) {
        console.error("Some error in fetching location");
        return;
      }
      const { county, state, country } = data;
      if (
        window.confirm(
          `Updated location: ${county}, ${state}, ${country}. Press 'Cancel' to fallback to the previous location.`
        )
      ) {
        this.setState({
          checkedLocal: true,
          city: county,
          state: state,
          country: country,
        });
      }
    };
    const onGeolocationError = (errMessage) => () => {
      console.error(errMessage);
    };

    // if checkedLocal is true, that means geolocation api has already
    // accessed user location and added it to state. So no need to run it
    // again, simply set relevance.
    this.setState({ relevance: e.target.value });
    if (e.target.value === "local" && !this.state.checkedLocal) {
      if (
        window.confirm(
          `Your current location from your IP address: ${this.state.city}, ${this.state.state}, ${this.state.country}. Please allow the Browser to access your location for a more accurate estimate.`
        )
      ) {
        const geolocation = window.navigator.geolocation;
        geolocation
          ? geolocation.getCurrentPosition(
              onGeolocationSuccess,
              onGeolocationError("Geolocation Error !!"),
              { timeout: 10000 }
            )
          : onGeolocationError(
              "This Browser does not support Geolocation !!"
            )();
      }
    }
  };

  processCachedData = async (data) => {
    data = await JSON.parse(data);
    const { aid } = this.props;
    data.items = data.items.map((item) => ({ ...item, aid }));
    data.details = data.details.map((detail) => ({ ...detail, aid }));
    return data;
  };
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  //Snackbar auto-hide method
  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false,
    });
  };

  showSnackBar = (message, code) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
      alertCode: code,
    });
  };

  // call this function to show a coming feature alert.
  featureComingAlert = () => {
    this.setState({
      showAlert: true,
      alertMessage: "Feature coming soon!",
      alertCode: "info",
    });
  };

  toggleDraftBox = () => {
    this.setState({
      showDraftBox: !this.state.showDraftBox,
    });
  };

  checkDuplicateInItems = (item) => {
    let { items } = this.state;
    const duplicate = (elem) => elem.name === item;
    // Returns true if at least one item already present in the list
    return items.some(duplicate);
  };

  checkDuplicateSections = (sectionTitle) => {
    const { details, currentListItemIndex } = this.state;
    return details[currentListItemIndex].sections.some(
      (section) => section.title === sectionTitle
    );
  };

  handleKeyDown = (e) => {
    e = e || window.event;
    handleInputFocusTransfer(e, "custom-focus-management");
  };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  toggleCollabModalBtn = (event) => {
    event.stopPropagation();
    this.setState({
      isCollabModalOpen: !this.state.isCollabModalOpen,
    });
    console.log(this.state.isCollabModalOpen);
  };

  toggleCollabModal = (event) => {
    this.setState({
      isCollabModalOpen: !this.state.isCollabModalOpen,
    });
    console.log(this.state.isCollabModalOpen);
  };

  onCollabSubmit = async (collab) => {
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.state.collaborators.length < 5) {
      if (collab && emailRegex.test(collab)) {
        if (this.props.mode === "edit") {
          let data = {
            aid: this.props.aid,
            collabEmail: collab,
            lsid: this.props.fetchedList.result[0]._id,
          };
          await this.props.addCollaborator(data, this.props.token);
          if (this.props.collab && this.props.collab.collabEmail === collab) {
            this.showSnackBar(`Added contributor`, "success");
          } else {
            this.showSnackBar(
              "Contributor already present or could not be added",
              "error"
            );
          }
        } else {
          this.storeCollabForPublishList(collab);
          this.showSnackBar("Collaborator added", "success");
        }
      } else {
        this.showSnackBar(
          "Please select a user or enter invite email",
          "error"
        );
      }
    } else {
      this.showSnackBar("You can add upto 5 collaborators per list ", "error");
    }
  };

  handleModalChange = (e, itemName) => {
    const value = e.target.value;
    if (value.includes("listiee.com/list/")) {
      this.setState({
        linkFromPlatform: true,
        [itemName]: value,
      });
    } else {
      this.setState({
        linkFromPlatform: false,
        [itemName]: value,
      });
    }
  };

  handleCheckTitle = (e) => {
    e.target.placeholder = "I am list title.";
    let listName = this.state.listName;
    if (!listName.toLowerCase().startsWith("list of") && listName.length > 0) {
      listName = handleCheckTitle(listName);
      this.setState({
        listName,
      });
      }



  };

  onDescriptionChange = (e) => {
    this.setState({
      description: e.target.value.slice(0, 168),
    });
  };

  onHandleChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    // if(e.target.name == "listName")
    await this.setState({
      listTitle: e.target.value,
    });

      this.checkForRelatedList(e);
  }

  onHandleCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleDelete = (tagName) => {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag) => tag !== tagName),
    });
  };

  handleTagInput = async (e) => {
    // e.keyCode was not working on chrome so I wrote this custom code...

    // const x = e.which || e.keyCode;

    // console.log("Key=",x)
    let str = e.target.value.trim();
    let currentTags = this.state.tags;

    console.log(this.state.tags);
    let enterKeyPressed = e.key === "Enter";
    // add tag to state when user press comma key
    if (
      (str !== "," && str.substr(str.length - 1) === ",") ||
      (enterKeyPressed && str.length !== 0)
    ) {
      if (!enterKeyPressed) {
        str = str.slice(0, -1); //remove the comma character from the string
      }
      str = str.replace(/\s+/g, "-");
      if (str[0] == "#") {
        str = str.slice(1);
      }
      if (currentTags) {



        if (!currentTags.includes(str)) {
          // checking if the tags exceed 20
          if (this.state.tags.length+1 > 20) {
            this.showSnackBar("Maximum 20 tags are allowed!", "warning");
          }
          else {
          this.setState({ tags: [...currentTags, str] });
          }

        }

        else this.showSnackBar("Please enter unique tags...", "warning");
      } else {
        // checking if the tags exceed 20
          if (this.state.tags.length+1 > 20) {
            this.showSnackBar("Maximum 20 tags are allowed!", "warning");
          }
          else {
          this.setState({ tags: [...currentTags, str] });
          }
        console.log(this.state.tags.length);
      }

        // make changes here
      e.target.value = "";
    }
  };

  handleshowEditDetails = (val) => {
    this.setState({ showEditDetails: val });
  };

  changeCurrentListItemIndex = (index) => {
    if (index >= 0) this.setState({ currentListItemIndex: index });
    else this.setState({ currentListItemIndex: 0 });
  };

  handleSwitchChange = (value, checked) => {
    this.setState({ [checked]: value });
    // if (checked === "sensitive") {
    //   let details = this.state.details;
    //   let new_section = {
    //     title: "Source",
    //     items: [],
    //   };
    //   details.forEach((el, i) => {
    //     if (el.sections.length === 0) {
    //       el.sections.push(new_section);
    //     }
    //   });
    //   this.setState({ details });
    // }
  };

  updateItems = (items) => {
    this.setState((prevState, props) => {
      //if item.aid === this.state.listAid or this.props.mode === share the approve to true
      return { items: [...items] };
    });
  };

  updateDetails = (detail) => {
    // console.log(this.state.details)
    this.setState((prevState, props) => {
      return { details: [...detail] };
    });
  };

  handleDeleting = (index) => {
    let { items, details } = this.state;
    items.splice(index, 1);
    details.splice(index, 1);
    this.setState((prevState, props) => {
      return { items, details };
    });

    this.changeCurrentListItemIndex(index - 1);
  };

  handleListEdit = (e, index) => {
    let updatedItemsList = [...this.state.items];
    let updatedDetails = [...this.state.details];
    let newName = e.target.value;
    updatedItemsList[index].name = newName;
    updatedDetails[index].itemName = newName;
    this.setState({ items: updatedItemsList, details: updatedDetails });
  };

  handleFocusNext = (e) => {
    if (e.key === "Enter" && e.target.value.length > 0) {
      e.preventDefault();
      document.querySelector("#description").focus();
    }
  };

  handleShowDetailPane = (e) => {
    if (this.state.listName.trim().length === 0) {
      this.showSnackBar("Please add a title.", "error");
      return;
    }

    this.setState({ showEditDetails: !this.state.showEditDetails });
  };

  /*********** Functions for the detail component*************/
  handleHighlightsChange = (e, index) => {
    let { details, currentListItemIndex } = this.state;
    details[currentListItemIndex].highlights[index] = e.target.value;
    this.setState({ details });
  };

  handleDeleteHighlight = (e, index) => {
    let { details, currentListItemIndex } = this.state;
    details[currentListItemIndex].highlights.splice(index, 1);
    this.setState({ details });
  };

  handleHighlightsAdd = (e) => {
    let { details, currentListItemIndex } = this.state;
    const value = e.target.value.trim();
    if (e.key === "Enter" && value.length > 0) {
      details[currentListItemIndex].highlights.push(value);
      this.setState({ details });
      e.target.value = "";
    }
  };

  addHighlight = () => {
    const inputBox = document.querySelector("#");
    const value = inputBox.value.trim();
    if (value.length > 0) {
      let { details, currentListItemIndex } = this.state;
      details[currentListItemIndex].highlights.push(value);
      this.setState({ details });
      inputBox.value = "";
    }
  };

  handleChangeOnDetailComponent = (e, targetName) => {
    let { details, currentListItemIndex } = this.state;
    details[currentListItemIndex][targetName] = e.target.value;
    this.setState({ details });
  };

  handleRemoveLink = (section, sectionIndex, deleteIndex) => {
    let { details, currentListItemIndex } = this.state;
    if (section === "aboutSection") {
      details[currentListItemIndex].about.splice(deleteIndex, 1);
      this.setState({ details });
    } else {
      details[currentListItemIndex].sections[sectionIndex].items.splice(
        deleteIndex,
        1
      );
      this.setState({ details });
    }
  };
  // TODO: remove this after testing
  // handleAboutLinkInput = link => {
  //   let icon = getIconFromLink(link);

  //   if (is_url(link)) {
  //     let { details, currentListItemIndex } = this.state;
  //     details[currentListItemIndex].about.push({ icon, link });
  //     this.setState({
  //       details,
  //       modalLink: ""
  //     });

  handleAboutLinkInput = (link) => {
    let icon = this.getIconFromLink(link);

    if (is_url(link)) {
      let { details, currentListItemIndex } = this.state;
      details[currentListItemIndex].about.push({ icon, link });
      this.setState({
        details,
        modalLink: "",
      });

      this.toggle();
    } else {
      alert("Please enter a valid URl !");
    }
  };

  /* getIconFromLink = (link) => {
       link = link.startsWith("http") ? link : `http://${link}`;
       const linkURL = link.split("/");

       let domainURL = linkURL[2];

       let icon = `http://${domainURL}/favicon.ico`;
       return icon;
     * }; */

  sectionModleToggle = () => {
    this.setState((prevState) => ({
      sectionModal: !prevState.sectionModal,
    }));
  };

  handleSectionInput = (e) => {
    const value = e.target.value.trim();

    if (e.key === "Enter" && value.length > 0) {
      const isAbout = value.toLowerCase() == "about";
      if (isAbout) {
        if (this.state.displayAboutSection) {
          alert("About Section already present !!");
          e.target.value = "";
          return;
        } else {
          //Add about section
          this.setState({ displayAboutSection: true });
          e.target.value = "";
          return;
        }
      }

      if (this.checkDuplicateSections(value)) {
        // If duplicate section present, ask user if add it again, if yes add it else skip
        if (
          window.confirm(`Section "${value}" already present.. add it anyway ?`)
        ) {
          //add the section
          this.addNewSection(value);
          e.target.value = "";
        }
      } else {
        // If no duplicate section present, add it-
        this.addNewSection(value);
        e.target.value = "";
      }
    }
  };

  addNewSection = (sectionTitle) => {
    let { details, currentListItemIndex } = this.state;
    details[currentListItemIndex].sections.push({
      title: sectionTitle,
      items: [],
    });
    this.setState({ details, displaySectionInput: false });
  };

  fetchItemsFromList = async (url) => {
    const { aid, email, token } = this.props;
    const listIds = extractListAndItemIdFromListURL(url);
    const list = await fetchListById(
      listIds.listId,
      listIds.nameSlug,
      aid,
      token,
      email
    );
    let linkObjArr = [];
    console.log("Final list-", list);

    const listNameSlug = list.slug;
    const linkIcon = getIconFromLink(url);
    if (list && list.item && list.item.length) {
      linkObjArr = list.item.map((lsItem) => {
        const { lsid, _id, list_name } = lsItem;
        return {
          link: window.origin + getListPath(listNameSlug, lsid, _id),
          listId: lsid,
          itemId: _id,
          icon: linkIcon,
          type: "listItem",
          text: list_name,
        };
      });
    }
    return linkObjArr;
  };

  handleSectionModelOk = async (
    link,
    sectionName,
    fetchAndLinkItems,
    applytoAll
  ) => {
    console.log("2 time");
    link = getValidURL(link);
    if (this.state.showEditDetails === true) {
      if (sectionName == "About") {
        let icon = getIconFromLink(link);
        if (is_url(link)) {
          let { details, currentListItemIndex } = this.state;

          if (fetchAndLinkItems) {
            // Fetching and linking items
            const arrayOfLinkObjects = await this.fetchItemsFromList(link);
            details[currentListItemIndex].about = [
              ...details[currentListItemIndex].about,
              ...arrayOfLinkObjects,
            ];
          } else {
            details[currentListItemIndex].about.push({
              icon,
              link,
            });
          }

          this.setState({
            details,
            modalLink: "",
          });

          this.toggle();
          this.setState({ linkFromPlatform: false });
        } else {
          alert("Please enter a valid URl !");
        }
      } else {
        const { currentSectionIndex, modalLink } = this.state;
        let { details, currentListItemIndex } = this.state;
        let link = modalLink;

        if (!is_url(link)) {
          alert("Please enter a valid URL");
          return;
        }

        let icon = getIconFromLink(link);

        if (fetchAndLinkItems) {
          // TODO: complete this fuctionality
          const arrayOfLinkObjects = await this.fetchItemsFromList(link);
          details[currentListItemIndex].sections[currentSectionIndex].items = [
            ...details[currentListItemIndex].sections[currentSectionIndex]
              .items,
            ...arrayOfLinkObjects,
          ];
        } else {
          details[currentListItemIndex].sections[
            currentSectionIndex
          ].items.push({
            link,
            icon,
          });
        }
        this.setState({
          details,
          sectionModal: false,
          modalLink: "",
          currentSectionIndex: null,
          linkFromPlatform: false,
        });
      }
    } else {
      console.log("What now", applytoAll);
      const { currentSectionIndex, modalLink } = this.state;
      let { details, currentListItemIndex } = this.state;
      let link = modalLink;

      this.setState({
        currentSectionIndex: 0,
        currentSectionTitle: "Source",
      });

      if (!is_url(link)) {
        alert("Please enter a valid URL");
        return;
      }

      let icon = getIconFromLink(link);
      if (applytoAll === false) {
        if (fetchAndLinkItems) {
          // TODO: complete this fuctionality
          const arrayOfLinkObjects = await this.fetchItemsFromList(link);
          details[currentListItemIndex].sections[currentSectionIndex].items = [
            ...details[currentListItemIndex].sections[currentSectionIndex]
              .items,
            ...arrayOfLinkObjects,
          ];
        } else {
          console.log("INDEX", currentListItemIndex);
          details[currentListItemIndex].sections[0].items.push({
            link,
            icon,
          });
        }
      } else {
        let new_section = {
          title: "Source",
          items: [{ link, icon }],
        };
        console.log("DO THIS STUFF");
        if (fetchAndLinkItems) {
          //code to link lists of listee site
        } else {
          details.forEach((el, i) => {
            if (el.sections.length === 0) {
              el.sections.push(new_section);
            } else {
              el.sections[0].items.push({
                link,
                icon,
              });
            }
          });
        }
      }
      this.setState({
        details,
        sectionModal: false,
        modalLink: "",
        currentSectionIndex: 0,
        currentListItemIndex: currentListItemIndex,
        linkFromPlatform: false,
      });
    }
  };
  handleAddProofLink = (currentListItemIndex) => {
    this.setState({
      currentListItemIndex,
    });
  };
  handleAddProofSource = () => {
    this.setState({
      currentSectionTitle: "Source",
      sectionModal: true,
    });
    let { details, currentListItemIndex } = this.state;
    let sections = details[currentListItemIndex].sections;
    let alreadyPresent = false;
    sections.map((item) => {
      if (item.title && item.title === "Source") {
        alreadyPresent = true;
        if (this.showEditDetails === true) {
          this.showSnackBar("Source section already present!!", "info");
        }
        return;
      }
    });
    if (!alreadyPresent) {
      let new_section = {
        title: "Source",
        items: [],
      };
      details[currentListItemIndex].sections.push(new_section);
      this.setState({ details });
    }
  };
  handleAddSource = () => {
    let { details, currentListItemIndex } = this.state;
    let sections = details[currentListItemIndex].sections;
    let alreadyPresent = false;
    sections.map((item) => {
      if (item.title && item.title === "Source") {
        alreadyPresent = true;
        if (this.showEditDetails === true) {
          this.showSnackBar("Source section already present!!", "info");
        }
        return;
      }
    });
    if (!alreadyPresent) {
      let new_section = {
        title: "Source",
        items: [],
      };
      details[currentListItemIndex].sections.push(new_section);
      this.setState({ details });
    }
  };

  handleAddLink = (e, currentSectionIndex, currentSectionTitle) => {
    this.setState({
      currentSectionIndex,
      currentSectionTitle,
      sectionModal: true,
    });
  };

  dragover_handler = (ev) => {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = "move";
  };
  drop_handler = (ev, section) => {
    ev.preventDefault();
    let data;
    // Get the links
    if ([...ev.dataTransfer.types].includes("application/uri-list")) {
      data = ev.dataTransfer.getData("application/uri-list");
    } else if ([...ev.dataTransfer.types].includes("application/folder")) {
      data = JSON.parse(ev.dataTransfer.getData("application/folder"));
    } else {
      data =
        ev.dataTransfer.getData("text") ||
        ev.dataTransfer.getData("text/plain");
    }
    this.handleDropLinks(data, section);
  };
  /*********** /Functions for the detail component*************/
  // Handle links or folders drop on list editor items.
  handlerDropOnLeft = (ev) => {
    // console.log("Event object-", ev);
    // console.log(`the browser shit` + ev.dataTransfer.getData("text") + `and its type ${typeof ev.dataTransfer.getData("text")}`);
    ev.preventDefault();
    // Get the links
    let data;
    // This type contains the bookmark name and bookmark url separated by a
    // CRLF line break.
    // NOTE: this format will only work inside current application as it is a
    // custom transfer data type.
    if ([...ev.dataTransfer.types].includes("application/uri-list")) {
      data = ev.dataTransfer.getData("application/uri-list");
      this.handleDropLinksOnLeft(data);
    }

    // Commented out browser specific code to reduce complexity.
    // Separate function for Firefox, since gecko engine supports custom data
    // transfer types for Drag and drop HTML API.
    // text/x-moz-url format provides the Link and the Title of link separated by
    // new line.
    // if (whichBrowser() === "Firefox") {
    //   data = ev.dataTransfer.getData("text/x-moz-url");
    //   this.handleDropLinksOnLeftFirefox(data);
    //   return;
    // }

    // If a folder is being dropped instead of a single list.
    else if ([...ev.dataTransfer.types].includes("application/folder")) {
      const bookmarkToList = JSON.parse(
        ev.dataTransfer.getData("application/folder")
      );
      // console.log(bookmarkToList);
      // console.log(bookmarkToList.bookmarks[0].link[0]);
      this.handleDropLinksOnLeft(bookmarkToList);
    } else {
      data =
        ev.dataTransfer.getData("text/x-moz-url") ||
        ev.dataTransfer.getData("text") ||
        ev.dataTransfer.getData("text/plain");
      this.handleDropLinksOnLeft(data);
    }
  };

  //handleDropLinksOnLeftFirefox = data => {
  //  // console.log("Dropped from FF bookmark-", data);
  //  let { items, details } = this.state;
  //  let linksArray = data.split("\n");
  //  let urlRegexp = /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/;

  //  // console.log("It is firefox", linksArray);

  //  if (linksArray.length === 0) {
  //    return;
  //  } else if (linksArray.length === 2) {
  //    if (linksArray[0].length === 0) return;
  //    else {
  //      //handle one item in array:
  //      let item = linksArray[0].trim();
  //      let NewName = linksArray[1].trim();

  //      if (urlRegexp.test(item) === true) {
  //        //handle if the item is a link

  //        if (this.checkDuplicateInItems(NewName)) {
  //          alert(NewName + " is already present in list !");
  //          return;
  //        }

  //        let detail = {
  //          itemName: NewName,
  //          description: "",
  //          highlights: [],
  //          about: [
  //            {
  //              link: item,
  //              icon: getIconFromLink(item),
  //            },
  //          ],
  //          sections: [],
  //          links: "",
  //          upv: 0,
  //          aid: this.props.aid,
  //        };
  //        items.push({ name: NewName, aid: this.props.aid });
  //        details.push(detail);
  //        this.setState({ items, details });
  //      } else {
  //        if (this.checkDuplicateInItems(item)) {
  //          alert(item + " is already present in list !");
  //          return;
  //        }

  //        items.push({ name: item, aid: this.props.aid });
  //        details.push({
  //          itemName: item,
  //          description: "",
  //          highlights: [],
  //          about: [],
  //          sections: [],
  //          links: "",
  //          upv: 0,
  //          aid: this.props.aid,
  //        });
  //        this.setState({ items, details });
  //      }
  //    }
  //  } else {
  //    //handle multiple items in array:
  //    if (linksArray.length % 2 !== 0) {
  //      console.log("Item count=", linksArray.length);
  //      return;
  //    }
  //    let item = "";
  //    let NewName = "";
  //    let detail = {};

  //    // detect if same folder is being dropped again-
  //    // TODO: Not a proper logic.. improve it...
  //    if (this.checkDuplicateInItems(linksArray[1])) {
  //      if (
  //        !window.confirm(
  //          "Seems like you are dropping same folder.. proceed it anyway ?"
  //        )
  //      ) {
  //        return;
  //      }
  //    }

  //    for (let i = 0, count = 1; i < linksArray.length; i = i + 2, count++) {
  //      item = linksArray[i].trim();
  //      NewName = linksArray[i + 1].trim();
  //      console.log("link=", linksArray[i]);
  //      console.log("name=", linksArray[i + 1]);

  //      if (this.checkDuplicateInItems(NewName)) {
  //        if (
  //          !window.confirm(
  //            NewName + " is already present in list.. add it anyway ?"
  //          )
  //        ) {
  //          continue;
  //        }
  //      }

  //      if (urlRegexp.test(item) === true) {
  //        //handle if the item is a link
  //        detail = {
  //          itemName: NewName,
  //          description: "",
  //          highlights: [],
  //          about: [
  //            {
  //              link: item,
  //              icon: getIconFromLink(item),
  //            },
  //          ],
  //          sections: [],
  //          links: "",
  //          upv: 0,
  //          aid: this.props.aid,
  //        };
  //        items.push({ name: NewName, aid: this.props.aid });
  //        details.push(detail);
  //      } else {
  //        // items.push(item);
  //        // details.push({
  //        //   itemName: item,
  //        //   description: "",
  //        //   highlights: [],
  //        //   about: [],
  //        //   sections: [],
  //        //   links: "",
  //        //   upv: 0
  //        // });
  //        // this.setState({ items, details });
  //        console.log(item);
  //        return;
  //      }
  //    } //loop ends

  //    this.setState({ items, details });
  //    return;
  //  }
  //};

  // Handle dropped links on the list editor items.
  handleDropLinksOnLeft = (data) => {
    const isSingle = typeof data === "string";
    let linksArray = isSingle ? data.split("\r\n") : data;
    let urlRegexp =
      /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/;
    // console.log(linksArray);
    if (linksArray.length === 0) {
      return;
    }
    // When links are directly dragged from browser.
    else if (isSingle && linksArray.length === 1) {
      if (linksArray[0].length === 0) return;
      else {
        //handle one item in array:
        let item = linksArray[0].trim();
        let NewName = item;
        let { items, details } = this.state;

        if (urlRegexp.test(item) === true) {
          // handle if the item is a link
          // let parser = new URL(item);
          // NewName = parser.hostname;
          if (this.checkDuplicateInItems(NewName)) {
            if (
              !window.confirm(
                NewName + " is already present in list.. add it anyway ?"
              )
            ) {
              return;
            }
          }

          let detail = {
            itemName: NewName,
            description: "",
            highlights: [],
            about: [
              {
                link: item,
                icon: getIconFromLink(item),
              },
            ],
            sections: [],
            links: "",
            upv: 0,
            aid: this.props.aid,
          };
          items.push({ name: NewName, aid: this.props.aid });
          details.push(detail);
          this.setState({ items, details });
        } else {
          if (this.checkDuplicateInItems(item)) {
            if (
              !window.confirm(
                item + " is already present in list.. add it anyway ?"
              )
            ) {
              return;
            }
          }

          items.push({ name: item, aid: this.props.aid });
          details.push({
            itemName: item,
            description: "",
            highlights: [],
            about: [],
            sections: [],
            links: "",
            upv: 0,
            aid: this.props.aid,
          });
          this.setState({ items, details });
        }
      }
    }
    // When lists are dragged from custom Bookmark menu.
    else if (isSingle && linksArray.length === 2) {
      if (linksArray[1].length === 0) return;
      else {
        //handle one item in array:
        let item = linksArray[1].trim();
        let NewName = linksArray[0].trim();
        let { items, details } = this.state;

        if (urlRegexp.test(item) === true) {
          // handle if the item is a link
          // let parser = new URL(item);
          // NewName = parser.hostname;
          if (this.checkDuplicateInItems(NewName)) {
            if (
              !window.confirm(
                NewName + " is already present in list.. add it anyway ?"
              )
            ) {
              return;
            }
          }

          let detail = {
            itemName: NewName,
            description: "",
            highlights: [],
            about: [
              {
                link: item,
                icon: getIconFromLink(item),
              },
            ],
            sections: [],
            links: "",
            upv: 0,
            aid: this.props.aid,
          };
          items.push({ name: NewName, aid: this.props.aid });
          details.push(detail);
          this.setState({ items, details });
        } else {
          if (this.checkDuplicateInItems(item)) {
            if (
              !window.confirm(
                item + " is already present in list.. add it anyway ?"
              )
            ) {
              return;
            }
          }

          items.push({ name: item, aid: this.props.aid });
          details.push({
            itemName: item,
            description: "",
            highlights: [],
            about: [],
            sections: [],
            links: "",
            upv: 0,
            aid: this.props.aid,
          });
          this.setState({ items, details });
        }
      }
    }
    // Folder being dropped on list item.
    else {
      //handle multiple items in array:
      const newList = {
        title: linksArray.folder,
        details: linksArray.bookmarks.map((i) => i.link[0]),
        items: linksArray.bookmarks.map((i) => i.name),
      };
      // const testList = {
      //   listName: newList.title,
      //   items: newList.items.map(i => ({
      //     aid: this.props.aid,
      //     name: i,
      //   })),
      //   details: newList.details.map((i, index) => ({
      //     about: [{
      //       icon: getIconFromLink(i),
      //       link: i,
      //     }],
      //     aid: this.props.aid,
      //     description: "",
      //     highlights: [],
      //     itemName:newList.items[index],
      //     link: "",
      //     sections: [],
      //     upv: 0,
      //   })),
      // };
      this.setState({
        listName: newList.title,
        items: newList.items.map((i) => ({
          aid: this.props.aid,
          name: i,
        })),
        details: newList.details.map((i, index) => ({
          about: [
            {
              icon: getIconFromLink(i),
              link: i,
            },
          ],
          aid: this.props.aid,
          description: "",
          highlights: [],
          itemName: newList.items[index],
          link: "",
          sections: [],
          upv: 0,
        })),
      });
      // console.log(testList);
    }
  };

  handleDropLinks = (data, section) => {
    const isSingle = typeof data === "string";
    let linksArray = isSingle ? data.split("\r\n") : data;
    let urlRegexp =
      /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/;
    if (linksArray.length === 0) {
      return;
    }
    // If links are being dragged from the browser directly, they will only
    // have url.
    else if (isSingle && linksArray.length === 1) {
      if (linksArray[0].length === 0) return;
      //Add the link
      let link = linksArray[0].trim();
      if (section === "about") {
        let { details, currentListItemIndex } = this.state;
        let aboutObject = {
          link: link,
          icon: getIconFromLink(link),
        };
        details[currentListItemIndex].about.push(aboutObject);
        this.setState({ details });
      } else if (typeof section === "number") {
        let { details, currentListItemIndex } = this.state;
        let sectionObject = {
          link: link,
          icon: getIconFromLink(link),
        };
        details[currentListItemIndex].sections[section].items.push(
          sectionObject
        );
        this.setState({ details });
      }
    }
    // if the link is dragged from custom bookmark menu, they will have a name
    // and link separated by crlf linebreak.
    else if (isSingle && linksArray.length === 2) {
      if (linksArray[1].length === 0) return;
      //Add the link
      let link = linksArray[1].trim();
      if (section === "about") {
        let { details, currentListItemIndex } = this.state;
        let aboutObject = {
          link: link,
          icon: getIconFromLink(link),
        };
        details[currentListItemIndex].about.push(aboutObject);
        this.setState({ details });
      } else if (typeof section === "number") {
        let { details, currentListItemIndex } = this.state;
        let sectionObject = {
          link: link,
          icon: getIconFromLink(link),
        };
        details[currentListItemIndex].sections[section].items.push(
          sectionObject
        );
        this.setState({ details });
      }
    }
    // When a whole bookmark folder is being dropped on the list details page.
    else {
      //make the section, extract the title and put all the links into the section.
      // console.log(linksArray);
      if (section === "newSection") {
        let { details, currentListItemIndex } = this.state;
        let newSectioArray = [];
        const newList = {
          title: linksArray.folder,
          links: linksArray.bookmarks.map((i) => i.link[0]),
        };
        let sectionObj = {
          title: newList.title,
          items: [],
        };
        newList.links.map((item) => {
          if (item.length > 0 && urlRegexp.test(item)) {
            sectionObj.items.push({
              link: item,
              icon: getIconFromLink(item),
            });
          }
        });

        newSectioArray.push(sectionObj);

        newSectioArray.map((item) => {
          console.log("sfsdf", item.title);
          if (this.checkDuplicateSections(item.title)) {
            if (
              window.confirm(
                `Section "${item.title}" already present.. add it anyway ?`
              )
            ) {
              details[currentListItemIndex].sections.push(item);
            }
          } else {
            details[currentListItemIndex].sections.push(item);
          }
        });

        this.setState({ details });
      }
    }
  };

  storeCollabForPublishList = (collab) => {
    let prevCollabs = this.state.collaborators;
    if (prevCollabs.indexOf(collab) === -1) {
      prevCollabs.push(collab);
      this.setState({
        collaborators: prevCollabs,
      });
    }
  };
  itemUpdated(previous, current) {
    console.log(
      current.itemName,
      _.isEqual(previous.about, current.about),
      _.isEqual(previous.links, current.links),
      previous.list_name === current.itemName,
      previous.description === current.description,
      _.isEqual(previous.sections, current.sections),
      _.isEqual(previous.highlights, current.highlights),
      _.isEqual(previous.about, current.about) &&
        _.isEqual(previous.links, current.links) &&
        previous.list_name === current.itemName &&
        previous.description === current.description &&
        _.isEqual(previous.sections, current.sections) &&
        _.isEqual(previous.highlights, current.highlights)
    );
    return !(
      _.isEqual(previous.about, current.about) &&
      _.isEqual(previous.links, current.links) &&
      previous.list_name === current.itemName &&
      previous.description === current.description &&
      _.isEqual(previous.sections, current.sections) &&
      _.isEqual(previous.highlights, current.highlights)
    );
  }
  getAllData = (draftValue = false) => {
    let { details } = this.state;
    let data = {};
    console.log(details);
    let totalListsWithDetails = details.map((item, i) => {
      let obj = {
        list_name: item.itemName,
        aid: item.aid,
        approved:
          this.props.listItems[i] && this.props.listItems[i].approved === true
            ? true
            : false,
        description: item.description,
        highlights: item.highlights,
        about: item.about,
        sections: item.sections,
        links: item.links,
        aid: item.aid,
        createdAt: !this.props.listItems[i]
          ? moment().utc().format()
          : this.props.listItems[i].createdAt,
        updatedAt:
          !this.props.listItems[i] ||
          this.itemUpdated(this.props.listItems[i], item)
            ? moment().utc().format()
            : this.props.listItems[i].updatedAt,
      };
      if (!!item["_id"]) {
        obj["_id"] = item["_id"];
      }
      return obj;
    });

    data["aid"] = this.props.aid;
    data["listStyle"] = this.state.listStyle;
    if (this.state.primary === "other") {
      data["primary"] = this.state.otherPrimary;
    } else {
      data["primary"] = this.state.primary;
    }

    data["category"] = this.state.category;
    data["name"] = this.state.listName;
    data["description"] = this.state.description;
    data["tags"] = this.state.tags;
    data["live"] = this.state.liveBtnVal;
    data["public"] = this.state.publicBtnVal;
    data["close"] = this.state.closedBtnVal;
    data["conscious"] = this.state.conciousBtnVal;
    data["draft"] = draftValue;
    data["media"] = this.state.mediaBtnVal;
    data["listItemDetails"] = totalListsWithDetails;
    data["collaborators"] = this.state.collaborators;
    data["sensitive"] = this.state.sensitive;
    data["city"] = this.state.city;
    data["state"] = this.state.state;
    data["country"] = this.state.country;
    console.log(JSON.stringify(data));
    return data;
  };

  finalSubmit = async (draftValue = false) => {

    // if the user isn't logged in
    if (!this.props.token) {
      console.log("Please log in first...");
      sessionStorage.setItem(
        "listiee_last_share_data",
        JSON.stringify({ ...this.state })
      );
      this.setState({ promptOnExit: false });
      setTimeout(() => {
        this.props.history.push(LOGIN_PATH, {
          // login_required: true,
          redirect_to: SHARE_PATH,
        });
      }, 200);
      return;
    }

    if (draftValue) {
      // console.log("SUBMITING LIST", data);
      if (this.state.listName.length < 8) {
        this.showSnackBar("Please enter a list name", "warning");
        return;
      }

      let data = await this.getAllData(draftValue);
      console.log(data);
      if (this.props.mode === "share") {
        await this.props
          .fetchDraftListv4({ aid: this.props.aid }, this.props.token)
          .then(async () => {
            if (this.props.draftlists.result.length >= 14) {
              this.showSnackBar(
                "Drafts limit is reached either publish or delete a previous draft.",
                "error"
              );
              return;
            }
            console.warn("API object for savelistversionThree:", data);
            const published = await this.props.saveListVersionThree(
              data,
              this.props.token
            );
            this.setState({
              saveBtnLoading: false,
              publishing: true,
              promptOnExit: false,
            });
            // this.props.history.push("/discover");
            // console.log("Success published:" ,published);
            if (published && published.status === 200) {
              this.showSnackBar("successfully saved as draft", "success");
              const lsid = published.data.data._id;
              // const slug = this.state.listName.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
              const slug = published.data.data.slug;
              setTimeout(() => {
                this.props.history.replace(getListPath(slug, lsid));
              }, 3000);
              // this.props.history.push(getListPath(slug, lsid));
            } else {
              // alert("Something went wrong!!");
              this.showSnackBar("Something went wrong!!", "error");
              this.setState({
                saveBtnLoading: false,
                publishing: false,
              });
            }
          });
      } else if (this.props.mode === "edit") {
        //Use edit list API instead of saveList below
        const lsid = this.props.match.params.id;
        // const slug = this.props.match.params.name;
        // const slug = this.state.listName.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        data["lsid"] = lsid;
        data["views"] = this.state.views;
        // data["lsStatus"] = this.state.lsStatus;
        data["email"] = this.props.email;
        data["tags"] = this.state.tags;

        console.log(data);

        // await this.editListPublish(data, this.props.token);
        // console.log("successfully edited list",data.name, data.lsid);

        // await this.setState({
        //   saveBtnLoading: false,
        //   publishing: true,
        //   promptOnExit: false
        // });
        // alert("Successfully edited list !");
        // this.props.history.push("/discover");
        // this.props.history.push(getListPath(slug, lsid));
        console.log(data);
        // const published=""
        const published = await this.props.editList(data, this.props.token);
        //console.log(published.data.data.slug);
        this.setState({
          saveBtnLoading: false,
          publishing: true,
          promptOnExit: false,
        });
        // this.props.history.push("/discover");
        // console.log("Success published:" ,published);

        if (published && published.status === 200) {
          // alert("successfully created list");

          // const lsid = published.data.data._id;
          // const slug = this.state.listName.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
          const slug = published.data.data.slug;
          this.showSnackBar("successfully edited list", "success");
          setTimeout(() => {
            this.props.history.replace(getListPath(slug, lsid));
          }, 3000);
          // this.props.history.push(getListPath(slug, lsid));
        } else {
          // alert("Something went wrong!!");
          this.showSnackBar("Something went wrong!!", "error");
          this.setState({ saveBtnLoading: false, publishing: false });
        }
      }
    } else {
      if (this.state.listName.length < 8) {
        this.showSnackBar("You need to add a list name", "warning");
        return;
      }

      if (
        this.state.primary === "" ||
        this.state.primary === "What is it" ||
        this.state.primary === "- What have you addded to the list -"
      ) {
        this.showSnackBar("Please add 'What is it", "warning");
        return;
      }

      if (this.state.primary === "other" && this.state.otherPrimary === "") {
        this.showSnackBar(
          "Please add what you meant by 'other' in 'What is it' input ",
          "warning"
        );
        return;
      }

      if (
        this.state.category === "" ||
        this.state.category === "Category" ||
        this.state.category === "- Select a category -"
      ) {
        this.showSnackBar("Category Missing", "warning");
        return;
      }

      if (this.state.details.length === 0) {
        this.showSnackBar("Please enter at lease one element", "warning");
        return;
      }

      // if list is marked sensitive by author, every listitem require links or source!!
      if (this.state.sensitive) {
        let i = 0,
          items = this.state.details;
        for (i; i < items.length; i++) {
          let item = items[i];
          let sourceInd = item.sections
            .map((sec) => {
              if (sec.items.length) {
                return sec.title;
              }
            })
            .indexOf("Source");

          if (
            item.links == "" &&
            (item.sections.length === 0 || sourceInd === -1)
          ) {
            break;
          }
        }
        if (i < items.length) {
          this.showSnackBar(
            "Sensitive lists require links/proof for every listitem.",
            "warning"
          );
          return;
        }
      }

      if (this.state.publishing) {
        this.showSnackBar("Please wait, list is getting published...", "info");
        return;
      }

      let data = await this.getAllData(draftValue);
      console.log(data);
      this.setState({ saveBtnLoading: true, publishing: true });
      this.setState({ draft: false });
      // console.log("SUBMITING LIST", data);
      if (this.props.mode === "share") {
        console.warn("API object for savelistversionThree:", data);
        const published = await this.props.saveListVersionThree(
          data,
          this.props.token
        );
        this.setState({
          saveBtnLoading: false,
          publishing: true,
          promptOnExit: false,
        });
        // this.props.history.push("/discover");
        // console.log("Success published:" ,published);
        if (published && published.status === 200) {
          this.showSnackBar("successfully created list", "success");
          const lsid = published.data.data._id;
          console.log(published.data.data);
          console.log(published.data.listsCreated);
          let listsCreated = published.data.listsCreated;
          // const slug = this.state.listName.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
          const slug = published.data.data.slug;
          // setTimeout(() => {
          //   this.props.history.replace(getListPath(slug, lsid));
          // }, 3000);

          /* any message to be passed can be passed in the state object and then read in the redirected component using:
          * this.props.location.state.messageName
          */

         setTimeout(() => {
           this.props.history.push({pathname: getListPath(slug, lsid),
                                   state: {
                                     published: true,
                                     listsCreated: listsCreated,
                                   }});
          }, 3000);

          // this.props.history.push(getListPath(slug, lsid));
        } else {
          // alert("Something went wrong!!");
          this.showSnackBar("Something went wrong!!", "error");
          this.setState({ saveBtnLoading: false, publishing: false });
        }
      } else if (this.props.mode === "edit") {
        //Use edit list API instead of saveList below
        const lsid = this.props.match.params.id;
        // const slug = this.props.match.params.name;
        // const slug = this.state.listName.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        data["lsid"] = lsid;
        data["views"] = this.state.views;
        // data["lsStatus"] = this.state.lsStatus;
        data["email"] = this.props.email;
        // await this.editListPublish(data, this.props.token);
        // console.log("successfully edited list",data.name, data.lsid);

        // await this.setState({
        //   saveBtnLoading: false,
        //   publishing: true,
        //   promptOnExit: false
        // });
        // alert("Successfully edited list !");
        // this.props.history.push("/discover");
        // this.props.history.push(getListPath(slug, lsid));

        const published = await this.props.editList(data, this.props.token);
        // console.log(published.data.data.slug);
        this.setState({
          saveBtnLoading: false,
          publishing: true,
          promptOnExit: false,
        });
        // this.props.history.push("/discover");
        console.log("Success published:", published);

        if (published && published.status === 200) {
          // alert("successfully created list");

          // const lsid = published.data.data._id;
          // const slug = this.state.listName.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
          const slug = published.data.data.slug;
          this.showSnackBar("successfully edited list", "success");
          setTimeout(() => {
            this.props.history.replace(getListPath(slug, lsid));
          }, 3000);
          // this.props.history.push(getListPath(slug, lsid));
        } else {
          // alert("Something went wrong!!");
          this.showSnackBar("Something went wrong!!", "error");
          this.setState({ saveBtnLoading: false, publishing: false });
        }
      }
    }
  };

  // editListPublish = async (data, token) => {
  //   const reqOptions = {
  //     method: "POST",
  //     url: EDIT_LIST_API,
  //     data: data,
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-access-token": token
  //     }
  //   };

  //   console.warn("API object for editListPublish:", reqOptions);

  //   await axios(reqOptions)
  //     .then(res => {
  //       console.log(res);
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // };

  saveList = () => {
    //sending updated list to parent component
    this.finalSubmit();
  };
  saveListAsDraft = async () => {
    //set draft=true and then publish
    //this.setState({ draft: true });

    this.finalSubmit(true);

    // alert("Saving Draft...");
    //this.showSnackBar('Saving Draft...', 'error');
  };

  saveListFromFirstBox = () => {

    // list validation
    if (this.state.listName.length < 8) {
      this.showSnackBar("Please add a title.", "error");
      return;
    }
    if (this.state.items.length > 2) {
      this.saveList();
    } else {
      this.showSnackBar(
        "Add atleast three items or click Add details",
        "warning"
      );
      return;
    }
  };

  preventDefaultActionOnDrop = (ev) => {
    /*
      This prevents the default link drop
      action of the browser which is to open
      the link or collection of links when
      bookmark folder is dropped.
    */
    ev.preventDefault();
  };

  handleGoBack = () => {
    // window.history.back();
    this.setState({showEditDetails: false});
  };
  handleContextChange = (event, sectionIndex) => {
    console.log("contextchange");
    let prevStateDetails = this.state.details;
    let newSections =
      this.state.details[this.state.currentListItemIndex].sections;
    newSections[sectionIndex].title = event.target.value;
    console.log(newSections);
    prevStateDetails[this.state.currentListItemIndex].sections = newSections;
    this.setState({ details: prevStateDetails });
  };
  ContextDelete = (currentSection) => {
    let prevStateDetails = this.state.details;
    let newSections = this.state.details[
      this.state.currentListItemIndex
    ].sections.filter((section, i) => {
      if (i !== currentSection) {
        return section;
      }
    });
    prevStateDetails[this.state.currentListItemIndex].sections = newSections;
    this.setState({ details: prevStateDetails });
    // console.log(this.state.details[this.state.currentListItemIndex].sections[currentSection])
    console.log(newSections);
  };
  isControlDisable = (itemAid = this.state.listAid) =>
    this.state.mode === "edit"
      ? this.props.aid !== itemAid &&
        this.props.user_type !== "amender" &&
        this.props.user_type === "mender"
      : false;


  checkForRelatedList = async (e) => {
    /* if (this.props.token) return; */

    e.preventDefault();
    let title = this.state.listTitle;
    console.log(title);
    let relatedLists = [];
    if (title.length > 4) {
      this.setState({ relatedLoading: true });
      //fetch related list
      const relatedListsData = await this.fetchRelated(title);
      if (relatedListsData.status === 200) {
        relatedLists = relatedListsData.data.data;
      }
      this.setState({ relatedLists, relatedLoading: false });
    } else {
      this.setState({ relatedLists, relatedLoading: false });
    }
  }

fetchRelated = async (query) => {
    let data = [];

    const reqOptions = {
      method: "POST",
      url: FETCH_RELATED_LIST_API,
      headers: {
        "x-access-token": this.props.token,
      },
      data: {
        search_query: query,
      },
    };

console.log("Fetching related list with data:", reqOptions);

  await axios(reqOptions)
      .then((res) => {
        //response is an array of related lists
        data = res;
        console.log(res);
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  };


  // a function to adjust the height of the related lists section according to the height of the list editor box.

  adjustRelatedList = () => {


    let editor = document.querySelector(".edit-container-left");
    let relatedList = document.querySelector(".related-list");

    // only proceed if related list section is rendered and not null
    if(relatedList) {



    // normal desktop width

    if(window.innerWidth <= 1135) {

      relatedList.style.top = editor.offsetHeight + 250 + "px"

    }

    // mobile or low desktop width

    else {

      relatedList.style.top = "28vh";

    }

    }

  }







  // Render Method
  render() {
    const {
      listName,
      tags,
      description,
      primary,
      // style,
      category,
      listStyle,
      showEditDetails,
      listTitle,
      relatedLists,
      relatedLoading,
      // details,
      // proofRequired,
      // sourceRequired,
      // sensitive,
      // anchorEl,
      // isSwitchDisabled,
    } = this.state;

const renderRelatedLists = () => {
      if (relatedLoading) {
        return (
          <p>
            Loading <i className="fas fa-spinner"></i>
          </p>
        );
      }
      return relatedLists.length !== 0 ? (
        relatedLists.map((item, id) => (
          <li key={id}>
            <Link
              to={getListPath(item.slug, item._id)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.name}
            </Link>
          </li>
        ))
      ) : (
        <>
          {listTitle.length > 0 ? (
            <p className={"related-no-data"}>
              Your list seems to be unique. <br /> No similar lists found.
            </p>
          ) : null}
        </>
      );
    }


    return (
      <div
        // className="testcreat container-fluid edit-container"
        className={`edit-container-list`}
        onDrop={(event) => {
          this.setState({
            bookmarksBeingDropped: false,
          });
          this.preventDefaultActionOnDrop(event);
        }}
        onDragOver={(event) => {
          this.setState({
            bookmarksBeingDropped: true,
          });
          this.dragover_handler(event);
        }}
        onDragLeave={() => {
          this.setState({
            bookmarksBeingDropped: false,
          });
        }}
      >
        {/* <button onClick={browserHistory.goBack}>Back</button> */}

        {/* {!showEditDetails &&
        this.props.pageName !== "home" &&
        this.props.pageName !== "home-desk" ? (
          <ShareAskLinkBtn {...this.props} />
        ) : null} */}
        <ReactTooltip place="right" type="info" effect="solid" />
        <Prompt
          when={
            (this.state.listName.length || this.state.items.length) &&
            this.state.promptOnExit
          }
          message="You have unsaved changes, are you sure you want to leave?"
        />
        {/* {console.log(this.state.details)} */}
        {!showEditDetails && this.props.pageName !== "home" ? (
          <p className="share-top-text">..cool, let's get started.</p>
        ) : null}
        <div
          className={
            showEditDetails
              ? "share-detailed"
              : "share-page-custom-grid-container"
          }
        >




{
        // only display the related section when the edit details section is collapsed to prevent overlap
  !showEditDetails &&
          <div className="related-list">
          <div className="container-2">
            <div className="h7 related-title">Related lists</div>
              <div className="related-list-container">
                <ul className={"simple-list"}>{renderRelatedLists()}</ul>
              </div>

          </div>
          </div>
}




          {showEditDetails ? (
            <div
            // className="col col-md-1"
            >
              {/*        <div className="access-btn-container">
                    <AccessControl
                      publicBtnVal={this.state.publicBtnVal}
                      conciousBtnVal={this.state.conciousBtnVal}
                      closedBtnVal={this.state.closedBtnVal}
                      mediaBtnVal={this.state.mediaBtnVal}
                      handleSwitchChange={this.handleSwitchChange}
                      liveBtnVal={this.state.liveBtnVal}
                      sensitive={this.state.sensitive}
                      covid={this.state.covid}
                      isSensitiveLocked={this.state.isSensitiveLocked}
                      listAid={this.state.listAid}
                      isControlDisable={this.isControlDisable}
                    />
                    <ReactTooltip place="right" type="info" />
                  </div> Commenting out as settings can be changed using settings button */}
            </div>
          ) : (
            <div className="box-left">
              <ReactTooltip place="right" type="info" effect="solid" />
            </div>
          )}
          <div className={showEditDetails ? "" : "box-center"}>
            <ErrorAlert
              AutoHideSnackbar={this.AutoHideSnackbar}
              showAlert={this.state.showAlert}
              message={this.state.alertMessage}
              status={this.state.alertCode}
            />
            {/* <div 
            // style={{ position: "relative" }}
            > */}
            <div className="Draft">
              <div className="func-btn-mobile">
                <PasteBtnComponent
                  className="fun-icon"
                  items={this.state.items}
                  details={this.state.details}
                  listName={this.state.listName}
                  onHandleChange={this.onHandleChange}
                  updateItems={this.updateItems}
                  updateDetails={this.updateDetails}
                  checkDuplicateInItems={this.checkDuplicateInItems}
                  {...this.props}
                />

                <CSVBtnComponent
                  className="fun-icon"
                  items={this.state.items}
                  details={this.state.details}
                  listName={this.state.listName}
                  onHandleChange={this.onHandleChange}
                  updateItems={this.updateItems}
                  updateDetails={this.updateDetails}
                  checkDuplicateInItems={this.checkDuplicateInItems}
                  {...this.props}
                />
                <ImportBookmarkModal
                  className="fun-icon"
                  {...this.props}
                  setBookmarks={this.props.setBookmarks}
                  featureComingAlert={this.featureComingAlert}
                  className="import"
                />
                {/* <ImportFromPocketModal
                      className="fun-icon"
                      {...this.props}
                      featureComingAlert={this.featureComingAlert}
                      className="pocket"
                    /> */}

                <div className="share-live">
                  <button className="btn" onClick={this.featureComingAlert}>
                    <i className="fa fa-share-alt" />{" "}
                    <span className="btn-text share-live">Share Live</span>
                  </button>
                </div>

                <div>
                  <button
                    className="btn hide-On-Small-Screens"
                    onClick={this.handleShowDetailPane}
                    title="Add links or lists in detail fromt the platform."
                  >
                    <i className="fun-icon fas fa-align-left" />
                    <span className="btn-text">Add more details</span>
                  </button>
                </div>
                {showEditDetails &
                (this.props.mode === "share" ||
                  (this.props.mode === "edit" &&
                    this.props.aid ===
                      this.props.fetchedList.result[0].aid)) ? (
                  <Badge
                    style={{ paddingBottom: "12px" }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    badgeContent={this.state.collaborators.length}
                  >
                    <CollabBtnComponent
                      style={{ paddingTop: "0px" }}
                      addCollaborator={this.props.addCollaborator}
                      storeCollabForPublishList={this.storeCollabForPublishList}
                      token={this.props.token}
                      {...this.props}
                      toggleCollabModal={this.toggleCollabModalBtn}
                    />
                  </Badge>
                ) : null}
              </div>
              {/* {!showEditDetails && this.props.pageName !== "home" ? ( */}
              <Fragment>
                {this.state.draftBtnLoading ? (
                  <i className={"fas fa-spinner"} />
                ) : (
                  <Tooltip arrow title="Save As Draft">
                    <Button size="small" onClick={this.saveListAsDraft}>
                      <SaveIcon />
                    </Button>
                  </Tooltip>
                )}

                <Button
                  className="draft-btn"
                  onClick={async () => {
                    await this.props.fetchDraftListv4(
                      { aid: this.props.aid },
                      this.props.token
                    );
                    this.toggleDraftBox();
                  }}
                >
                  Drafts
                </Button>
              </Fragment>
              {/* ) : null} */}
            </div>
            <div
              className={`edit-container-left${
                this.state.bookmarksBeingDropped ? " glowBordersOnDrop" : ""
              }`}
              onDrop={(event) => {
                this.setState({
                  bookmarksBeingDropped: false,
                });
                this.handlerDropOnLeft(event);
              }}

              onChange = {() => { this.adjustRelatedList() } }
              onResize = {() => { this.adjustRelatedList() } }
              onDragOver={(event) => {
                this.setState({
                  bookmarksBeingDropped: true,
                });
                this.dragover_handler(event);
              }}
              onDragLeave={() => {
                this.setState({
                  bookmarksBeingDropped: false,
                });
              }}
            >
              {!showEditDetails ? (
                <div
                // className="card-top"
                >
                  <div
                  // className="list-settings-btn"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="locationDropdown">
                        <select
                          title="change list's relevance"
                          value={this.state.relevance}
                          onChange={this.setRelevance}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          onFocus={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          <option value="national">National</option>
                          <option value="global">Global</option>
                          <option value="local">Local</option>
                        </select>
                      </div>
                      <div style={{ display: "flex" }}>
                        {this.props.mode === "share" ||
                        (this.props.mode === "edit" &&
                          this.props.aid ===
                            this.props.fetchedList.result[0].aid) ? (
                          <Badge
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={this.state.collaborators.length}
                          >
                            <CollabBtnComponent
                              addCollaborator={this.props.addCollaborator}
                              storeCollabForPublishList={
                                this.storeCollabForPublishList
                              }
                              token={this.props.token}
                              {...this.props}
                              toggleCollabModal={this.toggleCollabModalBtn}
                            />
                          </Badge>
                        ) : null}
                        {this.props.mode === "share" ? (
                          <ShareSettings
                            publicBtnVal={this.state.publicBtnVal}
                            conciousBtnVal={this.state.conciousBtnVal}
                            closedBtnVal={this.state.closedBtnVal}
                            handleSwitchChange={this.handleSwitchChange}
                            liveBtnVal={this.state.liveBtnVal}
                            sensitive={this.state.sensitive}
                            covid={this.state.covid}
                            listAid={this.state.listAid}
                            isControlDisable={this.isControlDisable}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div
                className="form-group list-title"
                style={
                  !showEditDetails
                    ? {
                        paddingTop: "0px",
                        top: "-1.5em",
                      }
                    : null
                }
              >


                <input
                  name={"listName"}
                  type="text"
                  className={"form-control list-title-input"}
                  id="listName"
                  // autoFocus='autofocus'
                  placeholder="Type list title here."
                  onFocus={(e) =>
                    (e.target.placeholder = "Type list title here.")
                  }
                  onChange={this.onHandleChange}
                  onBlur={this.handleCheckTitle}
                  value={listName}
                  onKeyPress={this.handleFocusNext}
                  onKeyDown={this.handleKeyDown}
                  style={
                    showEditDetails
                      ? {
                          fontSize: "1em",
                          color: "#000",
                          fontWeight: "500",
                          background: "#fff",
                          border: "none",
                          fontFamily:
                            "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
                        }
                      : { color: "black" }
                  }
                  title={listName}
                  autoComplete="off"
                  disabled={this.isControlDisable(this.state.listAid)}
                />
                <div className="import-btn-container">
                  <div className="pubsh-btn">
                    {!this.props.token ? (
                      <Link to="/login">
                        <button className="btn-css btn box-1-submit-btn">
                          Login To Publish
                        </button>
                      </Link>
                    ) : (
                      <button
                        className="btn-css btn box-1-submit-btn"
                        onClick={this.saveListFromFirstBox}
                        title="Click to publish this list on the platform"
                      >
                        {this.state.saveBtnLoading ? (
                          <i className={"fas fa-spinner"} />
                        ) : (
                          <i className="far fa-check-circle" />
                        )}{" "}
                        {this.state.saveBtnLoading
                          ? "Publishing..."
                          : "Publish"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="list-description-container">
                <TextareaAutosize
                  className="form-control custom-focus-management"
                  rowsMin={window.innerWidth < 980 ? 1 : 3}
                  id="description"
                  value={this.state.description}
                  onChange={this.onDescriptionChange.bind(this)}
                  placeholder="description (168 char)"
                />
                <span
                  className="description-char-count"
                  style={description.length > 167 ? { color: "red" } : null}
                >
                  {description.length}/168
                </span>
              </div>





              <div className="row">
                <div className="col-12">
                  {this.props.mode === "share" ? (
                    <ListItems
                      // isopen={this.state.modal}
                      // toggle={this.toggle}
                      items={this.state.items}
                      details={this.state.details}
                      listName={this.state.listName}
                      onHandleChange={this.onHandleChange}
                      currentListItemIndex={this.state.currentListItemIndex}
                      changeCurrentListItemIndex={
                        this.changeCurrentListItemIndex
                      }
                      updateItems={this.updateItems}
                      updateDetails={this.updateDetails}
                      showEditDetails={this.state.showEditDetails}
                      handleDeleting={this.handleDeleting}
                      handleListEdit={this.handleListEdit}
                      handleshowEditDetails={this.handleshowEditDetails}
                      checkDuplicateInItems={this.checkDuplicateInItems}
                      storeCollabForPublishList={this.storeCollabForPublishList}
                      listStyle={this.state.listStyle}
                      listStyle={listStyle}
                      isControlDisable={this.isControlDisable}
                      toggle={this.toggle}
                      handleAddLink={this.handleAddLink}
                      handleAddProofLink={this.handleAddProofLink}
                      handleSectionInput={this.handleSectionInput}
                      handleAddProofSource={this.handleAddProofSource}
                      dragover_handler={this.dragover_handler}
                      drop_handler={this.drop_handler}
                      isDragDisable={this.isDragDisable}
                      addNewProofSection={this.addNewProofSection}
                      sensitive={this.state.sensitive}
                      {...this.props}
                    />
                  ) : (
                    <ListItems
                      // isopen={this.state.modal}
                      // toggle={this.toggle}
                      items={this.state.items}
                      details={this.state.details}
                      listName={this.state.listName}
                      lsid={
                        this.props.fetchedList
                          ? this.props.fetchedList.result[0]._id
                          : null
                      }
                      onHandleChange={this.onHandleChange}
                      currentListItemIndex={this.state.currentListItemIndex}
                      changeCurrentListItemIndex={
                        this.changeCurrentListItemIndex
                      }
                      updateItems={this.updateItems}
                      updateDetails={this.updateDetails}
                      showEditDetails={this.state.showEditDetails}
                      handleDeleting={this.handleDeleting}
                      handleListEdit={this.handleListEdit}
                      handleshowEditDetails={this.handleshowEditDetails}
                      checkDuplicateInItems={this.checkDuplicateInItems}
                      addCollaborator={this.props.addCollaborator}
                      listStyle={this.state.listStyle}
                      listStyle={listStyle}
                      isControlDisable={this.isControlDisable}
                      {...this.props}
                    />
                  )}
                </div>
              </div>

              {/*<br />
                  <div className="proof-checkbox-container">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sourceRequired}
                          onChange={this.onHandleCheckbox}
                          value={sourceRequired}
                          color="primary"
                          name="sourceRequired"
                        />
                      }
                      label="Add source"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={proofRequired}
                          onChange={this.onHandleCheckbox}
                          value={proofRequired}
                          color="primary"
                          name="proofRequired"
                        />
                      }
                      label="Add proof"
                    />
                  </div> */}
              {showEditDetails || this.state.items.length > 2 ? (
                <div className="row-listEdit">
                  <div className="Primary-And-Category">
                    <EditPrimary
                      onHandleChange={this.onHandleChange}
                      value={primary}
                    />
                    <EditCategory
                      onHandleChange={this.onHandleChange}
                      value={category}
                    />
                  </div>
                  {primary === "other" && (
                    <div className="form-group other-primary-container">
                      <input
                        name={"otherPrimary"}
                        value={this.state.otherPrimary}
                        onChange={this.onHandleChange}
                        style={{
                          width: "100%",
                          border: "1px solid lightgrey",
                          padding: "10px",
                        }}
                        type="text"
                        placeholder="tell us, what are we missing."
                      />
                    </div>
                  )}
                  <EditTags
                    tags={tags}
                    handleDelete={this.handleDelete}
                    handleTagInput={this.handleTagInput}
                  />
                </div>
              ) : null}
              {showEditDetails && (
                <CollabModal
                  showEditDetails={this.showEditDetails}
                  onCollabSubmit={this.onCollabSubmit}
                  token={this.props.token}
                  mode={this.props.mode}
                  {...this.props}
                  isCollabModalOpen={this.state.isCollabModalOpen}
                  toggleCollabModal={this.toggleCollabModal}
                />
              )}
            </div>
          </div>
          {showEditDetails ? (
            <div
            // className="col col-12 col-md-6 col-lg-7"
            >
              <div className="edit-container-right">
                <i
                  className="fa fa-arrow-left close-btn close-btn-edit-page"
                  onClick={this.handleGoBack}
                  title="Go back"
                />

                <ListDetails
                  details={this.state.details}
                  aid={this.props.aid}
                  handleContextChange={this.handleContextChange}
                  handleContextDelete={this.ContextDelete}
                  currentListItemIndex={this.state.currentListItemIndex}
                  updatedetails={this.updateDetails}
                  handleshowEditDetails={this.handleshowEditDetails}
                  handleHighlightsChange={this.handleHighlightsChange}
                  handleDeleteHighlight={this.handleDeleteHighlight}
                  handleHighlightsAdd={this.handleHighlightsAdd}
                  addHighlight={this.addHighlight}
                  handleChangeOnDetailComponent={
                    this.handleChangeOnDetailComponent
                  }
                  handleRemoveLink={this.handleRemoveLink}
                  toggle={this.toggle}
                  handleAddLink={this.handleAddLink}
                  handleSectionInput={this.handleSectionInput}
                  handleAddSource={this.handleAddSource}
                  dragover_handler={this.dragover_handler}
                  drop_handler={this.drop_handler}
                  displayAboutSection={this.state.displayAboutSection}
                  setAboutSection={(val) =>
                    this.setState({
                      displayAboutSection: val,
                    })
                  }
                />
              </div>
            </div>
          ) : (
            <div className="box-right">
              <CollabModal
                showEditDetails={this.showEditDetails}
                onCollabSubmit={this.onCollabSubmit}
                token={this.props.token}
                mode={this.props.mode}
                {...this.props}
                isCollabModalOpen={this.state.isCollabModalOpen}
                toggleCollabModal={this.toggleCollabModal}
              />
            </div>
          )}


        </div>
        {/* Modal to add link in about section */}
        <AddLinkModal
          isOpen={this.state.modal}
          // isOpen={true}
          toggle={this.toggle}
          modalLink={this.state.modalLink}
          handleChange={this.handleModalChange}
          handleModelOk={this.handleSectionModelOk}
          sectionTitle={"About"}
          linkFromPlatform={this.state.linkFromPlatform}
        />

        {/* Modal to add link in custom section */}
        <AddLinkModal
          isOpen={this.state.sectionModal}
          // isOpen={true}
          toggle={this.sectionModleToggle}
          modalLink={this.state.modalLink}
          handleChange={this.handleModalChange}
          handleModelOk={this.handleSectionModelOk}
          sectionTitle={this.state.currentSectionTitle}
          linkFromPlatform={this.state.linkFromPlatform}
          showEditDetails={this.state.showEditDetails}
        />
        {this.props.pageName !== "home" && (
          <div className="row sharing_text">
            <div className="col col-12">
              <p className="para">
                <span className="semi-start">"</span>Sharing is caring, there is
                no doubt to that, You are a true altruist, keep it up!
                <span className="semi-last">"</span>
              </p>
            </div>
          </div>
        )}
        <Drawer
          variant="persistent"
          anchor="left"
          open={this.state.showDraftBox}
          //style of this component has been overridden using
          // .MuiDrawer-paperAnchorDockedLeft CSS class in nav.sass
          //This is used via CSS rule of Material UI
        >
          <div className={"drawerHeader drafts-header"}>
            <IconButton
              onClick={this.toggleDraftBox}
              style={{ alignSelf: "flex-end" }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </div>
          <Divider />
          <div className="modal-dialog notif_container">
            <div className="modal-content" style={{ border: "none" }}>
              <div className="modal-header">
                <h4 className="modal-title notif-header-title" id="ModalLabel">
                  Drafts
                </h4>
              </div>
            </div>
          </div>




          {this.props.draftlists ? (
            this.props.draftlists.result &&
            this.props.draftlists.result.length === 0 ? (
              <h4 className="mx-auto no-notif">You don't have any Drafts.</h4>
            ) : null
          ) : null}

          {this.props.draftlists
            ? this.props.draftlists.result &&
              this.props.draftlists.result.map((draft, i) => (
                <div key={i} className="card draft_card">
                  <div className="card-design">
                    <div className="draft-title-wrapper-container">
                      <div className="notif-title-wrapper">
                        <div
                          style={{
                            width: "300px",
                            color: "#ff607c",
                          }}
                        >
                          <b>{draft.name}</b>
                        </div>
                      </div>
                      <div className="edit-delete-draft-container">
                        <DeleteIcon
                          style={{
                            marginRight: "20px",
                          }}
                          onClick={async () => {
                            let isConfirmed = await confirmDeletion();
                            if (isConfirmed) {
                              const { token, aid } = this.props;
                              const id = draft._id;
                              const data = {
                                aid,
                                lsid: id,
                              };
                              console.log(data);
                              const res = await this.props
                                .deleteList(data, token)
                                .then(() => {
                                  this.toggleDraftBox();
                                  this.showSnackBar(
                                    "Successfully deleted the draft",
                                    "success"
                                  );
                                });
                            }
                          }}
                        />
                        <EditIcon
                          style={{
                            marginRight: "20px",
                          }}
                          onClick={(e) =>
                            this.props.history.push(
                              getEditPath(draft.slug, draft._id)
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </Drawer>


      </div>


    );
  }
}

ListEditor.propTypes = {
  totalList: PropTypes.object,
  mode: PropTypes.string,
  handleshowEditDetails: PropTypes.func,
};

export default ListEditor;
