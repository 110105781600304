import React, { useState, useEffect } from "react";
import {NavLink} from "react-router-dom";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import UserNameIBtn from "../Nested/UserNameIBtn";

import AuthContainer from "./AuthContainer";
import {
  HOME_PATH,
  getProfilePath,
  LOGIN_PATH,
  REGISTER_PATH,
} from "../../constants/pathConstants";
import ErrorAlert from "../Nested/ErrorAlert";
import SocialLogin from "./SocialLogin";
import OTPModal from "./OTPModal";

const ForgotPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password_signup, set_password_signup] = useState("");
  const [password_signup_conf, set_password_signup_conf] = useState("");
  const [alert, setAlert] = useState({ show: false, msg: null, type: null });

  useEffect(() => {
    if (props.token) {
      if (props.location.state && props.location.state.redirect_to) {
        props.history.replace(props.location.state.redirect_to);
      } else {
        props.history.replace(HOME_PATH);
      }
    }
  }, [props.token]);

  const AutoHideSnackbar = () => {
    setAlert({
      show: false,
    });
  };

  const showSnackBar = (msg, type) => {
    setAlert({
      show: true,
      msg: msg,
      type: type,
    });
  };

  const onSubmitforgetPassword = async (e) => {
    e.preventDefault();
    props.clearAuthError();

    if (email === "") {
      showSnackBar("Please enter your email", "error");
      return;
    }
    const data = {
      email,
    };
    props.forgetPassword(data);
  };

  const onSubmitResetPassword = async (e) => {
    e.preventDefault();
    props.clearAuthError();
    let pwdRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (password_signup === "") {
      showSnackBar("Please enter a new password", "error");
      return;
    }
    if (password_signup_conf === "") {
      showSnackBar("Please confirm your new password", "error");
      return;
    }
    if (password_signup !== password_signup_conf) {
      showSnackBar("Passwords do not match", "error");
      return;
    }
    if (!pwdRegex.test(String(password_signup).trim())) {
      showSnackBar(
        "Password must contain atleast one capital letter, Special charactor and numerical value",
        "error"
      );
      return;
    }

    const { aid } = props;

    const data = {
      aid,
      newpassword: password_signup,
    };

    await props.resetPassword(data);
    // if (!props.resetPasswordBool) {
    props.history.replace(LOGIN_PATH);
    // showSnackBar("Password reset success", "success");
    // }
  };

  return (
    <AuthContainer {...props}>
      <>
        <ErrorAlert
          AutoHideSnackbar={AutoHideSnackbar}
          showAlert={alert.show}
          message={alert.msg}
          status={alert.type}
        />
        <div className={"modal_form"}>
          {props.emailSent ? <OTPModal {...props} /> : null}
          {props.resetPasswordBool ? (
            <form>
              <div className={"modal_form__field"}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="adornment-password">
                    Create a new Password
                  </InputLabel>
                  <Input
                    type={showPassword ? "text" : "password"}
                    name={"password_signup"}
                    className={"custom-password-input"}
                    value={password_signup}
                    onChange={(e) => set_password_signup(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={(e) => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className={"modal_form__field"}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="adornment-password">
                    Confirm Password
                  </InputLabel>
                  <Input
                    type={showPassword ? "text" : "password"}
                    name={"password_signup_conf"}
                    className={"custom-password-input"}
                    value={password_signup_conf}
                    onChange={(e) => set_password_signup_conf(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={(e) => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <Button
                className={"modal_form__button"}
                color="primary"
                variant={"contained"}
                type={"submit"}
                disabled={props.loading}
                onClick={(e) => onSubmitResetPassword(e)}
              >
                Submit
              </Button>
            </form>
          ) : (
            <form>
              <div className={"modal_form__field"}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="adornment-password">
                    Enter your email
                  </InputLabel>
                  <Input
                    type={"email"}
                    name={"email"}
                    className={"custom-password-input"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus={true}
                  />
                </FormControl>
              </div>
                 
              <Button
                className={"modal_form__button mb-5"}
                color="primary"
                variant={"contained"}
                type={"submit"}
                disabled={props.loading}
                onClick={(e) => onSubmitforgetPassword(e)}
              >
                Reset
              </Button>


              <Button style={{
                padding: "8px 8px",
                margin: "1px 0px",
                background: "#4286f5",
                textAlign: "center",
                borderRadius: "4px",
                marginTop: "5px",
                width: "100%"
                
              }}>
              <NavLink 
                style={{
                  color: "#fff",
                  textTransform: "uppercase",
                  width: "100%"
                }}
               to="/login">
                 Return
                 </NavLink>
              </Button>




              <div className="Signup_tagline">
                Don't have an account ?{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(REGISTER_PATH);
                  }}
                >
                  Sign Up Now
                </a>
              </div>
            </form>
          )}
        </div>
      </>
    </AuthContainer>
  );
};

export default ForgotPassword;
