// User invitation form.
import Popup from "reactjs-popup";
import React, { useEffect, useState } from "react";
import { useForm } from "../utils/utils.js";
import {
  FETCH_INVITATIONS_LEFT,
  SEND_INVITATION,
} from "../constants/apiConstants.js";
import axios from "axios";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@mui/material/InputAdornment";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import TwitterIcon from "@mui/icons-material/Twitter";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const UserInvitationForm = ({
  setBetaInvitations,
  token,
  invitations,
  logoutUser,
}) => {
  const [invitationInfo, setInvitationInfo] = useForm({
    first_name: "",
    email: "",
    inviter_twitter_handle: "",
    invitee_twitter_handle: "",
  });
  const handleSubmit = async e => {
    e.preventDefault();
    // send data to backend.
    const {
      first_name,
      email,
      inviter_twitter_handle,
      invitee_twitter_handle,
    } = invitationInfo;
    const data = {
      first_name,
      email,
      inviter_twitter_handle,
      invitee_twitter_handle,
    };
    const reqOptions = {
      method: "POST",
      url: SEND_INVITATION,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    };
    try {
      // response will contain the new betaInvitations value.
      const response = await axios(reqOptions);
      setBetaInvitations(response.data.newValue);
      swal("Invitation sent.", "Thanks for spreading the word !!", "success");
    } catch (err) {
      console.error(err);
      switch (err.response.data.code) {
        // Custom Error types
        // 484 -> validation error.
        case 484:
          swal("Invalid parameters !!", "Please try again", "error");
          break;
        // 1400 -> fake email provided.
        case 1400:
          swal("Fake email provided", "Please enter a valid email", "error");
          break;
        // 700 -> In case jwt in invalid.
        case 700:
          swal("Invalid token !!", "Please login again", "error")
            .then(() => logoutUser())
            .then(() => (window.location = "/login"))
            .catch(_ => (window.location = "/login"));
          break;
        // 485 -> inviting an already existing user.
        case 485:
          swal("Invitee already a user !!", "", "error");
          break;
        // 486 -> Inviting an already invited user.
        case 486:
          swal("User already invited !!", "", "warning");
          break;
        case 487:
          swal("No invitations left !!", "", "error");
          break;
        // 500 -> server error.
        default:
          swal("Server error !!", "Please try again.", "error");
      }
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
          type={"text"}
          //   placeholder={"First Name"}
          name={"first_name"}
          size="small"
          id="standard-basic"
          label="First Name"
          variant="standard"
          value={invitationInfo.first_name}
          onChange={setInvitationInfo}
          required
          style={{
            marginTop: "1rem",
            marginLeft: "10px",
            marginRight: "10px",
            width: "220px",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          type="text"
          //   placeholder="Email"
          name="email"
          size="small"
          id="standard-basic"
          label="Email"
          variant="standard"
          value={invitationInfo.email}
          onChange={setInvitationInfo}
          required
          style={{
            marginTop: "1rem",
            marginLeft: "10px",
            marginRight: "10px",
            width: "220px",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AlternateEmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <br />
        <br />
        <span>Optional</span>
        <TextField
          type="text"
          placeholder="Inviters twitter handle"
          name="full_name"
          value={invitationInfo.inviter_twitter_handle}
          onChange={setInvitationInfo}
          size="small"
          id="standard-basic"
          //   label="Standard"
          variant="standard"
          style={{
            marginTop: "0.7rem",
            marginRight: "10px",
            marginLeft: "10px",
            width: "220px",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TwitterIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          type="text"
          placeholder="Invitees twitter handle"
          name="full_name"
          value={invitationInfo.invitee_twitter_handle}
          onChange={setInvitationInfo}
          id="standard-basic"
          //   label="Standard"
          variant="standard"
          size="small"
          style={{
            marginTop: "0.7rem",
            marginRight: "10px",
            marginLeft: "10px",
            width: "220px",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TwitterIcon />
              </InputAdornment>
            ),
          }}
        />
        <br />
        <br />
        <div class="submit-invite">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={invitations < 1}
            className="submit-invite-button">
            {" "}
            Invite{" "}
          </Button>
        </div>
      </form>
    </div>
  );
};

const UserInvitation = ({ token, logoutUser }) => {
  const [invitations, setInvitations] = useState(0);
  const setBetaInvitations = newValue => setInvitations(newValue);
  useEffect(() => {
    // api call, fetch user invitations left.
    // get user token.
    const fetchInvitationsLeft = async () => {
      const reqOptions = {
        method: "GET",
        url: FETCH_INVITATIONS_LEFT,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      };
      try {
        // console.log("Rendering");
        const response = await axios(reqOptions);
        // console.log(response);
        setInvitations(response.data.data.betaInvitations);
      } catch (err) {
        console.error(err);
        // Type of errors
        // 404 -> invalid token/user not found error.
        if (err.response.data.code == 700) {
          swal("Invalid token !!", "Please login again", "error")
            .then(() => logoutUser())
            .then(() => (window.location = "/login"))
            .catch(_ => (window.location = "/login"));
        }
        // 500 -> server error.
        if (err.response.status == 500) {
          swal("Server error !!", "Please try again.", "error");
        }
      }
    };
    fetchInvitationsLeft();
  });
  return (
    <Popup
      contentStyle={{
        width: "250px",
        border: "none",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "15px",
        paddingTop: "15px",
      }}
      trigger={
        <button class="invite-button scale-on-hover" title="Invite users">
          <i class="fas fa-paper-plane" aria-hidden="true"></i>
        </button>
      }
      closeOnDocumentClick>
      {" "}
      <span
        style={{
          fontSize: "s",
          fontWeight: "600",
          color: "#858181",
        }}>
        Invites left: {invitations}
      </span>
      <UserInvitationForm
        setBetaInvitations={setBetaInvitations}
        token={token}
        invitations={invitations}
        logoutUser={logoutUser}
      />
    </Popup>
  );
};

export default UserInvitation;
