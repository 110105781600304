import React from "react";

export default class Story extends React.Component {
    render() {
        return (
            <div className="story-container">
                <div className="container">
                    <div className="greetings mb-3 mt-4">The Story of Listiee 🎉</div>
                    <div className="content mb-2 text-left">
                        Listiee is a platform built with a dream to someday organize all the information on the internet to every bits and pieces of it.
                        <div className="mb-3" />
                        Our founder has been a consistent seeker of understanding life as it is, the way things work and it’s why’s. His love for expression, writing and playing with words early in his life connected him with deep roots of role and importance of language.
                        <div className="mb-3" />
                        After years of  seeking, comprehending and years of observation - he realized how a simple piece of information or knowing of just a bunch of words can unleash a whole new world of possibilities, thoughts, emotions & imagination in our mind, Which made him more interested to have more closer look into a Language - its importance, role and significance in our everyday lives among other things.
                        Being a kind of person who likes to learn, wonder, act and accomplish things on his own, he found that the first and foremost challenge that everyone faces since they start seeking things in their life towards whatever was getting the right guidance/information.
                        <div className="mb-3" />
                        While due to the internet and the desire of everyone coming together to share their knowledge and experiences, there is no limitations to quantity or the quality of guidance or information that someone can get their hands on. But there are few issues to it -
                        <ol>
                            <li>Language  - Although information is just a search away, you need the right lingo to access it out of the ocean of information. There are times when we are often searching things that we don’t know even the basics of it.</li>
                            <li>The information is often distributed - Which makes it very difficult to get it together. Often, we end up misguided or misinformed without getting full context of it.</li>
                            <li>Lingo is always on the move - The language and terms are evolving with new generations much more frequently than ever in the history.</li>
                            <li>2018 - 20’s rising era of duplicacy, misleading information adding to the chaos of existing information - making it slightly more difficult to identify and seek the genuine.</li>
                        </ol>
                        <div className="mb-3" />
                        When the chaos came to mind, the lists were the answer to begin with. And so, the drawing began.
                        <div className="mb-3" />
                        From the initial days, where the only thought the founder had in his mind was to build a generic listing platform that can help create any type of lists. As other issues that slow down the process of organizing, exploring discovery of information became more and more clear, the idea along with the platform's architecture evolved. It was also validated over the time that everyone was building lists everyday for themselves. Now, whether it was 5 minutes or research or hours/ days of time invested  on research by individuals to create useful lists, list stacks or research, we recognized it as one of the most underused & undervalued time, research and intelligence in itself. As it is mostly used only by the person researching at the end of the day in immediate time.
                        <div className="mb-3" />
                        So, we built Listiee to assist / solve / simplify all of the above.
                        <div className="mb-3" />
                        Today Listiee is a unique complex system that is capable of helping it’s users organize large sacks of digital information based on their context as lists and links. The user can further share/publish, collaborate, ask, stack and discover information on the platform.
                        <div className="mb-3" />
                        <div className="mb-3" />
                        <div className="inbetween-title">Why Listiee or specifically LISTS?</div>
                        <div className="mb-3" />
                        Lists are lucid, quick to consume and easy to remember. They can be effortlessly noted, collected, organized, re-organized, linked, featured, sorted. Over time, we realized so much more can be done with them compared to any other form of data.

We looked at the internet - the way data is evolving and size of data. It thought it may not have made more sense to build a universal listing and linking platform. A simple idea, just because of digital data and links, can be leveraged to collaborate and achieve incomprehensible things like never before in the history of information. We have seen examples like Wikipedia & Quora  where people are eager to collaborate and share knowledge.

Our research found dozens of list based platforms clearly indicating the need of organized information, while there wasn’t any active universal listing platform as such. So we decided to build one.
                    </div>
                    <div className="investor-footer mt-4">
                        <div>
                            <div>Follow us on social media</div>
                            <ul>
                                <li><a href="https://twitter.com/listiee01"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/listiee/"><i className="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                        <div>
                            <ul className="reaction-btn-container">
                                <li><img src="/assets/reaction/clap.png" alt="" /></li>
                                <li><img src="/assets/reaction/like.png" alt="" /></li>
                                <li><img src="/assets/reaction/question.png" alt="" /></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}