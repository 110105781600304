import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

class RecommendationsMob extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Accordion
          // style={{
          //   position: "sticky",
          //   bottom: "0px",
          //   zIndex: "100",
          //   height: "30vh"
          // }}
          // className="recommendedList-mob"

          headerstyle={{background: "red"}} 
        >
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={{padding: "2px !important", color: "#000",fontWeight: "300", fontSize: "1em", fontFamily: "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !important" }}>
              Other lists
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="recommendations-mob-box"
            style={{ display: "flex", flexDirection: "column"}}
          >
            {this.props.recommendations.map((recommendation, index) => (
              <a
                className="recommendations-mob"
                key={recommendation._id}
                href={`/list/${recommendation._id}/${recommendation.slug}/item`}
              >
                <Typography>{recommendation.name}</Typography>
              </a>
            ))}
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
}

export default RecommendationsMob;
