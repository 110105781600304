import Cookies from 'js-cookie';
import {
  AUTHENTICATE_USER,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  RETRY_SIGNUP,
  TOGGLE_EMAIL_VERIFIED,
  RESET_PASSWORD,
  LOADING,
  LOADED,
  AUTH_GOOGLE,
  AUTH_LINKEDIN,
  AUTH_TWITTER,
} from '../constants/actionTypes';
import { COOKIE_AUTH } from '../constants/baseConstants';

const initialState = {
  email: '',
  password: '',
  token: '',
  aid: '',
  user_name: '',
  user_type: '',
  name: '',
  bio: '',
  avatar: '',
  emailSent: false,
  verify_email_type: 0,
  resetPasswordBool: false,
  loading: false,
  city: '',
  state: '',
  country: '',
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        ...action.payload,
      };
    case REGISTER_USER:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        ...action.payload,
      };
    case AUTHENTICATE_USER:
      const cookieAuth = Cookies.getJSON(COOKIE_AUTH);
      return {
        ...state,
        ...cookieAuth,
      };
    case LOGOUT_USER:
      Cookies.remove(COOKIE_AUTH);
      return {
        ...state,
        email: '',
        name: '',
        bio: '',
        token: '',
        aid: '',
      };
    case TOGGLE_EMAIL_VERIFIED:
      return {
        ...state,
        emailSent: !state.emailSent,
      };
    case LOADING:
      return {
        ...state,
        ...action.payload,
      };
    case LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case RETRY_SIGNUP:
      return {
        ...state,
        emailSent: false,
      };
    default:
      return state;
  }
};

export default auth;
