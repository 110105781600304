import React from 'react'
import QRCode from 'qrcode.react';


export default function QRShareModal(props) {
  return (
  <div
  className="modal fade"
  id="qrModal"
  tabindex="-1"
  aria-hidden="true"
  >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body mx-auto">
        <div className="text-center">
          <QRCode
            value={`${props.baseShareUrl}sq`}
            size={200}
            level={"H"}
            includeMargin={true}
          />
        </div>
        </div>
        <div className="modal-footer text-center">
          <h5 className="text-center" style={{margin:"0 auto"}} title="Scan this using a QR code scanner">

            Scan to open this list 📲
                      
            <br/>
            <small>( link copied )</small>
          </h5>
        </div>
      </div>
    </div>
  </div>
  )
}
