import React,{Fragment} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import {
  fetchUserProfileBookmarks,
  fetchUserProfileBookmarksFunction,
} from "../../actions/apiActions";
import { Card, CardContent, Typography } from "@material-ui/core";
import { ListLoaderForDiscover } from "../ListLoader";
// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
// import GridListTileBar from '@material-ui/core/GridListTileBar';
// import ListSubheader from '@material-ui/core/ListSubheader';
// import IconButton from '@material-ui/core/IconButton';
// import InfoIcon from '@material-ui/icons/Info';

import { getListPath } from "../../constants/pathConstants";

import "./ProfileLists.sass";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

class ProfileBookmarks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: Array.from({ length: 20 }),
      bookmarks: [],
      hasMore: true,
      page: 1,
      total: 0,
      limit: 6,
      loading: true,
    };
  }

  componentDidMount() {
    const data = {
      aid: this.props.user.aid,
      email: this.props.user.email,
      username: this.props.username,
      page: this.state.page,
      limit: this.state.limit,
    };
    this.setState({ loading: true });
    fetchUserProfileBookmarksFunction(data, this.props.user.token).then(
      (res) => {
        // console.log(res);
        this.setState({
          bookmarks: this.state.bookmarks.concat(res.result),
          total: res.total,
          page: this.state.page + 1,
          loading: false,
        });
      }
    );
  }

  fetchMoreData = () => {
    if (this.state.bookmarks.length == this.state.total) {
      this.setState({ hasMore: false });
      return;
    }
    this.setState({ loading: true });
    // console.log("fetching");
    const data = {
      aid: this.props.user.aid,
      email: this.props.user.email,
      username: this.props.username,
      page: this.state.page,
      limit: this.state.limit,
    };

    // if(!this.state.loading) {
    // console.log("here");
    fetchUserProfileBookmarksFunction(data, this.props.user.token).then(
      (res) => {
        this.setState({
          bookmarks: this.state.bookmarks.concat(res.result),
          page: this.state.page + 1,
          loading: false,
        });
      }
    );
    // }
  };

  // http://localhost:3000/list/5da5b0b398e96030c7bbb1ad/List-of-Gold-Glove-Award-winners-at-first-base/5da5b11298e96030c7bbcb9e

  handleLink = (e, slug, id, itemId) => {
    e.preventDefault();
    this.props.history.push(getListPath(slug, id, itemId));
  };

  render() {
    return (
      // <div>
      <InfiniteScroll
        dataLength={this.state.bookmarks.length}
        next={this.fetchMoreData}
        hasMore={this.state.hasMore}
        loader={<ListLoader />}
        height={550}
        endMessage={
          <div style={{ textAlign: "center", height: "150px" }}>
            <b>Yay! You have seen it all</b>
          </div>
        }
      >
        <div className="listContainer">
          <GridList className="gridList">
            {console.log(this.state)}
            {this.state.bookmarks &&
              this.state.bookmarks.map((bookmark, index) => (
                <GridListTile className="gridTile" key={index}>
                  {console.log(bookmark.item)}
                  <Card
                    key={index}
                    className="card"
                    onClick={(e) => {
                      this.handleLink(
                        e,
                        bookmark.list[0].slug,
                        bookmark.list[0]._id,
                        bookmark.item[0] ? bookmark.item[0]._id : "item"
                      );
                    }}
                  >
                    <CardContent>
                      <Typography style={{fontWeight:"bold"}} gutterBottom>
                        <img
                          src="/assets/lists.png"
                          alt=""
                          style={{ height: "25px",marginRight:"1rem" }}
                        />
                        {bookmark.item.length > 0 ? (
                          <Fragment>
                            {bookmark.item[0]
                              ? bookmark.item[0].list_name &&
                                bookmark.item[0].list_name.length > 27
                                ? `${bookmark.item[0].list_name.substring(
                                    0,
                                    24
                                  )}...`
                                : bookmark.item[0].list_name
                              : bookmark.list[0].name}
                          </Fragment>
                        ) : null}
                      </Typography>
                      {bookmark.list.length > 0 ? (
                        <Fragment>
                          <span style={{marginLeft:"5px"}}>
                          {bookmark.list[0].name &&
                          bookmark.list[0].name &&
                          bookmark.list[0].name.length > 27
                            ? `${bookmark.list[0].name.substring(0, 24)}...`
                            : bookmark.list[0].name}
                          </span>
                        </Fragment>
                      ) : null}
                    </CardContent>
                  </Card>
                  bookmark.list[0].category
                  {/* <div className="list-category">{bookmark.list[0].name && `${bookmark.list[0].category}`}</div> */}
                </GridListTile>
              ))}
          </GridList>
        </div>

        {/* {this.state.lists &&
          this.state.lists.map((list, index) => (
            <Card key={index} style={{margin: '5px'}}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  {list.name}
                </Typography>
                <Typography variant="h5" component="h2">
                  {list.category}
                </Typography>
              </CardContent>
            </Card>
          ))} */}
      </InfiniteScroll>
      // </div>
    );
  }
}

const ListLoader = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col col-12 col-md-6 col-lg-4">
          <ListLoaderForDiscover />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <ListLoaderForDiscover />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <ListLoaderForDiscover />
        </div>
      </div>
      <div className="row">
        <div className="col col-12 col-md-6 col-lg-4">
          <ListLoaderForDiscover />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <ListLoaderForDiscover />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <ListLoaderForDiscover />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    myBookmarks: state.api.myBookmarks,
    user: state.auth,
  };
};

const mapDispatchToProps = {
  fetchUserProfileBookmarks,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBookmarks);
