const production = process.env.REACT_APP_DEVELOPMENT ? false : true;
const testing = process.env.REACT_APP_TESTING ? true : false;
const port = 5000;

// staging url.
const testingAPIUrl1 = "https://api-listiee.herokuapp.com/";

// staging url 2. Not in use.
const testingAPIUrl2 = "https://listiee-api-testing.herokuapp.com/";

// Current production url.
const productionUrl = "https://api-v2.listiee.com/";

// Old production url. Domain should be free, if not it will be pointing to
// digital ocean server that was used earlier for production backend.
const productionUrlOld = "https://api.listiee.com/";

export const API_HOSTNAME = production
  ? testing
    ? testingAPIUrl1
    : productionUrl
  : `http://localhost:${port}/`;

export const getApiUrl = url => `${API_HOSTNAME}${url}`;

export const COOKIE_AUTH = "auth";

//http://13.126.53.105/ https://api.listiee.com/
