import React, { useEffect } from "react";
import HomeIcon from '@material-ui/icons/Home';
import CircularProgress from "@material-ui/core/CircularProgress";

const AuthContainer = (props) => {
  const renderLoader = () => {
    if (props.loading)
      return (
        <CircularProgress
          disableShrink
          className={"custom-loader text-center mx-auto"}
          style={{ marginLeft: "auto", marginRight: "auto" }}
        />
      );
  };

  useEffect(() => {
    if (document.querySelector("header")) {
      document.querySelector("header").style.display = "none";
    }
  }, []);

  return (
    <div className=" login-modal-container">
          
          <div className = "home-icon">
              <a 
                onClick={() => {props.history.push("");}}
                title="Go Back to home.">
	            <HomeIcon  />
	            </a>
          </div>

      <div className="inner-login-container">
        <div className="row" style={{ margin: "0px" }}>      
          <div className="col-md-6">        
            <div className="landing">
              <div className="wrapper">
                <img src="/assets/landing_1.svg" alt="" />
              </div>
            </div>
          </div>
         
          <div className="col-md-6">
            <div className="login-modal-logo">
              <p>welcome to</p>
              <div>
		  <a href="/">
                      <img
			  style={{ height: "45px", marginBottom: "2px" }}
			  src={"/assets/logo.svg"}
			  alt={"Listiee"}
                      />
		  </a>
              </div>
              {renderLoader()}
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
