import React, { useState, Fragment } from "react";
import BookmarksRoundedIcon from "@material-ui/icons/BookmarksRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import BookmarkItem from "./BookmarkItem";

const BookmarkMenu = (props) => {
  const [isMenu, setIsMenu] = useState(false);
  const [showBookmarks, setShowBookmarks] = useState(false);
  const openMenu = () => {
    setIsMenu(true);
  };

  const closeMenu = () => {
    setIsMenu(false);
  };

  const showBookmarksMenu = () => {
    setShowBookmarks(!showBookmarks);
  };
  return (
    <Fragment>
      {
      props.bookmarks.length > 0 ? (
        <div className="openBtn" onClick={openMenu}>
          Bookmarks <ChevronRightRoundedIcon />
        </div>
      ) : null}
      <div className="blur" style={{ width: isMenu ? "250px" : "0px" }}>
      </div>
      <div className="bookmarkMenu" style={{ width: isMenu ? "250px" : "0px" }}>
        <div className="closebtn" onClick={closeMenu}>
          x
        </div>
        <div className="bookmark-menu-title mt-2 mb-3">
          <i className="fas fa-star" /> Bookmarks
        </div>
        <div className="hr"></div>
        {props.bookmarks.map((i, index) => (
          <BookmarkItem bookmark={i} index={index} />
        ))}
      </div>
    </Fragment>
  );
};

export default BookmarkMenu;
