import * as React from 'react';
import { PureComponent } from 'react';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';

export default class Pin extends PureComponent {
    render() {
        return (
            <RoomRoundedIcon style={{ color: "#5543ef" }} />
        );
    }
}