import {
  CREATE_NOTE,
  FETCH_MY_NOTES,
  DELETE_NOTE,
  DELETE_NOTE_LIST,
  EDIT_NOTE,
  EDIT_NOTE_LIST,
  FETCH_ALL_LIST_NOTES,
  CLOSE_NOTE_SIDE_BAR,
  OPEN_NOTE_SIDE_BAR,
  DROP_NOTE_BUTTON
} from "../constants/actionTypes";

const initialState = {
  myNotes: [],
  allNotes: [],
  NoteEditModal: false,
  noteSideBar: false,
  listDataForNote: {},
  error: ""
};

const noteReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NOTE:
      let note = action.payload;
      return {
        ...state,
        myNotes: [note, ...state.myNotes]
      };

    case FETCH_MY_NOTES:
      return {
        ...state,
        myNotes: action.payload
      };

    case DELETE_NOTE:
      return {
        ...state,
        myNotes: state.myNotes.filter(_note => _note._id !== action.payload)
      };
    
    case DELETE_NOTE_LIST:
      return {
        ...state,
        allNotes: state.allNotes.filter(_note => _note._id !== action.payload)
      };

    case EDIT_NOTE:
      let editedNote = action.payload;
      let updatedMyNotes = state.myNotes.map(note => {
        if (note._id === editedNote._id)
          return editedNote;
        else
          return note;
      })
      return {
        ...state,
        myNotes: updatedMyNotes
      };
    
    case EDIT_NOTE_LIST:
      editedNote = action.payload;
      let updatedAllNotes = state.allNotes.map(note => {
        if (note._id === editedNote._id)
          return editedNote;
        else
          return note;
        })
        return {
          ...state,
          allNotes: updatedAllNotes
      };

    case FETCH_ALL_LIST_NOTES:
      return {
        ...state,
        allNotes: action.payload
      };

    case OPEN_NOTE_SIDE_BAR:
      return {
        ...state,
        noteSideBar: true,
      };

    case DROP_NOTE_BUTTON:
      return {
        ...state,
        listDataForNote: action.payload
      };

    case CLOSE_NOTE_SIDE_BAR:
      return {
        ...state,
        noteSideBar: false,
      };

    default:
      return state;
  }
};

export default noteReducer;
