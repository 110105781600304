import React, { useState } from 'react';
import ClickOutside from 'react-click-outside';

const CollaboratorContainer = props => {
  const [isCollaborators, setIsCollaborators] = useState(false);
  const [isContributors, setIsContributors] = useState(false);
  const [collaborator, setCollaborator] = useState(null);

  const toggleCollaboratorMenu = () => {
    setIsCollaborators(!isCollaborators);
  };

  const toggleContributorMenu = () => {
    setIsContributors(!isContributors);
  };

  return (
    <div style={collaboratorStyle}>
      {/*Collaborators Menu */}
      <div className='collaborator-icon'>
        <i
          className='fas fa-users-cog '
          title='collaborators'
          onClick={toggleCollaboratorMenu}
          style={props.collaborators.length == 0 ? { cursor: 'pointer' } : null}
        />
      </div>

      <div className='collaborators-menu mt-3'>
        {props.collaborators.length > 0 ? (
          props.collaborators.map(i => (
            <CollabIconComponent imgSrc={i.avatar} collab={i} />
          ))
        ) : isCollaborators ? (
          <>
            <CollabIconComponent />
            <CollabIconComponent />
            <CollabIconComponent />
          </>
        ) : null}
      </div>

      {/*Contributors Menu */}
      {/*<div className='mt-5'>
        <div className='contributor-icon'>
          <i
            className='fas fa-users'
            title='contributors'
            onClick={toggleContributorMenu}
            style={
              props.contributors.length == 0 ? { cursor: 'pointer' } : null
            }
          />
        </div>

        <div className='contributors-menu mt-3'>
          {props.contributors.length > 0 ? (
            props.contributors.map(i => (
              <CollabIconComponent imgSrc='https://picsum.photos/200' />
            ))
          ) : isContributors ? (
            <>
              <CollabIconComponent />
              <CollabIconComponent />
              <CollabIconComponent />
            </>
          ) : null}
        </div>
      </div>*/}
    </div>
  );
};

export const CollabIconComponent = props => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const hideCollabModal = () => {
    setShowDetails(false);
  };

  return (
    <div className='collaborator-img' style={imgContainerStyle}>
      <ClickOutside onClickOutside={hideCollabModal}>
        {props.imgSrc ? (
          <img
            src={props.imgSrc}
            alt='random'
            style={imgStyle}
            onClick={toggleShowDetails}
          />
        ) : null}
        {showDetails ? <DetailCard collab={props.collab} /> : null}
      </ClickOutside>
    </div>
  );
};

export const DetailCard = props => {
  return (
    <div style={cardStyle}>
      <div className='row'>
        <div className='detail-logo col-sm-4'>
          <img
            className='detailCard_img'
            src={props.collab.avatar}
            alt='random'
            style={{ width: '50px', height: '50px' }}
          />
          <div className='detailCard_followBtn'>Follow</div>
        </div>

        <div className='col-sm-8'>
          <div className='mt-1 detailCard_info detailCard_info_title'>
            {props.collab.name}
          </div>
          <div className='mt-1 detailCard_info'>@{props.collab.user_name}</div>
          <div className='mt-3 detailCard_info'>
            {props.collab.bio ? props.collab.bio : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const cardStyle = {
  background: '#ffffffdd',
  boxShadow:
    '0 5px 15px -3px rgba(0, 0, 0, 0.22), 0 4px 6px -2px rgba(0,0,0,.05)',
  borderRadius: '10px',
  padding: '10px',
  width: '240px',
  position: 'absolute',
  right: '50px',
  zIndex: '999',
  marginTop: '-34px'
};

const collaboratorStyle = {
  position: 'absolute',
  right: '-40px'
};

const imgContainerStyle = {
  height: '25px',
  width: '25px',
  marginTop: '10px',
  background: '#f4f4f4',
  border: '2px solid #d9d9d9',
  marginTop: '10px',
  borderRadius: '50%'
};

const imgStyle = {
  height: '100%',
  width: '100%',
  cursor: 'pointer',
  borderRadius: '50%'
};

export default CollaboratorContainer;
