import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Admin from "../components/Admin";

const AdminContainer = (props) => {
  return ["", "normal","mender","amender"].includes(props.userType) ? (
    <Redirect to="/login" />
  ) : (
    <Admin {...props} />
  );
};

const mapStateToProps = (state) => {
  return {
    userType: state.auth.user_type,
  };
};

export default connect(mapStateToProps, {})(AdminContainer);
