import React, { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import ErrorAlert from "../Nested/ErrorAlert";
import { CREATE_ANNOUNCEMENT } from "../../constants/apiConstants";

const PublishAnnouncements = (props) => {
  let [visible, setVisibility] = useState(false);
  let [message, setMessage] = useState("");
  let [description, setDescription] = useState("");
  const [ShowAlert, setShowAlert] = useState(false);
  const [alertCode, setAlertCode] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      user_name: props.user_name,
      title: message,
      description: description,
      link: "",
    };
    console.log(data);
    let options = {
      headers: {
        "x-access-token": props.token,
      },
    };
    console.log(options);
    try {
      setShowAlert(true);
      setAlertCode("warning");
      setAlertMessage("Publishing Announcement...");
      let response = await axios.post(CREATE_ANNOUNCEMENT, data, options);
      console.log(response);
      setAlertCode("success");
      setAlertMessage("Announcement Published Succesfully");
      setMessage("");
      setVisibility(false);
    } catch (err) {
      setAlertCode("error");
      setAlertMessage("Announcement can't be published due to error");
      setShowAlert(true);
    }
  };

  const AutoHideSnackbar = () => {
    setShowAlert(false);
  };

  return (
    <Fragment>
      <ErrorAlert
        AutoHideSnackbar={AutoHideSnackbar}
        showAlert={ShowAlert}
        message={alertMessage}
        status={alertCode}
      />
      <IconButton
        className="scale-on-hover"
        onClick={() => {
          setVisibility(true);
        }}
      >
        <VolumeUpIcon />
      </IconButton>
      <Modal
        open={visible}
        // open={true}
        onClose={() => {
          setVisibility(false);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="announcement-modal">
          <div className="modal-header">
            <span>Publish An Announcement</span>
            <IconButton
              onClick={() => {
                setVisibility(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body-announcement">
            <form onSubmit={handleSubmit}>
              <TextField
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                label="Announcement"
                variant="outlined"
              />
              <TextField
                id="outlined-multiline-static"
                label="Description"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                value={description}
                multiline
                rows={6}
                variant="outlined"
              />

              <Button type="submit" variant="contained" color="primary">
                Publish
              </Button>
            </form>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default PublishAnnouncements;
