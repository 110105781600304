import React, { useEffect, useRef } from "react";
import PropType from "prop-types";
import moment from "moment";
const DetailedAnnouncements = ({ focusedIndex, announcements }) => {
  const focusedAnnouncement = useRef(null);
  useEffect(() => {
    if (!!focusedAnnouncement.current) {
      focusedAnnouncement.current.scrollIntoView(true);
      focusedAnnouncement.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [focusedIndex]);

  return (
    <div
      style={{
        width: "90%",
        marginTop: "2rem",
        maxWidth: "1000px",
        overflow: "wrap scroll",
      }}
    >
      {announcements.map((item, index) => {
        return (
          <div
            ref={index === focusedIndex ? focusedAnnouncement : null}
            key={index}
            style={{ marginBottom: "2rem" }}
          >
            <h2 style={{ fontFamily: "Poppins", color: "#303030" }}>
              {item.title}
            </h2>
            <div className="notif-time">
              {moment(moment(item.createdAt).utc()).fromNow()}
            </div>
            <div
              style={{
                fontFamily: "Poppins",
                lineHeight: "1.3em",
                color: "#303030",
              }}
            >
              {!item.description ? null : item.description}
            </div>
          </div>
        );
      })}
      <div style={{height:"2rem"}}></div>
    </div>
  );
};
DetailedAnnouncements.propType = {
  announcements: PropType.array,
};
export default DetailedAnnouncements;
