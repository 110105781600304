import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import UserNameIBtn from "../Nested/UserNameIBtn";
import {Redirect} from "react-router-dom";

import AuthContainer from "./AuthContainer";
import {
  HOME_PATH,
  getProfilePath,
  LOGIN_PATH,
} from "../../constants/pathConstants";
import ErrorAlert from "../Nested/ErrorAlert";
import SocialLogin from "./SocialLogin";
import OTPModal from "./OTPModal";
import HomeIcon from '@material-ui/icons/Home';

const Register = props => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [user_name, set_user_name] = useState("");
  const [name, setName] = useState("");
  const [password_signup, set_password_signup] = useState("");
  const [password_signup_conf, set_password_signup_conf] = useState("");
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: null, type: null });

  useEffect(() => {
    if (props.token) {
      if (props.location.state && props.location.state.redirect_to) {
        props.history.replace(props.location.state.redirect_to);
      } else {
        props.history.replace(HOME_PATH);
      }
    }
  }, [props.token]);

  const AutoHideSnackbar = () => {
    setAlert({
      show: false,
    });
  };

  const showSnackBar = (msg, type) => {
    setAlert({
      show: true,
      msg: msg,
      type: type,
    });
  };

  const onSubmitSignup = async e => {
    e.preventDefault();
    props.clearAuthError();

    // regex for password and email checking
    let pwdRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    // basic validation
    if (name === "") {
      showSnackBar("Please enter your name", "error");
      return;
    } else if (email === "") {
      showSnackBar("Please enter your email", "error");
      return;
    } else if (user_name === "") {
      showSnackBar("Please enter a new username", "error");
      return;
    } else if (user_name.length < 5) {
      showSnackBar("Username should be at least 5 characters", "error");
      return;
    } else if (password_signup === "") {
      showSnackBar("Please enter a new password", "error");
      return;
    } else if (password_signup_conf === "") {
      showSnackBar("Please confirm your new password", "error");
      return;
    } else if (password_signup !== password_signup_conf) {
      showSnackBar("Passwords do not match", "error");
      return;
    }

    // validating pasword and email using regexps
    else if (!pwdRegex.test(String(password_signup).trim())) {
      showSnackBar(
        "Password must contain atleast one capital letter, Special character and a numerical value",
        "error"
      );
      return;
    } else if (!emailRegex.test(String(email).trim())) {
      showSnackBar("Please enter a valid email address", "error");
      return;
    }

    // if there is no problem, only then send the request
    else {
      const data = {
        email,
        name,
        user_name,
        password: password_signup,
      };

      //registering the user and catching errors if any
      try {
        // In the locked version use a different register action.
        if (process.env.REACT_APP_SITE_LOCKED) {
          props.registerUserClosed(data);
        } else {
          props.registerUser(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <AuthContainer {...props}>
	{/* <a onClick={() => {props.history.push("");}}>
	    <HomeIcon style={{position: "fixed", top: "1.5vw", left: "15.5vw"}} />
	    </a> */}


      <div className={"modal_form"}>
        <ErrorAlert
          AutoHideSnackbar={AutoHideSnackbar}
          showAlert={alert.show}
          message={alert.msg}
          status={alert.type}
        />
        {process.env.REACT_APP_SITE_LOCKED ? null : props.emailSent ? (
          <OTPModal {...props} />
        ) : null}
        <form>
          <div className={"modal_form__field"}>
            <FormControl fullWidth>
              <InputLabel htmlFor="adornment-password">
                Enter your Name
              </InputLabel>
              <Input
                type={"text"}
                name={"name"}
                className={"custom-password-input"}
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </FormControl>
          </div>
          <div className={"modal_form__field"}>
            <FormControl fullWidth>
              <InputLabel htmlFor="adornment-password">
                Enter your email
              </InputLabel>
              <Input
                type={"email"}
                name={"email"}
                className={"custom-password-input"}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>
          </div>
          <div className={"modal_form__field"}>
            <FormControl fullWidth>
              <InputLabel htmlFor="adornment-password">
                Enter a new Username
              </InputLabel>
              <Input
                type={"text"}
                name={"user_name"}
                className={"custom-password-input"}
                value={user_name}
                onChange={e => set_user_name(e.target.value)}
              />
              <UserNameIBtn />
            </FormControl>
          </div>
          <div className={"modal_form__field"}>
            <FormControl fullWidth>
              <InputLabel htmlFor="adornment-password">
                Create a new Password
              </InputLabel>
              <Input
                type={showPassword ? "text" : "password"}
                name={"password_signup"}
                className={"custom-password-input"}
                value={password_signup}
                onChange={e => set_password_signup(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={e => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className={"modal_form__field"}>
            <FormControl fullWidth>
              <InputLabel htmlFor="adornment-password">
                Confirm Password
              </InputLabel>
              <Input
                type={showPassword ? "text" : "password"}
                name={"password_signup_conf"}
                className={"custom-password-input"}
                value={password_signup_conf}
                onChange={e => set_password_signup_conf(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={e => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div
            style={{
              position: "relative",
              height: "60px",
              width: "100%",
            }}>
            <Button
              className={"modal_form__button"}
              color="primary"
              variant={"contained"}
              type={"submit"}
              disabled={props.loading}
              onClick={e => onSubmitSignup(e)}
              style={{
                width: "100px",
                position: "absolute",
                right: "0px",
                fontWeight: "600",
                color: "#f2f2f2",
              }}>
              Submit
            </Button>
          </div>
          <div className="Signup_tagline m-1">
            Already have an account?{" "}
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                props.history.push(LOGIN_PATH);
              }}>
              Sign In
            </a>
          </div>
        </form>
        <SocialLogin {...props} />
          <p style={{textAlign: "center"}}>
            Interested in becoming a moderator at Listiee? Fill up this{" "}
            <a
              target="_blank"
              href="https://tinyurl.com/listcomune"
              title="Fill-up this form.">
              form.
            </a>
          </p>
          <p style={{textAlign: "center"}}>
            For Feedback and Queries we are live on{" "}
            <a
              target="_blank"
              href="https://discord.gg/gutd565yY7"
              title="Join our Discord Channel">
		Discord
            </a>
          </p>
      </div>
    </AuthContainer>
  );
};

export default Register;
