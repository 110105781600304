import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import PropTypes from "prop-types";
import {
  HOME_PATH,
  PROFILE_PATH,
  getProfilePath,
  LOGIN_PATH,
  SHARE_PATH,
} from "../constants/pathConstants";
// import LoginModal from "./LoginModal";
// import { VERIFY_API, VERIFY_FORGET_API } from "../constants/authConstants";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ErrorAlert from "./Nested/ErrorAlert";

/*start import right drawer*/
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

/*OutLined material icons */
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import Notes from "./Notes";
import moment from "moment";

import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import ShareAskLinkBtn from "./ShareAskLinkBtn";
import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import NotificationDrawer from "./NotificationDrawer/NotificationDrawer";
import Cookies from "js-cookie";
import { COOKIE_AUTH } from "../constants/baseConstants";
import PublishAnnouncements from "./PublishAnnouncements/PublishAnnouncements";
import FeedBackModal from "./FeedBackModal";
import UserInvitation from "./UserInvitation";
import $ from "jquery";


class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      query: "",
      // open: false,
      showAlert: false,
      alertMessage: "",
      alertCode: null,
      showNotificationBox: false,
      isUnreadNotifs: false,
      feedBackModalOpen: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { feedBackModalOpen } = this.state;
    this.setState({
      feedBackModalOpen: !feedBackModalOpen,
    });
  }

  //Snackbar auto-hide method
  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false,
    });
  };

  showSnackBar = (message, code) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
      alertCode: code,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (nextProps.authError !== "") {
        this.showSnackBar(nextProps.authError, "error");
      }
      if (nextProps.token) {
        // this.props.hideLoginModal();
        this.props.clearAuthError();
      }
    }
  }

  onUnload = async e => {
    e.preventDefault();
    this.setState({ feedBackModalOpen: true });
    e.returnValue = "testing";
    return "testing";
  };

  async componentDidMount() {
    // show the modal when the mouse leaves the webpage
    // $("html").bind("mouseleave", function () {

    //   this.setState({ feedBackModalOpen: true });
    //   alert("test")

    //     $("html").unbind("mouseleave");
    // }.bind(this));

    // only add the event listener if the feedback has not been given
    if (Cookies.get("visits") <= 5 && !Cookies.get("feedBackGiven")) {
      window.addEventListener("beforeunload", this.onUnload);
    }
    this.setState(
      { query: this.props.location.search.split("=")[1] },
      async () => {
        if (this.props.token) {
          this.props.getNotifications(
            { aid: this.props.aid },
            this.props.token
          );
          await this.props
            .fetchListv4({
              query: this.props.location.search.split("=")[1],
            })
            .then(() => {
              this.props.pageState({
                state: {
                  page: 1,
                  query: this.state.query,
                },
              });
              if (this.props.notifications.length) {
                let unread = this.props.notifications.filter(
                  notif => !notif.seen
                );
                if (unread.length > 0) {
                  this.setState({ isUnreadNotifs: true });
                } else {
                  this.setState({ isUnreadNotifs: false });
                }
              }
            });
        }
      }
    );
  }

  handleDrawerOpen = () => {
    if (!this.props.token)
      this.props.history.push(LOGIN_PATH, {
        redirect_to: HOME_PATH,
      });
    else {
      this.props.fetchMyNotes();
      this.props.toggleNoteSideBar();
    }
  };

  handleDrawerClose = () => {
    this.props.toggleNoteSideBar();
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onLogoutUser = async e => {
    e.preventDefault();
    this.setState({ feedBackModalOpen: true });

    this.props.history.push(LOGIN_PATH, { feedBack: true });
    await this.props.logoutUser();
  };

  //for search query
  handleChange = e => {
    var query = e.target.value;
    this.setState({
      query: query,
    });
  };

  //for search query
  async handleKeyDown(e) {
    if (e.keyCode == 13) {
      // this.props.history.push("/?query=" + this.state.query);
      let data = {
        aid: this.props.aid,
        email: this.props.email,
        query: this.state.query,
      };
      // this.setState({ isFetching: true });
      if (this.state.query) {
        await this.props.searchList(data, this.props.token).then(() => {
          this.props.pageState({
            state: {
              page: 1,
              query: this.state.query,
            },
          });
        });
      } else {
        await this.props.fetchListv4({}).then(() => {
          this.props.pageState({
            state: {
              page: 1,
              query: "",
            },
          });
        });
      }
    }
  }

  noteBtnDragStart = e => {
    // console.log("Drag start");
  };

  noteBtnDragEnd = e => {
    // console.log("Drag end");
    this.handleDrawerOpen();
  };

  toggleNotifBox = async () => {
    let data = {
      aid: this.props.aid,
    };

    this.props.getNotifications(data, this.props.token);
    this.setState({ showNotificationBox: !this.state.showNotificationBox });
    let unread = this.props.notifications.filter(notif => !notif.seen);
    if (unread.length > 0) {
      this.setState({ isUnreadNotifs: true });
    } else {
      this.setState({ isUnreadNotifs: false });
    }
  };

  handleViewNotif = async (e, id, link, seen) => {
    e.preventDefault();
    if (!seen) {
      let data = {
        aid: this.props.aid,
        notificationId: id,
      };

      let unread = this.props.notifications.filter(notif => !notif.seen);
      if (unread.length <= 1) {
        this.setState({ isUnreadNotifs: false });
      } else {
        this.setState({ isUnreadNotifs: true });
      }

      await this.props.markReadNotif(data, this.props.token);
    }

    let urlReg = /.+?\:\/\/.+?(\/.+?)(?:#|\?|$)/;
    let splittedUrl = urlReg.exec(link);

    if (link && link.includes("app.listiee.com")) {
      window.location.pathname = splittedUrl[1];
    } else if (link) {
      window.location.href = link;
    }
  };

  handleMarkAllRead = async () => {
    let data = {
      aid: this.props.aid,
    };
    await this.props.markAllReadNotif(data, this.props.token);
    this.setState({ isUnreadNotifs: false });
    this.props.getNotifications(data, this.props.token);
  };

  handleFeedBackClick = e => {
    Cookies.set("feedBackGiven", true, { expires: 730 });
  };

  render() {
    const { anchorEl } = this.state;

    const {
      token,

      classes,
      theme,
    } = this.props;

    return (
      <Fragment>
        <ErrorAlert
          AutoHideSnackbar={this.AutoHideSnackbar}
          showAlert={this.state.showAlert}
          message={this.state.alertMessage}
          status={this.state.alertCode}
        />

        <AppBar
          position="static"
          color="transparent"
          style={{ alignItems: "center" }}>
          <Toolbar
            style={{
              width: "100%",
              maxWidth: "1100px",
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Link to={HOME_PATH}>
              <div className="discover-head">
                {["/", "/discover"].includes(this.props.location.pathname) ||
                this.props.location.pathname.startsWith("/list") ? (
                  <div className="discover_text">You are discovering @</div>
                ) : null}
                <img src="/assets/logo.svg" alt="home" height="30px" />
              </div>
            </Link>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}>
              {["/share", "/ask"].includes(this.props.location.pathname) ? (
                <ShareAskLinkBtn {...this.props} />
              ) : (
                <Fragment>
                  {token ? <UserInvitation token={this.props.token} logoutUser={this.props.logoutUser} /> : null}
                  <Drawer
                    variant="persistent"
                    anchor="right"
                    open={this.props.noteSideBar}
                    // className="right-drawer"
                  >
                    <div className={"drawerHeader"}>
                      <IconButton onClick={this.handleDrawerClose}>
                        <ChevronRightIcon />
                      </IconButton>
                    </div>
                    <Divider />
                    <Notes
                      mode="my"
                      listDataForNote={this.props.listDataForNote}
                      {...this.props}
                    />
                  </Drawer>

                  <Box className="d-flex justify-content-end">
                    {window.location.pathname === "/admin" ? (
                      <PublishAnnouncements
                        user_name={this.props.user_name}
                        token={this.props.token}
                      />
                    ) : null}
                    <IconButton
                      className="scale-on-hover"
                      onClick={() => this.props.history.push(SHARE_PATH)}
                      title="Create a List"
                      size="small">
                      <AddIcon fontSize={"large"} />
                    </IconButton>
                    {!this.props.token ? null : (
                      <Fragment>
                        <IconButton
                          className="scale-on-hover"
                          onDragStart={this.noteBtnDragStart}
                          onDragEnd={this.noteBtnDragEnd}
                          title="Add Note"
                          size="small"
                          onClick={this.handleDrawerOpen}>
                          <img
                            style={{ height: "24px" }}
                            src={`${process.env.PUBLIC_URL}/assets/NotesIcon.svg`}
                          />
                        </IconButton>

                        <NotificationDrawer />
                      </Fragment>
                    )}
                  </Box>
                </Fragment>
              )}
              {!token ? (
                <Box my={1} className="align-content-center" title="Profile">
                  <Link to="/register">
                    <Button size="small">SignUp</Button>
                  </Link>
                </Box>
              ) : (
                <Box title="Profile">
                  <img
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    onClick={this.handleClick}
                    className="scale-on-hover nav-button"
                    title="Profile"
                    src={
                      this.props.avatar
                        ? this.props.avatar
                        : "assets/nfound.png"
                    }
                  />
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    style={{ marginTop: "3vw", zIndex: "999999" }}
                    transformOrigin={{
                      horizontal: "center",
                      vertical: "center",
                    }}>
                    <Link
                      /* getting the username from the user details cookie */
                      /* Now it will no longer redirect to the old username after changing to a new username */
                      to={getProfilePath(
                        JSON.parse(Cookies.get(COOKIE_AUTH)).user_name
                      )}
                      onClick={e => this.setState({ anchorEl: null })}>
                      <MenuItem>
                        <ListItemIcon>
                          <PersonOutlineOutlinedIcon className="outlined-icons" />
                        </ListItemIcon>
                        <ListItemText primary="Profile" />
                      </MenuItem>
                    </Link>

                    <Link
                      to="/profile-setting"
                      onClick={this.handleClose}
                      onClick={e => this.setState({ anchorEl: null })}>
                      <MenuItem>
                        <ListItemIcon>
                          <SettingsOutlinedIcon className="outlined-icons" />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                      </MenuItem>
                    </Link>

                    {/* <Link to="/message">
                          <MenuItem>
                            <ListItemIcon>
                              <EmailOutlinedIcon className="outlined-icons" />
                            </ListItemIcon>
                            <ListItemText primary="Message" />
                          </MenuItem>
                        </Link> */}

                    <span
                      color="inherit"
                      onClick={e => this.onLogoutUser(e)}
                      style={{ color: "rgba(0,0,0,0.87)" }}>
                      <a href="#">
                        <MenuItem>
                          <ListItemIcon>
                            <PowerSettingsNewOutlinedIcon className="outlined-icons" />
                          </ListItemIcon>
                          <ListItemText primary="Logout" />
                        </MenuItem>
                      </a>
                    </span>
                  </Menu>
                </Box>
              )}
            </div>
          </Toolbar>
        </AppBar>

        {/* refactored feedback modal */}
        {this.state.feedBackModalOpen &&
          Cookies.get("visits") <= 5 &&
          !Cookies.get("feedBackGiven") && (
            <FeedBackModal
              feedBackModalOpen={true}
              handleFeedBackClick={this.handleFeedBackClick}
            />
          )}

        {/* old */}

        {/* Modal For feedback which directs to google form
         but there is no way to access it from front end */}
        {/* <Modal isOpen={this.state.feedBackModalOpen} toggle={this.toggle}> */}
        {/*   <ModalHeader className="h5"> */}
        {/*     Thank you for your participation and join us early! */}
        {/*   </ModalHeader> */}
        {/*   <ModalBody> */}
        {/*     <p> */}
        {/*       Please fill up this form to help us understand how we are doing */}
        {/*       with the platform. */}
        {/*     </p> */}
        {/*   </ModalBody> */}
        {/*   <ModalFooter> */}
        {/*     <a */}
        {/*       href="https://docs.google.com/forms/d/14jy2u46erBWcTO7oM7OqQo4JYVjfv4cKKOnu2F4zs8c/viewform?edit_requested=true" */}
        {/*       className="btn btn-primary" */}
        {/*       target="_blank" */}
        {/*     > */}
        {/*       Send Feedback */}
        {/*     </a>{" "} */}
        {/*   </ModalFooter> */}
        {/* </Modal> */}
      </Fragment>
    );
  }
}

Nav.propTypes = {
  token: PropTypes.string.isRequired,
  aid: PropTypes.string.isRequired,
};

export default Nav;
