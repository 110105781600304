import React, { Fragment, useState, useEffect } from "react";
// import Button from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import moment from "moment";
import { connect } from "react-redux";
import { markReadNotif, markAllReadNotif } from "../../actions/apiActions";

const NotificationDrawer = (props) => {
  let [visibility, setVisibility] = useState(false);
  let [unseenCount, setUnseenCount] = useState(0);
  let [currentTab, setCurrentTab] = useState("Popularity");
  let [tabbedNotif, setTabbedNotif] = useState({});
  let [tabbedUnseenCount, setTabbedUnseenCount] = useState({});

  const filterFunctions = {
    Popularity: (notification) => {
      if (
        !notification.admin_announcement &&
        (notification.title.includes("views") ||
          notification.title.includes("follow") ||
          notification.title.includes("promoted") ||
          notification.title.includes("received") ||
          notification.title.includes("replied") ||
          notification.title.includes("request") ||
          notification.title.includes("report") ||
          notification.title.includes("platform") ||
          notification.title.includes("online") ||
          notification.title.includes("anchor") ||
          notification.title.includes("review") ||
          notification.title.includes("suspect"))
      ) {
        return notification;
      }
    },
    Collaborations: (notification) => {
      if (
        !notification.admin_announcement &&
        (notification.title.includes("volunteer") ||
          notification.title.includes("Volunteer") ||
          notification.title.includes("collaborator") ||
          notification.title.includes("contributor"))
      ) {
        return notification;
      }
    },
    Edits: (notification) => {
      if (
        !notification.admin_announcement &&
        notification.title.includes("edit")
      ) {
        return notification;
      }
    },
    Announcements: (notification) => {
      if (!!notification.admin_announcement) {
        return notification;
      }
    },
  };

  const tabContent = {
    Popularity: "Popularity",
    Collaborations: "Collaborations",
    Edits: "Edits",
    Announcements: <img src="/assets/lists.png" alt="" class="list-icon" />,
  };

  useEffect(() => {
    let categorizedNotif = {};
    let categorizedUnseenCount = {};
    ["Popularity", "Collaborations", "Edits", "Announcements"].forEach(
      (item) => {
        categorizedNotif[item] = props.notifications.filter(
          filterFunctions[item]
        );
        categorizedUnseenCount[item] = countUnseenLength(
          categorizedNotif[item]
        );
      }
    );
    setTabbedNotif(categorizedNotif);
    setTabbedUnseenCount(categorizedUnseenCount);
  }, [props.notifications]);

  const countUnseenLength = (notifications) => {
    if (!notifications) return 0;
    return notifications
      .map((item) => {
        return !item.seen;
      })
      .filter(Boolean).length;
  };
  useEffect(() => {
    setUnseenCount(countUnseenLength(props.notifications));
  }, [props.notifications]);
  const toggleNotifBox = async () => {
    setVisibility(!visibility);
  };

  const handleViewNotif = async (e, id, link, seen) => {
    e.preventDefault();
    if (!seen) {
      let data = {
        aid: props.accessId,
        notificationId: id,
      };
      await props.markReadNotif(data, props.token);
    }

    let urlReg = /.+?\:\/\/.+?(\/.+?)(?:#|\?|$)/;
    let splittedUrl = urlReg.exec(link);

    if (link && link.includes("app.listiee.com")) {
      window.location.pathname = splittedUrl[1];
    } else if (link) {
      window.location.href = link;
    }
  };
  const handleMarkAllRead = async () => {
    let data = {
      aid: props.accessId,
    };
    await props.markAllReadNotif(data, props.token);
    setUnseenCount(0);
  };
  // console.log(JSON.stringify(props.notifications));
  return (
    <Fragment>
      <IconButton
        className="scale-on-hover"
        title="Notification"
        size="small"
        onClick={toggleNotifBox}
      >
        {/* {console.log("check")} */}
        <Badge
          variant="dot"
          color="primary"
          invisible={unseenCount > 0 ? false : true}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Drawer variant="persistent" anchor="right" open={visibility}>
        <div className={"drawerHeader"}>
          <IconButton onClick={toggleNotifBox}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <div className="modal-dialog notif_container">
          <div className="modal-content" style={{ border: "none" }}>
            <div className="modal-header">
              <h4 className="modal-title notif-header-title" id="ModalLabel">
                Notifications
              </h4>
              {unseenCount > 0 ? (
                <span>
                  <i
                    className="fas fa-envelope-open-text"
                    style={{ fontSize: "25px" }}
                    onClick={handleMarkAllRead}
                  />
                </span>
              ) : null}
            </div>
            <div className="Notification-Tabs">
              {["Announcements", "Popularity", "Collaborations", "Edits"].map(
                (category) => {
                  return (
                    <button
                      className={`Tab ${
                        currentTab === category ? "current-Tab" : ""
                      }`}
                      style={
                        category === "Announcements"
                          ? { width: "auto", padding: "0 1rem" }
                          : {}
                      }
                      onClick={() => {
                        setCurrentTab(category);
                      }}
                    >
                      <Badge
                        variant="dot"
                        color="primary"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        invisible={
                          tabbedUnseenCount[category] > 0 ? false : true
                        }
                      >
                        {tabContent[category]}
                      </Badge>
                    </button>
                  );
                }
              )}
            </div>
          </div>
        </div>

        {props.notifications.length === 0 ? (
          <h4 className="mx-auto no-notif">No Notifications!!</h4>
        ) : (
          <Fragment>
            {!!tabbedNotif ? (
              <Fragment>
                {
                  // tabbedNotif[currentTab]
                  props.notifications
                    .filter(filterFunctions[currentTab])
                    .map((notif, i) => (
                      <div
                        key={i}
                        className="card notif_card"
                        style={notif.seen ? null : { background: "#deffe5" }}
                      >
                        <div
                          className="card-design"
                          onClick={(e) =>
                            handleViewNotif(
                              e,
                              notif._id,
                              notif.link,
                              notif.seen
                            )
                          }
                        >
                          <MailOutlineRoundedIcon
                            style={{ color: "#a9a9a9" }}
                          />
                          <div className="vl"></div>
                          <div className="notif-title-wrapper">
                            <div style={{ width: "300px" }}>{notif.title}</div>
                            <div className="team-tag">Team Listiee</div>
                            <div className="notif-time">
                              {moment(moment(notif.createdAt).utc()).fromNow()}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                }
              </Fragment>
            ) : null}
          </Fragment>
        )}
      </Drawer>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    notifications: state.api.notifications,
    accessId: state.auth.aid,
    token: state.auth.token,
  };
};

const mapDispatchToProps = {
  markReadNotif,
  markAllReadNotif,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDrawer);
