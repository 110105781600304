import React, { useState } from "react";

const ToggleBtn = (props) => {
  // const [isChecked, setIsChecked] = useState(false);

  // const toggleCheckBtn = () => {
  //   setIsChecked(!isChecked);
  // };

  return (
    <div className="toggle-switch">
      <label className="switch">
        <input
          type="checkbox"
          checked={props.isChecked}
          onChange={() => props.toggleChange()}
          disabled={props.isDisabled}
        />
        <span className="slider round">
          {props.isChecked ? (
            <i
              className={
                props.isDisabled
                  ? "fas fa-lock toggle-checked"
                  : "fas fa-arrow-left toggle-checked"
              }
            />
          ) : (
            <i
              className={
                props.isDisabled
                  ? "fas fa-lock toggle-unchecked"
                  : "fas fa-arrow-right toggle-unchecked"
              }
            />
          )}
        </span>
      </label>
    </div>
  );
};

export default ToggleBtn;
