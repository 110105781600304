import axios from "axios";

import {
    CREATE_PROSPECTIVE,
    CREATE_PROSPECTIVE_ITEM,
    EDIT_PROSPECTIVE,
    FETCH_PROSPECTIVE,
    DELETE_PROSPECTIVE,
    PROSPECTIVE_ERROR,
    CLEAR_PROSPECTIVE_ERROR,
    LOGOUT_USER
} from "../constants/actionTypes";

import {
    CREATE_PROSPECTIVE_API,
    CREATE_PROSPECTIVE_ITEM_API,
    EDIT_PROSPECTIVE_API,
    FETCH_PROSPECTIVE_API,
    DELETE_PROSPECTIVE_API
} from "../constants/apiConstants";

export const createProspective = (data, token) => async dispatch => {
    const reqOptions = {
        method: "post",
        url: CREATE_PROSPECTIVE_API,
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token
        }
    };
    dispatch({ type: CLEAR_PROSPECTIVE_ERROR });
    await axios(reqOptions)
        .then(res => {
            dispatch({
                type: CREATE_PROSPECTIVE,
                payload: res.data.data
            });
        })
        .catch(error => {
            console.log("error: ", error);
            if (error.response) {
                if (error.response.data.code === 1500) {
                    dispatch({ type: LOGOUT_USER });
                    window.location.reload();
                } else {
                    dispatch({
                        type: PROSPECTIVE_ERROR,
                        payload: error.response.data.message
                    });
                }
            } else {
                console.log("ERROR: something went wrong");
                dispatch({
                    type: PROSPECTIVE_ERROR,
                    payload: error.message
                });
            }
        });
};

export const createProspectiveItem = (data, token) => async dispatch => {
    const reqOptions = {
        method: "post",
        url: CREATE_PROSPECTIVE_ITEM_API,
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token
        }
    };
    dispatch({ type: CLEAR_PROSPECTIVE_ERROR });
    await axios(reqOptions)
        .then(res => {
            dispatch({
                type: CREATE_PROSPECTIVE_ITEM,
                payload: res.data.data
            });
        })
        .catch(error => {
            console.log("error: ", error);
            if (error.response) {
                if (error.response.data.code === 1500) {
                    dispatch({ type: LOGOUT_USER });
                    window.location.reload();
                } else {
                    dispatch({
                        type: PROSPECTIVE_ERROR,
                        payload: error.response.data.message
                    });
                }
            } else {
                console.log("ERROR: something went wrong");
                dispatch({
                    type: PROSPECTIVE_ERROR,
                    payload: error.message
                });
            }
        });
};

export const editProspective = (data, token) => async dispatch => {
    const reqOptions = {
        method: "post",
        url: EDIT_PROSPECTIVE_API,
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token
        }
    };
    dispatch({ type: CLEAR_PROSPECTIVE_ERROR });
    await axios(reqOptions)
        .then(res => {
            dispatch({
                type: EDIT_PROSPECTIVE,
                payload: res.data.data
            });
        })
        .catch(error => {
            console.log("error: ", error);
            if (error.response) {
                if (error.response.data.code === 1500) {
                    dispatch({ type: LOGOUT_USER });
                    window.location.reload();
                } else {
                    dispatch({
                        type: PROSPECTIVE_ERROR,
                        payload: error.response.data.message
                    });
                }
            } else {
                console.log("ERROR: something went wrong");
                dispatch({
                    type: PROSPECTIVE_ERROR,
                    payload: error.message
                });
            }
        });
};

export const fetchProspective = (data, token) => async dispatch => {
    const reqOptions = {
        method: "post",
        url: FETCH_PROSPECTIVE_API,
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token
        }
    };
    dispatch({ type: CLEAR_PROSPECTIVE_ERROR });
    await axios(reqOptions)
        .then(res => {
            dispatch({
                type: FETCH_PROSPECTIVE,
                payload: res.data.data
            });
        })
        .catch(error => {
            console.log("error: ", error);
            if (error.response) {
                if (error.response.data.code === 1500) {
                    dispatch({ type: LOGOUT_USER });
                    window.location.reload();
                } else {
                    dispatch({
                        type: PROSPECTIVE_ERROR,
                        payload: error.response.data.message
                    });
                }
            } else {
                console.log("ERROR: something went wrong");
                dispatch({
                    type: PROSPECTIVE_ERROR,
                    payload: error.message
                });
            }
        });
};

export const deleteProspective = (data, token) => async dispatch => {
    const reqOptions = {
        method: "post",
        url: DELETE_PROSPECTIVE_API,
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token
        }
    };
    dispatch({ type: CLEAR_PROSPECTIVE_ERROR });
    await axios(reqOptions)
        .then(res => {
            dispatch({
                type: DELETE_PROSPECTIVE,
                payload: res.data.data
            });
        })
        .catch(error => {
            console.log("error: ", error);
            if (error.response) {
                if (error.response.data.code === 1500) {
                    dispatch({ type: LOGOUT_USER });
                    window.location.reload();
                } else {
                    dispatch({
                        type: PROSPECTIVE_ERROR,
                        payload: error.response.data.message
                    });
                }
            } else {
                console.log("ERROR: something went wrong");
                dispatch({
                    type: PROSPECTIVE_ERROR,
                    payload: error.message
                });
            }
        });
};
