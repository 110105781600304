import React from 'react';
import { connect } from 'react-redux';
import Root from '../components/Root';
import {
  askQuestion,
  fetchList,
  fetchListv3,
  fetchListv4,
  fetchDraftListv4,
  fetchListById,
  clearCurrentListDataFromStore,
  addCollaborator,
  deleteCollab,
  respondInvite,
  saveList,
  saveListItem,
  deleteList,
  deleteListItem,
  upvoteListItem,
  bookmarkListItem,
  fetchUserBookmarks,
  fetchAllUserBookmarks,
  fetchFollowingUserList,
  followUser,
  unFollowUser,
  increaseView,
  editProfile,
  fetchProfile,
  pageState,
  changeUserName,
  changePassword,
  fetchPeople,
  fetchTags,
  saveListVersionThree,
  editList,
  fetchBookmarkOfList,
  deletebookmarkListItem,
  deleteUpvoteListItem,
  fetchBoards,
  deleteFolder,
  deleteBoard,
  changeBoardVisibility,
  addFolder,
  renameFolder,
  makeFolderPrivate,
  editFolder,
  addBoard,
  addListToFolder,
  fetchStack,
  fetchFolderItems,
  updateFolder,
  fetchFldrItemDetails,
  fetchRequests,
  fetchRequestReplies,
  replyToAsk,
  getPublicProfile,
  getNotifications,
  markReadNotif,
  markAllReadNotif,
  reportList,
  searchList,
  promoteList,
  addListItem,
  deleteAsked,
  fetchUsersBookmarked,
  bookmarkUser,
  unBookmarkUser,
  fetchUserLists,
  fetchUserProfileBookmarks,
  fetchUserListsWhereCollab,
  renameBoard,
  pinStackItem,
  deleteStackItem,
  setRefreshDiscover,
  unsetRefreshDiscover,
  fetchListByIdPublic,
  getRecommendation,
  checkboxListItem,
  changeEmail,
  sendVerifyEmail,
  verifyEmailOTP,
  makeListSuspected,
  respondSuspectedList,
  updateSuspectedListStatus,
} from '../actions/apiActions';

import {
  createNote,
  fetchMyNotes,
  deleteNote,
  editNote,
  fetchAllListNotes,
  toggleNoteSideBar,
  handleNoteButtonDrop,
} from '../actions/noteActions';

import {
  fetchAllComments,
  createComment,
  editComment,
  deleteComment,
} from '../actions/commentActions';

import {
  createProspective,
  createProspectiveItem,
  editProspective,
  fetchProspective,
  deleteProspective,
} from '../actions/prospectiveActions';

import {
  clearAskError,
  showLoginModal,
  hideLoginModal,
  toggleLoginModal,
} from '../actions/baseActions';

import {
  clearAuthError,
  loginUser,
  logoutUser,
  registerUser,
  registerUserClosed, 
  toggleEmailVerified,
  retrySignup,
  verifyOTP,
  resendOtp,
  forgetPassword,
  resetPassword,
  googleLogin,
  linkedinLogin,
  twitterLogin,
} from '../actions/authActions';

const RootContainer = (props) => <Root {...props} />;

const mapStateToProps = (state) => {
  return {
    lists: state.api.lists,
    draftlists: state.api.draftlists,
    myLists: state.api.myLists,
    allUserBookmarks:state.api.allUserBookmarks,
    fetchedList: state.api.fetchedList,
    recommendedList: state.api.recommendedList,
    searchedLists: state.api.searchedLists,
    profile: state.api.profile,
    followingList: state.api.followingList,
    following: state.api.following,
    createdList: state.api.createdList,
    bookmarks: state.api.bookmarks,
    listItems: state.api.listItems,
    people: state.api.people,
    tags: state.api.tags,
    email: state.auth.email,
    token: state.auth.token,
    aid: state.auth.aid,
    avatar: state.auth.avatar,
    user_name: state.auth.user_name,
    user_type: state.auth.user_type,
    name: state.auth.name,
    bio: state.auth.bio,
    authError: state.error.authError,
    city: state.auth.city,
    state: state.auth.state,
    country: state.auth.country,
    askError: state.error.askError,
    folders: state.api.folders,
    createdFolder: state.api.createdFolder,
    editedFolderDetails: state.api.editedFolderDetails,
    boards: state.api.boards,
    createdBoard: state.api.createdBoard,
    editedFolder: state.api.editedFolder,
    folderItems: state.api.folderItems,
    updatedFolder: state.api.updatedFolder,
    renamedFolder: state.api.renamedFolder,
    makeFolderPrivate: state.api.makeFolderPrivate,
    folderItemDetails: state.api.folderItemDetails,
    requests: state.api.requests,
    replies: state.api.replies,
    reply: state.api.reply,
    pstate: state.api.pstate,
    myNotes: state.noteReducer.myNotes,
    allNotes: state.noteReducer.allNotes,
    noteSideBar: state.noteReducer.noteSideBar,
    listDataForNote: state.noteReducer.listDataForNote,
    publicProfile: state.api.publicProfile,
    notifications: state.api.notifications,
    collab: state.api.collab,
    deletedCollab: state.api.deletedCollab,
    respondedCollab: state.api.respondedCollab,
    reportedListMessage: state.api.reportedListMessage,
    changePasswordMessage: state.api.changePasswordMessage,
    checkPasswordMessage: state.api.checkPasswordMessage,
    changeEmailMessage: state.api.changeEmailMessage,
    sendVerifyEmailMessage: state.api.sendVerifyEmailMessage,
    usersBookmarked: state.api.usersBookmarked,
    addListItemMessage: state.api.addListItemMessage,
    addedListItem: state.api.addedListItem,
    removedListItem: state.api.removedListItem,
    editProfileMessage: state.api.editProfileMessage,
    prospectives: state.prospective.prospectives,
    prospectiveItems: state.prospective.prospectiveItems,
    editProspective: state.prospective.editProspective,
    deleteProspective: state.prospective.deleteProspective,
    createProspective: state.prospective.createProspective,
    prospectiveError: state.prospective.prospectiveError,
    userLists: state.api.fetchUserLists,
    userBookmarks: state.api.fetchUserBookmarks,
    userListsWhereCollab: state.api.fetchUserListsWhereCollab,
    fetchedStack: state.api.fetchedStack,
    renamedBoard: state.api.renamedBoard,
    deletedAsked: state.api.deletedAsked,
    refreshDiscover: state.api.refreshDiscover,
    makeListSuspectedMessage: state.api.makeListSuspectedMessage,
    respondSuspectedListMessage: state.api.respondSuspectedListMessage,
    updateSuspectedListStatusMessage:
    state.api.updateSuspectedListStatusMessage,

    authError: state.error.authError,
    loading: state.auth.loading,
    emailSent: state.auth.emailSent,
    resetPasswordBool: state.auth.resetPasswordBool,
    verify_email_type: state.auth.verify_email_type,
    comments: state.commentReducer.comments,
    commentsReplys: state.commentReducer.commentsReplys,
  };
};

const mapDispatchToProps = {
  saveList,
  saveListItem,
  deleteList,
  deleteListItem,
  upvoteListItem,
  bookmarkListItem,
  deletebookmarkListItem,
  fetchUserBookmarks,
  fetchAllUserBookmarks,
  fetchList,
  fetchListv3,
  fetchListv4,
  fetchDraftListv4,
  fetchListById,
  searchList,
  clearCurrentListDataFromStore,
  addCollaborator,
  deleteCollab,
  respondInvite,
  fetchFollowingUserList,
  followUser,
  unFollowUser,
  increaseView,
  showLoginModal,
  askQuestion,
  clearAskError,
  editProfile,
  fetchProfile,
  fetchTags,
  changeUserName,
  changePassword,
  fetchPeople,
  saveListVersionThree,
  editList,
  fetchBookmarkOfList,
  deleteUpvoteListItem,
  fetchBoards,
  deleteFolder,
  deleteBoard,
  changeBoardVisibility,
  addFolder,
  renameFolder,
  makeFolderPrivate,
  editFolder,
  addBoard,
  addListToFolder,
  fetchFolderItems,
  updateFolder,
  fetchFldrItemDetails,
  fetchRequests,
  fetchRequestReplies,
  replyToAsk,
  pageState,
  createNote,
  fetchMyNotes,
  deleteNote,
  editNote,
  fetchAllListNotes,
  handleNoteButtonDrop,
  fetchAllComments,
  createComment,
  editComment,
  deleteComment,
  toggleNoteSideBar,
  getPublicProfile,
  getNotifications,
  markReadNotif,
  markAllReadNotif,
  reportList,
  promoteList,
  bookmarkUser,
  unBookmarkUser,
  fetchUsersBookmarked,
  addListItem,
  createProspective,
  createProspectiveItem,
  editProspective,
  fetchProspective,
  deleteProspective,
  fetchUserLists,
  fetchUserProfileBookmarks,
  fetchUserListsWhereCollab,
  fetchStack,
  renameBoard,
  pinStackItem,
  deleteStackItem,
  deleteAsked,
  setRefreshDiscover,
  unsetRefreshDiscover,
  fetchListByIdPublic,
  getRecommendation,
  checkboxListItem,
  changeEmail,
  sendVerifyEmail,
  verifyEmailOTP,
  makeListSuspected,
  respondSuspectedList,
  updateSuspectedListStatus,

  loginUser,
  registerUser,
  registerUserClosed, 
  logoutUser,
  verifyOTP,
  resendOtp,
  clearAuthError,
  toggleLoginModal,
  toggleEmailVerified,
  showLoginModal,
  hideLoginModal,
  forgetPassword,
  resetPassword,
  googleLogin,
  linkedinLogin,
  twitterLogin,
  retrySignup,
};
export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
