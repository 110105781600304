import React, { Component } from 'react'

export default class SearchResults extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    render(){
        return(
            <h1 className="tets">Search Results</h1>
        );
    }
}