import axios from "axios";
import React,{useState} from "react";
// import urlMetadata from "url-metadata";
export const getImageUrlsArray = inputArray =>
  inputArray
    .split(",")
    .map(item => item.trim())
    .filter(item => item !== "");

export const getLinkUrlsArray = inputArray => {
  const resultArray = inputArray
    .split("\n")
    .map(item => item.trim().split(" "));
  return resultArray.map(item => {
    let obj = {
      value: "",
      link: "",
    };
    if (item.length !== 2) {
      return "";
    } else {
      obj.value = item[0];
      obj.link = item[1];
    }
    return obj;
  });
};

export const getRegion = cb => {
  axios.get(`https://api.ipify.org/?format=json`).then(ip => {
    axios
      .get(`http://ip-api.com/json/` + ip.data.ip)
      .then(data => {
        cb(data.data, null);
      })
      .catch(err => {
        throw err;
      });
  });
};

export const getRegionAsync = async () => {
  try {
    const result = await axios.get(
      `https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

export const getFeviconLink = link => {
  const url = new URL(link);
  return url.origin + "/favicon.ico";
};

export const whichBrowser = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) != -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") != -1 ||
    !!document.documentMode == true
  ) {
    //IF IE > 10
    return "IE";
  } else {
    return "unknown";
  }
};

export const handleInputFocusTransfer = (e, focussableClass) => {
  // This method move focus to next item input on DOWN or to previous item input on UP arrow key press.
  const focusableInputElements = document.querySelectorAll(
    `.${focussableClass}`
  );
  const focusable = [...focusableInputElements];
  const index = focusable.indexOf(document.activeElement);
  let nextIndex = 0;

  if (e.keyCode === 38) {
    // up arrow
    e.preventDefault();
    nextIndex = index > 0 ? index - 1 : 0;
    focusableInputElements[nextIndex].focus();
  } else if (e.keyCode === 40) {
    // down arrow
    e.preventDefault();
    nextIndex = index + 1 < focusable.length ? index + 1 : index;
    focusableInputElements[nextIndex].focus();
  }
};

export const otherLink = linkString => {
  let linkArray = [];
  if (linkString.indexOf(",") > -1) {
    linkArray = linkString.split(",");
  } else {
    linkArray = linkString.split("\n");
  }

  return linkArray.map(link => {
    link = link.startsWith("http") ? link : `http://${link}`;
    const linkURL = link.split("/");
    let domainURL = linkURL[2];
    let icon = `http://${domainURL}/favicon.ico`;
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: "flex" }}>
        <img
          src={icon}
          width="20px"
          height="20px"
          onError={e => {
            e.target.onerror = null;
            e.target.src = "/assets/default-logo-www.png";
          }}
          alt=""
        />
        &nbsp;
        <p className="lp-link" title={link}>
          {link}
        </p>
      </a>
    );
  });
};

export const reverseGeocode = async (lat, long) => {
  try {
    const response = await axios.get(
      `https://us1.locationiq.com/v1/reverse.php?key=${process.env.REACT_APP_LOCATIONIQ_TOKEN}&lat=${lat}&lon=${long}&format=json`
    );
    return response.data.address;
  } catch (err) {
    console.error(err);
    return;
  }
};

// Custom react hook for forms
export const useForm = (initial) => {
    const [values, setValues] = useState(initial);

    return [
        values,
        (e) => {
            setValues({
                ...values,
                [e.target.name]: e.target.value,
            });
        },
    ];
};

