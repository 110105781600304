import React, { useState } from "react";
import LanguageIcon from "@material-ui/icons/Language";
import FolderSpecialRoundedIcon from "@material-ui/icons/FolderSpecialRounded";

const BookmarkItem = props => {
  const [showBookmarks, setShowbookmarks] = useState(false);

  const showBookmarksMenu = () => {
    setShowbookmarks(!showBookmarks);
  };

  const onDragStart = (e, data, name) => {
    // Custom data transfer type to handle drops from Custom Bookmark Menu.
    if (name !== "folder") {
      e.dataTransfer.setData("application/uri-list", `${name}\r\n${data}`);
    } else {
      e.dataTransfer.setData("application/folder", JSON.stringify(data));
    }
  };

  const renderBookmarkItems = items => {
    return (
      <div className="bookmark-item">
        {items.map(i => (
          <a
            href={i.link[0]}
            target="_blank"
            rel="noopenner"
            onDragStart={e => onDragStart(e, i.link[0], i.name)}
            draggable="true">
            <LanguageIcon />
            {i.name.length > 20 ? `${i.name.slice(0, 20)}...` : i.name}
          </a>
        ))}
      </div>
    );
  };

  return (
    <div className="bookmark-menu-items" onClick={showBookmarksMenu}>
      <div
        className="bookmark-folder"
        onDragStart={e => onDragStart(e, props.bookmark, "folder")}
        draggable="true">
        <i
          className={
            !showBookmarks
              ? "fas fa-angle-right pointer-icon"
              : "fas fa-angle-down pointer-icon"
          }
        />{" "}
        {props.index === 0 ? (
          <i className="fas fa-star" />
        ) : (
          <FolderSpecialRoundedIcon
            style={{ marginRight: "5px", fontSize: "20px" }}
          />
        )}{" "}
        <div className={props.index !== 0 && "folder-name"}>
          {props.bookmark.folder}{" "}
          <i className="fas fa-share-square bookmark-share-icon" />
        </div>
      </div>
      {showBookmarks ? renderBookmarkItems(props.bookmark.bookmarks) : null}
    </div>
  );
};

export default BookmarkItem;
