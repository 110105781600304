import React from "react";

export default class Career extends React.Component {
    render() {
        return (
            <div className="investor-container">
                <div className="container">
                    <div className="greetings mb-3 mt-4">Dear Investors <br />Welcome to Listiee 🎉</div>
                    <div className="content mb-2">
                        If you are interested in investing upon us,
                        please visit form page by clicking the submit button and fill up the form.
                        We shall get back to you in 48 hours.
                        For any other issues and queries reach us out on <a href="mailto:assist@listiee.com" target="_top">assist[at]listiee.com</a>.
                    </div>
                    <div>
                        <a href="https://tinyurl.com/inv-form" style={{ color: 'inherit' }}><button className="large-btn success-btn">Submit</button></a>
                    </div>

                    <div className="investor-footer mt-4">
                        <div>Follow us on social media</div>
                        <ul>
                            <li><a href="https://twitter.com/listiee01"><i className="fab fa-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/listiee/"><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div >
            </div>
        )
    }
}