import React, { Component,Fragment } from "react";
import PasteModal from "./PasteModal";
import ErrorAlert from "./ErrorAlert";
import { getItemsAndDetailsArrayFromDataArray,handleCheckTitle } from "../Utils/helperFunctions";

class PasteBtnComponent extends Component {
  state = {
    showAlert: false,
    alertMessage: "",
    alertCode: null,
  };

  //Snackbar auto-hide method
  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false,
    });
  };

  showSnackBar = (message, code) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
      alertCode: code,
    });
  };

  onPasteSubmit = (e) => {
    e.preventDefault();
    let { items, details, listName } = this.props;

    console.log("items:", items);
    console.log("details:", details);

    const textString = e.target[0].value;
    const data = textString
      .split("\n")
      .map((elem) => elem.trim())
      .filter((elem) => elem.length > 0);


    if (listName.length <= 8 && items.length === 0) {
      /*
      CASE 1: New list : if there is no item in the list and no list name has been added added
      -> Take the first item and make it the list name
      -> Take the rest of the items and make it items
     */

      if (data.length < 2) {
        this.showSnackBar(
          "Invalid format! Please enter at lease two items!!",
          "error"
        );
        return;
      }

      const { newItems, newDetails } = getItemsAndDetailsArrayFromDataArray(
        data.slice(1, data.length),
        this.props.checkDuplicateInItems,
        this.props.aid
      );

      //changing listTitle:
      let listname = handleCheckTitle(data[0]);
      let evelistTitle = {
        target: {
          name: "listName",
          value: listname,
        },
      };

      this.props.onHandleChange(evelistTitle);

      this.props.updateItems([...items, ...newItems]);
      this.props.updateDetails([...details, ...newDetails]);
    } else {
      /*
      CASE 2: New list : if some items is already present in the list and list already has a name
      -> Make every item of the array a list item
     */
      if (data.length < 1) {
        this.showSnackBar(
          "Invalid format!, Please enter at lease one item!!",
          "error"
        );
        return;
      }

      const { newItems, newDetails } = getItemsAndDetailsArrayFromDataArray(
        data,
        this.props.checkDuplicateInItems,
        this.props.aid
      );
      this.props.updateItems([...items, ...newItems]);
      this.props.updateDetails([...details, ...newDetails]);
    }
  };

  render() {
    return (
      <Fragment>
        <ErrorAlert
          AutoHideSnackbar={this.AutoHideSnackbar}
          showAlert={this.state.showAlert}
          message={this.state.alertMessage}
          status={this.state.alertCode}
        />
        <PasteModal onPasteSubmit={this.onPasteSubmit} />
      </Fragment>
    );
  }
}

export default PasteBtnComponent;
