import React, { Component, Fragment } from 'react';
import { Modal } from 'reactstrap';
import Button from '@material-ui/core/Button';
import EmailOTPInput from '../Utils/EmailOTPInput';
import ErrorAlert from '../Nested/ErrorAlert';

export default class VerifyEmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: null,
      showAlert: false,
      alertCode: '',
      message: ''
    };
  }

  setParentState = data => {
    this.setState(data);
  };

  onSendOTP = async e => {
    e.preventDefault();

    const { otp } = this.state;
    const { aid, email } = this.props;
    // console.log(this.props.verify_email_type)
    if (this.state.otp.trim() === '' || this.state.otp.trim().length < 6) {
      console.log('Please enter Valid OTP', 'error');
    } else {
      console.log(this.state.otp);
      console.log(this.props.aid);

      const data = {
        aid,
        code: otp,
        email
      };

      await this.props.verifyEmailOTP(data, this.props.token);
      //console.log('checking', this.props.changeEmailMessage);
      if (this.props.changeEmailMessage === 'success') {
        //this.props.handleGoBack();
        this.props.toggleVerifyEmailModal();
        this.showSnackBar('success', 'Email Changed successfully');
        this.props.setParentState({
          email: '',
          password: ''
        });
        await this.props.fetchProfile(
          { aid: this.props.aid },
          this.props.token
        );
      } else {
        console.log('error');
      }
    }
  };

  timerFunction = () => {
    let elem = document.getElementById('timerDisplay');
    let timer = setInterval(() => {
      if (this.timeLeft === -1) {
        clearTimeout(timer);
        this.props.setParentState({ disabled: false });
        return;
      } else {
        elem.innerHTML = `Retry in: 00:${
          this.timeLeft >= 10 ? this.timeLeft : `0${this.timeLeft}`
        }`;
        this.timeLeft--;
      }
    }, 1000);
  };

  showSnackBar = (code, msg) => {
    this.setState({
      showAlert: true,
      alertCode: code,
      message: msg
    });
  };

  onResendOTP = async () => {
    await this.props.setParentState({
      timer: false,
      disabled: true,
      attempts: this.props.attempts - 1
    });
    const { otp } = this.state;
    const { aid, email } = this.props;

    const data = {
      aid,
      email
    };

    this.props.sendVerifyEmail(data, this.props.token);

    if (this.props.attempts <= 0) {
      this.props.setParentState({ disabled: true, timer: false });
    } else {
      this.timeLeft = 30;
      this.timerFunction();
    }
  };

  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false
    });
  };

  render() {
    const { toggleVerifyEmailModal, VerifyEmailModalVisible } = this.props;
    return (
      <Fragment>
        <ErrorAlert
          AutoHideSnackbar={this.AutoHideSnackbar}
          showAlert={this.state.showAlert}
          code={this.state.alertCode}
          message={this.state.message}
        />
        <Modal
          className={'custom-modal'}
          isOpen={VerifyEmailModalVisible}
          toggle={toggleVerifyEmailModal}
          autoFocus={true}
        >
          <Fragment>
            <div className={'modal_form otp-form mx-auto'}>
              <h5 className={'text-success otp-form__heading'}>
                A verification email has been sent. Please check your inbox.
              </h5>
              <EmailOTPInput
                onChange={otp => this.setParentState({ otp })}
                verify={this.onSendOTP}
              />
              {/* <p className={"text-error text-center mt-3"}>
                            {modalError ? modalError : ""}
                        </p> */}
              <div className="text-center">
              <Button
                className={'modal_form__button'}
                color='primary'
                variant={'contained'}
                type={'submit'}
                style={{ display: 'inline', width: '90px' }}
              >
                Verify
              </Button>
              {'   '}
              {this.props.disabled ? (
                <h5
                  className='text-danger otp-form__heading'
                  style={{ display: 'inline' }}
                  id='timerDisplay'
                ></h5>
              ) : (
                <Button
                  onClick={this.onResendOTP}
                  className={'modal_form__button'}
                  color='secondary'
                  variant={'contained'}
                  type={'submit'}
                  disabled={this.props.disabled}
                  style={{ display: 'inline', width: '110px' }}
                >
                  Resend
                </Button>
              )}
              </div>
            </div>
          </Fragment>
        </Modal>
      </Fragment>
    );
  }
}
